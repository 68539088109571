import React from 'react';
import classnames from 'classnames';

import HiddenInput, { Props as HiddenInputProps } from '@/components/Input/HiddenInput';

import style from './Toggle.sass';

export interface Props extends HiddenInputProps {
  align?: 'left' | 'right';
  block?: boolean;
  invalid?: boolean;
  renderToggle: (toggled: boolean, disabled: boolean) => React.ReactNode;
  onInputLabelClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}

const Toggle: React.SFC<Props> = ({
  align,
  block,
  className,
  children,
  invalid,
  renderToggle,
  onInputLabelClick,
  ...rest
}) => (
  <label
    htmlFor={rest.id}
    className={classnames(
      style.root,
      {
        [style.block]: block,
        [style[align] || style.left]: true,
        'cursor-default': rest.disabled,
        'cursor-pointer': !rest.disabled
      },
      className
    )}
    onClick={onInputLabelClick}
  >
    <div className={style.container}>
      <span className={style.toggle}>
        <HiddenInput {...rest} />
        {renderToggle(rest.checked, rest.disabled)}
      </span>

      <div className={style.content}>{children}</div>
    </div>
  </label>
);

export default Toggle;
