import { Page, UserAdd, UserDetails, UserListItem, UserRole, WorkspaceUser } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const inviteUser = (user: UserAdd) => authorizedApi.post<null>(`/users/invitations`, { users: [user] });

export const getAllUsers = (pageNumber: number, pageSize: number, roles?: UserRole[], keyword?: string) =>
  authorizedApi.get<Page<UserListItem>>(`/users`, { pageNumber, pageSize, roles, keyword });

export const getUserById = (id: string) => authorizedApi.get<UserDetails>(`/users/${id}`);

export const deleteUserById = (id: string) => authorizedApi.delete<null>(`/users/${id}`);

export const updateUserStatus = (id: String, active: boolean) =>
  authorizedApi.patch<UserDetails>(`/users/${id}`, { active });

export const inviteUsersToWorksapce = (workspaceId: string, emails: string[]) =>
  authorizedApi.post<WorkspaceUser[]>(`/workspaces/${workspaceId}/users`, { emails });

export const getUsersForWorkspace = (
  workspaceId: string,
  pageNumber: number,
  pageSize: number,
  options?: { [x: string]: any }
) => authorizedApi.get<Page<WorkspaceUser>>(`/workspaces/${workspaceId}/users`, { pageNumber, pageSize, ...options });

export const deleteUserFromWorkspace = (workspaceId: string, userId: string) =>
  authorizedApi.delete<null>(`/workspaces/${workspaceId}/users/${userId}`);

export const updateUserForWorkspace = (userId: string, params: any) =>
  new Promise((resolve) => setTimeout(() => resolve(true), 1000));
