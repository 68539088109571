export const testCasesList = [
  'tc100',
  'tc101',
  'tc102',
  'tc200',
  'tc201',
  'tc300',
  'tc301',
  'tc401',
  'tc402',
  'tc403',
  'tc404',
  'tc405',
  'tc406',
  'tc407',
  'tc408',
  'tc409',
  'tc410',
  'tc500',
  'tc501',
  'tc502',
  'summary'
];

export const headerCSVFile = ['Test Case', 'Result', 'Comments', 'Aditional Information'];

export const actionLogCSVFile = ['Time Stamp', 'Editor', 'State', 'Sensor', 'Command'];

export const monitoringHistoryCSVFile = ['Monitored Date', 'Status', 'Interval'];

export const monitoringHistoryCSVFileAll = ['Workspace Name', 'Monitored Date', 'Status', 'Interval'];
