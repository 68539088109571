import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';

import { UpsertWorkspaceFeature, WorkspaceFeature, WorkspaceFeatureCategory } from '@/domains';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import ModalHeader from '../ModalHeader';
import Typography from '@/components/Typography';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { FeatureCategorySelectWithValidation } from '@/components/Select/FeatureCategorySelect';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import { createWorkspaceFeature, updateWorkspaceFeature } from '@/services/api/workspace-features';

export interface Props extends InstanceProps {
  feature?: WorkspaceFeature;
  onSuccess?: () => any;
}

const schema: yup.ObjectSchema<UpsertWorkspaceFeature> = yup.object({
  name: yup
    .string()
    .required()
    .min(3),
  description: yup
    .string()
    .required()
    .min(10),
  shortDescription: yup
    .string()
    .required()
    .min(5),
  category: yup.mixed<WorkspaceFeatureCategory>().required()
});

const AddFeatureModal: React.FC<Props & WrappedComponentProps> = ({ onSuccess, feature, close, intl }) => {
  const text = translations.pages.featureManagement.tabs.features.addModal;

  const saveData = (values: UpsertWorkspaceFeature) => {
    if (feature) {
      return updateWorkspaceFeature(feature.id, values).then(() => {
        onSuccess();
        close();
      });
    } else {
      return createWorkspaceFeature(values).then(() => {
        onSuccess();
        close();
      });
    }
  };

  return (
    <Card className="md:w-[570px] p-6 mb-10">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close} className="mb-8">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.title} />
            </Typography>
          </ModalHeader>
        </Grid.Column>
        <Form
          id="edit-feature"
          schema={schema}
          initialValues={feature ? feature : {}}
          subscription={{
            dirty: true,
            pristine: true,
            submitting: true,
            submitError: true,
            values: true
          }}
          onSubmit={(data) => saveData(data)}
          className="w-full"
        >
          {({ submitError, submitting, dirty, values, hasValidationErrors, form: { change } }, id) => (
            <React.Fragment>
              <Form.Field
                is={FeatureCategorySelectWithValidation}
                id={`${id}-category`}
                name="category"
                label={intl.formatMessage({ id: translations.inputs.fields.featureCategory.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.fields.featureCategory.placeholder })}
                readOnly={submitting}
                required
                className="mb-4"
              />

              <Form.Field
                is={TextInputWithValidation}
                type="text"
                id={`${id}-name`}
                name="name"
                label={intl.formatMessage({ id: translations.inputs.fields.featureName.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.fields.featureName.placeholder })}
                readOnly={submitting}
                required
                className="mb-4"
              />

              <Form.Field
                is={TextInputWithValidation}
                type="text"
                id={`${id}-description`}
                name="description"
                label={intl.formatMessage({ id: translations.inputs.fields.featureDescription.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.fields.featureDescription.placeholder })}
                readOnly={submitting}
                required
                className="mb-4"
              />

              <Form.Field
                is={TextInputWithValidation}
                type="text"
                id={`${id}-shortDescription`}
                name="shortDescription"
                label={intl.formatMessage({ id: translations.inputs.fields.featureDescription.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.fields.featureDescription.placeholder })}
                readOnly={submitting}
                required
                className="mb-4"
              />

              <SubmitError error={submitError} className="mb-4" />

              <div className="w-full flex justify-end">
                <Button type="submit" appearance="red" form={id} loading={submitting} disabled={hasValidationErrors}>
                  <FormattedMessage id={text.submit} />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Grid.Row>
    </Card>
  );
};

export default injectIntl(AddFeatureModal);
