import RegisteredUsersChart from '@/components/Chart/RegisteredUsersChart';
import SensorChart from '@/components/Chart/SensorChart';
import { SessionContext } from '@/components/Context/Session/Session';
import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import { Me } from '@/domains/me';
import { Period } from '@/domains/sensor-status';
import { getUserMetrics } from '@/services/api/metrics';
import { getSignalStrengthMetrics } from '@/services/api/monitoring';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

export interface Props {
  workspaceId: string;
  intervals: Period;
  sensor: string;
}

const SensorGraph = ({ workspaceId, intervals, sensor }) => {
  const { value: signalStrength } = useAsync(
    () => getSignalStrengthMetrics(workspaceId, intervals.startDate, intervals.endDate, sensor),
    [workspaceId, intervals, sensor]
  );
  return (
    <If
      condition={!signalStrength && workspaceId}
      then={() => (
        <Loading visible={true} center className="h-full">
          <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
        </Loading>
      )}
      else={() => (
        <div className="flex flex-col">
          <React.Fragment>
            <SensorChart metrics={signalStrength} />
          </React.Fragment>
        </div>
      )}
    />
  );
};

export default SensorGraph;
