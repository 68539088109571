import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS, urls } from '@/constants';
import { translations } from '@/locale';

import Link from '@/components/Link';
import Grid from '@/components/Grid';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import SignInPage from './SignInPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ForgotPasswordLinkSentPage from './ForgotPasswordLinkSentPage';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpiredPage';
import ResetPasswordDonePage from './ResetPasswordDonePage';

import logo from '@/assets/logo.svg';
import signIn from './sign-in.jpg';

const Public: React.FC = () => (
  <Grid className="h-full">
    <Grid.Row className="h-full">
      <Grid.Column
        style={{ backgroundImage: `url(${signIn})` }}
        className="bg-no-repeat bg-center bg-cover w-0 md:w-6/12"
      />

      <Grid.Column className="flex flex-col justify-center items-center p-10 w-full md:w-6/12">
        <Link to={urls.home} className="mt-auto mb-10">
          <img src={logo} className="inline-block w-[178px] h-[40px]" />
        </Link>

        <div className="w-full xs:w-8/12 lg:w-6/12">
          <Switch>
            <Route exact path={urls.signIn} component={SignInPage} />

            <Route exact path={urls.forgotPassword.main} component={ForgotPasswordPage} />

            <Route exact path={urls.forgotPassword.sent} component={ForgotPasswordLinkSentPage} />

            <Route exact path={urls.resetPassword.main} component={ResetPasswordPage} />

            <Route exact path={urls.resetPassword.expired} component={ResetPasswordLinkExpired} />

            <Route exact path={urls.resetPassword.done} component={ResetPasswordDonePage} />

            <ConsumeRedirectRoute
              render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.signIn} />} />}
            />
          </Switch>
        </div>

        <div className="mt-auto pt-10 text-gray">
          <div className="text-center text-sm mb-2">
            <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer" className="hover:underline mr-3">
              <FormattedMessage id={translations.pages.public.pp} />
            </a>

            <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer" className="hover:underline">
              <FormattedMessage id={translations.pages.public.tc} />
            </a>
          </div>

          <div className="text-xs text-center">
            <FormattedMessage
              id={translations.pages.public.allRightsReserved}
              values={{ year: new Date().getUTCFullYear() }}
            />
          </div>
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default Public;
