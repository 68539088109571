import React, { PureComponent } from 'react';
import { Pie } from 'react-chartjs-2';

interface Props {
  className?: string;
  items: { title: string; value: number }[];
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  }
};

export default class PieChart extends PureComponent<Props> {
  render() {
    const { className, items } = this.props;

    const data = {
      labels: items.map((e) => e.title),
      datasets: [
        {
          data: items.map((e) => e.value),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ]
        }
      ]
    };

    return <Pie className={className} data={data} options={options} />;
  }
}
