import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { WorkspaceState, WorkspaceStatus, WorkspaceVisibility } from '@/domains';

import Badge from './Badge';
import { workspaceVisibilityMap } from '@/util';

const workspaceStatusToClassNameMap: Record<WorkspaceStatus | WorkspaceState | WorkspaceVisibility, string> = {
  [WorkspaceStatus.Active]: 'text-[#2DCA73] bg-[#2DCA73] bg-opacity-10',
  [WorkspaceStatus.Inactive]: 'text-[#FFFFFF] bg-[#7E858E] bg-opacity-40',
  [WorkspaceState.Online]: 'text-[#2DCA73] bg-[#2DCA73] bg-opacity-10',
  [WorkspaceState.Offline]: 'text-[#FFFFFF] bg-[#FF0020] bg-opacity-70',
  [WorkspaceState.NOT_MONITORED]: 'text-[#FFFFFF] bg-[#7E858E] bg-opacity-40',
  [WorkspaceVisibility.Hidden]: 'text-[#FFFFFF] bg-[#7E858E] bg-opacity-40',
  [WorkspaceVisibility.Visible]: 'text-[#2DCA73] bg-[#2DCA73] bg-opacity-10'
};

export interface Props {
  status: WorkspaceStatus | WorkspaceState | WorkspaceVisibility;
  className?: string;
}

const WorkspaceStatusBadge = ({ status, className }: Props) => {
  return (
    <Badge className={classnames(workspaceStatusToClassNameMap[status], className)}>
      <FormattedMessage id={translations.domains.status[status]} />
    </Badge>
  );
};

export default WorkspaceStatusBadge;
