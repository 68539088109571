import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import Typography from '@/components/Typography';
import { CreateStatus } from './CreateStatus/CreateStatus';
import If from '@/components/If/If';
import { WorkspaceListItem } from '@/domains/workspace/workspace-list-item';
import { WorkspaceBoothStatus, WorkspaceBoothStatusResponse } from '@/domains/workspace-status';
import Badge from '@/components/Badge/Badge';
import Modal from '@/components/Modal/Modal';
import { useIntl } from 'react-intl';
import { translations } from '@/locale/messages';
import DownloadMonitoringModal from '@/components/Modal/DownloadMonitoringModal/DownloadMonitoringModal';

export interface Props {
  workspace: WorkspaceListItem;
  contentMonitoring: WorkspaceBoothStatusResponse<WorkspaceBoothStatus>;
  indexMonitor: number;
}

export const WorkspaceStatusMonitoring = ({ workspace, contentMonitoring, indexMonitor, numberElementsMonitored }) => {
  const [monitoringBooth, setMonitoringBooth] = useState([]);
  const [monitorWorkspace, setMonitorWorkspace] = useState(false);
  useEffect(() => {
    if (contentMonitoring != undefined) {
      setMonitoringBooth(contentMonitoring.content);
      setMonitorWorkspace(contentMonitoring.monitor);
    } else setMonitoringBooth([]);
  }, [contentMonitoring]);
  const intl = useIntl();

  return (
    <If
      condition={monitorWorkspace}
      then={() => (
        <div className="flex w-full">
          <Card.Row key={contentMonitoring.id + '-row'} className="w-full flex">
            <Grid.Column>
              <Grid.Row className="flex p-3 w-full">
                <Badge.WorkspaceStatusBadge status={workspace.state} />

                <Typography is="h1" className="pl-3">
                  {workspace.name}
                </Typography>

                <Modal
                  modal={DownloadMonitoringModal}
                  message={intl.formatMessage({ id: translations.placeholders.downloadMonitoringReport })}
                  messageInfo={intl.formatMessage({ id: translations.placeholders.downloadMonitoringReportInfo })}
                  workspaceId={workspace.id}
                  workspaceName={workspace.name}
                  confirmMessage={intl.formatMessage({ id: translations.placeholders.download })}
                  cancelMessage={intl.formatMessage({ id: translations.placeholders.cancel })}
                >
                  {({ open }) => (
                    <AiOutlineDownload type="button" className="text-xl ml-2 text-blue-light" onClick={open} />
                  )}
                </Modal>

                <Typography is="h1" className="ml-auto">
                  {contentMonitoring.downtime < 0
                    ? '0 % uptime'
                    : (contentMonitoring.downtime * 100).toFixed(2) + '% uptime'}
                </Typography>
              </Grid.Row>

              <div key={workspace.id + 'status'} className="flex justify-between gap-5  p-3 w-auto">
                {Object.values(monitoringBooth).map((item, indexElement) => (
                  <CreateStatus
                    key={item.monitoredAt}
                    monitor={item}
                    indexMonitor={indexMonitor}
                    indexElement={indexElement}
                    numberElementsMonitored={numberElementsMonitored}
                  />
                ))}
              </div>
            </Grid.Column>
          </Card.Row>
        </div>
      )}
    />
  );
};
export default WorkspaceStatusMonitoring;
