import React from 'react';
import { withRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import Helmet from 'react-helmet';

import 'tailwindcss/tailwind.css';
import './style/base.sass';

import { urls } from '@/constants';

import ScrollToTop from '@/components/ScrollToTop';
import { SessionConsumer } from '@/components/Context/Session';
import Loading from '@/components/Loading';
import If from '@/components/If';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import RedirectWithPersist from '@/components/Navigation/RedirectWithPersist';
import HomePage from '@/page/HomePage';
import Public from '@/page/Public';

export interface Props extends RouteComponentProps {}

const App: React.FC<Props> = () => {
  return (
    <ScrollToTop>
      <SessionConsumer>
        {({ loading, error, session, me }) => (
          <React.Fragment>
            <Helmet defaultTitle="Nooka Space" titleTemplate="%s - Nooka Space" />

            <Loading visible={loading} fixed center overlay>
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>

            <If
              condition={!loading}
              then={() => (
                <If
                  condition={!error}
                  then={() => (
                    <If
                      condition={session && me}
                      then={() => (
                        <Switch>
                          <Route path={urls.home} component={HomePage} />

                          <ConsumeRedirectRoute render={() => <Redirect to={urls.home} />} />
                        </Switch>
                      )}
                      else={() => (
                        <Switch>
                          <Route path={urls.home} component={Public} />

                          <ConsumeRedirectRoute render={() => <RedirectWithPersist to={urls.signIn} />} />
                        </Switch>
                      )}
                    />
                  )}
                />
              )}
            />
          </React.Fragment>
        )}
      </SessionConsumer>
    </ScrollToTop>
  );
};

export default hot(withRouter(App));
