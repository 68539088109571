import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';
import { MIN_ASSIGNABLE_CREDITS } from '@/constants';
import { assignCredits } from '@/services/api/wallets';

import { InstanceProps } from '@/components/Modal';
import ModalHeader from '@/components/Modal/ModalHeader';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import Spacer from '@/components/Spacer';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';

export interface Props extends InstanceProps {
  userId: string;
  onSuccess?: () => void;
}

const fields = translations.inputs.fields;
const schema: yup.ObjectSchema<{ credits: number }> = yup
  .object({
    credits: yup
      .number()
      .min(MIN_ASSIGNABLE_CREDITS)
      .label(fields.assignCredits.errorLabel)
      .required()
  })
  .required();

const text = translations.pages.users.details;

const AssignCreditsModal = ({ userId, onSuccess, close }: Props) => {
  const intl = useIntl();

  const handleSubmit = (values) => assignCredits(userId, values.credits).then(() => onSuccess && onSuccess());

  return (
    <Card className="w-[400px] p-6 mb-10">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close} className="mb-8">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.assignCredits} />
            </Typography>
          </ModalHeader>

          <Form
            id="assign-credits"
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, hasValidationErrors: true, dirty: true }}
            onSubmit={handleSubmit}
          >
            {({ submitError, submitting, dirty, values, hasValidationErrors }, id) => (
              <React.Fragment>
                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-credits`}
                  name="credits"
                  type="number"
                  min={MIN_ASSIGNABLE_CREDITS}
                  label={intl.formatMessage({ id: fields.assignCredits.label })}
                  placeholder={intl.formatMessage({ id: fields.assignCredits.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={2} />

                <SubmitError error={submitError} />

                <div className="w-full flex justify-end">
                  <Button type="submit" appearance="red" form={id} loading={submitting} disabled={hasValidationErrors}>
                    <FormattedMessage id={text.assignButton} />
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default AssignCreditsModal;
