import classnames from 'classnames';
import React from 'react';

import { WorkspaceFeature as WorkspaceFeatureDomain } from '@/domains';

import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

export interface Props {
  feature: WorkspaceFeatureDomain;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
}

const WorkspaceFeature: React.FC<Props> = ({ onEdit, onDelete, feature, className }) => (
  <div className={classnames('w-[325px] flex flex-row', className)}>
    <img src={feature.iconUrl} className="w-[26px] h-[26px] inline-block" alt="icon" />

    <div className="ml-5 w-full">
      <div className="flex flex-row justify-between align-middle mb-2">
        <Typography is="span" type="small-header" className="font-bold">
          {feature.name}
        </Typography>
        <div className="space-x-2">
          {onEdit && (
            <Button onClick={onEdit}>
              <Icon type="edit" size="text-sm" />
            </Button>
          )}

          {onDelete && (
            <Button onClick={onDelete}>
              <Icon type="close" size="text-sm" />
            </Button>
          )}
        </div>
      </div>

      <Typography is="p" type="regular" className="text-blue-dark">
        {feature.description}
      </Typography>
    </div>
  </div>
);

export default WorkspaceFeature;
