import React from 'react';
import classnames from 'classnames';

export interface Props {
  open: () => void;
  close: () => void;
  className?: string;
}

const InfoStatusButton = ({ open, close, className }: React.PropsWithChildren<Props>) => (
  <span
    className={classnames(
      'absolute ml-1 w-3 text-center leading-3 bg-gray rounded-full bold text-[0.625rem] lowercase text-gray-lightest transition group-hover:bg-red hover:cursor-pointer flex',
      className
    )}
    onMouseOver={open}
    onClick={close}
  ></span>
);

export default InfoStatusButton;
