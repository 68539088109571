import { UpsertWorkspaceFeature, WorkspaceFeatureItem } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getWorkspaceFeatures = () => authorizedApi.get<WorkspaceFeatureItem[]>(`/workspace-features`);

export const createWorkspaceFeature = (data: UpsertWorkspaceFeature) =>
  authorizedApi.post<WorkspaceFeatureItem>(`/workspace-features`, data);

export const updateWorkspaceFeature = (id: string, data: UpsertWorkspaceFeature) =>
  authorizedApi.patch<WorkspaceFeatureItem>(`/workspace-features/${id}`, data);

export const deleteWorkspaceFeature = (id: string) => authorizedApi.delete(`/workspace-features/${id}`);
