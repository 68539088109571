import classNames from 'classnames';
import React from 'react';

export type ColSize = 1 | 2 | 3 | 4 | 6 | 8;

const colSizeToClassName = (colSize: ColSize) => {
  switch (colSize) {
    case 1:
      return 'w-1/12';
    case 2:
      return 'w-1/6';
    case 3:
      return 'w-1/4';
    case 4:
      return 'w-1/3';
    case 6:
      return 'w-1/2';
    case 8:
      return 'w-2/3';
  }
};

interface RowContainerProps {
  className?: string;
}

interface ItemProps {
  className?: string;
  col?: ColSize;
}

const RowContainer = ({ children, className }: React.PropsWithChildren<{}> & RowContainerProps) => {
  return <div className={classNames('flex -mx-2', className)}>{children}</div>;
};

RowContainer.Item = ({ children, className, col }: React.PropsWithChildren<{}> & ItemProps) => {
  return <div className={classNames('px-2', col ? colSizeToClassName(col) : 'w-full', className)}>{children}</div>;
};

export default RowContainer;
