import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { fullName } from '@/util';
import { Magnitude, urls } from '@/constants';
import { translations } from '@/locale';
import { AdminBookingListItemDto, CleaningDepositStatus } from '@/domains';
import { deleteBooking, getPaginatedBookings, retainCleaningDepositFee } from '@/services/api/bookings';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import Price from '@/components/Price';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Refresh from '@/components/Refresh';
import BookingListItemDropdown from '@/components/Dropdown/BookingListItemDropdown';
import Info from '@/components/Info';
import Link from '@/components/Link';
import SearchInput from '@/components/SearchInput/SearchInput';

const text = translations.pages.bookings.list;

const headerList: { name?: string; sm: Magnitude }[] = [
  { name: text.table.header.customer, sm: 2 },
  { name: text.table.header.customerType, sm: 2 },
  { name: text.table.header.location, sm: 2 },
  { name: text.table.header.nookaName, sm: 2 },
  { name: text.table.header.date, sm: 2 },
  { name: text.table.header.timeInterval, sm: 2 },
  { name: text.table.header.deposit, sm: 2 },
  { name: text.table.header.billingInformation, sm: 2 },
  { name: text.table.header.cost, sm: 1 },
  { sm: 1 }
];

const BookingsListPage: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [state, setState] = React.useState({
    keyword: ''
  });
  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>

      <SearchInput
        id="users-search"
        className="w-[350px] pb-5"
        placeholder={intl.formatMessage({ id: translations.placeholders.searchByEmail })}
        onChange={(keyword) => {
          setState({ keyword: keyword });
        }}
      />

      <Refresh>
        {({ key, refresh }) => (
          <InfiniteScrollableTable
            key={`${key}-${state.keyword}`}
            source={(page, number) => getPaginatedBookings(page, number, undefined, state.keyword)}
            empty={() => (
              <Placeholder className="mt-12">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.empty} />
                </Typography>
              </Placeholder>
            )}
            renderHeader={() => (
              <Table.Header className="pr-1">
                {headerList.slice(0, -2).map((headerItem) => (
                  <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                    <Typography is="span" type="regular" className="font-bold uppercase text-gray">
                      {headerItem.name && <FormattedMessage id={headerItem.name} />}
                    </Typography>
                  </Table.Data>
                ))}

                <Table.Data header sm={headerList[7].sm} key={headerList[8].name}>
                  <Info message={intl.formatMessage({ id: translations.misc.revenueInfo })}>
                    {({ open, close }) => (
                      <Typography
                        is="span"
                        type="regular"
                        className="font-bold uppercase text-gray group hover:cursor-default"
                        onMouseOver={open}
                        onMouseOut={close}
                      >
                        <FormattedMessage id={headerList[8].name} />

                        <Info.Button open={open} close={close} />
                      </Typography>
                    )}
                  </Info>
                </Table.Data>
              </Table.Header>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((booking: AdminBookingListItemDto) => (
                  <Table.Row key={booking.id} className="pr-1">
                    <Table.Data sm={headerList[0].sm}>
                      <Typography is="span">
                        <div className="flex items-center">
                          <UserProfileImage
                            firstName={booking.user.firstName ? booking.user.firstName : '-'}
                            lastName={booking.user.lastName ? booking.user.lastName : '-'}
                            className="w-8 h-8 min-w-[2rem] rounded-md"
                          />

                          <Link to={urls.users.detail.get(booking.user.id)} appearance="blue">
                            <Typography is="span" className="ml-3">
                              {booking.user.firstName
                                ? fullName(booking.user.firstName, booking.user.lastName)
                                : booking.user.phoneNumber}
                            </Typography>
                          </Link>
                        </div>
                      </Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[1].sm}>
                      <Typography is="span">
                        <FormattedMessage id={translations.domains.userRole[booking.user.type]} />
                      </Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[2].sm}>
                      {translations.countries[booking.workspace.country] && (
                        <Typography is="span">
                          {booking.workspace.city},&nbsp;
                          <FormattedMessage id={translations.countries[booking.workspace.country]} />
                        </Typography>
                      )}
                    </Table.Data>

                    <Table.Data sm={headerList[3].sm}>
                      <Typography is="span">{booking.workspace.name}</Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[4].sm}>
                      <FormattedDate value={booking.startDate} day="2-digit" month="long" year="numeric" />
                    </Table.Data>

                    <Table.Data sm={headerList[5].sm} className="break-all">
                      <FormattedMessage
                        id={translations.misc.range}
                        values={{
                          start: intl.formatTime(booking.startDate, { hourCycle: 'h23' }),
                          end: intl.formatTime(booking.endDate, { hourCycle: 'h23' })
                        }}
                      />
                    </Table.Data>

                    <Table.Data sm={headerList[6].sm}>
                      {booking.cleaningDepositStatus ? (
                        <FormattedMessage
                          id={translations.domains.cleaningDepositStatus[booking.cleaningDepositStatus]}
                        />
                      ) : (
                        '-'
                      )}
                    </Table.Data>

                    <Table.Data sm={headerList[7].sm}>
                      <Typography is="span">
                        {booking.billingInformation ? (
                          <FormattedMessage
                            id={
                              booking.billingInformation.type
                                ? translations.domains.billingInformation[booking.billingInformation.type]
                                : translations.domains.billingInformation['PERSONAL']
                            }
                          />
                        ) : (
                          <FormattedMessage id={translations.domains.billingInformation['PERSONAL']} />
                        )}
                      </Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[7].sm}>
                      <Price value={booking.cost} />
                    </Table.Data>

                    <Table.Data sm={headerList[9].sm}>
                      <BookingListItemDropdown
                        onDeleteConfirm={() => deleteBooking(booking.id).then(refresh)}
                        {...(booking.cleaningDepositStatus === CleaningDepositStatus.StandBy && {
                          onRetainDepositClick: () => retainCleaningDepositFee(booking.id).then(refresh)
                        })}
                      />
                    </Table.Data>
                  </Table.Row>
                ))}
              </React.Fragment>
            )}
          </InfiniteScrollableTable>
        )}
      </Refresh>
    </div>
  );
};

export default injectIntl(BookingsListPage);
