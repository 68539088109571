import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import Card from '@/components/Card';
import Typography from '@/components/Typography';

export interface Props {
  IconType: string;
  title: string;
  className?: string;
}

const RouterCard = ({ IconType, title, className, children }: PropsWithChildren<Props>) => {
  return (
    <Card className={classnames('w-full p-4', className)}>
      <img src={IconType} />
      <div className="mt-2 w-full flex-1">
        <dl>
          <Typography is="dt" type="sm" className="text-gray-dark">
            {title}
          </Typography>

          <Typography is="dd" type="regular" className="text-gray-darkest">
            <div className="text-lg font-medium text-gray-900">{children}</div>
          </Typography>
        </dl>
      </div>
    </Card>
  );
};

export default RouterCard;
