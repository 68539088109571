import { WorkspaceState } from '@/domains';
import { WorkspaceBoothStatus } from '@/domains/workspace-status';

export const statusMessage = (monitor: WorkspaceBoothStatus) => {
  switch (monitor.status) {
    case WorkspaceState.NOT_MONITORED:
      return monitor.monitoredAt + ' Not monitored';
    case WorkspaceState.Online:
      return monitor.monitoredAt + ' OPERATIONAL';
    case WorkspaceState.Offline:
      if (monitor.downtimeIntervals.length == 0) return monitor.monitoredAt + ' OPERATIONAL';
      if (monitor.downtimeIntervals.length == 1 && monitor.downtimeIntervals[0] === '24h')
        return `${monitor.monitoredAt} \nDowntime for  ${monitor.downtimeIntervals[0]}`;
      else if (monitor.downtimeIntervals[0] === 'ONLINE') return monitor.monitoredAt + ' OPERATIONAL';
      else return `${monitor.monitoredAt} \nDowntime intervals:\n ${monitor.downtimeIntervals.join(`\n`)}`;
  }
};

export const setWorkspacesStatusPrevDay = (
  setActivationPrevDay,
  setInactivePrevDay,
  activePrevDay: number,
  inactivePrevDay: number
) => {
  setActivationPrevDay(activePrevDay);
  setInactivePrevDay(inactivePrevDay);
};
