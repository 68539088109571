import React from 'react';
import classnames from 'classnames';

type Type = 'header' | 'subheader' | 'large-header' | 'small-header' | 'regular' | 'sm' | 'xs';

const style: Record<Type, string> = {
  header: 'text-3xl',
  subheader: 'text-2xl',
  'large-header': 'text-xl',
  'small-header': 'text-lg',
  regular: 'text-base',
  sm: 'text-sm',
  xs: 'text-xs'
};

export interface Props<T> {
  is: React.ElementType<T>;
  type?: Type;
  className?: string;
  [x: string]: any;
}

export default class Typography<T extends any> extends React.PureComponent<Props<T>> {
  render() {
    const { is: Component, type = 'regular', className, ...rest } = this.props;

    return (
      // @ts-ignore
      <Component {...rest} className={classnames(style[type], className)} />
    );
  }
}
