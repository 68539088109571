import React from 'react';
import classnames from 'classnames';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { Calendar as BigCalendar, CalendarProps, dateFnsLocalizer } from 'react-big-calendar';

const locales = {
  'en-US': require('date-fns/locale/en-US')
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date, options?) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales
});

import CalendarToolbar from './CalendarToolbar';
import DayEventWrapper from './DayEventWrapper';
import CalendarMonthEventWrapper from './CalendarMonthEventWrapper';

import './Calendar.sass';

export interface Props
  extends Omit<CalendarProps, 'views' | 'components' | 'localizer' | 'startAccessor' | 'endAccessor'> {}

const Calendar = ({ events, className, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <BigCalendar
      components={{
        toolbar: CalendarToolbar,
        month: {
          eventWrapper: CalendarMonthEventWrapper
        } as any,
        week: {
          event: (props) => <DayEventWrapper view="month" {...props} />
        } as any,
        day: {
          event: (props) => <DayEventWrapper view="day" {...props} />
        } as any
      }}
      views={['month', 'week', 'day']}
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      className={classnames(className)}
      {...rest}
    />
  );
};

Calendar.Toolbar = CalendarToolbar;

export default Calendar;
