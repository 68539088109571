import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { urls } from '@/constants';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import { TestReports } from '@/page/Testing/TestingReportsPage/TestReports/TestReports';

const TestingReportsPage: React.FC = () => (
  <Switch>
    <Route exact path={urls.testingReports.list} component={TestReports} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.testingReports.list} />} />
  </Switch>
);

export default TestingReportsPage;
