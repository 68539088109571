import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import { urls } from '@/constants';

import Typography from '@/components/Typography';
import TabbedContainer from '@/components/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import WorkspaceFeatureTemplatesPage from './WorkspaceFeatureTemplatesPage';
import WorkspaceFeaturesPage from './WorkspaceFeaturesPage';

const text = translations.pages.featureManagement;

const WorkspaceFeatures: React.FC<WrappedComponentProps> = ({ intl }) => (
  <div className="m-8">
    <div className="flex justify-between mb-8">
      <Typography is="h1" type="header" className="font-bold text-gray-darkest">
        <FormattedMessage id={text.title} />
      </Typography>
    </div>

    <TabbedContainer resourceId="">
      <Tab title={intl.formatMessage({ id: text.tabs.features.title })} to={urls.features.list}>
        <WorkspaceFeaturesPage />
      </Tab>

      <Tab title={intl.formatMessage({ id: text.tabs.templates.title })} to={urls.features.templates}>
        <WorkspaceFeatureTemplatesPage />
      </Tab>
    </TabbedContainer>
  </div>
);

export default injectIntl(WorkspaceFeatures);
