import React, { useState } from 'react';

import minusIcon from './minusIcon.svg';
import Typography from '@/components/Typography';
import Icon from '../Icon';
import TextInput from '../Input/TextInput';

type Position = 'start' | 'center';
type Type = 'credit' | 'euro';
interface Props {
  creditSelected: number | string;
  step: number;
  handleSetCreditSelected: (value: number | string) => void;
  className: string;
  position: Position;
  type: Type;
}

const CreditSelector = ({ creditSelected, handleSetCreditSelected, className, position, step, type }: Props) => {
  const handleIncrease = () => {
    if (typeof creditSelected === 'string') {
      handleSetCreditSelected(step);
    } else {
      const number = creditSelected + step;
      handleSetCreditSelected(number);
    }
  };

  const handleDecrease = () => {
    if (typeof creditSelected === 'string') {
      handleSetCreditSelected(step);
    } else {
      if (creditSelected > 10) {
        const number = creditSelected - step;
        handleSetCreditSelected(number);
      }
    }
  };

  const handleOnChange = (e) => {
    if (typeof e.target.value === 'string' && e.target.value != '') {
      handleSetCreditSelected(parseInt(e.target.value));
    } else {
      handleSetCreditSelected(e.target.value);
    }
  };

  return (
    <div className={`flex justify-${position} items-center`}>
      <div
        className={`flex justify-evenly items-center border border-solid border-gray-lighter rounded-full ${className}`}
      >
        <div className="py-1 cursor-pointer" onClick={() => handleDecrease()}>
          <img src={minusIcon} width={20} />
        </div>
        <Typography is="span" type="regular" className="font-bold text-gray-darkest flex justify-center py-1 w-1/2">
          {type === 'euro' ? '€' : ''}
          <TextInput
            onChange={(e) => handleOnChange(e)}
            value={creditSelected}
            id={'' + step}
            className={'w-3/5'}
            placeholder={creditSelected.toString()}
            typeOfInput={'credit'}
          />
          {type === 'credit' ? 'tokens' : ''}
        </Typography>

        <div className="py-1 cursor-pointer" onClick={() => handleIncrease()}>
          <Icon type="plus" className="text-base leading-none" />
        </div>
      </div>
    </div>
  );
};

export default CreditSelector;
