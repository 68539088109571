import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import PromoCreditsPage from './PromoCreditsPage';
import AssignedCreditsPage from './AssignedCreditsPage';

const PromoCredits: React.FC = () => (
  <Switch>
    <Route exact path={urls.promoCredits.assignedCredits} component={AssignedCreditsPage} />

    <Route path={urls.promoCredits.list} component={PromoCreditsPage} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.promoCredits.list} />} />
  </Switch>
);

export default PromoCredits;
