import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import { Image } from '@/domains';
import { translations } from '@/locale';
import { generateImageId } from '@/util';

import FileUpload, { Props as FileUploadProps } from '@/components/FileUpload';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { withValidation } from '@/components/hoc/withValidation';

import ImageUploadGalleryItem from './ImageUploadGalleryItem';

export interface Props extends Pick<FileUploadProps, 'onBlur'> {
  images: Image[];
  setImages: (images: Image[]) => void;
  empty?: () => any;
  onImageUploaded: (images: Image[]) => void;
  loading?: boolean;
  className?: string;
}

const readAsDataUrl = (file: File) =>
  new Promise<Image>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      resolve({ id: generateImageId('l'), url: reader.result as string, file });
    });
  });

const ImageGalleryUpload: React.FC<Props> = (props) => {
  const { images, setImages, empty, onImageUploaded, loading, className, onBlur } = props;

  const moveImage = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = images[dragIndex];
      setImages(
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      );
    },
    [images]
  );

  return (
    <div className={classnames('block', className)}>
      <div className="relative flex flex-col bg-opacity-40 border border-solid border-gray-light border-opacity-50 rounded-xl px-4 pt-6 pb-10">
        <div className="flex justify-center mb-6">
          <FileUpload
            accept="image/x-png,image/jpeg"
            onChange={(files: FileList) => {
              Promise.all(Object.values(files).map(readAsDataUrl)).then(onImageUploaded);
            }}
            multiple
            onBlur={onBlur}
          >
            <Button is="div" appearance="ghost">
              <Icon type="upload" className="text-base leading-none pr-2" />

              <FormattedMessage id={translations.inputs.photoGallery.upload} />
            </Button>
          </FileUpload>
        </div>

        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className="min-h-[200px]">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          )}
          else={() => (
            <If
              condition={images.length === 0}
              then={empty}
              else={() => (
                <ul className="flex flex-wrap">
                  {images.map((image, index) => (
                    <ImageUploadGalleryItem
                      id={index}
                      key={index}
                      index={index}
                      image={image}
                      onImageDeleted={() => {
                        setImages(images.filter((img) => img.id !== image.id));
                      }}
                      moveImage={moveImage}
                    />
                  ))}
                </ul>
              )}
            />
          )}
        />
      </div>
    </div>
  );
};

export const ImageGalleryUploadWithValidation = withValidation(ImageGalleryUpload);
export default ImageGalleryUpload;
