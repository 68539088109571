import React, { ReactElement } from 'react';

import TextInput, { Props as TextInputProps } from '@/components/Input/TextInput';
import Icon from '@/components/Icon';
import Typography from '@/components/Typography';
import Button from '@/components/Button';

interface TagRenderProps {
  tag: string;
  index: number;
  removeTag: (index: number) => unknown;
}

interface Props extends Omit<TextInputProps, 'value' | 'onChange'> {
  value?: string;
  inputButton?: string;
  inputClassName?: string;
  inputHeight?: string;
  tagList?: string[];
  onChange: (tagList: string[]) => unknown;
  generateTags?: (inputValue: string) => string[];
  renderTag?: (tagRenderProps: TagRenderProps) => ReactElement;
}

const TagInput = ({
  value = '',
  inputButton,
  inputClassName = '',
  inputHeight = 'min-h-[170px]',
  tagList = [],
  onChange,
  generateTags,
  renderTag,
  ...rest
}: Props) => {
  const [inputValue, setInputValue] = React.useState(value);

  const addTags = (newTags: string[]) => {
    onChange([...tagList, ...newTags]);
  };

  const removeTag = (index: number) => {
    const newTagList = [...tagList];
    newTagList.splice(index, 1);
    onChange(newTagList);
  };

  const addValidTagsFromInputValue = () => {
    if (inputValue !== '') {
      const inputTags = generateTags ? generateTags(inputValue) : [inputValue];
      if (inputTags.length) {
        addTags(inputTags);
        setInputValue('');
      }
    }
  };

  const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        addValidTagsFromInputValue();
        break;
      case 'Backspace':
        if (inputValue === '' && tagList.length > 0) {
          removeTag(tagList.length - 1);
        }
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="relative">
        <TextInput
          inputClassName={inputClassName}
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          {...rest}
        />

        {inputButton && (
          <Button
            onClick={addValidTagsFromInputValue}
            disabled={inputValue === ''}
            className="absolute right-0 top-1/2 transform -translate-y-1/2"
            type="button"
            appearance="transparent"
          >
            {inputButton}
          </Button>
        )}
      </div>

      <div className={inputHeight}>
        <div className="flex flex-wrap mt-4">
          {tagList.map((tag, index) =>
            renderTag ? (
              renderTag({ tag, index, removeTag })
            ) : (
              <div
                key={`${tag}-${index}`}
                className="flex items-center bg-gray-lighter h-full px-2 py-1 mr-2 mb-2 rounded-sm"
              >
                <Typography is="span" type="xs">
                  {tag}
                </Typography>

                <Icon
                  type="close"
                  size="text-xs"
                  className="ml-1 hover:cursor-pointer"
                  onClick={() => removeTag(index)}
                />
              </div>
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TagInput;
