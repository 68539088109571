import { authorizedApi } from '@/services/network/authorized-api';

import { UpsertWorkspaceFeatureTemplate, WorkspaceFeatureTeamplate } from '@/domains/workspace-feature-template';

export const getWorkspaceFeatureTemplates = () =>
  authorizedApi.get<WorkspaceFeatureTeamplate[]>(`/workspace-feature-templates`);

export const createFeatureTemplate = (data: UpsertWorkspaceFeatureTemplate) =>
  authorizedApi.post<WorkspaceFeatureTeamplate>(`/workspace-feature-templates`, data);

export const updateFeatureTemplate = (id: string, data: UpsertWorkspaceFeatureTemplate) =>
  authorizedApi.patch<WorkspaceFeatureTeamplate>(`/workspace-feature-templates/${id}`, data);

export const deleteFeatureTemplate = (id: string) => authorizedApi.delete(`/workspace-feature-templates/${id}`);
