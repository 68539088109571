import * as yup from 'yup';
import { isAfter, parse } from 'date-fns';

import { translations } from '@/locale';
import { shortStringSchema } from '@/validation';
import { PromoCodeEditForm, PromoCodeCreationForm, PromoCodeNumberOfUseType } from '@/domains';

export const promoCodeCreateOrEditSchema: yup.ObjectSchema<PromoCodeCreationForm | PromoCodeEditForm> = yup
  .object({
    userId: yup
      .string()
      .label(translations.inputs.fields.nookian.label)
      .nullable()
      .notRequired(),
    workspaceIds: yup
      .array()
      .of(yup.string())
      .label(translations.inputs.fields.nooka.label)
      .nullable()
      .notRequired(),
    code: shortStringSchema
      .min(4)
      .label(translations.inputs.fields.promoCode.label)
      .required(),
    discountPercentage: yup
      .number()
      .positive()
      .max(100)
      .label(translations.inputs.fields.discountPercentage.label)
      .required(),
    numberOfUse: yup
      .mixed<PromoCodeNumberOfUseType>()
      .label(translations.inputs.fields.numberOfUses.label)
      .required(),
    maximumHourLimit: yup
      .number()
      .positive()
      .max(24)
      .label(translations.inputs.fields.timeLimitInHours.label)
      .required(),
    limitUsagePerUser: yup
      .number()
      .positive()
      .label(translations.inputs.fields.numberOfUsesPerUser.label)
      .nullable(),
    limitUsage: yup
      .number()
      .positive()
      .label(translations.inputs.fields.numberOfUsesInTotal.label)
      .nullable(),
    validUntil: yup
      .string()
      .label(translations.inputs.fields.validUnit.label)
      .test('dateAfterToday', translations.validation.custom.dateAfterToday, function(value) {
        const date = parse(value, 'yyyy-MM-dd', new Date());

        return isAfter(date, new Date());
      })
      .required()
  })
  .required();
