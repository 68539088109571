import React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string;
  onClick: () => any;
}

const MenuButton = ({ className, onClick }: Props) => (
  <div
    className={classnames('w-[28px] h-[28px] flex flex-col justify-around items-center cursor-pointer p-1', className)}
    onClick={onClick}
  >
    {[0, 1, 2].map((key) => (
      <div key={key} className="h-[2px] w-[20px] bg-gray-darkest" />
    ))}
  </div>
);

export default MenuButton;
