import React from 'react';
import Typography from '@/components/Typography';
import RenderDownloadButtons from '@/page/Testing/TestingReportsPage/TestReports/RenderDownloadButtons/RenderDownloadButtons';

export interface Props {
  workspace: string;
  style: string;
}

const RenderWorkspace: React.FC<Props> = ({ workspace, style }) => {
  return (
    <Typography is="span">
      <div>
        <Typography is="h1" className={style}>
          {workspace}
        </Typography>
      </div>

      <RenderDownloadButtons workspace={workspace} />
    </Typography>
  );
};

export default RenderWorkspace;
