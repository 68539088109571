import React from 'react';
import { useIntl } from 'react-intl';
import { endOfMonth, startOfMonth } from 'date-fns';
import { DateRange } from 'react-date-range';
import { TimeFrame } from '@/domains/metrics';
import { BsCalendarEvent } from 'react-icons/bs';
import { translations } from '@/locale/messages';
import Typography from '@/components/Typography/Typography';
import Grid from '@/components/Grid';
import Dropdown from '@/components/Dropdown/Dropdown';
import SensorSelect from '@/components/Select/SensorSelect/SensorSelect';
import SensorGraph from './SensorGraph/SensorGraph';
import WorkspaceSelectGraph from '@/components/Select/WorkspaceSelectGraph/WorkspaceSelectGraph';
import { toInteger } from 'lodash';

const StatusGraphSection = () => {
  const text = translations.pages.overview;
  const intl = useIntl();
  let [workspaceId, setWorkspaceId] = React.useState<string>('');
  const [interval, setInterval] = React.useState({
    monitoringGraph: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      timeFrame: TimeFrame.Monthly,
      key: 'monitoringGraph'
    }
  });
  const [workspaceSelected, setWorkspaceSelected] = React.useState(
    intl.formatMessage({
      id: translations.inputs.fields.troubleshooting.placeholder
    })
  );
  const [sensor, setSensor] = React.useState();

  return (
    <>
      <div className="w-full flex">
        <Grid.Row className="w-full mt-6">
          <Grid.Column sm={5}>
            <Typography
              is={WorkspaceSelectGraph}
              placeholder={workspaceSelected}
              editable
              required
              setWorkspaceSelected={setWorkspaceSelected}
              setWorkspaceId={setWorkspaceId}
            />
          </Grid.Column>

          <Grid.Column className="ml-3">
            <Dropdown
              renderMenu={({ close }) => (
                <DateRange
                  className="overflow-hidden  rounded-xl w-full"
                  onChange={(item) => {
                    setInterval({ ...interval, ...item });
                  }}
                  months={1}
                  maxDate={new Date()}
                  direction="vertical"
                  ranges={[interval.monitoringGraph]}
                />
              )}
              className="mb-6"
            >
              {({ isOpen, open, close }) => (
                <div
                  className="hover:cursor-pointer inline-flex rounded-full mr-2.5 group-hover:shadow-red transition w-full"
                  onClick={isOpen ? close : open}
                >
                  <BsCalendarEvent className="text-4xl mb-4" />
                </div>
              )}
            </Dropdown>
          </Grid.Column>

          <Grid.Column sm={5} className="w-1/2 flex">
            <Typography
              is={SensorSelect}
              placeholder={
                sensor
                  ? sensor
                  : intl.formatMessage({
                      id: translations.inputs.fields.monitoringSensor
                    })
              }
              editable
              onChange={(value) => {
                setSensor(value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </div>

      {workspaceId && sensor && interval.monitoringGraph && (
        <SensorGraph
          workspaceId={workspaceId}
          intervals={{
            startDate: toInteger(interval.monitoringGraph.startDate) / 1000,
            endDate: toInteger(interval.monitoringGraph.endDate) / 1000
          }}
          sensor={sensor}
        />
      )}
    </>
  );
};
export default StatusGraphSection;
