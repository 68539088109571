import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

interface Props {
  verified: boolean;
}

const UserEmailStatus: React.FC<Props> = ({ verified }) => {
  return (
    <div className={verified ? 'text-green' : 'text-red'}>
      <FormattedMessage id={verified ? translations.emailStatus.verified : translations.emailStatus.notVerified} />
    </div>
  );
};

export default UserEmailStatus;
