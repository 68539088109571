import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { WorkspaceDetails, Widget, WidgetType } from '@/domains';
import { discoverWorkspaceSensors, getAllWorkspaceWidgets, updateWidgetValue } from '@/services/api/widgets';
import useLoad from '@/hooks/useLoad';

import If from '@/components/If';
import Loading from '@/components/Loading';
import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import ToggleSwitch from '@/components/ToggleSwitch';
import LiveSubmit from '@/components/LiveSubmit';
import Slider from '@/components/Slider';
import Button from '@/components/Button';
import Placeholder from '@/components/Placeholder';

export interface Props {
  workspace: WorkspaceDetails;
}

const text = translations.pages.workspaces.details.tabs.troubleshooting;

const TroubleshootingTab: React.FC<Props & WrappedComponentProps> = ({ workspace }) => {
  const [discoverSensors, setDiscoverSensors] = React.useState(false);
  const { value, loading, reload } = useLoad({ load: () => getAllWorkspaceWidgets(workspace.id) });

  React.useEffect(() => {
    if (discoverSensors) {
      const timer = setTimeout(() => {
        setDiscoverSensors(false);
        reload();
      }, 3 * 1000);

      discoverWorkspaceSensors(workspace.id);

      return () => {
        clearTimeout(timer);
        setDiscoverSensors(false);
      };
    }
  }, [discoverSensors]);

  const renderWidgetBasedOnType = React.useCallback(
    (widget: Widget) => {
      switch (widget.type) {
        case WidgetType.Switch:
        case WidgetType.Boolean:
          return (
            <LiveSubmit
              loading={false}
              value={{ value: Boolean(widget.value) }}
              onChange={({ value }) => updateWidgetValue(workspace.id, widget.id, Number(value))}
            >
              {({ value: { value }, set }) => (
                <ToggleSwitch
                  id={widget.id}
                  label={false}
                  checked={value}
                  onChange={(event) => set('value', event.target.checked)}
                >
                  <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                    {text.controlTextMapper[widget.id] ? (
                      <FormattedMessage id={text.controlTextMapper[widget.id]} />
                    ) : (
                      widget.title
                    )}
                  </Typography>
                </ToggleSwitch>
              )}
            </LiveSubmit>
          );
        case WidgetType.Stepper:
          return (
            <React.Fragment>
              <div className="flex items-center">
                <Slider
                  value={widget.value}
                  min={widget.metadata.min}
                  max={widget.metadata.max}
                  onFinalChange={(value) => updateWidgetValue(workspace.id, widget.id, value)}
                  renderValue={(value) => <>{value}&deg;</>}
                  className="w-1/2"
                />

                <Typography is="div" type="regular" className="font-bold text-gray-darkest ml-8">
                  {text.controlTextMapper[widget.id] ? (
                    <FormattedMessage id={text.controlTextMapper[widget.id]} />
                  ) : (
                    widget.title
                  )}
                </Typography>
              </div>

              {widget.id === 'ac-temperature' && (
                <Typography is="div" className="font-bold mt-4">
                  <FormattedMessage id={text.ambientTemperature} values={{ value: widget.currentValue }} />
                </Typography>
              )}
            </React.Fragment>
          );
        default:
          return null;
      }
    },
    [workspace.id]
  );

  return (
    <React.Fragment>
      <div className="flex justify-between mb-8">
        <Typography is="h2" type="subheader" className="font-bold mt-3 mb-6">
          <FormattedMessage id={text.title} />
        </Typography>

        <div>
          <Button
            appearance="red"
            className="mr-1"
            onClick={() => {
              setDiscoverSensors(true);
            }}
            loading={discoverSensors}
          >
            <FormattedMessage id={text.discover.button} />
          </Button>

          <Button appearance="red" onClick={reload}>
            <FormattedMessage id={text.refresh} />
          </Button>
        </div>
      </div>

      <Card className="min-h-[46vh]">
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading || discoverSensors} center className="min-h-[46vh]">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          )}
          else={() => (
            <If
              condition={discoverSensors}
              then={() => (
                <React.Fragment>
                  <div className="h-[10vh] mt-12">
                    <Loading visible={discoverSensors} center className="h-full">
                      <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                    </Loading>
                  </div>

                  <Placeholder className="mt-12">
                    <Typography is="span" type="small-header" className="font-bold">
                      <FormattedMessage id={text.discover.loading} />
                    </Typography>

                    <Button appearance="red" className="mt-8" onClick={() => setDiscoverSensors(false)}>
                      <FormattedMessage id={text.discover.abort} />
                    </Button>
                  </Placeholder>
                </React.Fragment>
              )}
              else={() => (
                <If
                  condition={!value || !value.length}
                  then={() => (
                    <Placeholder className="mt-12">
                      <Typography is="span" type="small-header" className="font-bold">
                        <FormattedMessage id={text.empty.title} />
                      </Typography>

                      <Typography is="span" type="small-header" className="text-gray-dark">
                        <FormattedMessage id={text.empty.description} />
                      </Typography>
                    </Placeholder>
                  )}
                  else={() => (
                    <React.Fragment>
                      {value.map(
                        (group) =>
                          group.title != 'Wifi' && (
                            <Card.Row is="section" key={group.title}>
                              <Grid.Column className="p-8 w-full">
                                <Typography is="h6" type="small-header" className="font-bold mb-8">
                                  {group.title}
                                </Typography>

                                {group.widgets.map((widget) => (
                                  <div key={widget.id} className="inline-block w-full my-5">
                                    {renderWidgetBasedOnType(widget)}
                                  </div>
                                ))}
                              </Grid.Column>
                            </Card.Row>
                          )
                      )}
                    </React.Fragment>
                  )}
                />
              )}
            />
          )}
        />
      </Card>
    </React.Fragment>
  );
};

export default injectIntl(TroubleshootingTab);
