import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@/components/Typography';
import { WorkspaceListItem } from '@/domains';
import { translations } from '@/locale';
import { getMonitoringStatusWorkspace } from '@/services/api/workspace';
import SearchInput from '@/components/SearchInput/SearchInput';
import Placeholder from '@/components/Placeholder';
import MonitoringScroll from './MonitoringScroll/MonitoringScroll';
import { MonitoringPaginateWorkspaces } from './MonitoringPaginateWorkspaces/MonitoringPaginateWorkspaces';
import { AiOutlineDownload } from 'react-icons/ai';
import Button from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal';
import DownloadMonitoringModal from '@/components/Modal/DownloadMonitoringModal/DownloadMonitoringModal';
import StatusGraphSection from '../StatusGraphSection/StatusGraphSection';
import DownloadMonitoringModalAll from '@/components/Modal/DownloadMonitoringModal/DownloadMonitoringModalAll/DownloadMonitoringModalAll';

export interface Props {
  workspaces: WorkspaceListItem[];
}

const MonitoringSection = ({ workspaces }) => {
  const text = translations.pages.overview;
  const intl = useIntl();
  let [state, setState] = React.useState({
    keyword: ''
  });

  return (
    <div className="w-full h-full">
      <div className="flex mb-2 mt-5">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.subtitle} />
        </Typography>
      </div>

      <StatusGraphSection />

      <div className="justify-between w-full flex">
        <SearchInput
          id="users-search"
          className="w-[300px] pt-5 pb-5"
          placeholder={intl.formatMessage({ id: translations.placeholders.monitoringInput })}
          onChange={(keyword) => {
            setState({ keyword: keyword });
          }}
        />

        <Modal
          modal={DownloadMonitoringModalAll}
          message={intl.formatMessage({ id: translations.placeholders.downloadAllReports })}
          messageInfo={intl.formatMessage({ id: translations.placeholders.downloadAllMonitoringReportInfo })}
          confirmMessage={intl.formatMessage({ id: translations.placeholders.download })}
          cancelMessage={intl.formatMessage({ id: translations.placeholders.cancel })}
        >
          {({ open }) => (
            <Button appearance="blue" className="mt-5 justify-between" onClick={open}>
              <AiOutlineDownload className="text-xl mr-2" />
              {intl.formatMessage({ id: translations.placeholders.downloadEntireHistory })}
            </Button>
          )}
        </Modal>
      </div>

      <MonitoringScroll
        key={state.keyword}
        source={(page, number) =>
          getMonitoringStatusWorkspace(page, number, [], state.keyword.length ? state.keyword : null)
        }
        empty={() => (
          <Placeholder className="mt-12">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.empty} />
            </Typography>
          </Placeholder>
        )}
        className="flex h-screen w-auto max-h-[32rem] max-w-[72rem]"
      >
        {(data, totalNumberOfElements) => (
          <MonitoringPaginateWorkspaces
            workspaces={workspaces}
            data={data}
            numberElementsMonitored={totalNumberOfElements}
          />
        )}
      </MonitoringScroll>
    </div>
  );
};
export default MonitoringSection;
