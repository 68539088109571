import { testCasesList } from '@/page/Testing/TestingProcessPage/util/test-form';
import { testCaseMapper } from './test-case-mapper';

export const nextTestCase = (
  condition,
  textArea,
  textInternet,
  count,
  setCount,
  setValue,
  setText,
  setInternet,
  setTestCase,
  dataWithTestCases
) => {
  const next = count + 1;
  if (condition == '1' || condition == '0') {
    if (count == testCasesList.length) {
    } else {
      const increase = () => {
        setCount(next);
        return next;
      };
      dataWithTestCases.push(testCaseMapper(testCasesList[count], condition, textArea, textInternet));
      setValue(-1);
      setText('');
      setInternet('');
      setTestCase(testCasesList[increase()]);
    }
  }
};
