import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { fullName } from '@/util';
import { Magnitude } from '@/constants';
import { translations } from '@/locale';
import { WorkspaceDetails, LessorBookingListItemDto } from '@/domains';
import { getPaginatedBookings } from '@/services/api/bookings';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import Price from '@/components/Price';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Info from '@/components/Info';

export interface Props {
  workspace: WorkspaceDetails;
}

const text = translations.pages.workspaces.details.tabs.bookings;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.table.header.customer, sm: 3 },
  { name: text.table.header.phoneNumber, sm: 2 },
  { name: text.table.header.customerType, sm: 2 },
  { name: text.table.header.date, sm: 2 },
  { name: text.table.header.timeInterval, sm: 2 },
  { name: text.table.header.billingInformation, sm: 2 },
  { name: text.table.header.cost, sm: 2 }
];

const BookingsTab: React.FC<Props & WrappedComponentProps> = ({ workspace, intl }) => (
  <React.Fragment>
    <Typography is="h2" type="subheader" className="font-bold mt-3 mb-6">
      <FormattedMessage id={text.title} />
    </Typography>

    <InfiniteScrollableTable
      source={(page, number) => getPaginatedBookings(page, number, workspace.id)}
      empty={() => (
        <Placeholder className="mt-12">
          <Typography is="span" type="small-header" className="font-bold">
            <FormattedMessage id={text.empty} />
          </Typography>
        </Placeholder>
      )}
      renderHeader={() => (
        <Table.Header>
          {headerList.slice(0, -1).map((headerItem) => (
            <Table.Data header sm={headerItem.sm} key={headerItem.name}>
              <Typography is="span" type="regular" className="font-bold uppercase text-gray">
                <FormattedMessage id={headerItem.name} />
              </Typography>
            </Table.Data>
          ))}

          <Table.Data header sm={headerList[6].sm} key={headerList[6].name}>
            <Info message={intl.formatMessage({ id: translations.misc.revenueInfo })}>
              {({ open, close }) => (
                <Typography
                  is="span"
                  type="regular"
                  className="font-bold uppercase text-gray group hover:cursor-default"
                  onMouseOver={open}
                  onMouseOut={close}
                >
                  <FormattedMessage id={headerList[6].name} />

                  <Info.Button open={open} close={close} />
                </Typography>
              )}
            </Info>
          </Table.Data>
        </Table.Header>
      )}
    >
      {(data) => (
        <React.Fragment>
          {data.map((booking: LessorBookingListItemDto) => (
            <Table.Row key={booking.id}>
              <Table.Data sm={headerList[0].sm}>
                <Typography is="span" className="ml-3">
                  <div className="flex items-center">
                    <UserProfileImage
                      firstName={booking.user.firstName ? booking.user.firstName : '-'}
                      lastName={booking.user.lastName ? booking.user.lastName : '-'}
                      className="w-8 h-8 rounded-md"
                    />

                    <Typography is="span" className="ml-3">
                      {booking.user.firstName ? fullName(booking.user.firstName, booking.user.lastName) : '--'}
                    </Typography>
                  </div>
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[1].sm}>
                <Typography is="span">
                  <FormattedMessage id={booking.user.phoneNumber ? booking.user.phoneNumber : '--'} />
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[2].sm}>
                <Typography is="span">
                  <FormattedMessage id={translations.domains.userRole[booking.user.type]} />
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[3].sm}>
                <FormattedDate value={booking.startDate} day="2-digit" month="long" year="numeric" />
              </Table.Data>

              <Table.Data sm={headerList[4].sm} className="break-all">
                <FormattedMessage
                  id={translations.misc.range}
                  values={{
                    start: intl.formatTime(booking.startDate, { hourCycle: 'h23' }),
                    end: intl.formatTime(booking.endDate, { hourCycle: 'h23' })
                  }}
                />
              </Table.Data>

              <Table.Data sm={headerList[5].sm}>
                <Typography is="span">
                  {booking.billingInformation ? (
                    <FormattedMessage id={translations.domains.billingInformation[booking.billingInformation]} />
                  ) : (
                    '-'
                  )}
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[6].sm}>
                <Price value={booking.cost} />
              </Table.Data>
            </Table.Row>
          ))}
        </React.Fragment>
      )}
    </InfiniteScrollableTable>
  </React.Fragment>
);

export default injectIntl(BookingsTab);
