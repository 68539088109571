import Card from '@/components/Card';
import { WorkspaceBoothStatusResponse, WorkspaceBoothStatus } from '@/domains/workspace-status';
import React from 'react';
import { WorkspaceStatusMonitoring } from '../WorkspaceStatusMonitoring/WorkspaceStatusMonitoring';

export const MonitoringPaginateWorkspaces = ({ workspaces, data, numberElementsMonitored }) => {
  return (
    <React.Fragment>
      {data.map((monitors: WorkspaceBoothStatusResponse<WorkspaceBoothStatus>, indexMonitor: number) => (
        <Card.Row key={monitors.id}>
          {workspaces.map((workspace) =>
            workspace.id === monitors.id ? (
              <WorkspaceStatusMonitoring
                key={'monitor-' + monitors.id}
                workspace={workspace}
                contentMonitoring={monitors}
                indexMonitor={indexMonitor}
                numberElementsMonitored={numberElementsMonitored}
              />
            ) : null
          )}
        </Card.Row>
      ))}
    </React.Fragment>
  );
};
