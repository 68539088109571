export interface Period {
  startDate: number;
  endDate: number;
}

export enum SensorStatus {
  signalStrength = 'Signal Strength'
}

export interface SignalStrength {
  byDay: {
    date: string;
    total: number;
    strength: number;
  }[];
  lastUpdatedAt: Date;
}
