import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import Select, { Props as SelectProps } from '@/components/Select';
import { SensorStatus } from '@/domains/sensor-status';

export interface Props extends Omit<SelectProps<SensorStatus>, 'items'>, WrappedComponentProps {}

const SensorSelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = Object.values(SensorStatus).map((sensor) => ({
    value: sensor,
    name: sensor
  }));

  return <Select {...rest} items={items} />;
};

export default SensorSelect;
