import React from 'react';
import * as yup from 'yup';
import { parse, stringify } from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { sendResetPasswordLink } from '@/services/api/session';

import Form from '@/components/Form';
import Button from '@/components/Button';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import Link from '@/components/Link';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .label(translations.inputs.email.label)
    .required()
});

export interface Props extends RouteComponentProps {}

const ForgotPasswordPage: React.FC<Props & WrappedComponentProps> = ({ location, ...props }) => {
  const email = parse(location.search).email as string;

  return (
    <React.Fragment>
      <div className="flex flex-col text-center mb-4">
        <Typography is="h1" type="header" className="font-bold mb-4">
          <FormattedMessage id={translations.pages.forgotPassword.title} />
        </Typography>

        <Typography is="p" type="regular" className="text-gray-dark">
          <FormattedMessage id={translations.pages.forgotPassword.subtitle} />
        </Typography>
      </div>

      <Form
        schema={schema}
        initialValues={{ email }}
        subscription={{ submitError: true, submitting: true, values: true }}
        onSubmit={({ email }) => sendResetPasswordLink(email).then(() => props.history.push(urls.forgotPassword.sent))}
      >
        {({ submitError, submitting, values }) => (
          <div className="flex flex-col">
            <div className="mb-8">
              <Form.Field
                is={TextInputWithValidation}
                id="email"
                name="email"
                type="email"
                label={props.intl.formatMessage({ id: translations.inputs.email.label })}
                placeholder={props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                readOnly={submitting}
                icon="mail"
              />
            </div>

            {!!submitError && <SubmitError error={submitError} className="mb-8" />}

            <Button type="submit" loading={submitting} appearance="red" className="mb-12">
              <FormattedMessage id={translations.navigation.submit} />
            </Button>

            <Link to={{ pathname: urls.signIn, search: stringify({ email: values.email }) }} appearance="red">
              <Typography is="div" type="regular" className="text-center text-red">
                <FormattedMessage id={translations.pages.forgotPassword.backButton} />
              </Typography>
            </Link>
          </div>
        )}
      </Form>
    </React.Fragment>
  );
};

export default injectIntl(ForgotPasswordPage);
