export const MAIN_METRICS_VIEW = 'main:metrics:view';
export const MAIN_LOCATIONS_VIEW = 'main:locations:view';
export const MAIN_USERS_VIEW = 'main:users:view';
export const MAIN_BOOKINGS_VIEW = 'main:bookings:view';
export const MAIN_PROMOTIONS_VIEW = 'main:promotions:view';
export const MAIN_PROMO_PACKS_VIEW = 'main:promo-packs:view';
export const MAIN_PURCHASED_PACKAGES_VIEW = 'main:purchased-packages:view';
export const MAIN_STATUS_VIEW = 'main:status:view';
export const MAIN_FEATURES_VIEW = 'main:features:view';
export const MAIN_TROUBLESHOOTING_VIEW = 'main:troubleshooting:view';
export const MAIN_TESTING_VIEW = 'main:testing:view';
export const MAIN_TESTING_REPORTS_VIEW = 'main:testig-reports:view';
export const SUPER_ADMIN_METRICS_VIEW = 'superadmin:metrics:view';
export const LESSOR_METRICS_VIEW = 'lessor:metrics:view';
export const MAP_STATUS_VIEW = 'map:status:view';
export const MONITORING_VIEW = 'monitoring:status:view';

export const WORKSPACE_CREATE = 'workspace:create';
export const WORKSPACE_DELETE = 'workspace:delete';
