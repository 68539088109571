import React from 'react';
import classnames from 'classnames';

export interface Props {
  url: string;
  className?: string;
}

const Image = ({ url, className }: React.PropsWithChildren<Props>) => (
  <div
    style={{ backgroundImage: `url(${url})` }}
    className={classnames('inline-block rounded-xl bg-center bg-cover bg-no-repeat', className)}
  />
);

export default Image;
