import {
  format,
  parse,
  eachDayOfInterval,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getDay,
  parseISO,
  differenceInYears,
  formatISO,
  differenceInDays
} from 'date-fns';

export const getWeekdayLabels = (firstDayOfWeek = 1, weekDayFormat = 'EEE'): string[] => {
  const now = new Date();
  const arr = eachDayOfInterval({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek)
  });
  return arr.reduce((array: string[], date) => {
    // @ts-ignore
    array.push(format(date, weekDayFormat));
    return array;
  }, []);
};

export const getDays = ({
  year,
  month,
  firstDayOfWeek = 1,
  dayNumberFormat = 'd'
}): (number | { date: Date; dayNumber: string })[] => {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);

  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek ? monthStartDay - firstDayOfWeek : 6 - firstDayOfWeek + monthStartDay + 1
    ).keys()
  ).fill(0);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd }).map((date) => ({
    date,
    dayNumber: format(date, dayNumberFormat)
  }));

  return [...prevMonthDays, ...days];
};

export const getTime = (isoString: string) => format(new Date(isoString), 'HH:mm');

export const getDateFormat = (isoString: string, dateFormat = 'MMM d, yyyy, HH:mm') =>
  format(new Date(isoString), dateFormat);

export const getISOString = (date: string, dateFormat: string) => parse(date, dateFormat, new Date()).toISOString();

export const getNow = (dateFormat: string) => format(new Date(), dateFormat);

export const getAgeFromBirthdate: (birthDate: string) => number = (birthDate) => {
  return differenceInYears(new Date(), parseISO(birthDate));
};

export const getDaysBetween: (date1: Date, date2: Date) => number = (date1, date2) => {
  return Math.abs(differenceInDays(date1, date2));
};

export const getISODateFromDateTime = (dateTime: string) => formatISO(parseISO(dateTime), { representation: 'date' });

export const isDateInPast = (date: string) => new Date(date) <= new Date();

export const altDateFormat = 'dd-MM-yyyy';
