import React from 'react';
import classnames from 'classnames';

export interface Props {
  is?: React.ElementType;
  className?: string;
  hoverable?: boolean;
  [x: string]: any;
}

const Row: React.FC<Props> = ({ is: Component = 'div', className, hoverable, children, ...rest }) => (
  <Component
    className={classnames(
      'flex flex-wrap px-6 py-5 bg-white even:bg-gray-lightest border-b border-solid border-gray-lighter last:border-none',
      { 'hover:cursor-pointer hover:bg-gray-lighter hover:bg-opacity-50': hoverable },
      className
    )}
    {...rest}
  >
    {children}
  </Component>
);

export default Row;
