import React, { DOMAttributes } from 'react';
import classnames from 'classnames';

export interface Props extends DOMAttributes<any> {
  is?: any;

  className?: string;
}

const Row: React.FC<Props> = ({ is: Component = 'div', className, ...rest }) => (
  <Component
    {...rest}
    className={classnames('relative flex wrap border-b border-solid border-gray-lighter last:border-0', className)}
  />
);

export default Row;
