import { Me, UpdatePassword, UserDetails } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

const mapMe = (user: UserDetails): Me => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  profileImageUrl: user.profileImageUrl,
  role: user.role
});

export const getMe = (userId: string) => authorizedApi.get<UserDetails>(`/users/${userId}`).then(mapMe);

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/users/me/password`, data);
