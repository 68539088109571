import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Typography from '@/components/Typography';

export interface Props {
  textId: string;
  className?: string;
}

const Property = ({ textId, className, children }: React.PropsWithChildren<Props>) => (
  <div className={classnames('inline-block', className)}>
    <Typography is="div" type="sm" className="text-gray-dark">
      <FormattedMessage id={textId} />
    </Typography>

    <Typography is="div">{children}</Typography>
  </div>
);

export default Property;
