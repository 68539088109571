import React from 'react';
import { debounce, uniqBy } from 'lodash';

import { fullName } from '@/util';
import { UserDetails, UserListItem, UserRole } from '@/domains';
import { getAllUsers, getUserById } from '@/services/api/users';
import usePagination from '@/hooks/usePagination';

import Select, { Props as SelectProps, Item as SelectItem } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<string>, 'items'> {}

interface State {
  selected?: SelectItem<string>;
  keyword?: string;
}

const toUserSelectItem = (user: UserDetails | UserListItem): SelectItem<string> => ({
  value: user.id,
  name: fullName(user.firstName, user.lastName)
});

const UserSelect = ({ ...rest }: React.PropsWithChildren<Props>) => {
  const [state, setState] = React.useState<State>({});
  const { loading, more, data, page, loadPage } = usePagination({
    id: state.keyword,
    source: (page, size) => getAllUsers(page, size, [UserRole.IndividualLessor], state.keyword)
  });

  const loadFromId = (id: string) =>
    getUserById(id).then((user) => setState((previous) => ({ ...previous, selected: toUserSelectItem(user) })));

  const memoizedData = React.useMemo(() => data.map(toUserSelectItem), [data]);
  React.useEffect(() => {
    if (!!rest.value) loadFromId(rest.value as string);
  }, []);

  const ref = React.useRef<HTMLUListElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  const handleSearch = debounce((value) => {
    if (!value || loading) return;
    setState((previous) => ({ ...previous, keyword: previous.selected?.name === value ? undefined : value }));
  }, 300);

  return (
    <Select
      {...rest}
      onChange={(value) => {
        loadFromId(value as string);
        rest.onChange(value);
      }}
      onInputValueChange={handleSearch}
      items={!state.selected ? memoizedData : uniqBy([state.selected, ...memoizedData], (item) => item.value)}
      dropdownContentLoading={loading}
      onDropdownContentScroll={handleScroll}
      dropdownContentRef={ref}
    />
  );
};

export const UserSelectSelectWithValidation = withValidation(UserSelect);
export default UserSelect;
