import React from 'react';
import classnames from 'classnames';

import icon from './style.css';

export type Type = keyof typeof icon;

export type Size = 'text-xs' | 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' | 'text-2xl' | 'text-3xl' | 'text-4xl';

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  type: Type;
  size?: Size;
}

const Icon: React.FC<Props> = ({ style, className, type, size = 'text-xl', ...rest }) => (
  <i style={style} className={classnames('align-middle leading-none', icon[type], size, className)} {...rest} />
);

export default Icon;
