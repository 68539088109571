import React from 'react';
import classnames from 'classnames';

import { urls } from '@/constants';

import Link from '@/components/Link';
import MenuButton from '@/components/Header/MenuButton';
import { SidebarConsumer } from '@/components/Context/Sidebar';
import ProfileDropdown from '@/components/Header/ProfileDropdown';

import logo from '@/assets/logo.svg';

import style from './Header.sass';

const Header: React.FC = () => (
  <header className={classnames(style.headerHeight, 'relative')}>
    <div
      className={classnames(
        style.headerHeight,
        style.content,
        'flex items-center justify-between relative w-full px-4 bg-white border-b border-solid border-gray-light border-opacity-40 sm:fixed sm:top-0 sm:left-0 sm:right-0 md:px-10'
      )}
    >
      <div className="flex items-center">
        <SidebarConsumer>{({ open }) => <MenuButton onClick={open} className="lg:hidden mr-5" />}</SidebarConsumer>

        <Link to={urls.home}>
          <img src={logo} />
        </Link>
      </div>

      <ProfileDropdown />
    </div>
  </header>
);

export default Header;
