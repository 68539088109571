import React from 'react';
import { useAsync } from 'react-use';

import Image from '@/components/Image';
import Typography from '@/components/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import If from '@/components/If';

import { translations } from '@/locale';
import { getWorkspaceDetails } from '@/services/api/workspace';
import Loading from '@/components/Loading';
import { IotProvider, WorkspaceState, WorkspaceVisibility } from '@/domains';
import Link from '@/components/Link/Link';
import { urls } from '@/constants';

export interface Props {
  workspaceId: string;
  name: string;
  state: WorkspaceState;
}

const text = translations.pages.overview.map.infoWindow;

const MapInfoWindow: React.FC<Props> = ({ workspaceId, name, state }) => {
  const { value: details, loading } = useAsync(async () => getWorkspaceDetails(workspaceId));
  const intl = useIntl();

  return (
    <If
      condition={loading}
      then={() => (
        <Loading visible={loading} center className="h-full">
          <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
        </Loading>
      )}
      else={() => (
        <div className="flex">
          <div className="grid gap-3 grid-cols-2 place-items-center">
            {!!details.imagesUrls.length && (
              <div>
                <Image key={workspaceId} url={details.imagesUrls[0].url} className="w-[7.25rem] h-[7.25rem] m-3" />
              </div>
            )}
            <div>
              {details.provider === IotProvider.NookaLive ? (
                <Link
                  to={urls.troubleshooting.nooka.get(workspaceId)}
                  className="text-blue-light text-base font-bold mb-5"
                >
                  {name}
                </Link>
              ) : (
                <Typography is="h2" type="regular" className="font-bold text-gray-darkest mb-3">
                  {name}
                </Typography>
              )}
              <div className="flex items-stretch ">
                <Typography is="h3" type="sm" className="text-gray-darkest mb-2">
                  <FormattedMessage id={text.status} />{' '}
                  <FormattedMessage id={translations.domains.connectivityStatus[state]} />
                </Typography>

                <div className="ml-2 mt-0.5">
                  {state === WorkspaceState.Online ? (
                    <FaArrowAltCircleUp color="green" fontSize="1.2em" />
                  ) : (
                    <FaArrowAltCircleDown color="red" fontSize="1.2em" />
                  )}
                </div>
              </div>

              <Typography is="h4" type="sm" className="text-gray-darkest">
                <FormattedMessage id={text.providerId} /> {details.providerId}
              </Typography>

              <Typography is="h4" type="sm" className="text-gray-darkest mt-3">
                <FormattedMessage id={text.visible} />{' '}
                {details.visibilityOnMap === WorkspaceVisibility.Visible
                  ? intl.formatMessage({ id: text.visibleState.on })
                  : intl.formatMessage({ id: text.visibleState.off })}
              </Typography>
            </div>
          </div>
        </div>
      )}
    ></If>
  );
};

export default MapInfoWindow;
