import { AssignedPackage, Package, Page, PurchasePackage } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getPackages = () => authorizedApi.get<Package[]>(`/packages`);

export const purchasePackages = (packageId: string, count: number) =>
  authorizedApi.post<PurchasePackage>(`/packages/purchase`, { packageId, count });

export const getAssignedPackages = (pageNumber: number, pageSize: number, options?: { [x: string]: any }) =>
  authorizedApi.get<Page<AssignedPackage>>(`/packages/assigned`, { pageNumber, pageSize, ...options });

export const sendAssignedPackageReminder = (id: string) => authorizedApi.post(`/packages/assigned/${id}/reminder`);

export const revokeAssignedPackage = (id: string) => authorizedApi.delete(`/packages/assigned/${id}/revoke`);
