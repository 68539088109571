import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import UsersListPage from './UsersListPage/UsersListPage';
import UserDetailsPage from './UserDetailsPage/UserDetailsPage';

const Users: React.FC = () => (
  <Switch>
    <Route exact path={urls.users.list} component={UsersListPage} />

    <Route path={urls.users.detail.main} component={UserDetailsPage} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.users.list} />} />
  </Switch>
);

export default Users;
