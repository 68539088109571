import React from 'react';
import { debounce, uniqBy } from 'lodash';

import { WorkspaceDetails, WorkspaceListItem } from '@/domains';
import { getWorkspaceDetails, getWorkspaces } from '@/services/api/workspace';
import usePagination from '@/hooks/usePagination';

import Select, { Props as SelectProps, Item as SelectItem } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<string>, 'items'> {
  ownedBy: string;
}

interface State {
  selected?: SelectItem<string>[];
  keyword?: string;
}

const toWorkspaceSelectItem = (workspace: WorkspaceDetails | WorkspaceListItem): SelectItem<string> => ({
  value: workspace.id,
  name: workspace.name
});

const WorkspacesSelect = ({ ownedBy, ...rest }: React.PropsWithChildren<Props>) => {
  const [state, setState] = React.useState<State>({});
  const { loading, more, data, page, loadPage } = usePagination({
    id: ownedBy,
    source: (page, size) => getWorkspaces(page, size, [ownedBy], state.keyword)
  });

  const loadFromId = (ids: string[]) => {
    setState((prevState) => ({ ...prevState, selected: [] }));
    if (ids) {
      ids?.map((id) => {
        getWorkspaceDetails(id).then((workSpace) =>
          setState((previous) => ({
            ...previous,
            selected: previous.selected?.concat(toWorkspaceSelectItem(workSpace))
          }))
        );
      });
    }
  };

  const memoizedData = React.useMemo(() => data.map(toWorkspaceSelectItem), [data]);
  React.useEffect(() => {
    if (!!rest.value) {
      loadFromId(rest.value as string[]);
    }
  }, []);

  const ref = React.useRef<HTMLUListElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  const handleSearch = debounce((value) => {
    if (!value || loading) return;

    setState((prevState) => ({ ...prevState, keyword: value }));
    loadPage(1);
  }, 300);

  const onBlur = () => {
    setState((prevState) => ({ ...prevState, keyword: undefined }));
  };

  return (
    <Select
      {...{ ...rest, onBlur }}
      onChange={(value) => {
        loadFromId(value as string[]);
        rest.onChange(value);
      }}
      onInputValueChange={handleSearch}
      items={!state.selected ? memoizedData : uniqBy(memoizedData.concat(state.selected), (item) => item.value)}
      dropdownContentLoading={loading}
      onDropdownContentScroll={handleScroll}
      dropdownContentRef={ref}
      multiple={true}
    />
  );
};

const Default = WorkspacesSelect;
export const WorkspacesSelectSelectWithValidation = withValidation(Default);
export default Default;
