import React from 'react';
import classnames from 'classnames';

import Card from '@/components/Card';
import Dropdown, { Props as DropdownProps } from '@/components/Dropdown';

import InfoButton from './InfoButton';

export interface Props extends Pick<DropdownProps, 'children'> {
  message: string;
  className?: string;
}

const Info = ({ children, message, ...props }: React.PropsWithChildren<Props>) => (
  <div {...props} className={classnames('relative normal-case font-normal', props.className)}>
    <Dropdown
      renderMenu={() => (
        <Card className="p-2 absolute text-gray-darkest w-[200px] z-50 -left-3/4 mt-2 break-normal text-center">
          {message}
        </Card>
      )}
    >
      {children}
    </Dropdown>
  </div>
);

Info.Button = InfoButton;

export default Info;
