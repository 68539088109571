export interface Image {
  id?: string;
  url: string;
  file?: File;
}

export interface Owner {
  id: string;
  firstName: string;
  lastName: string;
  type: string;
}

export enum AssetType {
  WorkspaceImage = 'WORKSPACE_IMAGE'
}
