import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { UserRole } from '@/domains';
import { deleteUserById, getUserById, updateUserStatus } from '@/services/api/users';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Property, { Props as PropertyProps } from '@/components/Property';
import Optional from '@/components/Optional';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { SessionConsumer } from '@/components/Context/Session';
import TabbedContainer from '@/components/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import Link from '@/components/Link';
import UserStatus from '@/components/Status/UserStatus';
import UserEmailStatus from '@/components/Status/UserEmailStatus';
import AssignedHoursStatus from '@/components/AssignedHoursStatus';

import BookingsTab from './BookingsTab';
import PurchasedPackagesTab from './PurchasedPackagesTab';
import AssignedCreditsStatus from '@/components/AssignedCreditsStatus';
import PurchasedCreditsTab from './PurchasedCreditsTab';

const text = translations.pages.users.details;

const UserProperty = ({ className, ...props }: React.PropsWithChildren<PropertyProps>) => (
  <Property className={classnames('my-3 mx-5 min-w-min w-3/12', className)} {...props} />
);

const UserDetailsPage: React.FC<RouteComponentProps<{ id: string }> & WrappedComponentProps> = ({
  intl,
  match,
  history
}) => {
  const userId = match.params.id;

  return (
    <SessionConsumer>
      {({ me }) => (
        <LoadData id={userId} load={getUserById}>
          {({ value: user, loading, reload }) => (
            <div className="m-8">
              <div className="flex justify-between mb-8">
                <Typography is="h1" type="header" className="text-gray-darkest font-bold">
                  <FormattedMessage id={text.title} />
                </Typography>

                {me.id !== userId && (
                  <Modal
                    modal={ConfirmationModal}
                    message={intl.formatMessage({ id: translations.pages.users.delete.message })}
                    confirmMessage={intl.formatMessage({ id: translations.pages.users.delete.confirm })}
                    cancelMessage={intl.formatMessage({ id: translations.pages.users.delete.cancel })}
                    onConfirm={() => deleteUserById(userId).then(() => history.push(urls.users.list))}
                  >
                    {({ open }) => (
                      <Button appearance="ghost" onClick={open}>
                        <Icon type="close" className="text-base leading-none pr-2" />

                        <FormattedMessage id={text.delete} />
                      </Button>
                    )}
                  </Modal>
                )}
              </div>

              <Card className="w-full">
                <Grid.Row>
                  <Grid.Column sm={12} padded className="min-h-[11.5rem]">
                    <If
                      condition={loading}
                      then={() => (
                        <Loading visible={loading} center className="h-full">
                          <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                        </Loading>
                      )}
                      else={() => (
                        <If
                          condition={!user}
                          then={() => (
                            <Placeholder className="h-full justify-center font-bold">
                              <Typography is="span">
                                <FormattedMessage id={text.notFound} />
                              </Typography>
                            </Placeholder>
                          )}
                          else={() => (
                            <Grid.Row className="h-full items-center flex-nowrap">
                              <Grid.Column>
                                <UserProfileImage
                                  firstName={user.firstName ? user.firstName : '--'}
                                  lastName={user.lastName ? user.lastName : '--'}
                                  className="m-5 h-[7.5rem] w-[7.5rem] rounded-full text-5xl"
                                />
                              </Grid.Column>

                              <Grid.Column className="min-w-[500px]">
                                <Grid.Row>
                                  <UserProperty textId={translations.inputs.fields.firstName.label}>
                                    {user.firstName}
                                  </UserProperty>

                                  <UserProperty textId={translations.inputs.fields.lastName.label}>
                                    {user.lastName}
                                  </UserProperty>

                                  <UserProperty textId={translations.inputs.fields.email.label}>
                                    {user.email}
                                  </UserProperty>

                                  <Optional
                                    of={user.phoneNumber}
                                    then={(phoneNumber) => (
                                      <UserProperty textId={translations.inputs.fields.phoneNumber.label}>
                                        {phoneNumber}
                                      </UserProperty>
                                    )}
                                  />

                                  <Optional
                                    of={user.country}
                                    then={(country) => (
                                      <UserProperty textId={translations.inputs.fields.country.label}>
                                        <FormattedMessage id={translations.countries[country]} />
                                      </UserProperty>
                                    )}
                                  />

                                  <Optional
                                    of={user.city}
                                    then={(city) => (
                                      <UserProperty textId={translations.inputs.fields.city.label}>{city}</UserProperty>
                                    )}
                                  />

                                  <Optional
                                    of={user.customerId}
                                    then={(customerId) => (
                                      <UserProperty textId={translations.inputs.fields.billingDashboardReference.label}>
                                        <Link to={urls.billingDashboard.customer(customerId)} external={true}>
                                          {translations.inputs.fields.billingDashboardReference.callout}
                                        </Link>
                                      </UserProperty>
                                    )}
                                  />

                                  <UserProperty textId={translations.inputs.fields.userType.label}>
                                    <FormattedMessage id={translations.domains.userRole[user.role]} />
                                  </UserProperty>
                                </Grid.Row>

                                <Grid.Row>
                                  <UserProperty textId={translations.inputs.fields.emailVerificatiom.label}>
                                    <UserEmailStatus verified={user.email ? user.verified : false} />
                                  </UserProperty>

                                  <UserProperty textId={translations.inputs.fields.status.label}>
                                    <UserStatus
                                      active={user.active}
                                      onChangeStatus={() => {
                                        updateUserStatus(user.id, !user.active).then(() => reload());
                                      }}
                                    />
                                  </UserProperty>

                                  <UserProperty textId={translations.inputs.fields.assignedCredits.label}>
                                    <AssignedCreditsStatus userId={userId} />
                                  </UserProperty>
                                </Grid.Row>
                              </Grid.Column>
                            </Grid.Row>
                          )}
                        />
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column sm={12} padded></Grid.Column>
                </Grid.Row>
              </Card>

              <TabbedContainer resourceId={userId}>
                <Tab title={intl.formatMessage({ id: text.tabs.bookings.title })} to={urls.users.detail}>
                  <BookingsTab userId={userId} />
                </Tab>

                {!loading && user && (user.role === UserRole.Tenant || user.role === UserRole.Business) ? (
                  <Tab
                    title={intl.formatMessage({ id: text.tabs.purchasedCredis.title })}
                    to={urls.users.purchasedCredits}
                  >
                    <PurchasedCreditsTab userId={userId} />
                  </Tab>
                ) : (
                  false
                )}
              </TabbedContainer>
            </div>
          )}
        </LoadData>
      )}
    </SessionConsumer>
  );
};
export default injectIntl(UserDetailsPage);
