import React from 'react';

import { Page } from '@/domains';
import { MonitoringPage } from '@/domains/monitoring-page';

export interface Options<K, T> {
  id?: K;
  size?: number;
  source: (page: number, size: number) => Promise<Page<T>> | Promise<MonitoringPage<T>>;
}

interface State<T> {
  page: number;
  more: boolean;
  totalNumberOfElements: number;
  totalNumberOfPages: number;
  data: T[];
  loading: boolean;
  activePrevDay?: number;
  inactivePrevDay?: number;
}

export interface Return<T> extends State<T> {
  loadPage: (number: number) => void;
  reload: () => void;
}

const initialState: State<any> = {
  page: 1,
  data: [],
  more: true,
  totalNumberOfPages: 1,
  totalNumberOfElements: 0,
  loading: true
};

const usePaginationMonitoring = <T, K>({ size = 15, ...options }: Options<K, T>): Return<T> => {
  const [state, setState] = React.useState<State<T>>(initialState);

  const fetchPage = (pageNumber: number) =>
    options
      .source(pageNumber, size)
      .then((response) =>
        setState(({ data, page }) => {
          if (!response.activePrevDay)
            return {
              data: [...data, ...response.content],
              more: (page - 1) * size + response.pageSize < response.totalNumberOfElements,
              totalNumberOfElements: response.totalNumberOfElements,
              totalNumberOfPages: Math.ceil(response.totalNumberOfElements / size),
              page,
              loading: false,
              activePrevDay: response.activePrevDay,
              inactivePrevDay: response.inactivePrevDay
            };
          else
            return {
              data: [...data, ...response.content],
              more: (page - 1) * size + response.pageSize < response.totalNumberOfElements,
              totalNumberOfElements: response.totalNumberOfElements,
              totalNumberOfPages: Math.ceil(response.totalNumberOfElements / size),
              page,
              loading: false
            };
        })
      )
      .catch(() => setState((previous) => ({ ...previous, loading: false })));

  const loadPage = (page: number) => {
    if (!state.more || page > state.totalNumberOfPages) return;

    setState({ ...state, page, loading: true });
    fetchPage(page);
  };

  const reload = () => {
    setState(initialState);
    fetchPage(initialState.page);
  };

  React.useEffect(reload, [options.id]);

  if (state.inactivePrevDay)
    return {
      page: state.page,
      data: state.data,
      activePrevDay: state.activePrevDay,
      inactivePrevDay: state.inactivePrevDay,
      loading: state.loading,
      totalNumberOfPages: state.totalNumberOfPages,
      totalNumberOfElements: state.totalNumberOfElements,
      more: state.more,

      loadPage,
      reload
    };
  else
    return {
      page: state.page,
      data: state.data,
      loading: state.loading,
      totalNumberOfPages: state.totalNumberOfPages,
      totalNumberOfElements: state.totalNumberOfElements,
      more: state.more,
      loadPage,
      reload
    };
};

export default usePaginationMonitoring;
