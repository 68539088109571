import React, { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { Magnitude, urls } from '@/constants';
import { translations } from '@/locale';
import { UserListItem } from '@/domains';
import { fullName } from '@/util';
import { getAllUsers } from '@/services/api/users';

import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import AddUserModal from '@/components/Modal/AddUserModal';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Optional from '@/components/Optional';
import DashPlaceholder from '@/components/Placeholder/DashPlaceholder';
import Price from '@/components/Price';
import Info from '@/components/Info';
import SearchInput from '@/components/SearchInput';

const pageTranslation = translations.pages.users.list;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: pageTranslation.table.header.customer, sm: 4 },
  { name: pageTranslation.table.header.phoneNumber, sm: 4 },
  { name: pageTranslation.table.header.customerType, sm: 2 },
  { name: pageTranslation.table.header.country, sm: 2 },
  { name: pageTranslation.table.header.city, sm: 2 },
  { name: pageTranslation.table.header.joinDate, sm: 3 },
  { name: pageTranslation.table.header.paid, sm: 2 }
];

const UsersListPage: React.FC = () => {
  const intl = useIntl();
  let [state, setState] = useState({
    keyword: ''
  });

  return (
    <div className="m-8">
      <Typography is="h1" type="header" className="text-gray-darkest font-bold">
        <FormattedMessage id={pageTranslation.title} />
      </Typography>

      <div className="flex justify-between mb-4 mt-4">
        <SearchInput
          id="users-search"
          className="w-[300px] bg-white"
          placeholder={intl.formatMessage({ id: pageTranslation.searchPlaceholder })}
          onChange={(keyword) => {
            setState({ keyword: keyword });
          }}
        />

        <Modal modal={AddUserModal}>
          {({ open }) => (
            <Button appearance="red" onClick={open}>
              <Icon type="plus" className="text-base leading-none pr-2" />

              <FormattedMessage id={pageTranslation.addNew} />
            </Button>
          )}
        </Modal>
      </div>

      <InfiniteScrollableTable
        key={state.keyword}
        source={(page, number) => getAllUsers(page, number, null, state.keyword.length ? state.keyword : null)}
        empty={() => (
          <Placeholder className="mt-12">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={pageTranslation.empty} />
            </Typography>
          </Placeholder>
        )}
        renderHeader={() => (
          <Table.Header>
            {headerList.slice(0, -1).map((headerItem) => (
              <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                <Typography is="span">
                  <FormattedMessage id={headerItem.name} />
                </Typography>
              </Table.Data>
            ))}

            <Table.Data header sm={headerList[5].sm} key={headerList[5].name}>
              <Info message={intl.formatMessage({ id: translations.misc.revenueInfo })}>
                {({ open, close }) => (
                  <Typography
                    is="span"
                    className="font-bold uppercase text-gray group hover:cursor-default"
                    onMouseOver={open}
                    onMouseOut={close}
                  >
                    <FormattedMessage id={headerList[5].name} />

                    <Info.Button open={open} close={close} />
                  </Typography>
                )}
              </Info>
            </Table.Data>
          </Table.Header>
        )}
      >
        {(data) => (
          <React.Fragment>
            {data.map((user: UserListItem) => (
              <Table.LinkRow key={user.id} hoverable url={urls.users.detail.get(user.id)}>
                <Table.Data sm={headerList[0].sm}>
                  <div className="flex items-center">
                    <UserProfileImage
                      firstName={user.firstName ? user.firstName : '-'}
                      lastName={user.lastName ? user.lastName : '-'}
                      className="w-8 h-8 rounded-md"
                    />

                    <Typography is="span" className="ml-3">
                      {fullName(user.firstName ? user.firstName : '--', user.lastName ? user.lastName : '--')}
                    </Typography>
                  </div>
                </Table.Data>

                <Table.Data sm={headerList[1].sm}>
                  <Typography is="span">
                    <FormattedMessage id={user.phoneNumber ? user.phoneNumber : '--'} />
                  </Typography>
                </Table.Data>

                <Table.Data sm={headerList[2].sm}>
                  <Typography is="span">
                    <FormattedMessage id={translations.domains.userRole[user.role]} />
                  </Typography>
                </Table.Data>

                <Table.Data sm={headerList[3].sm}>
                  <Optional
                    of={user.country}
                    then={(country) => (
                      <Typography is="span">
                        <FormattedMessage id={translations.countries[country]} />
                      </Typography>
                    )}
                    else={() => <DashPlaceholder />}
                  />
                </Table.Data>

                <Table.Data sm={headerList[4].sm}>
                  <Optional of={user.city} then={(city) => city} else={() => <DashPlaceholder />} />
                </Table.Data>

                <Table.Data sm={headerList[5].sm}>
                  <FormattedDate value={user.createdAt} day="2-digit" month="long" year="numeric" />
                </Table.Data>

                <Table.Data sm={headerList[6].sm}>
                  <Optional
                    of={user.earnings}
                    then={(earnings) => <Price value={earnings} />}
                    else={() => <DashPlaceholder />}
                  />
                </Table.Data>
              </Table.LinkRow>
            ))}
          </React.Fragment>
        )}
      </InfiniteScrollableTable>
    </div>
  );
};

export default UsersListPage;
