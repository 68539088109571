import React from 'react';
import { DateRange } from 'react-date-range';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { endOfWeek, isSameDay, startOfWeek } from 'date-fns';

import { getBookingMetrics } from '@/services/api/metrics';
import { translations } from '@/locale';

import Card from '@/components/Card';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import If from '@/components/If';
import Loading from '@/components/Loading';
import PieChart from '@/components/Chart/PieChart';
import Dropdown from '@/components/Dropdown';
import { BookingGroupBy, PurchasedPackageGroupBy } from '@/domains/metrics';
import Table from '@/components/Table';

const text = translations.pages.dashboard;

const DashboardBookingsSection: React.FC = () => {
  const [bookingMetrics, setBookingMetrics] = React.useState({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    groupBy: BookingGroupBy.USER,
    key: 'bookingMetrics'
  });

  const [purchasedPackageMetrics, setPurchasedPackageMetrics] = React.useState({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    groupBy: PurchasedPackageGroupBy.USER,
    key: 'purchasedPackageMetrics'
  });

  return (
    <div className="flex gap-2 mt-8 mb-8">
      <Card className="mt-6 flex-1">
        <div className="flex">
          <Dropdown
            renderMenu={({ close }) => (
              <DateRange
                className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
                onChange={(item) => {
                  setBookingMetrics({ ...bookingMetrics, ...item.key });
                }}
                months={1}
                maxDate={new Date()}
                direction="vertical"
                ranges={[bookingMetrics]}
              />
            )}
            className="p-6"
            dropdownClassName=" left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                <FormattedDate value={bookingMetrics.startDate} day="2-digit" month="long" />
                <If
                  condition={!isSameDay(bookingMetrics.startDate, bookingMetrics.endDate)}
                  then={() => {
                    return (
                      <div>
                        <span> - </span>
                        <FormattedDate value={bookingMetrics.endDate} day="2-digit" month="long" />
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </Dropdown>

          <Dropdown
            renderMenu={({ close }) => (
              <Card className="flex flex-col overflow-hidden">
                <button
                  type="button"
                  onClick={() => {
                    setBookingMetrics({ ...bookingMetrics, groupBy: BookingGroupBy.USER });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Users
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setBookingMetrics({ ...bookingMetrics, groupBy: BookingGroupBy.NOOKA });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Locations
                </button>
              </Card>
            )}
            className="p-6"
            dropdownClassName=" left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                {`Group by: ${bookingMetrics.groupBy}`}
              </div>
            )}
          </Dropdown>
        </div>

        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest pl-6 p-4">
          <FormattedMessage id={text.section.bookings} />
        </Typography>

        <div className="p-4">
          <LoadData key={JSON.stringify(bookingMetrics)} load={() => getBookingMetrics(bookingMetrics)}>
            {({ value: metrics, loading }) => (
              <If
                condition={loading}
                then={() => (
                  <Loading visible={loading} center className="h-full">
                    <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                  </Loading>
                )}
                else={() => (
                  <div className="flex items-start">
                    <div className="w-1/2">
                      <PieChart items={metrics.topPerformers} />
                    </div>
                    <Table className="w-1/2 ml-2">
                      {metrics.topPerformers.map((e) => {
                        return (
                          <Table.Row key={e.title}>
                            <Table.Data>{e.title}</Table.Data>
                            <Table.Data>{e.value}</Table.Data>
                          </Table.Row>
                        );
                      })}
                    </Table>
                  </div>
                )}
              />
            )}
          </LoadData>
        </div>
      </Card>

      {/* <Card className="mt-6 flex-1">
        <div className="flex">
          <Dropdown
            renderMenu={({ close }) => (
              <DateRange
                className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
                onChange={(item) => {
                  setPurchasedPackageMetrics({ ...purchasedPackageMetrics, ...item.key });
                }}
                months={1}
                maxDate={new Date()}
                direction="vertical"
                ranges={[purchasedPackageMetrics]}
              />
            )}
            className="p-6"
            dropdownClassName=" left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                <FormattedDate value={purchasedPackageMetrics.startDate} day="2-digit" month="long" />
                <If
                  condition={!isSameDay(purchasedPackageMetrics.startDate, purchasedPackageMetrics.endDate)}
                  then={() => {
                    return (
                      <div>
                        <span> - </span>
                        <FormattedDate value={purchasedPackageMetrics.endDate} day="2-digit" month="long" />
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </Dropdown>

          <Dropdown
            renderMenu={({ close }) => (
              <Card className="flex flex-col overflow-hidden">
                <button
                  type="button"
                  onClick={() => {
                    setPurchasedPackageMetrics({ ...purchasedPackageMetrics, groupBy: PurchasedPackageGroupBy.USER });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Users
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setPurchasedPackageMetrics({
                      ...purchasedPackageMetrics,
                      groupBy: PurchasedPackageGroupBy.PACKAGE
                    });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Package
                </button>
              </Card>
            )}
            className="p-6"
            dropdownClassName=" left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                {`Group by: ${purchasedPackageMetrics.groupBy}`}
              </div>
            )}
          </Dropdown>
        </div>

        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest pl-6 p-4">
          <FormattedMessage id={text.section.purchasedPackages} />
        </Typography>

        <div className="p-4">
          <LoadData
            key={JSON.stringify(purchasedPackageMetrics)}
            // TODO: Remove ts-ignore
            // @ts-ignore
            load={() => getBookingMetrics(purchasedPackageMetrics)}
          >
            {({ value: metrics, loading }) => (
              <If
                condition={loading}
                then={() => (
                  <Loading visible={loading} center className="h-full">
                    <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                  </Loading>
                )}
                else={() => (
                  <div className="flex items-start">
                    <div className="w-1/2">
                      <PieChart items={metrics.topPerformers} />
                    </div>
                    <Table className="w-1/2 ml-2">
                      {metrics.topPerformers.map((e) => {
                        return (
                          <Table.Row key={e.title}>
                            <Table.Data>{e.title}</Table.Data>
                            <Table.Data>{e.value}</Table.Data>
                          </Table.Row>
                        );
                      })}
                    </Table>
                  </div>
                )}
              />
            )}
          </LoadData>
        </div>
      </Card> */}
    </div>
  );
};

export default DashboardBookingsSection;
