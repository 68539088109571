import React, { useState } from 'react';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { SubmitError } from '@/components/Error';

import Grid from '@/components/Grid';

import deleteIcon from './deleteIcon.svg';
import { TbRefresh } from 'react-icons/tb';

interface Props extends InstanceProps {
  title?: string;
  message?: string;
  confirmMessage: string;
  cancelMessage: string;
  onConfirm?: (event: React.MouseEvent) => Promise<any>;
  onCancel?: (event: React.MouseEvent) => Promise<any>;
}

interface State {
  confirmLoading: boolean;
  cancelLoading: boolean;
  error?: any;
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  message,
  confirmMessage,
  cancelMessage,
  onConfirm,
  onCancel,
  close
}) => {
  const [state, setState] = useState<State>({ confirmLoading: false, cancelLoading: false });

  const loading = state.cancelLoading || state.confirmLoading;

  const handleConfirm = (event: React.MouseEvent) => {
    if (onConfirm) {
      setState((previous) => ({ ...previous, confirmLoading: true }));
      onConfirm(event)
        .then(close)
        .catch((error) => {
          setState((previous) => ({ ...previous, confirmLoading: false, error }));
        });
    } else {
      close();
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (onCancel) {
      setState((previous) => ({ ...previous, cancelLoading: true }));
      onCancel(event)
        .then(close)
        .catch((error) => {
          setState((previous) => ({ ...previous, cancelLoading: false, error }));
        });
    } else {
      close();
    }
  };

  return (
    <Card className="w-[360px] p-6 mb-10">
      <div className=" mb-4 ">
        {title === 'retain' ? (
          <TbRefresh className="m-auto text-7xl bg-red-opacity-30 text-red border-red-opacity-30 rounded-full" />
        ) : (
          <img src={deleteIcon} className="m-auto" />
        )}
      </div>

      {title && title != 'retain' && (
        <Typography is="div" className="mb-4 font-bold text-center">
          {title}
        </Typography>
      )}

      <Grid.Row>
        {message && (
          <Typography is="div" className="text-center text-sm text-gray-dark mb-8">
            {message}
          </Typography>
        )}

        {state.error && <SubmitError error={state.error} className="mb-3" />}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column className="w-1/2 pr-1">
          <Button appearance="ghost" onClick={handleCancel} loading={state.cancelLoading} disabled={loading} block>
            {cancelMessage}
          </Button>
        </Grid.Column>

        <Grid.Column className="w-1/2 pl-1">
          <Button appearance="red" onClick={handleConfirm} loading={state.confirmLoading} disabled={loading} block>
            {confirmMessage}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default ConfirmationModal;
