import React from 'react';
import classnames from 'classnames';

import InfiniteScroll, { Props as InfiniteScrollProps } from '@/components/InfiniteScroll';
import If from '@/components/If';
import Loading from '@/components/Loading';

import style from '@/components/InfiniteScrollableTable/InfiniteScrollableTable.sass';
import Table from '@/components/Table/Table';
import InfiniteScrollMonitoring from '@/components/InfiniteScrollMonitoring/InfiniteScroll/InfiniteScrollMonitoring';

export interface Props<K, T> extends Pick<InfiniteScrollProps<K, T>, 'source'> {
  empty: () => any;
  children: (data: T[], totalNumberOfElements: number) => React.ReactElement;
  className?: string;
  listClassName?: string;
}

export default class MonitoringScroll<K, T> extends React.PureComponent<Props<K, T>> {
  render() {
    const { source, empty, children, className, listClassName } = this.props;

    return (
      <Table className={className} is="div">
        <InfiniteScrollMonitoring size={5} source={source} className={classnames(style.list, listClassName)}>
          {({ data, loading, totalNumberOfElements }) => (
            <If
              condition={!loading && data.length === 0}
              then={empty}
              else={() => (
                <ul>
                  {children(data, totalNumberOfElements)}

                  <Loading visible={loading} center className={style.loadingIndicator}>
                    <Loading.Indicator size={5} borderWidth={2} color="#F72431" />
                  </Loading>
                </ul>
              )}
            />
          )}
        </InfiniteScrollMonitoring>
      </Table>
    );
  }
}
