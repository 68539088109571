import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { PaymentStatus, PurchasedPackage } from '@/domains';
import { getPurchasedPackages } from '@/services/api/purchased-packages';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import Table from '@/components/Table';
import Price from '@/components/Price';
import Badge from '@/components/Badge';
import PurchasedPackagesListItemDropdown from '@/components/Dropdown/PurchasedPackagesListItemDropdown';
import Refresh from '@/components/Refresh';
import DashPlaceholder from '@/components/Placeholder/DashPlaceholder';
import Optional from '@/components/Optional';
import { getPurchasedCredits } from '@/services/api/purchased-credits';
import { PurchasedCredits } from '@/domains/purchased-credits';

const text = translations.pages.users.details.tabs.purchasedCredis;

const headerList: { name?: string; sm: Magnitude }[] = [
  { name: text.table.header.credits, sm: 3 },
  { name: text.table.header.transactionDate, sm: 3 },
  { name: text.table.header.included, sm: 2 },
  { name: text.table.header.availability, sm: 3 },
  { name: text.table.header.price, sm: 2 },
  { name: text.table.header.status, sm: 3 },
  { sm: 2 }
];

export interface Props {
  userId: string;
}

const PurchasedCreditsTab = ({ userId }: Props) => {
  return (
    <Refresh>
      {({ key, refresh }) => (
        <InfiniteScrollableTable
          key={key}
          className="bg-white"
          source={(page, number) => getPurchasedCredits(page, number, { userId })}
          empty={() => (
            <Placeholder className="mt-12">
              <Typography is="span" type="small-header" className="font-bold">
                <FormattedMessage id={text.empty} />
              </Typography>
            </Placeholder>
          )}
          renderHeader={() => (
            <Table.Header>
              {headerList.map((headerItem, index) => (
                <Table.Data header sm={headerItem.sm} key={`${headerItem.name}-${index}`}>
                  {headerItem.name && (
                    <Typography is="span" className="font-bold uppercase text-gray">
                      <FormattedMessage id={headerItem.name} />
                    </Typography>
                  )}
                </Table.Data>
              ))}
            </Table.Header>
          )}
        >
          {(data) => (
            <React.Fragment>
              {data.map((purchasedCredits: PurchasedCredits) => (
                <Table.LinkRow key={purchasedCredits.id}>
                  <Table.Data sm={headerList[0].sm}>
                    <Typography is="span">{purchasedCredits.name}</Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[1].sm}>
                    <Typography is="span">
                      <FormattedDate value={purchasedCredits.date} day="2-digit" month="long" year="numeric" />
                    </Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[2].sm}>
                    {`${purchasedCredits.availableCredits}/${purchasedCredits.includedCredits}`}
                  </Table.Data>

                  <Table.Data sm={headerList[3].sm}>
                    <Optional
                      of={purchasedCredits.expiresAt}
                      then={(expiresAt) => (
                        <Typography is="span">
                          <FormattedDate value={expiresAt} day="2-digit" month="long" year="numeric" />
                        </Typography>
                      )}
                      else={() => <DashPlaceholder />}
                    />
                  </Table.Data>

                  <Table.Data sm={headerList[4].sm}>
                    <Price value={purchasedCredits.paidPrice} />
                  </Table.Data>

                  <Table.Data sm={headerList[5].sm}>
                    <Badge.PaymentStatus status={purchasedCredits.paymentStatus} />
                  </Table.Data>

                  <Table.Data sm={headerList[6].sm}>
                    {purchasedCredits.paymentStatus === PaymentStatus.Paid && (
                      <PurchasedPackagesListItemDropdown
                        purchasedPackageId={purchasedCredits.id}
                        refreshList={refresh}
                      />
                    )}
                  </Table.Data>
                </Table.LinkRow>
              ))}
            </React.Fragment>
          )}
        </InfiniteScrollableTable>
      )}
    </Refresh>
  );
};

export default PurchasedCreditsTab;
