import React from 'react';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import Typography from '@/components/Typography';
import { translations } from '@/locale';
import { FormattedMessage } from 'react-intl';
import { TestCaseItem } from '@/domains/test-report';

export interface Props {
  data: TestCaseItem;
}

export const CreateStatusElement: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex  w-full gap-28 mb-3">
      <Typography is="p" className="w-5 pl-6">
        {data.id}
      </Typography>

      {data.result == 1 ? (
        <BsCheckCircleFill className="text-green text-1xl w-10 ml-20 mt-1" />
      ) : (
        <BsFillXCircleFill className="text-red ml-20 text-1xl w-10  mt-1" />
      )}

      <Typography is="p" className="flex w-20 ">
        {data.comments}
      </Typography>

      {data.internetSpeed && (
        <Typography key={data.internetSpeed} is="p" className="w-20 flex">
          <FormattedMessage id={translations.placeholders.internet} />
          {data.internetSpeed}
        </Typography>
      )}
    </div>
  );
};
