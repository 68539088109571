import {
  BookingMetrics,
  BookingMetricsData,
  MetricsRange,
  OverviewMetrics,
  UserMetrics,
  LessorRevenue,
  LessorBookings,
  LessorData
} from '@/domains/metrics';
import { format, formatISO } from 'date-fns';
import { authorizedApi } from '../network/authorized-api';

export const getOverviewMetrics = () => authorizedApi.get<OverviewMetrics>('/metrics/overview');

export const getUserMetrics = (range: MetricsRange) =>
  authorizedApi.get<UserMetrics>('/metrics/users', {
    startDate: format(range.startDate, 'yyyy-MM-dd'),
    endDate: format(range.endDate, 'yyyy-MM-dd')
  });

export const getBookingMetrics = (data: BookingMetricsData) =>
  authorizedApi.get<BookingMetrics>('/metrics', {
    startDate: formatISO(data.startDate, { representation: 'date' }),
    endDate: formatISO(data.endDate, { representation: 'date' }),
    groupBy: data.groupBy
  });

export const getLessorRevenueMetrics = (data: LessorData) =>
  authorizedApi.get<LessorRevenue>('/metrics/revenue', {
    ownerId: data.ownerId,
    startDate: formatISO(data.startDate, { representation: 'date' }),
    endDate: formatISO(data.endDate, { representation: 'date' })
  });

export const getLessorBookingMetrics = (data: LessorData) =>
  authorizedApi.get<LessorBookings>('/metrics/booking', {
    ownerId: data.ownerId,
    startDate: formatISO(data.startDate, { representation: 'date' }),
    endDate: formatISO(data.endDate, { representation: 'date' })
  });
