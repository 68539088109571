import React from 'react';
import classnames from 'classnames';

import { SidebarConsumer } from '@/components/Context/Sidebar';

export interface Props {
  className?: string;
  children: any;
}

const SidebarWrapper: React.FC<Props> = (props) => {
  return (
    <SidebarConsumer>
      {({ isOpen, close }) => (
        <React.Fragment>
          <div
            className={classnames(
              'fixed inset-0 transform -translate-x-full bg-black bg-opacity-0 transition-colors z-30 lg:hidden',
              isOpen && 'translate-x-0 bg-opacity-50'
            )}
            onClick={close}
          />
          <div
            className={classnames(
              'fixed top-0 left-0 h-full transform -translate-x-full transition-transform z-40 lg:relative lg:translate-x-0',
              props.className,
              isOpen && 'translate-x-0'
            )}
          >
            {props.children}
          </div>
        </React.Fragment>
      )}
    </SidebarConsumer>
  );
};

export default SidebarWrapper;
