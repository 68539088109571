export enum WifiBand {
  FIVE = 'FIVE',
  TWO = 'TWO'
}

export interface Wifi {
  id: string;
  workspaceId: string;
  ssid: string;
  password: string;
  band: WifiBand;
  createdAt: string;
}
