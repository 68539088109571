import { generatePath } from 'react-router';

export const signIn = `/login`;
export const forgotPassword = {
  main: `/forgot-password`,
  sent: `/forgot-password/sent`
};

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`,
  done: `/reset-password/done`
};

export const home = `/`;

export const dashboard = '/dashboard';

export const locations = {
  list: `/locations`,
  create: `/locations/new`,
  detail: {
    main: `/locations/:id`,
    get: (id: string) => generatePath(`/locations/:id`, { id })
  },
  edit: {
    main: `/locations/:id/edit`,
    get: (id: string) => generatePath(`/locations/:id/edit`, { id })
  },
  about: {
    main: `/locations/:id/about`,
    get: (id: string) => generatePath(`/locations/:id/about`, { id })
  },
  overview: {
    main: `/locations/:id/overview`,
    get: (id: string) => generatePath(`/locations/:id/overview`, { id })
  },
  calendar: {
    main: `/locations/:id/calendar`,
    get: (id: string) => generatePath(`/locations/:id/calendar`, { id })
  },
  promoCodes: {
    main: `/locations/:id/promo-codes`,
    get: (id: string) => generatePath(`/locations/:id/promo-codes`, { id })
  },
  bookings: {
    main: `/locations/:id/bookings`,
    get: (id: string) => generatePath(`/locations/:id/bookings`, { id })
  },
  troubleshooting: {
    main: `/locations/:id/troubleshootings`,
    get: (id: string) => generatePath(`/locations/:id/troubleshootings`, { id })
  },
  expenses: {
    main: `/locations/:id/expenses`,
    get: (id: string) => generatePath(`/locations/:id/expenses`, { id })
  },
  reviews: {
    main: `/locations/:id/reviews`,
    get: (id: string) => generatePath(`/locations/:id/reviews`, { id })
  },
  userManagement: {
    main: `/locations/:id/user-management`,
    get: (id: string) => generatePath(`/locations/:id/user-management`, { id })
  }
};

export const users = {
  list: `/users`,
  detail: {
    main: `/users/:id`,
    get: (id: string) => generatePath(`/users/:id`, { id })
  },
  purchasedPackages: {
    main: `/users/:id/purchased-packages`,
    get: (id: string) => generatePath(`/users/:id/purchased-packages`, { id })
  },
  purchasedCredits: {
    main: `/users/:id/purchased-credits`,
    get: (id: string) => generatePath(`/users/:id/purchased-credits`, { id })
  }
};

export const bookings = {
  list: `/bookings`
};

export const troubleshooting = {
  list: `/troubleshooting`,
  nooka: {
    main: `/troubleshooting/:id/nooka`,
    get: (id: string) => generatePath(`/troubleshooting/:id/nooka`, { id })
  }
};

export const testing = {
  list: `/testing`,
  nooka: {
    main: `/testing/:id/nooka`,
    get: (id: string) => generatePath(`/testing/:id/nooka`, { id })
  }
};

export const testingReports = {
  list: `/testing-reports`
};

export const promotions = {
  list: `/promotions`
};
export const promoPacks = {
  list: `/promo-packs`,
  success: `/promo-packs/payment_success`,
  fail: `/promo-packs/payment_fail`,
  assignedPackages: `/promo-packs/assigned-packages`
};

export const promoCredits = {
  list: `/promo-credits`,
  success: `/promo-credits/payment_success`,
  fail: `/promo-credits/payment_fail`,
  assignedCredits: `/promo-credits/assigned-credits`
};

export const purchasedPackages = {
  list: `/purchased-packages`
};

export const features = {
  list: {
    main: `/features`,
    get: (id: string) => generatePath(`/features`)
  },
  templates: {
    main: `/features/templates`,
    get: (id: string) => generatePath(`/features/templates`)
  }
};

export const status = 'https://nookaspace.betteruptime.com';

export const billingDashboard = {
  customer: (customerId: string) => `https://dashboard.stripe.com/customers/${customerId}`,
  translation: (transactionId: string) => `https://dashboard.stripe.com/payments/${transactionId}`
};

export const overview = '/overview';
export const monitoring = '/monitoring';
