import { testCasesList } from '@/page/Testing/TestingProcessPage/util/test-form';

export const backTestCase = (count, setCount, setTestCase, dataWithTestCases) => {
  const back = count - 1;
  if (count != 0) {
    const decrease = () => {
      setCount(back);
      return back;
    };
    dataWithTestCases.pop();
    setTestCase(testCasesList[decrease()]);
  }
};
