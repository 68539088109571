import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import OverviewPage from './OverviewPage';

const Overview: React.FC = () => (
  <Switch>
    <Route exact path={urls.overview} component={OverviewPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.locations.list} />} />
  </Switch>
);

export default Overview;
