import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Currency } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<Currency>, 'items'>, WrappedComponentProps {}

const CurrencySelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = Object.values(Currency).map((currency) => ({
    value: currency,
    name: intl.formatMessage({ id: translations.domains.currency[currency].text })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(CurrencySelect);
export const CurrencySelectWithValidation = withValidation(Default);
export default Default;
