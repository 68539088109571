import React from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@/components/Button';
import { translations } from '@/locale/messages';
import { TestCaseItem } from '@/domains/test-report';
import { headerCSVFile } from '../../util/test-form';

export interface Props {
  data: TestCaseItem[];
  title: string;
  placeholder: string;
  className: string;
}

const text = translations.pages.testReports;
export const DownloadTestReport: React.FC<Props> = ({ data, title, placeholder, className }) => {
  const intl = useIntl();
  const reportTestCase = data.map((value: TestCaseItem) => [
    value.id,
    value.result == 1
      ? intl.formatMessage({ id: text.status.passed })
      : intl.formatMessage({ id: text.status.notPassed }),
    value.comments,
    value.internetSpeed ? intl.formatMessage({ id: text.status.internetSpeed }) + value.internetSpeed : null
  ]);

  return (
    <Button
      is={CSVLink}
      key={placeholder}
      data={reportTestCase}
      headers={headerCSVFile}
      filename={title}
      appearance="red"
      {...{ className }}
      onClick={() => {}}
    >
      {placeholder ?? <FormattedMessage id={translations.placeholders.download} />}
    </Button>
  );
};
