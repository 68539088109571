import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { PromoCodeListItem, PromoCodeEditForm } from '@/domains';
import { promoCodeCreateOrEditSchema } from '@/validation/schema';
import { getISODateFromDateTime, getPromoCodeNumberOfUseTypeFromBoolean } from '@/util';
import { deletePromoCode, updatePromoCode } from '@/services/api/promo-code';

import Modal, { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import PromoCodeForm from '@/components/Form/PromoCodeForm';
import Form from '@/components/Form';
import { SessionConsumer } from '@/components/Context/Session';
import Button from '@/components/Button';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';

export interface Props extends InstanceProps {
  value: PromoCodeListItem;
  reloadPromoCodeList: () => any;
}

const text = translations.pages.promotions.edit;

const EditPromoCodeModal: React.FC<Props> = ({ reloadPromoCodeList, value, close }) => {
  const intl = useIntl();
  const { owner, workspaces, ...promoCode } = value;

  return (
    <SessionConsumer>
      {({ me }) => (
        <Card className="w-[450px] p-6 mb-10">
          <Grid.Row>
            <Grid.Column className="w-full">
              <ModalHeader close={close} className="mb-8">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.title} />
                </Typography>
              </ModalHeader>

              <Form
                id="edit-promo-code"
                schema={promoCodeCreateOrEditSchema as yup.ObjectSchema<PromoCodeEditForm>}
                initialValues={{
                  ...promoCode,
                  workspaceIds: workspaces.map((workspace) => workspace.workspace.id),
                  userId: owner?.id,
                  maximumHourLimit: promoCode.timeLimit / 60,
                  validUntil: getISODateFromDateTime(promoCode.expiresAt),
                  numberOfUse: getPromoCodeNumberOfUseTypeFromBoolean(
                    promoCode.isSingleUse,
                    promoCode.dailyUseLimit,
                    promoCode.limitUsagePerUser
                  )
                }}
                subscription={{ dirty: true, pristine: true, submitting: true, submitError: true, values: true }}
                onSubmit={(values) =>
                  updatePromoCode(promoCode.id, values)
                    .then(close)
                    .then(reloadPromoCodeList)
                }
              >
                {(renderProps, id) => {
                  const { submitting, dirty, pristine } = renderProps;

                  return (
                    <React.Fragment>
                      <PromoCodeForm id={id} scope="edit" formProps={renderProps} me={me} />

                      <Grid.Row>
                        <Grid.Column className="w-1/2 pr-1">
                          <Modal
                            modal={ConfirmationModal}
                            message={intl.formatMessage({ id: translations.pages.promotions.delete.message })}
                            confirmMessage={intl.formatMessage({ id: translations.pages.promotions.delete.confirm })}
                            cancelMessage={intl.formatMessage({ id: translations.pages.promotions.delete.cancel })}
                            onConfirm={() =>
                              deletePromoCode(promoCode.id)
                                .then(close)
                                .then(reloadPromoCodeList)
                            }
                          >
                            {({ open }) => (
                              <Button type="button" appearance="ghost" onClick={open} block>
                                <FormattedMessage id={text.delete} />
                              </Button>
                            )}
                          </Modal>
                        </Grid.Column>

                        <Grid.Column className="w-1/2 pl-1">
                          <Button
                            type="submit"
                            loading={submitting}
                            disabled={!dirty || pristine}
                            form={id}
                            appearance="red"
                            block
                          >
                            <FormattedMessage id={text.save} />
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  );
                }}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Card>
      )}
    </SessionConsumer>
  );
};

export default EditPromoCodeModal;
