import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { WorkspaceDetails } from '@/domains';
import { translations } from '@/locale';

import Card from '@/components/Card';
import Grid from '@/components/Grid';
import Typography from '@/components/Typography';
import WorkspaceFeature from '@/components/WorkspaceFeature';
import WorkspaceInformation from '@/components/WorkspaceInformation';
import ImageGallery from '@/components/ImageGallery';

export interface Props {
  workspace: WorkspaceDetails;
}

const text = translations.pages.workspaces.details.tabs.about;

const AboutTab: React.FC<Props & WrappedComponentProps> = ({ intl, workspace }) => (
  <React.Fragment>
    <Typography is="h2" type="subheader" className="font-bold mt-3 mb-6">
      <FormattedMessage id={text.details} />
    </Typography>

    <Card className="w-full">
      <Card.Row is="section">
        <Grid.Column className="p-8 w-full">
          <Typography is="h6" type="small-header" className="font-bold mb-8">
            <FormattedMessage id={text.images.title} />
          </Typography>

          {workspace.imagesUrls.length ? (
            <ImageGallery images={workspace.imagesUrls} />
          ) : (
            <Typography is="div" type="regular" className="h-8 text-center">
              <FormattedMessage id={text.images.empty} />
            </Typography>
          )}
        </Grid.Column>
      </Card.Row>

      <Card.Row is="section">
        <Grid.Column className="p-8 w-full">
          <Typography is="h6" type="small-header" className="font-bold mb-8">
            <FormattedMessage id={text.description} />
          </Typography>

          <Typography is="p" type="regular" className="text-blue-dark">
            {workspace.description}
          </Typography>
        </Grid.Column>
      </Card.Row>

      <Card.Row is="section">
        <Grid.Column className="p-8 w-full">
          <Typography is="h6" type="small-header" className="font-bold mb-8">
            <FormattedMessage id={text.features} />
          </Typography>

          <div className="flex flex-row flex-wrap -mx-6 -my-4">
            {workspace.features.map((feature, index) => (
              <WorkspaceFeature key={index} feature={feature} className="mx-6 my-4" />
            ))}
          </div>
        </Grid.Column>
      </Card.Row>

      <Card.Row is="section">
        <Grid.Column className="p-8 w-full">
          <Typography is="h6" type="small-header" className="font-bold mb-8">
            <FormattedMessage id={text.information.title} />
          </Typography>

          <div className="flex flex-row flex-wrap -mx-14">
            <WorkspaceInformation
              icon="calendar"
              title={intl.formatMessage({ id: text.information.constructionDate })}
              className="mx-14"
            >
              <FormattedDate value={workspace.constructionDate} day="2-digit" month="long" year="numeric" />
            </WorkspaceInformation>

            <WorkspaceInformation
              icon="calendar"
              title={intl.formatMessage({ id: text.information.installationDate })}
              className="mx-14"
            >
              <FormattedDate value={workspace.installationDate} day="2-digit" month="long" year="numeric" />
            </WorkspaceInformation>
          </div>
        </Grid.Column>
      </Card.Row>
    </Card>
  </React.Fragment>
);

export default injectIntl(AboutTab);
