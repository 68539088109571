import React from 'react';
import ReactModal from 'react-modal';

import ModalHeader from './ModalHeader';

export type Props<P extends InstanceProps, T> = Omit<P, keyof InstanceProps> & {
  modal: React.ComponentType<P>;
  children?(props: RenderProps<T>): React.ReactNode;
  onClose?: () => any;
  isOpen?: boolean; // initial state
};

export interface InstanceProps {
  close: () => any;
  className: string;
}

export interface RenderProps<T> {
  open(): any;
}

const Modal = <P extends InstanceProps, T>({ modal: Component, children, ...rest }: Props<P, T>) => {
  const [isOpen, setIsOpen] = React.useState(rest.isOpen || false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  React.useEffect(() => {
    if (!isOpen) {
      rest.onClose && rest.onClose();
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <ReactModal
        isOpen={isOpen}
        className="relative"
        htmlOpenClassName="overflow-hidden"
        bodyOpenClassName="overflow-hidden"
        overlayClassName="fixed inset-0 inline-flex justify-center bg-black bg-opacity-20 transition-colors ease-in-out duration-300 overflow-auto z-[100]"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={close}
      >
        <div className="my-16 mx-auto">
          {/**
        // @ts-ignore */}
          <Component {...rest} close={close} />
        </div>
      </ReactModal>

      {children && children({ open })}
    </React.Fragment>
  );
};

Modal.Header = ModalHeader;

export default Modal;
