import { WorkspaceStatus, WorkspaceVisibility } from '@/domains';

export const buildValuesMap = (obj: Record<number | string, number | string>) =>
  Object.entries(obj).reduce((obj, [key, value]) => {
    obj[key] = value;
    obj[value] = key;
    return obj;
  }, {});

export const workspaceStatusMap = buildValuesMap({ false: WorkspaceStatus.Inactive, true: WorkspaceStatus.Active });
export const workspaceVisibilityMap = buildValuesMap({
  true: WorkspaceVisibility.Hidden,
  false: WorkspaceVisibility.Visible
});
