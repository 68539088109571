import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { getWorkspaceFeatureTemplates } from '@/services/api/workspace-feature-templates';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Typography from '@/components/Typography';
import WorkspaceFeatureTemplate from '@/components/WorkspaceFeatureTemplate';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Modal from '@/components/Modal';
import AddFeatureTemplateModal from '@/components/Modal/AddFeatureTemplateModal';

const text = translations.pages.featureManagement.tabs.templates;

const WorkspaceFeatureTemplatesPage: React.FC = () => {
  return (
    <Card className="w-full p-5 min-h-[350px]">
      <LoadData load={getWorkspaceFeatureTemplates}>
        {({ value: templates, loading, reload }) => (
          <React.Fragment>
            <div className="flex justify-between mb-4">
              <Typography is="h1" type="small-header" className="font-bold text-gray-darkest">
                <FormattedMessage id={text.title} />
              </Typography>

              <Modal modal={AddFeatureTemplateModal} onSuccess={reload} selectedIds={[]}>
                {({ open }) => (
                  <Button appearance="red" onClick={open}>
                    <Icon type="plus" className="text-base leading-none pr-2" />
                    <FormattedMessage id={text.addNew} />
                  </Button>
                )}
              </Modal>
            </div>

            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} className="h-[30px] pt-2">
                  <Loading.Indicator size={30} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <div className="flex flex-row flex-wrap -m-2">
                  {templates.map((template) => (
                    <Modal modal={AddFeatureTemplateModal} onSuccess={reload} key={template.id} selectedIds={[]}>
                      {({ open }) => <WorkspaceFeatureTemplate template={template} onClick={open} />}
                    </Modal>
                  ))}
                </div>
              )}
            />
          </React.Fragment>
        )}
      </LoadData>
    </Card>
  );
};

export default WorkspaceFeatureTemplatesPage;
