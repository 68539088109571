import React from 'react';
import classnames from 'classnames';
import { Range } from 'react-range';

import Typography from '@/components/Typography';

export interface Props {
  min: number;
  max: number;
  value: number;
  renderValue: (value: number) => any;
  onFinalChange: (value: number) => void;
  className?: string;
}

const Slider: React.FC<Props> = ({ value: initialValue, min, max, onFinalChange, renderValue, className }) => {
  const [value, setValue] = React.useState(initialValue);

  return (
    <Range
      step={1}
      onChange={(values) => setValue(values[0])}
      onFinalChange={(values) => onFinalChange(values[0])}
      min={min}
      max={max}
      values={[value]}
      renderTrack={({ props, children }) => (
        <div className={classnames('flex items-center', className)}>
          <Typography is="span" className="mr-2 font-bold">
            {renderValue(min)}
          </Typography>

          <div {...props} className="w-full h-1 bg-gray-light rounded-sm">
            {children}
          </div>

          <Typography is="span" className="ml-2 font-bold">
            {renderValue(max)}
          </Typography>
        </div>
      )}
      renderThumb={({ props, value }) => (
        <div {...props} className="relative w-3.5 h-3.5 bg-red rounded-full">
          <div className="inline-block absolute -top-7 mb-1 px-1 -left-1/2 rounded-sm border border-solid border-gray font-bold">
            {renderValue(value)}
          </div>
        </div>
      )}
    />
  );
};

export default Slider;
