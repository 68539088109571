import { Page } from '@/domains';
import { CreditProduct, CreditTier, PurchaseCreditsSessionResult, ResponseContent } from '@/domains/credits';
import { PurchasedCredits } from '@/domains/purchased-credits';

import { authorizedApi } from '@/services/network/authorized-api';

export const getCreditProduct = () => authorizedApi.get<CreditProduct>(`/credits/product`);
export const getCreditTiers = () => authorizedApi.get<ResponseContent<CreditTier>>(`/credits/tiers`);
export const getCreditHistory = (pageNumber: number, pageSize: number, options?: { [x: string]: any }) =>
  authorizedApi.get<Page<PurchasedCredits>>(`/credits/history`, { pageNumber, pageSize, ...options });
export const purchaseCredits = (credits: number) =>
  authorizedApi.post<PurchaseCreditsSessionResult>(`/credits/purchases/sessions`, { credits });
