import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

const timeSlots = [15, 30, 60];

export interface Props extends Omit<SelectProps<{}>, 'items'>, WrappedComponentProps {}

const BookingMinimTimeIntervalSelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = timeSlots.map((time) => ({
    value: time,
    name: intl.formatMessage({ id: translations.bookingMinimTimeInterval.listItem }, { value: time })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(BookingMinimTimeIntervalSelect);
export const BookingMinimTimeIntervalSelectWithValidation = withValidation(Default);
export default Default;
