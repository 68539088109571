export const setDefaultRouterInformation = () => {
  return {
    bootTime: NaN,
    wanConnection: NaN,
    gsmSignalLevel: NaN,
    connectionState3G: NaN,
    operator: NaN,
    carrierType: NaN,
    model: NaN
  };
};
