import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { urls } from '@/constants';

import Typography from '@/components/Typography';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import Price from '@/components/Price';
import Link from '@/components/Link';
import Badge from '@/components/Badge';
import { getPurchasedCredits } from '@/services/api/purchased-credits';
import { PurchasedCredits } from '@/domains/purchased-credits';
import Button from '@/components/Button';

const text = translations.pages.promoCredits.transactionHistory;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.list.table.header.packageName, sm: 3 },
  { name: text.list.table.header.transactionDate, sm: 3 },
  { name: text.list.table.header.included, sm: 2 },
  { name: text.list.table.header.availability, sm: 3 },
  { name: text.list.table.header.price, sm: 2 },
  { name: text.list.table.header.status, sm: 3 },
  { name: text.list.table.header.receipt, sm: 2 }
];

interface Props {
  userId: string;
}

const PromoCreditsTransactionHistorySection = ({ userId }: Props) => {
  return (
    <InfiniteScrollableTable
      className="bg-white"
      source={(page, number) => getPurchasedCredits(page, number, { userId })}
      empty={() => (
        <Placeholder className="mt-12">
          <Typography is="span" type="small-header" className="font-bold">
            <FormattedMessage id={text.list.empty} />
          </Typography>
        </Placeholder>
      )}
      renderHeader={() => (
        <React.Fragment>
          <div className="m-6 flex justify-between">
            <div>
              <Typography is="h1" type="header" className="font-bold text-gray-darkest mb-1">
                <FormattedMessage id={text.title} />
              </Typography>

              <Typography is="span" type="sm" className="text-gray-dark">
                <FormattedMessage id={text.subtitle} />
              </Typography>
            </div>

            <Button is={Link} to={urls.promoCredits.assignedCredits} appearance="red">
              <FormattedMessage id={translations.pages.promoCredits.wallet.assignedCreditsButton} />
            </Button>
          </div>

          <Table.Header>
            {headerList.map((headerItem) => (
              <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                <Typography is="span" className="font-bold uppercase text-gray">
                  <FormattedMessage id={headerItem.name} />
                </Typography>
              </Table.Data>
            ))}
          </Table.Header>
        </React.Fragment>
      )}
    >
      {(data) => (
        <React.Fragment>
          {data.map((transactionHistory: PurchasedCredits, index: number) => (
            <Table.LinkRow key={index}>
              <Table.Data sm={headerList[0].sm}>
                <Typography is="span">{transactionHistory.includedCredits}</Typography>
              </Table.Data>

              <Table.Data sm={headerList[1].sm}>
                <Typography is="span">
                  <FormattedDate value={transactionHistory.date} day="2-digit" month="long" year="numeric" />
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[2].sm}>Included credits</Table.Data>

              <Table.Data sm={headerList[3].sm}>
                <Typography is="span">
                  {transactionHistory.expiresAt != null ? (
                    <FormattedDate value={transactionHistory.expiresAt} day="2-digit" month="long" year="numeric" />
                  ) : (
                    ''
                  )}
                </Typography>
              </Table.Data>

              <Table.Data sm={headerList[4].sm}>
                <Price value={transactionHistory.paidPrice} />
              </Table.Data>

              <Table.Data sm={headerList[5].sm}>
                <Badge.PaymentStatus status={transactionHistory.paymentStatus} />
              </Table.Data>

              <Table.Data sm={headerList[6].sm}>
                <Link to={''} appearance="red">
                  <FormattedMessage id={text.list.table.download} />
                </Link>
              </Table.Data>
            </Table.LinkRow>
          ))}
        </React.Fragment>
      )}
    </InfiniteScrollableTable>
  );
};

export default PromoCreditsTransactionHistorySection;
