import { TestCaseItem } from '@/domains/test-report';

export const testCaseMapper = (testCase, value, textArea, textInternet): TestCaseItem => {
  if (textInternet != '') {
    return {
      id: testCase,
      result: value,
      comments: textArea,
      internetSpeed: textInternet
    };
  }
  return {
    id: testCase,
    result: value,
    comments: textArea
  };
};
