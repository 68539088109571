import React from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { DateLocalizer, EventProps, View } from 'react-big-calendar';

import { translations } from '@/locale';

import Typography from '@/components/Typography';

export interface Props extends EventProps {
  localizer: DateLocalizer;
  view: View;
}

const DayEventWrapper = (props: React.PropsWithChildren<Props>) => {
  return (
    <div>
      {props.view === 'day' && (
        <Typography is="span" className="font-bold mr-2">
          <FormattedMessage id={translations.misc.calendar.booked} />
        </Typography>
      )}

      <Typography is="span" type="sm">
        <FormattedMessage
          id={translations.misc.range}
          values={{ start: format(props.event.start, 'HH:mm'), end: format(props.event.end, 'HH:mm') }}
        />
      </Typography>
    </div>
  );
};

export default DayEventWrapper;
