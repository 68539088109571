import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { revokeAssignedPackage, sendAssignedPackageReminder } from '@/services/api/packages';

import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Dropdown from '@/components/Dropdown';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import Card from '@/components/Card';
import Loading from '@/components/Loading';

enum ActionState {
  SendReminder = 'SEND_REMINDER',
  RevokePackage = 'REVOKE_PACKAGE'
}

const action: Record<ActionState, (id: string) => Promise<unknown>> = {
  [ActionState.SendReminder]: sendAssignedPackageReminder,
  [ActionState.RevokePackage]: revokeAssignedPackage
};

export interface Props {
  id: string;
  email: string;
  refreshList: () => any;
}

const AssignedPackagesListItemDropdown = ({ id, email, refreshList }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const [loadingState, setLoadingState] = React.useState<ActionState>(null);

  const buttonClick = (open: () => any, actionState: ActionState) => () => {
    setLoadingState(actionState);
    action[actionState](id)
      .then(() => open())
      .finally(() => setLoadingState(null));
  };

  const goToWallet = () => Promise.resolve(history.push(urls.promoPacks.list));

  return (
    <Dropdown
      disableStateChange={!!loadingState}
      renderMenu={({ close: closeDropdown }) => (
        <Card className="flex flex-col overflow-hidden items-start">
          <Modal
            modal={ConfirmationModal}
            title={intl.formatMessage({ id: translations.pages.assignedPackages.sendReminder.title })}
            message={intl.formatMessage({ id: translations.pages.assignedPackages.sendReminder.message }, { email })}
            confirmMessage={intl.formatMessage({ id: translations.pages.assignedPackages.sendReminder.confirm })}
            cancelMessage={intl.formatMessage({ id: translations.pages.assignedPackages.sendReminder.cancel })}
            onClose={closeDropdown}
          >
            {({ open }) => (
              <button
                type="button"
                onClick={buttonClick(open, ActionState.SendReminder)}
                disabled={!!loadingState}
                className="p-3 hover:bg-gray-lightest focus:outline-none whitespace-nowrap"
              >
                <div className="flex">
                  <FormattedMessage id={translations.pages.assignedPackages.dropdown.sendReminder} />

                  <Loading visible={loadingState === ActionState.SendReminder} className="ml-2">
                    <Loading.Indicator size={20} borderWidth={3} />
                  </Loading>
                </div>
              </button>
            )}
          </Modal>

          <Modal
            modal={ConfirmationModal}
            title={intl.formatMessage({ id: translations.pages.assignedPackages.revokePackage.title })}
            message={intl.formatMessage({ id: translations.pages.assignedPackages.revokePackage.message }, { email })}
            confirmMessage={intl.formatMessage({ id: translations.pages.assignedPackages.revokePackage.confirm })}
            cancelMessage={intl.formatMessage({ id: translations.pages.assignedPackages.revokePackage.cancel })}
            onConfirm={goToWallet}
            onClose={refreshList}
          >
            {({ open }) => (
              <button
                type="button"
                onClick={buttonClick(open, ActionState.RevokePackage)}
                disabled={!!loadingState}
                className="p-3 hover:bg-gray-lightest focus:outline-none whitespace-nowrap"
              >
                <div className="flex">
                  <FormattedMessage id={translations.pages.assignedPackages.dropdown.revokePackage} />

                  <Loading visible={loadingState === ActionState.RevokePackage} className="ml-2">
                    <Loading.Indicator size={20} borderWidth={3} />
                  </Loading>
                </div>
              </button>
            )}
          </Modal>
        </Card>
      )}
      dropdownClassName="mt-1 -left-9"
    >
      {({ open }) => <Icon type="more" className="hover:cursor-pointer hover:font-bold transition" onClick={open} />}
    </Dropdown>
  );
};

export default AssignedPackagesListItemDropdown;
