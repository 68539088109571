import classnames from 'classnames';
import React from 'react';

import { WorkspaceFeatureTeamplate } from '@/domains';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import ConfirmationModal from '../Modal/ConfirmationModal';
import { translations } from '@/locale';
import { injectIntl, WrappedComponentProps } from 'react-intl';

const text = translations.pages.featureManagement.tabs.templates;

export interface Props {
  onClick?: () => void;
  template: WorkspaceFeatureTeamplate;
  className?: string;
}

const WorkspaceFeatureTemplate: React.FC<Props & WrappedComponentProps> = ({ onClick, template, className, intl }) => (
  <Card
    className={classnames(
      'w-[165px] h-[165px] flex flex-col items-center justify-between p-4 m-2 hover:bg-gray-lightest hover:shadow-sm cursor-pointer',
      className
    )}
    onClick={onClick}
  >
    <div className="flex w-full content-end">
      <Modal
        modal={ConfirmationModal}
        title={intl.formatMessage({ id: text.confirmDeletion.title })}
        message={intl.formatMessage({ id: text.confirmDeletion.message })}
        confirmMessage={intl.formatMessage({ id: text.confirmDeletion.confirmAction })}
        cancelMessage={intl.formatMessage({ id: text.confirmDeletion.cancelAction })}
      >
        {({ open }) => (
          <Button onClick={open}>
            <Icon type="close" size="text-sm" />
          </Button>
        )}
      </Modal>
    </div>

    <div className="grow w-full">
      <Typography is="span" type="regular" className="font-bold mb-4">
        {template.name}
      </Typography>

      <Typography is="p" type="sm" className="text-blue-dark">
        {template.features.length} features
      </Typography>
    </div>
  </Card>
);

export default injectIntl(WorkspaceFeatureTemplate);
