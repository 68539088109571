import React from 'react';
import { RouteComponentProps } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { fullName } from '@/util';
import { Magnitude, rules, urls } from '@/constants';
import { translations } from '@/locale';
import { Currency, WorkspaceListItem } from '@/domains';
import { getWorkspaces } from '@/services/api/workspace';

import Typography from '@/components/Typography';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import HasRole from '@/components/HasRole';
import Info from '@/components/Info';
import Price from '@/components/Price';
import DashPlaceholder from '@/components/Placeholder/DashPlaceholder';
import Optional from '@/components/Optional';
import Badge from '@/components/Badge';
import classNames from 'classnames';
import SearchInput from '@/components/SearchInput/SearchInput';
import Grid from '@/components/Grid/Grid';

const text = translations.pages.workspaces.list;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.table.header.nooka, sm: 5 },
  { name: text.table.header.country, sm: 3 },
  { name: text.table.header.city, sm: 3 },
  { name: text.table.header.customer, sm: 3 },
  { name: text.table.header.revenue, sm: 2 },
  { name: text.table.header.status, sm: 1 }
];

export interface Props {}

const WorkspacesListPage: React.FC<Props & RouteComponentProps> = ({ history }) => {
  const createLocation = () => history.push(urls.locations.create);
  const intl = useIntl();
  let [state, setState] = React.useState({
    keyword: ''
  });
  return (
    <div className="m-8">
      <div className="flex justify-between mb-8 mt-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>

        <HasRole to={rules.WORKSPACE_CREATE}>
          <Button appearance="red" onClick={createLocation}>
            <Icon type="plus" className="text-base leading-none pr-2" />
            <FormattedMessage id={text.addNew} />
          </Button>
        </HasRole>
      </div>

      <SearchInput
        id="users-search"
        className="w-[350px] pb-5"
        placeholder={intl.formatMessage({ id: translations.placeholders.searchLocation })}
        onChange={(keyword) => {
          setState({ keyword: keyword });
        }}
      />

      <InfiniteScrollableTable
        key={state.keyword}
        source={(page, number) => getWorkspaces(page, number, [], state.keyword.length ? state.keyword : null)}
        empty={() => (
          <Placeholder className="mt-12">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.empty} />
            </Typography>
          </Placeholder>
        )}
        renderHeader={() => (
          <Table.Header>
            {headerList.slice(0, -2).map((headerItem) => (
              <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                <Typography is="span" className="font-bold uppercase text-gray">
                  <FormattedMessage id={headerItem.name} />
                </Typography>
              </Table.Data>
            ))}

            <Table.Data header sm={headerList[5].sm} key={headerList[5].name}>
              <Info message={intl.formatMessage({ id: translations.misc.revenueInfo })}>
                {({ open, close }) => (
                  <Typography
                    is="span"
                    type="regular"
                    className="font-bold uppercase text-gray group hover:cursor-default"
                    onMouseOver={open}
                    onMouseOut={close}
                  >
                    <FormattedMessage id={headerList[4].name} />

                    <Info.Button open={open} close={close} />
                  </Typography>
                )}
              </Info>
            </Table.Data>

            <Table.Data header sm={headerList[5].sm} key={headerList[5].name + headerList[5].sm} className="ml-12">
              <Typography is="span" className="font-bold uppercase text-gray">
                <FormattedMessage id={headerList[5].name} />
              </Typography>
            </Table.Data>
          </Table.Header>
        )}
      >
        {(data) => (
          <React.Fragment>
            {data.map((workspace: WorkspaceListItem) => (
              <Table.LinkRow key={workspace.id} hoverable url={urls.locations.about.get(workspace.id)}>
                <Table.Data sm={headerList[0].sm}>
                  <div className="flex items-center">
                    <Image url={workspace.thumbnailUrl} className="w-8 h-8" />

                    <div className="flex flex-col ml-3">
                      <Typography is="span">{workspace.name}</Typography>
                      <PrivateNookaBadge isPrivate={workspace.private} />
                    </div>
                  </div>
                </Table.Data>

                <Table.Data sm={headerList[1].sm}>
                  <Typography is="span">
                    <FormattedMessage id={translations.countries[workspace.country]} />
                  </Typography>
                </Table.Data>

                <Table.Data sm={headerList[2].sm}>{workspace.city}</Table.Data>

                <Table.Data sm={headerList[3].sm}>
                  <div className="flex flex-col items-start">
                    {workspace.owners.map((owner) => (
                      <p key={owner.id}>{fullName(owner.firstName, owner.lastName)}</p>
                    ))}
                  </div>
                </Table.Data>

                <Table.Data sm={headerList[4].sm} className="w-5">
                  <Optional
                    of={workspace.revenue}
                    then={(revenue) => (
                      <Grid.Column className="flex flex-col mr-10">
                        <Typography is="span">
                          <Price value={revenue} />
                        </Typography>
                        <Typography is="span">
                          {workspace.tokensRevenue && workspace.tokensRevenue !== 0 ? (
                            <Price value={{ price: workspace.tokensRevenue, currency: Currency.TK }} />
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Grid.Column>
                    )}
                  />
                </Table.Data>

                <Table.Data sm={headerList[5].sm} className="flex flex-col">
                  <Badge.WorkspaceStatusBadge status={workspace.state} className="mb-2 mr-14" />
                  <Badge.WorkspaceStatusBadge status={workspace.status} className="mb-2 mr-14" />
                  <Badge.WorkspaceStatusBadge status={workspace.visibilityOnMap} className="mb-2 mr-14" />
                </Table.Data>
              </Table.LinkRow>
            ))}
          </React.Fragment>
        )}
      </InfiniteScrollableTable>
    </div>
  );
};

const PrivateNookaBadge: React.FC<{ isPrivate: boolean }> = ({ isPrivate }) => {
  return (
    <div className={classNames('flex', isPrivate ? 'text-red' : 'text-green-dark')}>
      <Icon type={isPrivate ? 'lockClosed' : 'lockOpen'} size="text-sm" />
      <Typography is="span" type="sm" className="ml-1">
        {isPrivate ? 'Private' : 'Public'}
      </Typography>
    </div>
  );
};

export default WorkspacesListPage;
