import React from 'react';
import Grid from '@/components/Grid';
import { translations } from '@/locale';
import Card from '@/components/Card/Card';
import { RouterBitTable } from '../RouterBitTable/RouterBitTable';
import { useIntl } from 'react-intl';
import { RouterBit } from '@/domains/router';
import arrowUP from '@/assets/RouterIcons/arrowUp.svg';
import arrowDown from '@/assets/RouterIcons/arrowDown.svg';

export interface Props {
  routerBits: RouterBit;
}

export const RouterBitCard: React.FC<Props> = ({ routerBits }) => {
  const intl = useIntl();

  return (
    <Card className="h-auto mb-2 text-black">
      <Grid.Row className=" h-10 gap-10 grid-cols-3 grid-rows-1 border-gray  border-b-2 mt-3">
        <div className="h-full w-16"></div>
        <img src={arrowDown} className="h-1/2 w-10 align-middle mr-8" />
        <img src={arrowUP} className=" h-1/2 w-10 align-middle" />
      </Grid.Row>

      <RouterBitTable
        name={intl.formatMessage({ id: translations.pages.troubleshooting.routerInfo.routerBit.lan })}
        recived={routerBits.lanReceved}
        sent={routerBits.lanSent}
      />

      <RouterBitTable
        name={intl.formatMessage({ id: translations.pages.troubleshooting.routerInfo.routerBit.wan })}
        recived={routerBits.wanReceved}
        sent={routerBits.wanSent}
      />

      <RouterBitTable
        name={intl.formatMessage({ id: translations.pages.troubleshooting.routerInfo.routerBit.vpn })}
        recived={routerBits.vpnReceved}
        sent={routerBits.vpnSent}
      />

      <RouterBitTable
        name={intl.formatMessage({
          id: translations.pages.troubleshooting.routerInfo.routerBit.ghz24
        })}
        recived={routerBits.ghz24Receved}
        sent={routerBits.ghz24Sent}
      />

      <RouterBitTable
        name={intl.formatMessage({ id: translations.pages.troubleshooting.routerInfo.routerBit.ghz5 })}
        recived={routerBits.ghz5Receved}
        sent={routerBits.ghz5Sent}
      />

      <RouterBitTable
        name={intl.formatMessage({ id: translations.pages.troubleshooting.routerInfo.routerBit.sim })}
        recived={routerBits.simReceved}
        sent={routerBits.simSent}
      />
    </Card>
  );
};
