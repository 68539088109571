import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import Button from '../Button';

interface Props {
  active: boolean;
  onChangeStatus: () => void;
}

const UserStatus: React.FC<Props> = ({ active, onChangeStatus }) => {
  return (
    <div>
      <div className={active ? 'text-green' : 'text-red'}>
        <FormattedMessage id={active ? translations.userStatus.active : translations.userStatus.inactive} />
      </div>
      <Button className="underline hover:no-uderline" onClick={onChangeStatus}>
        <FormattedMessage
          id={active ? translations.inputs.fields.status.deactivate : translations.inputs.fields.status.activate}
        />
      </Button>
    </div>
  );
};

export default UserStatus;
