import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useInterval, useAsyncRetry } from 'react-use';
import { translations } from '@/locale';
import Typography from '@/components/Typography';
import MapSection from './Sections/MapSection/MapSection';
import HeaderSection from './Sections/HeaderSection/HeaderSection';
import ToggleSwitch from '@/components/ToggleSwitch';
import { getWorkspaces } from '@/services/api/workspace';
import { WorkspaceState, WorkspaceStatus, WorkspaceVisibility } from '@/domains';
import { getWorkspaceMonitoringStatusPreviousDays } from '@/services/api/monitoring';

const text = translations.pages.overview;

const OverviewPage: React.FC = () => {
  const [refresh, setRefresh] = React.useState(true);

  const { value: workspaces = { content: [] }, loading, retry } = useAsyncRetry(async () => getWorkspaces(1, 1000));

  const activeWorkspaces = React.useMemo(() => workspaces.content.filter((w) => w.status === WorkspaceStatus.Active), [
    workspaces.content
  ]);

  const visibleWorkspaces = React.useMemo(
    () => workspaces.content.filter((w) => w.visibilityOnMap === WorkspaceVisibility.Visible),
    [workspaces.content]
  );
  const { value: prevStatus } = useAsyncRetry(async () => getWorkspaceMonitoringStatusPreviousDays(1, 1000));

  useInterval(
    () => {
      retry();
    },
    refresh ? 60000 : null
  );

  return (
    <div className="m-8">
      {!activeWorkspaces.length && !loading && (
        <div>
          <Typography is="h1" type="header" className="font-bold text-gray-darkest">
            <FormattedMessage id={text.empty} />
          </Typography>
        </div>
      )}

      {!!activeWorkspaces.length && (
        <>
          <div>
            <div className="flex justify-between mb-2">
              <Typography is="h1" type="header" className="font-bold text-gray-darkest">
                <FormattedMessage id={text.title} />
              </Typography>
            </div>

            <div className="mb-2 flex flex-row-reverse">
              <ToggleSwitch label={false} checked={refresh} onChange={() => setRefresh((refresh) => !refresh)}>
                <Typography is="div" type="regular" className="text-gray-darkest">
                  <FormattedMessage id={text.refresh} />
                </Typography>
              </ToggleSwitch>
            </div>

            <HeaderSection
              visible={visibleWorkspaces.length}
              active={activeWorkspaces.filter((w) => w.state === WorkspaceState.Online).length}
              inactive={activeWorkspaces.filter((w) => w.state === WorkspaceState.Offline).length}
              prevStatus={prevStatus}
            />

            <MapSection workspaces={activeWorkspaces} />
          </div>
        </>
      )}
    </div>
  );
};

export default OverviewPage;
