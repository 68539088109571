import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { Pricing } from '@/domains';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Button from '@/components/Button';
import boltIcon from './boltIcon.svg';
import promoPackBgImg from './promo-pack-background.png';
import CreditSelector from '../CreditSelector';
import { CreditTier } from '@/domains/credits';
import { purchaseCredits } from '@/services/api/credits';

interface Props {
  includedCredits: number;
  className?: string;
  creditTiers: CreditTier[];
}

interface Bonus {
  tokenBonus: number;
  amplifier: number;
}

interface Credit {
  creditTotal: number;
  creditSelected: number | string;
  creditStep: number;
  creditError: boolean;
}

interface CreditsData {
  credit: Credit;
  bonus: Bonus;
}

const defaultQuantity = 20;

const PromoCreditsCard = ({ includedCredits, className, creditTiers }: Props) => {
  const [creditData, setCreditData] = useState<CreditsData>({
    credit: {
      creditTotal: defaultQuantity,
      creditSelected: defaultQuantity,
      creditStep: defaultQuantity,
      creditError: false
    },
    bonus: { tokenBonus: 0, amplifier: 0 }
  });

  const setSelectedValue = (value: number | string) => {
    setCreditData((prevState) => ({
      ...prevState,
      credit: { ...prevState.credit, creditSelected: value }
    }));
  };

  const setBonusByValue = (value: number | string) => {
    setSelectedValue(value);
    if (typeof value === 'string') return;

    const selectedCredits = value * includedCredits;
    creditTiers.map((el: CreditTier) => {
      if (
        (el.minCredits <= selectedCredits && selectedCredits < el.maxCredits) ||
        (el.maxCredits === null && selectedCredits > 2500)
      ) {
        let bonusToken = Math.ceil((selectedCredits * el.discountPercentage) / 100);
        setCreditData((prevState) => ({
          ...prevState,
          credit: {
            ...prevState.credit,
            creditTotal: selectedCredits,
            creditStep: el.defaultStep / includedCredits,
            creditError: false
          },
          bonus: { tokenBonus: bonusToken, amplifier: el.discountPercentage }
        }));
      }
    });
  };

  const handleSetCreditSelected = (value: number | string) => {
    if (typeof value === 'string') {
      setCreditData((prevState) => ({
        ...prevState,
        credit: { ...prevState.credit, creditError: true }
      }));
    }
    setBonusByValue(value);
  };

  const handleSubmit = () => {
    purchaseCredits(creditData.credit.creditTotal).then((res) => {
      window.open(res.url, '_self', 'noopener,noreferrer');
    });
  };

  useEffect(() => {
    handleSetCreditSelected(defaultQuantity);
  }, []);

  return (
    <Card className={classnames('p-4 group', className)}>
      <div className="flex justify-center p-2">
        <Typography is="span" type="xs" className="text-gray-dark">
          {`Select amount to top-up`}
        </Typography>
      </div>
      <div className="flex justify-center p-2">
        <Typography is="span" type="header" className="font-bold text-gray-darkest">
          {`${creditData.credit.creditTotal} tokens`}
        </Typography>
      </div>
      <CreditSelector
        creditSelected={creditData.credit.creditSelected}
        step={creditData.credit.creditStep}
        handleSetCreditSelected={handleSetCreditSelected}
        className={`my-6 w-2/5 ${creditData.credit.creditError ? 'border-red' : ''}`}
        position={'center'}
        type={'euro'}
      />
      {creditData.credit.creditError ? (
        <div className="flex justify-center py-3">
          <Typography is="span" type="xs" className="text-red">
            {`This field should not be empty`}
          </Typography>
        </div>
      ) : (
        <></>
      )}

      <div
        className="flex items-center justify-evenly h-[58px] bg-[#F7F7F7] bg-contain rounded-xl"
        style={{ backgroundImage: `url(${promoPackBgImg})` }}
      >
        <div className="flex flex-col justify-center">
          <Typography is="span" type="xs" className="text-gray-dark flex justify-center">
            {`Amplifier`}
          </Typography>
          <Typography is="span" type="sm" className="font-bold text-gray-darkest flex justify-center">
            {`${creditData.bonus.amplifier}%`}
          </Typography>
        </div>
        <div>
          <Typography is="span" type="xs" className="text-gray-dark flex justify-center">
            {`Token bonus`}

            <img className="pl-1" src={boltIcon} width={18} />
          </Typography>
          <Typography is="span" type="sm" className="font-bold text-gray-darkest  flex justify-center">
            {`+ ${creditData.bonus.tokenBonus}`}
          </Typography>
        </div>
      </div>

      <div className="flex justify-center py-3 mb-6">
        <Typography is="span" type="xs" className="text-gray-dark">
          {`Buy 300 more tokens to get `}
          <Typography is="span" type="xs" className="text-red">
            {`7% bonus`}
          </Typography>
        </Typography>
      </div>

      <div className="pt-11">
        <Button block appearance="red-rounded" onClick={() => handleSubmit()} disabled={creditData.credit.creditError}>
          <FormattedMessage id={translations.pages.promoCredits.credits.button} />
        </Button>
      </div>
    </Card>
  );
};

export default PromoCreditsCard;
