import React from 'react';
import { debounce, uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IotProvider, WorkspaceListItem } from '@/domains';
import usePagination from '@/hooks/usePagination';
import Select, { Props as SelectProps, Item as SelectItem } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';
import { getWorkspaceById, getWorkspaces } from '@/services/api/workspace';
import { urls } from '@/constants';
import { translations } from '@/locale';
import { id } from 'date-fns/locale';
import Placeholder from '@/components/Placeholder';

export interface Props extends PropsUsedFor, Omit<SelectProps<string>, 'items'> {}

export interface PropsUsedFor {
  usedFor?: string;
  placeholder?: string;
}

interface State {
  selected?: SelectItem<string>[];
  keyword?: string;
}

const toWorkspaceSelectItem = (workspace: WorkspaceListItem): SelectItem<string> => ({
  value: workspace.id,
  name: workspace.name
});

export const WorkspaceSelectList = ({ usedFor, placeholder, ...rest }: React.PropsWithChildren<Props>) => {
  const intl = useIntl();
  const [state, setState] = React.useState<State>({ selected: [] });
  const { loading, more, data, page, loadPage } = usePagination({
    id: state.keyword,
    source: (page, size) => getWorkspaces(page, size, [], state.keyword, IotProvider.NookaLive)
  });
  const history = useHistory();

  const loadFromId = (ids: string[]) => {
    setState((prevState) => ({ ...prevState, selected: [] }));
    if (ids) {
      ids?.map((el) => {
        getWorkspaceById(el).then((workspace) =>
          setState((prevState) => ({
            ...prevState,
            selected: prevState.selected?.concat(toWorkspaceSelectItem(workspace))
          }))
        );
      });
    }
  };

  const memoizedData = React.useMemo(() => data.map(toWorkspaceSelectItem), [data]);
  React.useEffect(() => {
    if (!!rest.value) {
      loadFromId(rest.value as string[]);
    }
  }, []);

  const ref = React.useRef<HTMLUListElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  const handleSearch = debounce((value) => {
    if (!value || loading) return;

    setState((prevState) => ({ ...prevState, keyword: value }));
    loadPage(1);
  }, 300);

  const onBlur = () => {
    setState((prevState) => ({ ...prevState, keyword: undefined }));
  };

  return (
    <Select
      {...{ ...rest, onBlur }}
      onChange={(value: any) => {
        usedFor === intl.formatMessage({ id: translations.navigation.troubleshooting })
          ? (history.push(urls.troubleshooting.nooka.get(String(value))), history.go(0))
          : history.push(urls.testing.nooka.get(String(value)));
      }}
      onInputValueChange={handleSearch}
      items={!state.selected ? memoizedData : uniqBy(memoizedData.concat(state.selected), (item) => item.value)}
      dropdownContentLoading={loading}
      onDropdownContentScroll={handleScroll}
      dropdownContentRef={ref}
      multiple={true}
      placeholder={
        placeholder
          ? placeholder
          : usedFor === intl.formatMessage({ id: translations.navigation.troubleshooting })
          ? intl.formatMessage({ id: translations.inputs.fields.troubleshooting.placeholder })
          : intl.formatMessage({ id: translations.placeholders.testNookaSelect })
      }
    />
  );
};

export const WorkspaceSelectListWithValidation = withValidation(WorkspaceSelectList);
export default WorkspaceSelectList;
