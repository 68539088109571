import {
  Currency,
  Page,
  TransactionHistory,
  PaymentStatus,
  UserWallet,
  Wallet,
  WalletAssignmentResponse,
  UserAvailableCredits
} from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getWallets = () => authorizedApi.get<Wallet[]>(`/admin/wallet`);

export const getWallet = (userId: string) => authorizedApi.get<UserWallet>(`/wallet/${userId}`);

export const getAvailableCredits = (userId: string) =>
  authorizedApi.get<UserAvailableCredits>(`/credits/available`, { userId });

export const assignPackages = (packageId: string, emails: string[]) =>
  authorizedApi.post<WalletAssignmentResponse>(`/admin/wallet/assign`, { packageId, emails });

export const assignHours = (userId: string, hours: number) =>
  authorizedApi.post(`/admin/wallet/assign-hours`, {
    userId,
    hours,
    expiresAt: null
  });

export const assignCredits = (userId: string, credits: number) =>
  authorizedApi.post(`/credits/assign`, {
    userId,
    credits,
    expiryDate: null
  });

export const getTransactionHistory = (pageNumber: number, pageSize: number) =>
  new Promise<Page<TransactionHistory>>((resolve, reject) =>
    setTimeout(
      () =>
        resolve({
          pageNumber,
          pageSize,
          totalNumberOfElements: 2,
          content: [
            {
              id: '1',
              userName: 'John Doe',
              title: 'Advanced pack',
              createAt: '2022-03-31T19:11:47.302Z',
              price: { currency: Currency.EUR, price: 20 },
              status: PaymentStatus.Paid,
              receiptUrl: ''
            },
            {
              id: '2',
              userName: 'Jane Doe',
              title: 'Advanced pack',
              createAt: '2022-03-30T09:23:17.302Z',
              price: { currency: Currency.EUR, price: 54 },
              status: PaymentStatus.Refunded,
              receiptUrl: ''
            }
          ]
        }),
      1000
    )
  );
