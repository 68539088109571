import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Link from '@/components/Link';
import Typography from '@/components/Typography';

export interface Props extends RouteComponentProps {}

const text = translations.pages.resetPasswordDone;

const ResetPasswordDonePage: React.FC<Props> = () => (
  <div className="flex flex-col justify-around p-5 text-center w-full">
    <div className="flex flex-col">
      <Typography is="h1" type="header" className="font-bold mb-4">
        <FormattedMessage id={text.title} />
      </Typography>

      <Typography is="p" type="regular" className="text-gray-dark">
        <FormattedMessage id={text.subtitle} />
      </Typography>

      <Link to={urls.signIn} appearance="red">
        <Typography is="div" type="regular" className="text-center mt-8">
          <FormattedMessage id={text.signIn} />
        </Typography>
      </Link>
    </div>
  </div>
);

export default ResetPasswordDonePage;
