import React from 'react';
import { useIntl } from 'react-intl';

import { UserRole } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<UserRole>, 'items'> {}

const RoleSelect = ({ ...rest }: Props) => {
  const intl = useIntl();

  const items = Object.values(UserRole).map((userRole) => ({
    value: userRole,
    name: intl.formatMessage({ id: translations.domains.userRole[userRole] })
  }));

  return <Select {...rest} items={items} />;
};

export const RoleSelectWithValidation = withValidation(RoleSelect);
export default RoleSelect;
