import React from 'react';
import classnames from 'classnames';

import Loading from '@/components/Loading';

import style from './Button.sass';

export type Appearance = 'red' | 'blue' | 'green' | 'ghost' | 'group-red' | 'transparent' | 'red-rounded';

interface AppearanceStyle {
  enabled: string;
  disabled?: string;
}

const appearanceClasses: Record<Appearance, AppearanceStyle> = {
  red: {
    enabled: 'bg-red text-white hover:bg-opacity-80 rounded',
    disabled: 'bg-gray text-white hover:bg-opacity-80 rounded'
  },
  'red-rounded': {
    enabled: 'bg-red text-white hover:bg-opacity-80 rounded-full',
    disabled: 'bg-gray text-white hover:bg-opacity-80 rounded-full'
  },
  blue: {
    enabled: 'bg-blue-light text-white hover:bg-opacity-80 rounded',
    disabled: 'bg-gray text-white hover:bg-opacity-80 rounded'
  },
  ghost: {
    enabled: 'bg-white text-gray-darkest border border-solid border-gray-lighter hover:border-gray-dark rounded',
    disabled: 'bg-gray text-gray-darkest border border-solid border-gray-lighter hover:border-gray-dark rounded'
  },
  'group-red': {
    enabled: 'bg-white text-red group-hover:bg-red group-hover:text-white group-hover:hover:bg-opacity-80 rounded',
    disabled: 'bg-white text-red-opacity-30 group-hover:hover:bg-opacity-80 rounded'
  },
  transparent: {
    enabled: 'text-red outline-none rounded',
    disabled: 'text-gray outline-none rounded'
  },
  green: {
    enabled: 'bg-green text-white hover:bg-opacity-80 rounded',
    disabled: 'bg-gray text-white hover:bg-opacity-80 rounded'
  }
};

export interface Props extends React.ButtonHTMLAttributes<any> {
  is?: any;
  appearance?: Appearance;
  block?: boolean;
  loading?: boolean;
  [x: string]: any;
}

export default class Button extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    is: 'button',
    appearance: null,
    loading: null
  };

  render() {
    const { is: Component, appearance, block, loading, disabled, className, children, onClick, ...rest } = this.props;
    const appearanceStyle = appearanceClasses[appearance!];

    return (
      <Component
        className={classnames(
          style.root,
          {
            'block w-full': block,
            [style.loading]: loading,
            [appearanceStyle?.enabled]: !disabled,
            [appearanceStyle?.disabled]: disabled,
            'px-4 h-10 leading-10 min-w-[40px] text-sm focus:outline-none': appearance
          },
          className
        )}
        disabled={loading || disabled}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {!!loading && (
          <span className={style.loader}>
            <Loading visible={!!loading} center>
              <Loading.Indicator size={24} borderWidth={3} />
            </Loading>
          </span>
        )}

        <span className={style.content}>{children}</span>
      </Component>
    );
  }
}
