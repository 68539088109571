import { Session, ResetPassword, TokenValidity, RawSession } from '@/domains/session';

import { api } from '@/services/network/api';
import { authorizedApi } from '@/services/network/authorized-api';

const mapSession = (session: RawSession): Session => ({
  accessToken: session.access_token,
  refreshToken: session.refresh_token,
  userId: session.userId
});

export const signInWithEmail = (email: string, password: string) => {
  const authData = new URLSearchParams();

  authData.append('grant_type', 'password');
  authData.append('username', email);
  authData.append('password', password);

  return api
    .post<RawSession>(`/auth/token`, authData, {
      sentry: { status: [401] },
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(mapSession);
};

export const renewToken = (refreshToken: string) => {
  const authData = new URLSearchParams();

  authData.append('grant_type', 'refresh_token');
  authData.append('refresh_token', refreshToken);

  return api
    .post<RawSession>(`/auth/token`, authData, {
      sentry: { status: [401] },
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(mapSession);
};

export const sendResetPasswordLink = (email: string) => api.post<null>(`/users/password-token`, { email });

export const checkResetPasswordTokenValidity = (token: string) =>
  api.post<TokenValidity>(`/users/password-token-validation`, { token });

export const resetPassword = (password: ResetPassword) => api.put<null>(`/users/password`, password);
