import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UserAdd, UserRole } from '@/domains';
import { translations } from '@/locale';
import { phoneNumberSchema, shortStringSchema } from '@/validation';
import { inviteUser } from '@/services/api/users';

import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { CountrySelectWithValidation } from '@/components/Select/CountrySelect';
import Button from '@/components/Button';
import Grid from '@/components/Grid';
import { SubmitError } from '@/components/Error';
import Icon from '@/components/Icon';
import { RoleSelectWithValidation } from '@/components/Select/RoleSelect';

import { Step, StepInstanceProps } from './AddUserModal';

export interface Props extends StepInstanceProps {}

const fields = translations.inputs.fields;
const schema: yup.ObjectSchema<UserAdd> = yup
  .object({
    firstName: shortStringSchema.label(fields.firstName.label).required(),
    lastName: shortStringSchema.label(fields.lastName.label).required(),
    email: yup
      .string()
      .email()
      .label(fields.email.label)
      .required(),
    phoneNumber: phoneNumberSchema.label(fields.phoneNumber.label).required(),
    type: yup
      .mixed<UserRole>()
      .oneOf(Object.values(UserRole))
      .label(fields.userType.label)
      .required(),
    city: shortStringSchema.label(fields.city.label).required(),
    country: yup
      .string()
      .label(fields.country.label)
      .required()
  })
  .required();

const InviteUserStep: React.FC<Props & WrappedComponentProps> = ({ intl, nextStep }) => (
  <Form
    id="add-user"
    schema={schema}
    subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
    onSubmit={(values) =>
      inviteUser(values).then(() => nextStep(Step.InvitationSent, { email: values.email, type: values.type }))
    }
    className="mt-8"
  >
    {({ submitError, submitting, dirty, pristine }, id) => (
      <React.Fragment>
        <Form.Field
          is={TextInputWithValidation}
          id={`${id}-email`}
          name="email"
          type="email"
          label={intl.formatMessage({ id: fields.email.label })}
          placeholder={intl.formatMessage({ id: fields.email.placeholder })}
          readOnly={submitting}
        />

        <Grid.Row className="my-4">
          <Grid.Column className="w-1/2 pr-1">
            <Form.Field
              is={TextInputWithValidation}
              id={`${id}-first-name`}
              name="firstName"
              type="text"
              label={intl.formatMessage({ id: fields.firstName.label })}
              placeholder={intl.formatMessage({ id: fields.firstName.placeholder })}
              readOnly={submitting}
            />
          </Grid.Column>

          <Grid.Column className="w-1/2 pl-1">
            <Form.Field
              is={TextInputWithValidation}
              id={`${id}-last-name`}
              name="lastName"
              type="text"
              label={intl.formatMessage({ id: fields.lastName.label })}
              placeholder={intl.formatMessage({ id: fields.lastName.placeholder })}
              readOnly={submitting}
            />
          </Grid.Column>
        </Grid.Row>

        <Form.Field
          is={TextInputWithValidation}
          id={`${id}-phone-number`}
          name="phoneNumber"
          type="text"
          label={intl.formatMessage({ id: fields.phoneNumber.label })}
          placeholder={intl.formatMessage({ id: fields.phoneNumber.placeholder })}
          readOnly={submitting}
          className="my-4"
        />

        <Form.Field
          is={RoleSelectWithValidation}
          id={`${id}-type`}
          name="type"
          label={intl.formatMessage({ id: fields.userType.label })}
          placeholder={intl.formatMessage({ id: fields.userType.placeholder })}
          readOnly={submitting}
          className="my-4"
        />

        <Grid className="my-5">
          <Grid.Row>
            <Grid.Column className="w-1/2 pr-1">
              <Form.Field
                is={TextInputWithValidation}
                id={`${id}-city`}
                name="city"
                type="text"
                label={intl.formatMessage({ id: fields.city.label })}
                placeholder={intl.formatMessage({ id: fields.city.placeholder })}
                readOnly={submitting}
              />
            </Grid.Column>

            <Grid.Column className="w-1/2 pl-1">
              <Form.Field
                is={CountrySelectWithValidation}
                id={`${id}-country`}
                name="country"
                type="text"
                label={intl.formatMessage({ id: fields.country.label })}
                placeholder={intl.formatMessage({ id: fields.country.placeholder })}
                readOnly={submitting}
                searchable
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <SubmitError error={submitError} />

        <Button
          type="submit"
          loading={submitting}
          disabled={!dirty || pristine}
          icon="plus"
          appearance="red"
          className="mt-4 w-full"
        >
          <Icon type="plus" className="text-base leading-none pr-2" />

          <FormattedMessage id={translations.modals.addUserModal.inviteUserStep.button} />
        </Button>
      </React.Fragment>
    )}
  </Form>
);

export default injectIntl(InviteUserStep);
