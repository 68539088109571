import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { useInterval } from 'react-use';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import { Widget, WidgetType } from '@/domains';
import useLoad from '@/hooks/useLoad';
import { translations } from '@/locale';
import { getAllWorkspaceWidgets } from '@/services/api/widgets';
import { getWorkspaceDetails } from '@/services/api/workspace';
import StatusTroubleshooting from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/StatusTroubleshooting';
import TroubleshootingWidgetsLayout from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/TroubleshootingWidgetsLayout';
import { ActionLogComponent } from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/ActionLogComponent/ActionLogComponent';
import WorkspaceSelectList from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/WorkspaceSelectList/WorkspaceSelectList';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import Router from './Router';
import { VersionIOT } from '@/domains/iot-boards/iot-version';

export interface Props {}

const WorkspaceTroubleshootingDetails: React.FC<Props &
  WrappedComponentProps &
  RouteComponentProps<{ id: string }>> = ({ match }) => {
  const text = translations.pages.workspaces.details.tabs.troubleshooting;
  const [refresh, setRefresh] = React.useState(true);
  const workspaceId = match.params.id;
  const { value, loading, reload } = useLoad({ load: () => getAllWorkspaceWidgets(workspaceId) });
  const [page, setCurrentPage] = React.useState(1);
  const intl = useIntl();
  const handlePage = (value: number) => {
    setCurrentPage(value);
  };
  const [routerStatus, setRouterStatus] = React.useState(false);

  const renderWidgetBasedOnType = React.useCallback(
    (version: VersionIOT, widget: Widget, providerId) => {
      switch (widget.type) {
        case WidgetType.Boolean:
          return (
            <TroubleshootingWidgetsLayout
              version={version}
              widget={widget}
              workspaceId={workspaceId}
              providerId={providerId}
              handlePage={handlePage}
            />
          );

        default:
          return null;
      }
    },
    [workspaceId]
  );

  useInterval(
    () => {
      reload();
    },
    refresh ? 60000 : null
  );

  return (
    <LoadData load={() => getWorkspaceDetails(workspaceId)}>
      {({ value: workspace, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className="h-full">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          )}
          else={() => (
            <div className="m-8">
              <div className="flex justify-between mb-8">
                <Typography is="h1" type="header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.title} />
                </Typography>
              </div>

              <React.Fragment>
                <div className="mb-2 flex flex-row-reverse  justify-between w-full">
                  <ToggleSwitch label={false} checked={refresh} onChange={() => setRefresh((refresh) => !refresh)}>
                    <Typography is="div" type="regular" className="text-gray-darkest">
                      <FormattedMessage id={text.refresh} />
                    </Typography>
                  </ToggleSwitch>

                  <Card className="w-1/2 h-full bg-transparent ml-3">
                    <Typography
                      is={WorkspaceSelectList}
                      id={`workspace-select`}
                      name="workspaceSelect"
                      className="w-full bg-white"
                      placeholder={workspace.name}
                      editable
                      required
                      usedFor={intl.formatMessage({ id: translations.navigation.troubleshooting })}
                    />
                  </Card>
                </div>

                <StatusTroubleshooting workspace={workspace} routerStatus={routerStatus} />

                <Router
                  workspaceId={workspace.id}
                  providerId={workspace.providerId}
                  setRouterStatus={setRouterStatus}
                />

                <div className="w-full h-full">
                  <Grid.Column sm={5} padded>
                    <Grid.Row>
                      <Typography is="span" className="font-bold text-gray-darkest p-2 gap-2">
                        {intl.formatMessage({ id: translations.placeholders.identifierWorskpace })}
                      </Typography>
                      <Typography is="span" className="font-bold text-gray-darkest p-2">
                        {workspace.providerId}
                      </Typography>
                    </Grid.Row>
                  </Grid.Column>

                  <If
                    condition={!value || !value.length}
                    then={() => (
                      <Placeholder className="mt-12">
                        <Typography is="span" type="small-header" className="font-bold">
                          <FormattedMessage id={text.empty.title} />
                        </Typography>

                        <Typography is="span" type="small-header" className="text-gray-dark">
                          <FormattedMessage id={text.empty.description} />
                        </Typography>
                      </Placeholder>
                    )}
                    else={() => (
                      <React.Fragment>
                        <div className="justify-between w-full h-full justify-items-start ml-3">
                          {value.map(
                            (group) =>
                              group.title != 'door' &&
                              group.title != 'Wifi' && (
                                <Card key={group.title} className="w-[500px] ml-3 mt-3">
                                  {group.widgets.map((widget) => (
                                    <div key={widget.id} className="w-full my-5 ">
                                      {widget.id != 'door-status' &&
                                        widget.id != 'PIR' &&
                                        widget.id != 'QRcode' &&
                                        renderWidgetBasedOnType(workspace.versionIOT, widget, workspace.providerId)}
                                    </div>
                                  ))}
                                </Card>
                              )
                          )}
                        </div>

                        <ActionLogComponent
                          {...{ workspaceId, page, setCurrentPage }}
                          workspaceName={workspace.name}
                          versionIOT={
                            workspace.versionIOT
                              ? intl.formatMessage({
                                  id: translations.domains.iotProviderVersion[workspace.versionIOT]
                                })
                              : ''
                          }
                        />
                      </React.Fragment>
                    )}
                  />
                </div>
              </React.Fragment>
            </div>
          )}
        />
      )}
    </LoadData>
  );
};

export default injectIntl(WorkspaceTroubleshootingDetails);
