import Button from '@/components/Button';
import React from 'react';

export interface Props {
  page: number;
  setCurrentPage: any;
  numberOfElements: number;
}

const TableFooter = ({ page, setCurrentPage, numberOfElements }) => {
  return (
    <div key="Pagination">
      <Button appearance="transparent" onClick={() => setCurrentPage(1)}>
        {'<<'}
      </Button>
      <Button appearance="transparent" onClick={() => (page > 1 ? setCurrentPage(page - 1) : setCurrentPage(1))}>
        {'<'}
      </Button>

      <Button appearance="transparent" onClick={() => setCurrentPage(page)}>
        {page}
      </Button>
      <Button
        appearance="transparent"
        onClick={() => (page < Math.ceil(numberOfElements / 10) ? setCurrentPage(page + 1) : setCurrentPage(page))}
      >
        {'>'}
      </Button>
      <Button
        appearance="transparent"
        onClick={() =>
          numberOfElements % 10 == 0
            ? setCurrentPage(numberOfElements / 10)
            : setCurrentPage(Math.ceil(numberOfElements / 10))
        }
      >
        {'>>'}
      </Button>
    </div>
  );
};

export default TableFooter;
