import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Icon from '@/components/Icon';
import Button from '@/components/Button';

export interface Props extends InstanceProps {
  onConfirm: () => void;
}

const text = translations.pages.promoPacks.paymentFail;

const PaymentFailModal = ({ onConfirm, close }: Props) => {
  return (
    <Card className="w-[450px] p-6 mb-10">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close} className="mb-8" />

          <div className="flex flex-col items-center px-[90px]">
            <div className="flex items-center justify-center w-[70px] h-[70px] rounded-full border-2 border-solid border-gray-dark mb-6">
              <Icon type="exclamation" className="text-red" size="text-4xl" />
            </div>

            <Typography is="span" type="small-header" className="text-center font-bold mb-4">
              <FormattedMessage id={text.title} />
            </Typography>

            <Typography is="span" type="sm" className="text-center text-gray-dark mb-10">
              <FormattedMessage id={text.subtitle} />
            </Typography>
          </div>

          <div className="w-full flex justify-end">
            <Button type="button" appearance="red" onClick={onConfirm}>
              <FormattedMessage id={text.retryButton} />
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default PaymentFailModal;
