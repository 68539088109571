export const COMMON_CHART_OPTIONS = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      ticks: {
        min: 0,
        precision: 0
      }
    }
  },
  tooltips: {
    mode: 'index'
  },
  plugins: {
    legend: {
      position: 'bottom' as const
    }
  }
};
