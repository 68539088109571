import React from 'react';
import { getAllTestReportsByWorkspaceName } from '@/services/api/test-reports';
import { useIntl } from 'react-intl';
import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import { TestReport } from '@/domains/test-report';
import { DownloadTestReport } from '@/page/Testing/TestingProcessPage/TestingForm/DownloadTestReport';

import { translations } from '@/locale';

export interface Props {
  workspace: TestReport[];
}
const RenderWorkspace = ({ workspace }) => {
  const intl = useIntl();
  return (
    <LoadData load={() => getAllTestReportsByWorkspaceName(workspace)}>
      {({ value: testReports, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className="h-full">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          )}
          else={() => (
            <React.Fragment>
              <div className=" justify-items-top  ">
                <div className="justify-between">
                  {testReports.content.map((value: TestReport, index: number) => (
                    <DownloadTestReport
                      key={value.workspaceName + '' + String(index)}
                      data={value.metadata}
                      title={intl.formatMessage(
                        {
                          id: translations.placeholders.downloadTestReportTitle
                        },
                        {
                          name: workspace.name,
                          providerId: workspace.providerId,
                          date: intl.formatDate(value.createdAt) + ' ' + intl.formatTime(value.createdAt)
                        }
                      )}
                      placeholder={'TR-' + (index + 1)}
                      className=" bg-transparent text-red text-sm ml-2 "
                    />
                  ))}
                </div>
              </div>
            </React.Fragment>
          )}
        />
      )}
    </LoadData>
  );
};

export default RenderWorkspace;
