import React from 'react';
import classnames from 'classnames';

import Header from './Header';
import Body from './Body';
import Row from './Row';
import LinkRow from './LinkRow';
import Data from './Data';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

export default class Table extends React.PureComponent<Props> {
  static Header = Header;
  static Body = Body;
  static Row = Row;
  static LinkRow = LinkRow;
  static Data = Data;

  render() {
    const { is: Component = 'div', className, children } = this.props;
    return (
      <Component
        className={classnames(
          'flex flex-col border border-solid border-gray-lighter rounded-t-xl overflow-hidden',
          className
        )}
      >
        {children}
      </Component>
    );
  }
}
