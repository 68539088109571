import React from 'react';
import Grid from '@/components/Grid/Grid';
import Typography from '@/components/Typography/Typography';
import { FormattedMessage } from 'react-intl';

export interface Props {
  name: string;
  recived: string;
  sent: string;
}

export const RouterBitTable: React.FC<Props> = ({ name, sent, recived }) => {
  return (
    <Grid.Row className=" h-10 gap-10 grid-cols-3 grid-rows-1 border-gray border-solid  border-b-2  ">
      <Typography is="h1" className=" h-1/2 w-14">
        <FormattedMessage id={name} />
      </Typography>

      <Typography is="h1" className=" h-1/2 w-20 text-xs">
        {sent}
      </Typography>

      <Typography is="h1" className=" h-1/2 w-20 text-xs">
        {recived}
      </Typography>
    </Grid.Row>
  );
};
