import React from 'react';
import MapInfoWindow from './MapInfoWindow';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { useGeolocation } from 'react-use';
import { WorkspaceListItem, WorkspaceState } from '@/domains';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import { WorkspaceMarker } from '@/domains/workspace/workspace-marker';

const greenMarker = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  iconSize: [25, 41]
});

const greyMarker = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
  iconSize: [25, 41]
});

const redMarker = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [25, 41]
});

const blackMarker = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
  iconSize: [25, 41]
});

const mapMarker: { [k in WorkspaceMarker]?: Icon } = {
  [WorkspaceMarker.onlineVisible]: greenMarker,
  [WorkspaceMarker.onlineHidden]: greyMarker,
  [WorkspaceMarker.offlineVisible]: redMarker,
  [WorkspaceMarker.offlineHidden]: blackMarker
};

const MAP_OPTIONS = {
  containerStyle: {
    width: '100%',
    height: '100vh',
    borderRadius: '10px'
  },
  center: {
    lat: 45.9432,
    lng: 24.9668
    //Romania coordinates
  },
  zoom: 7
};

export interface Props {
  workspaces: WorkspaceListItem[];
}

const MapSection: React.FC<Props> = ({ workspaces }) => {
  const { latitude, longitude, loading, error } = useGeolocation();
  const center = React.useMemo(() => ({ lat: latitude, lng: longitude }), [loading]);
  const getCoordinates = () => {
    if (!error) return center;
    else return MAP_OPTIONS.center;
  };

  return (
    <If
      condition={loading}
      then={() => (
        <Loading visible={true} center className="h-full mt-20">
          <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
        </Loading>
      )}
      else={() => (
        <MapContainer
          center={getCoordinates()}
          zoom={MAP_OPTIONS.zoom}
          scrollWheelZoom={true}
          style={MAP_OPTIONS.containerStyle}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {workspaces.map((w) => (
            <Marker
              key={w.id}
              position={{ lat: w.latitude, lng: w.longitude }}
              icon={mapMarker[w.state + w.visibilityOnMap] ?? redMarker}
            >
              <Popup className="request-popup">
                <div style={{ width: '300px' }}>
                  <MapInfoWindow workspaceId={w.id} name={w.name} state={w.state} />
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    ></If>
  );
};

export default MapSection;
