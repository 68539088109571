import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import PromotionsListPage from './PromotionsListPage';

const Promotions: React.FC = () => (
  <Switch>
    <Route exact path={urls.promotions.list} component={PromotionsListPage} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.promotions.list} />} />
  </Switch>
);

export default Promotions;
