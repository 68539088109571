import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import PromoCreditsTransactionHistorySection from './Sections/PromoCreditsTransactionHistorySection';
import { SessionConsumer } from '@/components/Context/Session';
import PromoCreditsSelectionSection from './Sections/PromoCreditsSelectionSection';

export interface AssignPackageModalState {
  packageId: string;
  isOpen: boolean;
}

const text = translations.pages.promoCredits;

const PromoCreditsPage = () => {
  const [assignPackageModalState, setAssignPackageModalState] = React.useState<AssignPackageModalState>(null);

  return (
    <SessionConsumer>
      {({ me }) => (
        <div className="m-8">
          <div className="flex justify-between mb-8">
            <Typography is="h1" type="header" className="font-bold text-gray-darkest">
              <FormattedMessage id={text.credits.title} />
            </Typography>
          </div>

          <React.Fragment>
            <PromoCreditsSelectionSection setAssignPackageModalState={setAssignPackageModalState} userId={me.id} />

            <PromoCreditsTransactionHistorySection userId={me.id} />
          </React.Fragment>
        </div>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(PromoCreditsPage);
