import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { IotProvider } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<IotProvider>, 'items'>, WrappedComponentProps {}

const IotProviderSelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = Object.values(IotProvider).map((provider) => ({
    value: provider,
    name: intl.formatMessage({ id: translations.domains.iotProvider[provider] })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(IotProviderSelect);
export const IotProviderSelectWithValidation = withValidation(Default);
export default Default;
