import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import PaymentStatusBadge from './PaymentStatusBadge';
import WorkspaceStatusBadge from './WorkspaceStatusBadge';

export interface Props {
  className?: string;
}

const Badge = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <span
      className={classnames('px-2.5 py-1 rounded-full uppercase text-xs font-bold', className || 'text-white bg-green')}
    >
      {children}
    </span>
  );
};

Badge.PaymentStatus = PaymentStatusBadge;
Badge.WorkspaceStatusBadge = WorkspaceStatusBadge;

export default Badge;
