import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import { InstanceProps } from '@/components/Modal';
import ModalHeader from '@/components/Modal/ModalHeader';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import EmailListForm, { SubmitValuesProps } from '@/components/Form/EmailListForm';
import { inviteUsersToWorksapce } from '@/services/api/users';

export interface Props extends InstanceProps {
  workspaceId: string;
  onSuccess?: () => any;
}

const text = translations.pages.workspaces.details.tabs.userManagement.addUsersModal;

const AddUserByEmailModal = ({ workspaceId, onSuccess, close }: Props) => {
  const intl = useIntl();

  const handleSubmit = (values: SubmitValuesProps) =>
    inviteUsersToWorksapce(workspaceId, values.emailList).then(() => {
      onSuccess && onSuccess();
      close();
    });

  return (
    <Card className="w-[670px] p-6 mb-10">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close} className="mb-8">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.title} />
            </Typography>
          </ModalHeader>

          <EmailListForm
            id="assign-package"
            onSubmit={handleSubmit}
            submitButtonText={intl.formatMessage({ id: text.submit })}
          />
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default AddUserByEmailModal;
