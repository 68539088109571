import React from 'react';
import classnames from 'classnames';

import Select, { Props as SelectProps } from '@/components/Select';

import style from './SimpleSelect.sass';

type AppearanceType = 'slim' | 'normal';

export interface Props<T>
  extends Omit<
    SelectProps<T>,
    'onSelect' | 'onInputValueChange' | 'getValue' | 'getItemValue' | 'renderItems' | 'dropdownContentLoading'
  > {
  appearance?: AppearanceType;
  label?: string;
  dropdownContentLoading?: boolean;
}

export default class SimpleSelect<T> extends React.PureComponent<Props<T>> {
  static defaultProps: Pick<Props<any>, 'appearance'> = {
    appearance: 'slim'
  };

  render() {
    const { value, appearance, dropdownContentLoading = false, ...rest } = this.props;

    return (
      <Select
        value={this.props.value}
        onChange={this.props.onChange}
        onInputValueChange={() => null}
        onSelect={() => null}
        inputClassName={classnames(style[appearance!])}
        dropdownContentLoading={dropdownContentLoading}
        {...rest}
      />
    );
  }
}
