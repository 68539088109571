import React from 'react';
import classnames from 'classnames';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import Link from '@/components/Link';
import Typography from '@/components/Typography';

import Tab from './Tab';

interface Props {
  resourceId: string;
  className?: string;
}

const TabbedContainer: React.FC<Props & RouteComponentProps> = ({ resourceId, className, children, location }) => {
  const tabChildren = React.Children.toArray(children).filter(Boolean);

  const renderTabItem = (child: Tab, index) => {
    const url = child.props.to.get(resourceId);
    const isTabActive = location.pathname === url;

    return (
      <Link
        key={index}
        to={url}
        className="group relative sm:mx-2 lg:mx-4 py-4"
        activeClassName="text-black opacity-100"
      >
        <Typography
          is="span"
          type="regular"
          className={classnames('uppercase group-hover:text-gray-darkest', {
            'text-gray': !isTabActive,
            'text-gray-darkest': isTabActive
          })}
        >
          {child.props.title}
        </Typography>

        <div
          className={classnames(
            'absolute inset-x-0 mx-auto -bottom-px w-[26px] h-[1px] transition transform group-hover:scale-150 group-hover:bg-red',
            {
              'bg-red': isTabActive
            }
          )}
        />
      </Link>
    );
  };

  return (
    <div className={classnames('h-full w-full flex flex-col', className)}>
      <div className="w-full border-b border-gray-light border-solid mb-5">
        <div className="flex flex-row items-center justify-start sm:-mx-2 md:-mx-4">
          {React.Children.map(tabChildren, renderTabItem)}
        </div>
      </div>

      <Switch>
        {React.Children.map(tabChildren, (child: Tab, index) => (
          <Route key={index} exact path={child.props.to.main} component={() => child as any} />
        ))}
      </Switch>
    </div>
  );
};

export default withRouter(TabbedContainer);
