import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Icon, { Type } from '@/components/Icon';
import Typography from '@/components/Typography';
import { SidebarConsumer } from '@/components/Context/Sidebar';

import Link from '@/components/Link';
import If from '@/components/If';
import { CustomIcons } from '@/components/Sidebar/MenuItem/CustomIcons/CustomIcons';

export interface Props {
  icon: Type;
  url: string;
  className?: string;
  external?: boolean;
  newIcon?: string;
}

const MenuItem: React.FC<Props & RouteComponentProps> = ({
  icon,
  newIcon,
  url,
  className,
  external,
  children,
  location
}) => {
  const isActive = location.pathname.indexOf(url) === 0;

  return (
    <SidebarConsumer>
      {({ close }) => (
        <Link
          to={url}
          className={classnames('flex items-center relative my-4 h-[28px] group', className)}
          activeClassName="text-red"
          onClick={close}
          external={external}
        >
          <If
            condition={newIcon}
            then={() => {
              return <CustomIcons icon={newIcon} />;
            }}
            else={() => {
              return <Icon type={icon} className="text-xl group-hover:text-red" />;
            }}
          />

          <Typography is="span" type="regular" className="pl-3 text-current group-hover:text-red">
            {children}
          </Typography>

          <div
            className={classnames('transition-all group-hover:w-[4px] bg-red h-full rounded-l-sm ml-auto mr-0', {
              'w-0': !isActive,
              'w-[3px]': isActive
            })}
          />
        </Link>
      )}
    </SidebarConsumer>
  );
};

export default withRouter(MenuItem);
