import * as yup from 'yup';

import {
  CountryCode,
  Currency,
  IotProvider,
  QrAccessCodeType,
  WorkspaceCreationForm,
  WorkspaceEditForm,
  WorkspaceStatus,
  WorkspaceVisibility
} from '@/domains';
import { translations } from '@/locale';
import { SHORT_STRING_MAX_LENGTH, STRING_MIN_LENGTH } from '@/constants';
import { imageSchema, latitudeSchema, longitudeSchema, shortStringSchema } from '@/validation';
import { WifiBand } from '@/domains/wifi';

export const priceSchema = yup
  .number()
  .test('price', 'Price must be 0 or greater than 1', (value) => value == 0 || value >= 1);

export const workspaceCreateOrEditSchema: yup.ObjectSchema<WorkspaceCreationForm | WorkspaceEditForm> = yup
  .object({
    name: shortStringSchema.label(translations.inputs.fields.name.label).required(),
    address: shortStringSchema.label(translations.inputs.fields.address.label).required(),
    city: yup
      .string()
      .label(translations.inputs.fields.city.label)
      .required(),
    country: yup
      .mixed<CountryCode>()
      .label(translations.inputs.fields.country.label)
      .required(),
    description: yup
      .string()
      .min(STRING_MIN_LENGTH)
      .label(translations.inputs.fields.description.label)
      .max(1024)
      .required(),
    latitude: latitudeSchema.label(translations.inputs.fields.latitude.label).required(),
    longitude: longitudeSchema.label(translations.inputs.fields.longitude.label).required(),
    hasQrAccess: yup.boolean().required(),
    accessMessage: yup.string().max(SHORT_STRING_MAX_LENGTH),
    private: yup.boolean().required(),
    price: yup
      .number()
      .label(translations.inputs.fields.price.label)
      .when('private', {
        is: true,
        then: yup
          .number()
          .nullable()
          .notRequired(),
        otherwise: priceSchema.required()
      }),
    currency: yup
      .mixed<Currency>()
      .label(translations.inputs.fields.currency.label)
      .when('private', {
        is: true,
        then: yup
          .mixed<Currency>()
          .nullable()
          .notRequired(),
        otherwise: yup.mixed<Currency>().required()
      }),
    iotEnabled: yup
      .boolean()
      .nullable()
      .notRequired(),
    provider: yup
      .mixed<IotProvider>()
      .label(translations.inputs.fields.iotIntegrator.label)
      .when('iotEnabled', {
        is: true,
        then: yup.mixed<IotProvider>().required(),
        otherwise: yup.mixed<IotProvider>().nullable()
      }),
    providerId: shortStringSchema.label(translations.inputs.fields.identifier.label).when('iotEnabled', {
      is: true,
      then: shortStringSchema.required(),
      otherwise: yup.string().nullable()
    }),
    images: yup
      .array()
      .of(imageSchema.label(translations.inputs.image.label).required())
      .min(1)
      .max(16)
      .label(translations.inputs.imageUpload.label)
      .required(),
    ownerIds: yup
      .array()
      .of(yup.string())
      .min(1)
      .label(translations.inputs.fields.owner.label)
      .required(),
    status: yup.mixed<WorkspaceStatus>(),
    visibility: yup.mixed<WorkspaceVisibility>(),
    installationDate: yup
      .string()
      .label(translations.inputs.fields.installationDate.label)
      .nullable()
      .notRequired(),
    constructionDate: yup
      .string()
      .label(translations.inputs.fields.installationDate.label)
      .nullable()
      .notRequired(),
    featureIds: yup.array().of(yup.string()),
    mainWifiSSID: yup
      .string()
      .label(translations.inputs.fields.mainWifiSSID.label)
      .required(),
    mainWifiPassword: yup
      .string()
      .label(translations.inputs.fields.wifiPassword.label)
      .min(8)
      .required(),
    mainWifiBand: yup
      .mixed<WifiBand>()
      .label(translations.wifiBand.label)
      .required()
      .required(),
    additionalWifiSSID: yup.string().label(translations.inputs.fields.mainWifiSSID.label),
    additionalWifiPassword: yup
      .string()
      .label(translations.inputs.fields.wifiPassword.label)
      .min(8),
    additionalWifiBand: yup.mixed<WifiBand>().label(translations.wifiBand.label),
    routerIp: yup
      .string()
      .label(translations.inputs.fields.routerIp.label)
      .notRequired(),
    changeWifiPassword: yup.boolean().notRequired(),
    preferredQrAccessCode: yup.mixed<QrAccessCodeType>().when('hasQrAccess', {
      is: true,
      then: yup.mixed<QrAccessCodeType>().required(),
      otherwise: yup.mixed<QrAccessCodeType>().nullable()
    }),
    minimumBookingPeriodInMinutes: yup
      .number()
      .min(15)
      .max(120)
      .required(),
    bookingTimeIncrementStepInMinutes: yup
      .number()
      .min(15)
      .max(120)
      .required(),
    cleaningDeposit: yup.boolean().notRequired(),
    cleaningDepositFee: yup.number().notRequired(),
    versionIOT: shortStringSchema.label(translations.inputs.fields.identifier.label).when('iotEnabled', {
      is: true,
      then: shortStringSchema.required(),
      otherwise: yup.string().nullable()
    })
  })
  .required();
