import React from 'react';

export interface Props {
  percentage: number;
}

const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <div className="h-1.5 w-full bg-red border rounded-full">
      <div style={{ width: `${percentage}%` }} className="h-full border rounded-full bg-green" />
    </div>
  );
};

export default ProgressBar;
