import React from 'react';
import { Event, View } from 'react-big-calendar';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { addDays, endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import { translations } from '@/locale';
import { WorkspaceDetails, BookingCoverage } from '@/domains';
import { getWorkspaceBookingsCoverageWithinTimeFrame } from '@/services/api/bookings';

import Calendar from '@/components/Calendar';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';

export interface Props {
  workspace: WorkspaceDetails;
}

interface RangeState {
  view: View;
  start: Date;
  end: Date;
  visible: Date;
}

const mapEvents = (bookings: BookingCoverage[]): Event[] =>
  bookings.map((value) => ({ start: new Date(value.startDate), end: new Date(value.endDate) }));

const CalendarTab: React.FC<Props & WrappedComponentProps> = ({ workspace, intl }) => {
  const [range, setRange] = React.useState<RangeState>({
    visible: new Date(),
    view: 'month',
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date())
  });

  return (
    <div className="min-h-[350px]">
      <LoadData
        key={JSON.stringify(range)}
        load={() => getWorkspaceBookingsCoverageWithinTimeFrame(workspace.id, range.start, range.end)}
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="min-h-[350px]">
                <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
              </Loading>
            )}
            else={() => (
              <Calendar
                showMultiDayTimes
                events={mapEvents(value)}
                className="h-[500px]"
                defaultView={range.view}
                defaultDate={range.visible}
                messages={{
                  showMore: (total) => intl.formatMessage({ id: translations.misc.calendar.more }, { amount: total })
                }}
                formats={{
                  timeGutterFormat: 'HH:mm'
                }}
                onDrillDown={(date, view) => {
                  setRange({ start: startOfDay(date), end: endOfDay(date), view, visible: startOfDay(date) });
                }}
                onRangeChange={(range, view) => {
                  let mappedRange;
                  if (Array.isArray(range))
                    mappedRange = {
                      start: startOfDay(range[0]),
                      end: endOfDay(range[range.length - 1]),
                      visible: startOfDay(range[0])
                    };
                  else mappedRange = { ...range, visible: startOfMonth(addDays(range.start as Date, 13)) };
                  setRange((previous) => ({ ...previous, ...mappedRange, ...(!!view && { view }) }));
                }}
              />
            )}
          />
        )}
      </LoadData>
    </div>
  );
};

export default injectIntl(CalendarTab);
