import React from 'react';
import { useInterval, useAsyncRetry } from 'react-use';
import { getWorkspaces } from '@/services/api/workspace';
import MonitoringSection from '@/page/Monitoring/MonitoringPage/MonitoringSection/MonitoringSection';

const MonitoringPage: React.FC = () => {
  const [refresh, setRefresh] = React.useState(true);

  const { value: workspaces = { content: [] }, loading, retry } = useAsyncRetry(async () => getWorkspaces(1, 1000));

  useInterval(
    () => {
      retry();
    },
    refresh ? 60000 : null
  );

  return (
    <div className="m-8">
      <MonitoringSection workspaces={workspaces.content} />
    </div>
  );
};

export default MonitoringPage;
