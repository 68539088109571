import { Wifi, WifiBand } from '@/domains/wifi';
import { authorizedApi } from '@/services/network/authorized-api';

const mapWifi = (wifi: Wifi) => ({
  id: wifi.id,
  wifiSSID: wifi.ssid,
  wifiPassword: wifi.password,
  workspaceId: wifi.workspaceId,
  wifiBand: wifi.band
});

export const getWifiCredentialsByWorkspaceId = (workspaceId: string) =>
  authorizedApi.get<Wifi[]>('workspace-wifi', { workspaceId });

export const getWifi = async (workspaceId: string) => {
  let listWifi = await getWifiCredentialsByWorkspaceId(workspaceId);
  listWifi.map((el) => mapWifi(el));
  listWifi.sort((a, b) => a.band.localeCompare(b.band));
  return listWifi;
};

export const createWifi = (ssid: string, password: string, workspaceId: string, band: WifiBand) => {
  return authorizedApi.post<Wifi>('/workspace-wifi', { workspaceId, ssid, password, band });
};

export const updateWifi = (wifi: Wifi, workspaceId: string) => {
  return authorizedApi.patch<Wifi>(`/workspace-wifi/${wifi.id}`, {
    ssid: wifi.ssid,
    password: wifi.password,
    workspaceId: workspaceId,
    band: wifi.band
  });
};

export const deleteWifi = (wifiId: string) => {
  return authorizedApi.delete<Wifi>(`/workspace-wifi/${wifiId}`);
};
