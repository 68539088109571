import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { WorkspaceDetails, WorkspaceUser, WorkspaceUserType } from '@/domains';
import { fullName } from '@/util';
import { deleteUserFromWorkspace, getUsersForWorkspace } from '@/services/api/users';

import Typography from '@/components/Typography';
import SearchInput from '@/components/SearchInput';
import Modal from '@/components/Modal';
import AddUserByEmailModal from '@/components/Modal/AddUserByEmailModal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Refresh from '@/components/Refresh';
import EditWorkspaceUserModal from '@/components/Modal/EditWorkspaceUserModal';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import If from '@/components/If';

const text = translations.pages.workspaces.details.tabs.userManagement;

const headerList: { name?: string; sm: Magnitude }[] = [
  { name: text.table.header.name, sm: 5 },
  { name: text.table.header.email, sm: 5 },
  { name: text.table.header.dateAdded, sm: 3 },
  { sm: 5 }
];

export interface Props {
  workspace: WorkspaceDetails;
}

const UserManagementTab = ({ workspace }: Props) => {
  const intl = useIntl();

  const [state, setState] = React.useState({
    keyword: ''
  });

  return (
    <Refresh>
      {({ key, refresh }) => (
        <React.Fragment>
          <Typography is="h2" type="subheader" className="font-bold mt-3 mb-6">
            <FormattedMessage id={text.title} />
          </Typography>

          <div className="flex justify-between mb-4 mt-4">
            <SearchInput
              id="users-search"
              className="w-[300px] bg-white"
              placeholder={intl.formatMessage({ id: text.searchPlaceholder })}
              onChange={(keyword) => {
                setState({ keyword: keyword });
              }}
            />

            <Modal modal={AddUserByEmailModal} workspaceId={workspace.id} onSuccess={refresh}>
              {({ open }) => (
                <Button appearance="red" onClick={open}>
                  <Icon type="plus" className="text-base leading-none pr-2" />

                  <FormattedMessage id={text.newUserButton} />
                </Button>
              )}
            </Modal>
          </div>

          <InfiniteScrollableTable
            key={`${JSON.stringify(state)}${key}`}
            source={(page, number) =>
              getUsersForWorkspace(workspace.id, page, number, {
                keyword: state.keyword || '',
                workspaceId: workspace.id
              })
            }
            empty={() => (
              <Placeholder className="mt-12">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.table.empty} />
                </Typography>
              </Placeholder>
            )}
            renderHeader={() => (
              <Table.Header>
                {headerList.map((headerItem, index) => (
                  <Table.Data header sm={headerItem.sm} key={`${headerItem.name}-${index}`}>
                    {headerItem.name && (
                      <Typography is="span" className="font-bold uppercase text-gray">
                        <FormattedMessage id={headerItem.name} />
                      </Typography>
                    )}
                  </Table.Data>
                ))}
              </Table.Header>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((user: WorkspaceUser) => (
                  <Table.LinkRow key={user.id}>
                    <Table.Data sm={headerList[0].sm}>
                      <div className="flex items-center">
                        <If
                          condition={user.type === WorkspaceUserType.USER}
                          then={() => (
                            <React.Fragment>
                              <UserProfileImage
                                firstName={user.firstName}
                                lastName={user.lastName}
                                className="w-8 h-8 rounded-md"
                              />

                              <Typography is="span" className="ml-3">
                                {fullName(user.firstName, user.lastName)}
                              </Typography>
                            </React.Fragment>
                          )}
                          else={() => (
                            <Typography is="span" className="text-gray-light">
                              Invite pending
                            </Typography>
                          )}
                        />
                      </div>
                    </Table.Data>

                    <Table.Data sm={headerList[1].sm}>
                      <Typography is="span">{user.email}</Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[2].sm}>
                      <FormattedDate value={user.createdAt} day="2-digit" month="long" year="numeric" />
                    </Table.Data>

                    <Table.Data sm={headerList[3].sm} className="flex justify-end">
                      <Modal
                        modal={ConfirmationModal}
                        title={intl.formatMessage(
                          { id: text.removeConfirmationModal.title },
                          { name: fullName(user.firstName, user.lastName) }
                        )}
                        message={intl.formatMessage({ id: text.removeConfirmationModal.message })}
                        confirmMessage={intl.formatMessage({ id: text.removeConfirmationModal.confirm })}
                        cancelMessage={intl.formatMessage({ id: text.removeConfirmationModal.cancel })}
                        onConfirm={() => deleteUserFromWorkspace(workspace.id, user.id).then(() => refresh())}
                      >
                        {({ open }) => (
                          <Button appearance="ghost" className="mr-2" onClick={open}>
                            <Icon type="close" className="text-base leading-none pr-2" />
                            <FormattedMessage id={text.table.buttons.remove} />
                          </Button>
                        )}
                      </Modal>
                    </Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </InfiniteScrollableTable>
        </React.Fragment>
      )}
    </Refresh>
  );
};

export default UserManagementTab;
