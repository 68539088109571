import React from 'react';

import TextInput, { Props as TextInputProps } from '@/components/Input/TextInput';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<TextInputProps, 'is'> {}

const TextAreaInput: React.FC<Props> = (props) => <TextInput {...props} is="textarea" />;

export const TextAreaInputWithValidation = withValidation(TextAreaInput);
export default TextAreaInput;
