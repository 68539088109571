import React from 'react';
import { AiOutlineStar, AiOutlineTool } from 'react-icons/ai';
import { VscGithubAction } from 'react-icons/vsc';
import { TbReportAnalytics } from 'react-icons/tb';
import { MdTravelExplore } from 'react-icons/md';
import { GrStatusWarning } from 'react-icons/gr';
import { BsDisplay } from 'react-icons/bs';

export interface Props {
  icon: string;
}

export const CustomIcons: React.FC<Props> = ({ icon }) => {
  switch (icon) {
    case 'AiOutlineTool':
      return <AiOutlineTool className="text-xl group-hover:text-red" />;
    case 'VscGithubAction':
      return <VscGithubAction className="text-xl group-hover:text-red" />;
    case 'TbReportAnalytics':
      return <TbReportAnalytics className="text-xl group-hover:text-red" />;
    case 'AiOutlineStar':
      return <AiOutlineStar className="text-xl group-hover:text-red" />;
    case 'MdTravelExplore':
      return <MdTravelExplore className="text-xl group-hover:text-red" />;
    case 'GrStatusWarning':
      return <GrStatusWarning className="text-xl group-hover:text-red" />;
    case 'BsDisplay':
      return <BsDisplay className="text-xl group-hover:text-red" />;
  }
};
