import { SignalStrengthMetrics } from '@/domains/metrics';
import { MonitoringPreviousDays } from '@/domains/monitoring-page';
import {
  WorkspaceBoothStatus,
  WorkspaceBoothStatusResponse,
  WorkspaceStatusElements
} from '@/domains/workspace-status';
import { authorizedApi } from '@/services/network/authorized-api';

export const getWorkspaceMonitoringStatus = (workspaceId: string, minutes?: number) =>
  authorizedApi.get<string>(`monitoring/status/${workspaceId}`, {
    minutes
  });

export const getWorkspaceMonitoringStatusElements = (workspaceId: string) =>
  authorizedApi.get<WorkspaceStatusElements>(`monitoring/status/elements/${workspaceId}`);

export const getWorkspaceMonitoringStatusPreviousDays = (pageNumber: number, pageSize: number) =>
  authorizedApi.get<MonitoringPreviousDays>(`/monitoring/status-booth/previous`, {
    pageNumber,
    pageSize
  });

export const getSignalStrengthMetrics = (workspaceId: string, startDate: number, endDate: number, sensor: string) =>
  authorizedApi.get<
    {
      date: string;
      value: number;
    }[]
  >(`/monitoring/signal-strength`, { workspaceId, startDate, endDate, sensor });

export const getWorkspaceDowntimeHistory = (startDate: number, endDate: number, workspaceId?: string, name?: string) =>
  authorizedApi.get<any>(
    `/download/workspace-history`,
    {
      workspaceId,
      name,
      startDate,
      endDate
    },
    { responseType: 'blob' }
  );
