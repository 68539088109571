import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { parse, ParsedQuery } from 'query-string';

import { urls } from '@/constants';

import RowContainer from '@/components/RowContainer';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Modal from '@/components/Modal';
import PaymentSuccessModal from '@/components/Modal/PaymentSuccessModal';
import PaymentFailModal from '@/components/Modal/PaymentFailModal';

import { AssignPackageModalState } from '@/page/PromoPacks/PromoPacksPage';
import { getCreditProduct, getCreditTiers, purchaseCredits } from '@/services/api/credits';
import PromoCreditsCard from '@/components/PromoCreditsCard';
import PromoCreditsAssignCard from '@/components/PromoCreditsAssignCard';
import { useAsyncRetry } from 'react-use';
import { CreditTier } from '@/domains/credits';
import { getAvailableCredits } from '@/services/api/wallets';

export interface Props {
  setAssignPackageModalState: React.Dispatch<React.SetStateAction<AssignPackageModalState>>;
  userId: string;
}

const compareCreditTiers = (a: CreditTier, b: CreditTier) => {
  return a.minCredits - b.minCredits;
};

const PromoCreditsSelectionSection = ({ setAssignPackageModalState, userId }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const credit = useAsyncRetry(async () => {
    return await getCreditProduct();
  });

  const creditTiers = useAsyncRetry(async () => {
    const result = await getCreditTiers();
    return result.content.sort(compareCreditTiers);
  });

  const { count }: ParsedQuery<string> = parse(location.search);

  return (
    <RowContainer className="mb-8">
      <If
        condition={credit.loading}
        then={() => (
          <RowContainer.Item>
            <Loading visible={credit.loading} center className="h-[60px]">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          </RowContainer.Item>
        )}
        else={() => (
          <React.Fragment>
            <RowContainer.Item col={4}>
              <PromoCreditsCard
                includedCredits={parseInt(credit.value.metadata.includedCredits)}
                className="w-full"
                creditTiers={creditTiers.value}
              />
            </RowContainer.Item>
            <RowContainer.Item col={8}>
              <PromoCreditsAssignCard
                id={credit.value.id}
                name={credit.value.name}
                includedCredits={credit.value.metadata.includedCredits}
                validityPeriod={'month'}
                validityValue={1}
                price={credit.value.cost}
                className="w-full"
                userId={userId}
              />
            </RowContainer.Item>

            {count && (
              <Switch>
                <Route
                  path={urls.promoCredits.success}
                  component={() => (
                    <Modal
                      modal={PaymentSuccessModal}
                      credits={parseInt(count as string)}
                      isOpen
                      onClose={() => history.push(urls.promoPacks.list)}
                      onConfirm={() => {}}
                    />
                  )}
                />

                <Route
                  path={urls.promoCredits.fail}
                  component={() => (
                    <Modal
                      modal={PaymentFailModal}
                      isOpen
                      onClose={() => history.push(urls.promoPacks.list)}
                      onConfirm={() => {
                        purchaseCredits(parseInt(count as string)).then((res) => {
                          window.open(res.url, '_self', 'noopener,noreferrer');
                        });
                      }}
                    />
                  )}
                />
              </Switch>
            )}
          </React.Fragment>
        )}
      />
    </RowContainer>
  );
};

export default PromoCreditsSelectionSection;
