import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { rules } from '@/constants';

import Typography from '@/components/Typography';
import HasRole from '@/components/HasRole';

import 'react-circular-progressbar/dist/styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import DashboardOverviewSection from './Sections/DashboardOverviewSection';
import DashboardUserSection from './Sections/DashboardUsersSection';
import DasbhoardBookingSection from './Sections/DashboardBookingsSection';
import LessorRevenueSection from './Sections/LessorRevenueSection';
import LessorBookingSection from './Sections/LessorBookingSection';
import { SessionContext } from '@/components/Context/Session';

const text = translations.pages.dashboard;

const DashboardPage: React.FC = () => {
  const { me } = React.useContext(SessionContext);
  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>

      <HasRole to={rules.SUPER_ADMIN_METRICS_VIEW}>
        <DashboardOverviewSection />
        <DashboardUserSection />
        <DasbhoardBookingSection />
      </HasRole>

      <HasRole to={rules.LESSOR_METRICS_VIEW}>
        <LessorRevenueSection ownerId={me.id} />
        <LessorBookingSection ownerId={me.id} />
      </HasRole>
    </div>
  );
};

export default DashboardPage;
