import React from 'react';
import * as yup from 'yup';
import { parse } from 'query-string';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { passwordSchema } from '@/validation';
import { resetPassword, checkResetPasswordTokenValidity } from '@/services/api/session';

import Form from '@/components/Form';
import Button from '@/components/Button';
import { PasswordInputWithValidation } from '@/components/Input/PasswordInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';

const schema = yup.object({
  password: passwordSchema.label(translations.inputs.password.label).required(),
  passwordConfirmation: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
    .oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch)
});

export interface Props extends RouteComponentProps {}

class ResetPasswordPage extends React.PureComponent<Props & WrappedComponentProps> {
  getTokenFromUrl = () => parse(this.props.location.search).t as string;

  componentDidMount = () => {
    const token = this.getTokenFromUrl();
    checkResetPasswordTokenValidity(token)
      .then((tokenValidity) => {
        if (!tokenValidity.valid) this.props.history.push(urls.resetPassword.expired);
      })
      .catch(() => this.props.history.push(urls.forgotPassword.main));
  };

  render() {
    const token = this.getTokenFromUrl();

    if (!token) return <Redirect to={urls.forgotPassword.main} />;

    return (
      <React.Fragment>
        <div className="flex flex-col text-center mb-8">
          <Typography is="h1" type="header" className="font-bold">
            <FormattedMessage id={translations.pages.resetPassword.title} />
          </Typography>
        </div>

        <Form
          id="reset-password"
          schema={schema}
          subscription={{ submitError: true, submitting: true }}
          onSubmit={({ password, passwordConfirmation }) =>
            resetPassword({ password, passwordConfirmation, token }).then(() =>
              this.props.history.push(urls.resetPassword.done)
            )
          }
        >
          {({ submitError, submitting }, id) => (
            <div className="flex flex-col">
              <div className="mb-8">
                <Form.Field
                  is={PasswordInputWithValidation}
                  id={`${id}-password`}
                  name="password"
                  label={this.props.intl.formatMessage({ id: translations.inputs.newPassword.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.newPassword.placeholder })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={PasswordInputWithValidation}
                  id={`${id}-password-confirmation`}
                  name="passwordConfirmation"
                  label={this.props.intl.formatMessage({ id: translations.inputs.confirmedPassword.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.confirmedPassword.placeholder })}
                  readOnly={submitting}
                  className="mt-4"
                />
              </div>

              {!!submitError && <SubmitError error={submitError} className="mb-8" />}

              <Button type="submit" loading={submitting} appearance="red" className="mb-8">
                <FormattedMessage id={translations.navigation.submit} />
              </Button>
            </div>
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default injectIntl(ResetPasswordPage);
