import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';

import { getWorkspaceFeatures } from '@/services/api/workspace-features';
import WorkspaceFeature from '@/components/WorkspaceFeature';
import Modal from '@/components/Modal';
import AddFeatureModal from '@/components/Modal/AddFeatureModal/AddFeatureModal';

const text = translations.pages.featureManagement.tabs.features;

const WorkspaceFeaturesPage: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <Card className="w-full p-5 min-h-[350px]">
      <LoadData load={getWorkspaceFeatures}>
        {({ value: features, loading, reload }) => (
          <React.Fragment>
            <div className="flex justify-between mb-4">
              <Typography is="h1" type="small-header" className="font-bold text-gray-darkest">
                <FormattedMessage id={text.title} />
              </Typography>
              <Modal modal={AddFeatureModal} onSuccess={reload}>
                {({ open }) => (
                  <Button appearance="red" onClick={open}>
                    <Icon type="plus" className="text-base leading-none pr-2" />
                    <FormattedMessage id={text.addNew} />
                  </Button>
                )}
              </Modal>
            </div>

            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} className="h-[30px] pt-2">
                  <Loading.Indicator size={30} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <React.Fragment>
                  {features.map((category) => (
                    <React.Fragment key={category.category}>
                      <Typography is="h3" type="small-header" className="mb-4">
                        {intl.formatMessage({
                          id: translations.domains.workspaceFeatureCategory[category.category]
                        })}
                      </Typography>
                      <div className="flex mb-8">
                        <div className="flex flex-row flex-wrap -mx-6 -my-4">
                          {category.features.map((feature) => (
                            <Modal modal={AddFeatureModal} key={feature.id} feature={feature} onSuccess={reload}>
                              {({ open }) => <WorkspaceFeature feature={feature} className="mx-6 my-4" onEdit={open} />}
                            </Modal>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            />
          </React.Fragment>
        )}
      </LoadData>
    </Card>
  );
};

export default injectIntl(WorkspaceFeaturesPage);
