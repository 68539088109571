import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps, FormattedDate } from 'react-intl';
import { isSameDay, subDays, format } from 'date-fns';
import { DateRange } from 'react-date-range';
import { CSVLink } from 'react-csv';

import { translations } from '@/locale';
import { getLessorBookingMetrics } from '@/services/api/metrics';
import { Magnitude, urls } from '@/constants';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Table from '@/components/Table';
import PieChart from '@/components/Chart/PieChart';
import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import Link from '@/components/Link';
import OverviewCard from '@/components/OverviewCard';
import { BiArrowToBottom } from 'react-icons/bi';

const text = translations.pages.dashboard.lessor.section.bookings;

const headerList: { name?: string; sm: Magnitude; key: string }[] = [
  { name: text.bookingsTable.header.label.workspace, sm: 5, key: text.bookingsTable.header.key.workspace },
  { name: text.bookingsTable.header.label.total, sm: 4, key: text.bookingsTable.header.key.total }
];

export interface Props {
  ownerId: string;
}

const LessorBookingSection: React.FC<Props & WrappedComponentProps> = ({ intl, ownerId }) => {
  const [state, setState] = React.useState({
    metrics: {
      ownerId: ownerId,
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'metrics'
    }
  });

  return (
    <div className="mt-8">
      <Typography is="h2" type="small-header" className="font-bold text-gray-darkest mb-2 ml-2">
        <FormattedMessage id={text.overview} />
      </Typography>
      <Card className="w-full">
        <div className="flex">
          <Dropdown
            renderMenu={({ close }) => (
              <DateRange
                className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
                onChange={(item) => {
                  setState({ ...state, ...item });
                }}
                months={1}
                maxDate={new Date()}
                direction="vertical"
                ranges={[state.metrics]}
              />
            )}
            className="p-6"
            dropdownClassName="left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                <FormattedDate value={state.metrics.startDate} day="2-digit" month="long" />
                <If
                  condition={!isSameDay(state.metrics.startDate, state.metrics.endDate)}
                  then={() => {
                    return (
                      <React.Fragment>
                        {` - `}
                        <FormattedDate value={state.metrics.endDate} day="2-digit" month="long" />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            )}
          </Dropdown>
        </div>

        <LoadData key={JSON.stringify(state.metrics)} load={() => getLessorBookingMetrics(state.metrics)}>
          {({ value: metrics, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center className="h-full">
                  <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <div>
                  <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 place-items-center">
                    <div className="mb-3">
                      <div className="grid place-items-center w-full">
                        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                          <FormattedMessage id={text.chart} />
                        </Typography>
                      </div>
                      <PieChart
                        className="mt-5 w-1/2"
                        items={metrics.byLocation.map((e) => ({
                          title: e.name,
                          value: e.totalLocation
                        }))}
                      />
                    </div>
                    <div className="w-2/3 grid place-items-center">
                      <OverviewCard iconType="wallet" title={intl.formatMessage({ id: text.total })}>
                        {metrics.total}
                      </OverviewCard>
                    </div>
                  </div>
                  <Table className="bg-white">
                    <Table.Header>
                      {headerList.map((headerItem) => (
                        <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                          <Typography is="span">
                            <FormattedMessage id={headerItem.name} />
                          </Typography>
                        </Table.Data>
                      ))}
                    </Table.Header>
                    <Table.Body>
                      {metrics.byLocation.map((e) => {
                        return (
                          <Table.Row key={e.name}>
                            <Table.Data sm={headerList[0].sm}>
                              <Link to={urls.locations.about.get(e.id)} appearance="blue">
                                {e.name}
                              </Link>
                            </Table.Data>
                            <Table.Data sm={headerList[1].sm}>{e.totalLocation}</Table.Data>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <div className="flex flex-row-reverse mr-2 mb-2 ">
                    <CSVLink
                      data={metrics.byLocation.map((e) => ({
                        name: e.name,
                        totalLocation: e.totalLocation
                      }))}
                      headers={headerList.map((e) => ({
                        label: intl.formatMessage({ id: e.name }),
                        key: intl.formatMessage({ id: e.key })
                      }))}
                      filename={
                        intl.formatMessage({ id: text.bookingsTable.download.fileName }) +
                        format(state.metrics.startDate, 'dd/MM/yyyy') +
                        '-' +
                        format(state.metrics.endDate, 'dd/MM/yyyy') +
                        '.csv'
                      }
                    >
                      <button
                        type="button"
                        className="mt-2 p-3 bg-gray-lighter hover:bg-gray-light focus:outline-none whitespace-nowrap rounded-xl"
                      >
                        <div className="flex items-center">
                          <BiArrowToBottom className="mr-2"> </BiArrowToBottom>
                          <FormattedMessage id={text.bookingsTable.download.button} />
                        </div>
                      </button>
                    </CSVLink>
                  </div>
                </div>
              )}
            />
          )}
        </LoadData>
      </Card>
    </div>
  );
};

export default injectIntl(LessorBookingSection);
