import React, { useMemo } from 'react';
import * as yup from 'yup';
import { RouteComponentProps, StaticContext } from 'react-router';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { formatISO } from 'date-fns';
import { urls } from '@/constants';
import { translations } from '@/locale';
import { updateWorkspace } from '@/services/api/workspace';
import { workspaceCreateOrEditSchema } from '@/validation/schema';
import { IotProvider, UserRole, WorkspaceDetails, WorkspaceEditForm } from '@/domains';
import { addIdIfMissing, getISODateFromDateTime, workspaceStatusMap, workspaceVisibilityMap } from '@/util';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Grid from '@/components/Grid';
import ToggleSwitch from '@/components/ToggleSwitch';
import WorkspaceForm from '@/components/Form/WorkspaceForm';
import { SessionConsumer } from '@/components/Context/Session';
import { createWifi, deleteWifi, getWifi, updateWifi } from '@/services/api/wifi';
import { useAsync } from 'react-use';

const text = translations.pages.workspaces.edit;

export interface Props {}

const mapWorkspace = (workspace: WorkspaceDetails) => ({
  name: workspace.name,
  address: workspace.address,
  description: workspace.description,
  city: workspace.city,
  country: workspace.country,
  latitude: workspace.latitude,
  longitude: workspace.longitude,
  status: workspace.status,
  ownerIds: workspace.owners.map((el) => el.id),
  price: workspace.pricing.price,
  currency: workspace.pricing.currency,
  visibility: workspace.visibilityOnMap,
  images: workspace.imagesUrls.map(addIdIfMissing),
  hasQrAccess: workspace.hasQrAccess,
  accessMessage: workspace.accessMessage,
  provider: workspace.provider,
  providerId: workspace.providerId,
  installationDate: workspace.installationDate as string,
  constructionDate: workspace.constructionDate as string,
  private: workspace.private,
  iotEnabled: !!(workspace.provider && workspace.providerId),
  featureIds: workspace.features.map((feature) => feature.id),
  mainWifiSSID: undefined,
  mainWifiPassword: undefined,
  mainWifiBand: undefined,
  additionalWifiSSID: undefined,
  additionalWifiPassword: undefined,
  additionalWifiBand: undefined,
  routerIp: workspace.routerIp,
  changeWifiPassword: workspace.changeWifiPassword,
  preferredQrAccessCode: workspace.preferredQrAccessCode,
  cleaningDeposit: workspace.cleaningDeposit,
  cleaningDepositFee: workspace.cleaningDepositFee ? workspace.cleaningDepositFee : undefined,
  versionIOT: workspace.versionIOT ? workspace.versionIOT : null,
  minimumBookingPeriodInMinutes: workspace.minimumBookingPeriodInMinutes,
  bookingTimeIncrementStepInMinutes: workspace.bookingTimeIncrementStepInMinutes
});

const WorkspaceEditPage: React.FC<Props &
  WrappedComponentProps &
  RouteComponentProps<{ id: string }, StaticContext, WorkspaceDetails>> = ({ intl, match, history }) => {
  const locationId = match.params.id;
  const workspace = history.location.state;
  if (!workspace) history.push(urls.locations.bookings.get(locationId));
  const { images, installationDate, constructionDate, ...mappedWorkspace } = mapWorkspace(workspace);
  const memoizedimages = useMemo(() => images || [], []);
  const [changePassword, setChangePassword] = React.useState(workspace.changeWifiPassword);

  const { value: wifi } = useAsync(() => getWifi(workspace.id), [workspace.id]);

  return (
    <SessionConsumer>
      {({ me }) => (
        <div className="m-8">
          <div className="flex justify-between mb-8">
            <Typography is="h1" type="header" className="text-gray-darkest font-bold">
              {workspace.name}
            </Typography>
          </div>

          <Form
            id="edit-workspace"
            schema={workspaceCreateOrEditSchema as yup.ObjectSchema<WorkspaceEditForm>}
            subscription={{
              dirty: true,
              pristine: true,
              submitting: true,
              submitError: true,
              values: true
            }}
            initialValues={{
              ...mappedWorkspace,
              mainWifiSSID: wifi?.[0]?.ssid,
              mainWifiPassword: wifi?.[0]?.password,
              mainWifiBand: wifi?.[0]?.band,
              additionalWifiSSID: wifi?.[1]?.ssid,
              additionalWifiPassword: wifi?.[1]?.password,
              additionalWifiBand: wifi?.[1]?.band,
              images: memoizedimages,
              changePassword: mappedWorkspace.changeWifiPassword,
              installationDate: installationDate
                ? getISODateFromDateTime(installationDate)
                : formatISO(new Date(), { representation: 'date' }),
              constructionDate: constructionDate
                ? getISODateFromDateTime(constructionDate)
                : formatISO(new Date(), { representation: 'date' })
            }}
            onSubmit={(data) => {
              const mainWifi = {
                ssid: data.mainWifiSSID,
                password: data.mainWifiPassword,
                band: data.mainWifiBand
              };

              const additionalWifi = {
                ssid: data.additionalWifiSSID,
                password: data.additionalWifiPassword,
                band: data.additionalWifiBand
              };

              if (!mainWifi.ssid && !mainWifi.password && !!wifi[0].id) {
                deleteWifi(wifi[0].id);
              } else if (!wifi.length) {
                createWifi(mainWifi.ssid, mainWifi.password, workspace.id, mainWifi.band);
              } else {
                updateWifi(
                  { ...wifi[0], ssid: mainWifi.ssid, password: mainWifi.password, band: mainWifi.band },
                  workspace.id
                );
              }

              if (!additionalWifi.ssid && !additionalWifi.password && !!wifi[1]?.id) {
                deleteWifi(wifi[1].id);
              } else if (additionalWifi.ssid && additionalWifi.password && additionalWifi.band) {
                if (wifi.length === 1)
                  createWifi(additionalWifi.ssid, additionalWifi.password, workspace.id, additionalWifi.band);
                else {
                  updateWifi(
                    {
                      ...wifi[1],
                      ssid: additionalWifi.ssid,
                      password: additionalWifi.password,
                      band: additionalWifi.band
                    },
                    workspace.id
                  );
                }
              }

              data.changeWifiPassword = changePassword;

              return updateWorkspace(workspace.id, data).then((workspace) =>
                history.push(urls.locations.about.get(workspace.id))
              );
            }}
          >
            {(formRenderProps, id) => {
              const { dirty, pristine, submitting } = formRenderProps;

              return (
                <Card className="w-full p-5">
                  <WorkspaceForm
                    id={id}
                    text={text}
                    formProps={formRenderProps}
                    scope="edit"
                    setChangePassword={setChangePassword}
                    nookaLive={mappedWorkspace.provider === IotProvider.NookaLive}
                  />

                  <Spacer className="border-b border-solid border-gray-lighter my-7" />

                  <Grid.Row>
                    <Grid.Column sm={3} className="flex items-center" padded>
                      <Form.Field
                        is={ToggleSwitch}
                        id={`${id}-status`}
                        name="status"
                        label={false}
                        map={workspaceStatusMap}
                      >
                        <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                          <FormattedMessage id={translations.inputs.toggles.workspaceStatus} />
                        </Typography>
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column sm={3} className="flex items-center" padded>
                      <Form.Field
                        is={ToggleSwitch}
                        id={`${id}-visibility`}
                        name="visibility"
                        label={false}
                        map={workspaceVisibilityMap}
                        disabled={me.role === UserRole.IndividualLessor}
                      >
                        <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                          <FormattedMessage id={translations.inputs.toggles.workspaceVisibility} />
                        </Typography>
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column sm={3} className="flex items-center justify-end" padded>
                      <Button loading={submitting} disabled={!dirty || pristine} appearance="red">
                        <Icon type="tickSquare" className="text-base leading-none pr-2" />

                        <FormattedMessage id={text.save} />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Card>
              );
            }}
          </Form>
        </div>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(WorkspaceEditPage);
