import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';
import { VersionIOT } from '@/domains/iot-boards/iot-version';

export interface Props extends Omit<SelectProps<VersionIOT>, 'items'>, WrappedComponentProps {}

const IotProviderVersionSelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = Object.values(VersionIOT).map((provider) => ({
    value: provider,
    name: intl.formatMessage({ id: translations.domains.iotProviderVersion[provider] })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(IotProviderVersionSelect);
export const IotProviderVersionSelectWithValidation = withValidation(Default);
export default Default;
