import React from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@/components/Button';
import { translations } from '@/locale/messages';
import { actionLogCSVFile } from '@/page/Testing/TestingProcessPage/util/test-form';
import { ActionLog } from '@/domains/action-log';

export interface Props {
  data: ActionLog[];
  title: string;
  placeholder: string;
  className: string;
  onClick: () => void;
}

const text = translations.pages.testReports;
export const DownloadActionLogReport: React.FC<Props> = ({ data, title, placeholder, className, onClick }) => {
  const intl = useIntl();
  const reportActionLog = data.map((value: ActionLog) => [
    new Date(value.createdAt).toLocaleDateString() + ' ' + new Date(value.createdAt).toLocaleTimeString(),
    value.editor,
    value.state,
    value.sensor,
    value.command
  ]);

  return (
    <Button
      is={CSVLink}
      key={placeholder}
      data={reportActionLog}
      headers={actionLogCSVFile}
      filename={title}
      appearance="blue"
      {...{ className, onClick }}
    >
      {placeholder ?? <FormattedMessage id={translations.placeholders.download} />}
    </Button>
  );
};
