import React from 'react';

import { Image as DomainImage } from '@/domains';

import Image from '@/components/Image';
import BoxedIcon from '@/components/Icon/BoxedIcon';

interface Props {
  images: DomainImage[];
}

const ImageGallery = ({ images }: React.PropsWithChildren<Props>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [index, setIndex] = React.useState(0);

  return (
    <div className="relative overflow-hidden whitespace-nowrap h-[16.25rem]">
      <button
        type="button"
        className="z-10 absolute inset-y-1/2 -translate-x-1/2 left-5 focus:outline-none"
        onClick={() => {
          const newIndex = index > 0 ? index - 1 : 0;
          setIndex(newIndex);
          ref.current.style.transform = `translateX(calc(-${newIndex} * (21.25rem + 1.5rem)))`;
        }}
      >
        <BoxedIcon type="arrowLeft" />
      </button>

      <div className="-m-3 transition" ref={ref}>
        {images.map((image, index) => (
          <Image key={index} url={image.url} className="w-[21.25rem] h-[16.25rem] m-3" />
        ))}
      </div>

      <button
        type="button"
        className="z-10 absolute inset-y-1/2 -translate-x-1/2 right-5 focus:outline-none"
        onClick={() => {
          const newIndex = index < images.length - 1 ? index + 1 : index;
          setIndex(newIndex);
          ref.current.style.transform = `translateX(calc(-${newIndex} * (21.25rem + 1.5rem)))`;
        }}
      >
        <BoxedIcon type="arrowRight" />
      </button>
    </div>
  );
};

export default ImageGallery;
