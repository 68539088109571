import React from 'react';
import classnames from 'classnames';

import { InstanceProps } from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import CloseIcon from '@/components/Icon/CloseIcon';

export interface Props extends Pick<InstanceProps, 'close'> {
  className?: string;
}

const ModalHeader: React.FC<Props> = ({ close, children, className }) => (
  <div className={classnames('flex flex-row justify-between items-center', className)}>
    <Typography is="h6" type="regular">
      {children}
    </Typography>

    <Button onClick={close} className="w-5 h-5">
      <CloseIcon />
    </Button>
  </div>
);

export default ModalHeader;
