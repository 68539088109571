import { RouterBit, RouterInformation } from '@/domains/router';
import { authorizedApi } from '../network/authorized-api';

export const getRouterInformations = (workspaceId: string) =>
  authorizedApi.get<RouterInformation>(`/workspace-router`, { workspaceId });

export const rebootRouter = (workspaceId: string) =>
  authorizedApi.post<null>(`/workspace-router/reboot`, { workspaceId });

export const refreshRouter = (workspaceId: string) =>
  authorizedApi.post<null>(`/workspace-router/ping`, { workspaceId });

export const getRouterBits = (workspaceId: string) =>
  authorizedApi.get<RouterBit>(`/workspace-router/bit-rate`, { workspaceId });
