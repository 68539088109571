import React from 'react';
import { debounce, uniqBy } from 'lodash';

import { fullName } from '@/util';
import { IotProvider, UserDetails, UserListItem, UserRole, WorkspaceDetails, WorkspaceListItem } from '@/domains';
import { getAllUsers, getUserById } from '@/services/api/users';
import usePagination from '@/hooks/usePagination';

import Select, { Props as SelectProps, Item as SelectItem } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';
import { getWorkspaceById, getWorkspaces } from '@/services/api/workspace';
import UserSelect from '../UserSelect';
import WorkspacesSelect from '../WorkspacesSelect/WorkspacesSelect';

export interface Props extends SetHooks, Omit<SelectProps<string>, 'items'> {}

export interface SetHooks {
  setWorkspaceSelected?: (value) => void;
  setWorkspaceId?: (value) => void;
}

interface State {
  selected?: SelectItem<string>;
  keyword?: string;
}

const toWorkspaceSelectItem = (workspace: WorkspaceDetails | WorkspaceListItem): SelectItem<string> => ({
  value: workspace.id,
  name: workspace.name
});

const WorkspaceSelectGraph = ({ setWorkspaceSelected, setWorkspaceId, ...rest }: React.PropsWithChildren<Props>) => {
  const [state, setState] = React.useState<State>({});
  const { loading, more, data, page, loadPage } = usePagination({
    id: state.keyword,
    source: (page, size) => getWorkspaces(page, size, [], state.keyword, IotProvider.NookaLive)
  });

  const loadFromId = (id: string) =>
    getWorkspaceById(id).then((user) =>
      setState((previous) => ({ ...previous, selected: toWorkspaceSelectItem(user) }))
    );

  const memoizedData = React.useMemo(() => data.map(toWorkspaceSelectItem), [data]);
  React.useEffect(() => {
    if (!!rest.value) loadFromId(rest.value as string);
  }, []);

  const ref = React.useRef<HTMLUListElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  const handleSearch = debounce((value) => {
    if (!value || loading) return;
    setState((previous) => ({ ...previous, keyword: previous.selected?.name === value ? undefined : value }));
  }, 300);

  const getWorkspaceName = (value: string | string[]): string => {
    let name = '';
    memoizedData.map((item) => {
      if (item.value[0] === value) name = item.name;
    });
    return name;
  };

  return (
    <Select
      {...rest}
      onChange={(value) => {
        setWorkspaceSelected(getWorkspaceName(value[0]));
        setWorkspaceId(value);
        loadFromId(value as string);
      }}
      onInputValueChange={handleSearch}
      items={!state.selected ? memoizedData : uniqBy([state.selected, ...memoizedData], (item) => item.value)}
      dropdownContentLoading={loading}
      onDropdownContentScroll={handleScroll}
      dropdownContentRef={ref}
    />
  );
};

const Default = WorkspacesSelect;
export const WorkspacesSelectSelectWithValidation = withValidation(Default);
export default WorkspaceSelectGraph;
