import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';

import InviteUserStep, { Props as InviteUserStepProps } from './InviteUserStep';
import InvitationSentStep, { Props as InvitationSentStepProps } from './InvitationSentStep';

export interface Props extends InstanceProps {}

export interface StepInstanceProps {
  nextStep: (step: Step, data: any) => void;
}

export enum Step {
  InviteUser,
  InvitationSent
}

interface StepProps {
  [Step.InvitationSent]: InvitationSentStepProps;
  [Step.InviteUser]: InviteUserStepProps;
}
const renderStep: Record<Step, any> = {
  [Step.InviteUser]: InviteUserStep,
  [Step.InvitationSent]: InvitationSentStep
};

type StepData = { [K in Step]?: any };

interface State {
  currentStep: Step;
  data: StepData;
}

const getStepComponent: <T extends Step>(step: T) => React.ComponentType<StepProps[T]> = (step) => renderStep[step];
const getStepComponentProps = (state: State) => state.data[state.currentStep] || {};

const AddUserModal: React.FC<Props> = ({ close }) => {
  const [state, setState] = React.useState<State>({
    currentStep: Step.InviteUser,
    data: {}
  });

  const StepComponent = getStepComponent(state.currentStep);
  const nextStep = (step: Step, data: StepData) =>
    setState((prev) => ({ currentStep: step, data: { ...prev.data, [step]: data } }));

  return (
    <Card className="w-[450px] p-6">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close}>
            {state.currentStep === Step.InviteUser && (
              <Typography is="span" type="small-header" className="font-bold">
                <FormattedMessage id={translations.modals.addUserModal.title} />
              </Typography>
            )}
          </ModalHeader>

          <StepComponent nextStep={nextStep} {...getStepComponentProps(state)} close={close} />
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default AddUserModal;
