import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import BookingsListPage from './BookingsListPage';

const Bookings: React.FC = () => (
  <Switch>
    <Route exact path={urls.bookings.list} component={BookingsListPage} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.bookings.list} />} />
  </Switch>
);

export default Bookings;
