import React from 'react';
import { WorkspaceState } from '@/domains';
import { WorkspaceBoothStatus } from '@/domains/workspace-status';
import Typography from '@/components/Typography';
import InfoStatus from '@/page/Monitoring/MonitoringPage/MonitoringSection/WorkspaceStatusMonitoring/CreateStatus/InfoStatus';
import { statusMessage } from './InfoStatus/utils/utils';

export interface Props {
  monitor: WorkspaceBoothStatus;
  indexMonitor: number;
  indexElement: number;
  numberElementsMonitored: number;
}

const customStyle = (indexMonitor: number, indexStatus: number, numberElementsMonitored: number) => {
  if (indexMonitor === numberElementsMonitored - 1)
    if (indexStatus <= 2 && numberElementsMonitored - indexMonitor == 1)
      return '-top-24 p-2 bg-black text-white w-[150px] h-auto -z-50 -left-1 mb-30 gap-5 text-center';
    else if (indexStatus <= 2 && numberElementsMonitored - indexMonitor == -1)
      return '-top-24 p-2 bg-black text-white w-[150px] h-auto -z-50 -left-1 mb-30 gap-5 text-center';
    else if (indexStatus >= 27 && numberElementsMonitored - indexMonitor == 1)
      return '-top-24 p-2 bg-black text-white w-[150px] h-auto  -z-50 -left-3/4 mb-30 gap-5 text-center';
    else return '-top-24 p-2 bg-black text-white w-[150px] h-auto  -z-50 -left-24 mb-30 gap-5 text-center';
  else if (indexStatus <= 2 && indexMonitor < numberElementsMonitored - 1)
    return '-top-28 p-2 bg-black text-white w-[150px] h-auto  -z-50 -left-1 mb-30 gap-5 text-center';
  else if (indexMonitor == 0)
    if (indexStatus <= 29 && numberElementsMonitored - indexMonitor == numberElementsMonitored)
      return '-top-24 p-2 bg-black text-white w-[150px] h-auto  -z-50 -left-3/4 mb-30 gap-5 text-center';
    else if (indexStatus <= 2)
      return '-top-27 p-2 bg-black text-white w-[150px] h-auto -z-50 -left-1 mb-30 gap-5 text-center';
    else return '-top-27 p-2 bg-black text-white w-[150px] h-auto  -z-50 left-1 mb-30 gap-5 text-center';
  else return '-top-28 p-2 bg-black text-white w-[150px] h-auto  -z-50 -left-3/4  mb-30 gap-5 text-center';
};

export const CreateStatus = ({ monitor, indexMonitor, indexElement, numberElementsMonitored }) => {
  return (
    <InfoStatus
      key={monitor.date}
      message={statusMessage(monitor)}
      customStyle={customStyle(indexMonitor, indexElement, numberElementsMonitored)}
      className=" text-bottom text-sm"
    >
      {({ open, close }) => (
        <Typography
          is="span"
          type="header"
          className={
            monitor.status === WorkspaceState.Online
              ? 'bg-green p-1 rounded'
              : monitor.status === WorkspaceState.NOT_MONITORED
              ? 'bg-gray  p-1 rounded'
              : monitor.downtimeIntervals.length == 1 && monitor.downtimeIntervals[0] === 'ONLINE'
              ? 'bg-green p-1 rounded'
              : 'bg-red p-1 rounded'
          }
          onMouseOver={open}
          onMouseOut={close}
        ></Typography>
      )}
    </InfoStatus>
  );
};
export default CreateStatus;
