import React from 'react';
import classnames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type Appearance = 'red' | 'blue';

export interface Props extends NavLinkProps {
  appearance?: Appearance;
  className?: string;
  external?: boolean;
}

const style: Record<Appearance, string> = {
  red: 'text-red hover:underline',
  blue: 'text-blue-light hover:underline'
};

const Link: React.FC<Props> = ({ appearance, className, external, children, ...rest }) => {
  const root = 'cursor-pointer no-underline';

  return (
    <React.Fragment>
      {!!external ? (
        <a
          className={classnames(root, style[appearance], className)}
          target="_blank"
          rel="noopener noreferrer"
          href={rest.to.toString()}
        >
          {children}
        </a>
      ) : (
        <NavLink className={classnames(root, style[appearance], className)} {...rest}>
          {children}
        </NavLink>
      )}
    </React.Fragment>
  );
};

export default Link;
