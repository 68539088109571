import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@/components/Grid';
import If from '@/components/If';
import Typography from '@/components/Typography';
import { ActionLog } from '@/domains/action-log';
import { Page } from '@/domains/page';
import { translations } from '@/locale';
import { getActionsByWorkspacdId } from '@/services/api/workspace';
import TableFooter from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/ActionLogComponent/WorkspaceTroubleshootingTable/PagesForTable';
import WorkspaceTroubleshoootingTable from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/ActionLogComponent/WorkspaceTroubleshootingTable/WorkspaceTroubleshootingTable';
import Modal from '@/components/Modal/Modal';
import DownloadActionLogModal from '@/components/Modal/DownloadActionLogModal/DownloadActionLogModal';
import { AiOutlineDownload } from 'react-icons/ai';
import { VersionIOT } from '@/domains/iot-boards/iot-version';

export interface Props {
  workspaceId: string;
  workspaceName: string;
  versionIOT?: VersionIOT;
}

export const ActionLogComponent = ({ workspaceId, workspaceName, page, setCurrentPage, versionIOT }) => {
  const text = translations.pages.workspaces.details.tabs.troubleshooting;
  const [actionLogData, setActionLogData] = React.useState<Page<ActionLog>>();
  React.useEffect(() => {
    getActionsByWorkspacdId(page, 10, workspaceId).then((data) => setActionLogData(data));
  }, [page]);
  const intl = useIntl();

  return (
    <If
      condition={actionLogData}
      then={() => {
        return (
          <React.Fragment>
            <div className="flex gap-3 mb-8 mt-auto p-4">
              <Typography is="h4" type="header" className="font-bold text-gray-darkest">
                <FormattedMessage id={text.subtitle} />
              </Typography>

              <Modal
                modal={DownloadActionLogModal}
                message={intl.formatMessage({ id: translations.placeholders.downloadActionLogReport })}
                messageInfo={intl.formatMessage({ id: translations.placeholders.downloadActionLogReportInfo })}
                workspaceId={workspaceId}
                workspaceName={workspaceName}
                confirmMessage={intl.formatMessage({ id: translations.placeholders.download })}
                cancelMessage={intl.formatMessage({ id: translations.placeholders.cancel })}
                downloadFileTitle={intl.formatMessage({ id: translations.placeholders.downloadActionLogReportTitle })}
              >
                {({ open }) => (
                  <AiOutlineDownload type="button" className="text-xl mt-3 ml-2 text-blue-light" onClick={open} />
                )}
              </Modal>
            </div>

            <WorkspaceTroubleshoootingTable data={actionLogData.content} />

            <Grid.Row className="p-3 flex w-full justify-between">
              <Grid.Column>
                <TableFooter
                  page={page}
                  setCurrentPage={setCurrentPage}
                  numberOfElements={actionLogData.totalNumberOfElements}
                />
              </Grid.Column>
              {versionIOT && (
                <Grid.Column>
                  <Grid.Row>
                    <Typography is="span" className="text-xs text-gray-darkest p-2 gap-2">
                      <FormattedMessage id={text.versionIOT} />
                    </Typography>
                    <Typography is="span" className="font-bold text-xs text-gray-darkest p-2">
                      <FormattedMessage id={versionIOT} />
                    </Typography>
                  </Grid.Row>
                </Grid.Column>
              )}
            </Grid.Row>
          </React.Fragment>
        );
      }}
    />
  );
};
export default ActionLogComponent;
