import React from 'react';
import { FormattedMessage, useIntl, WrappedComponentProps } from 'react-intl';
import { translations } from '@/locale';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import Card from '@/components/Card';
import { WorkspaceSelectList } from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/WorkspaceSelectList/WorkspaceSelectList';

const TestingProcessPage: React.FC<WrappedComponentProps> = () => {
  const text = translations.pages.testing;
  const intl = useIntl();

  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>
      <Card className="w-full h-full p-3">
        <Grid.Row>
          <Grid.Column sm={5} padded>
            <Typography
              is={WorkspaceSelectList}
              editable
              required
              usedFor={intl.formatMessage({ id: translations.navigation.testing })}
            />
          </Grid.Column>
        </Grid.Row>

        {Object.entries(text.startPage.notes).map(([key, value]) => {
          return (
            <Typography key={value} is="h1" className="p-3">
              <FormattedMessage id={value} />
            </Typography>
          );
        })}
      </Card>
    </div>
  );
};
export default TestingProcessPage;
