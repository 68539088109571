import React from 'react';
import classnames from 'classnames';

import Icon, { Type as IconType } from '@/components/Icon';

export interface Props {
  type: IconType;
  className?: string;
}

const BoxedIcon = ({ type, className }: React.PropsWithChildren<Props>) => (
  <span
    className={classnames(
      'flex items-center justify-center rounded-full text-gray-lightest bg-gray h-5 w-5 hover:bg-red transition',
      className
    )}
  >
    <Icon type={type} style={{ fontSize: '1rem' }} className="font-bold" />
  </span>
);

export default BoxedIcon;
