import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { sendResetPasswordLink } from '@/services/api/session';

import Form from '@/components/Form';
import Button from '@/components/Button';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .label(translations.inputs.email.label)
    .required()
});

export interface Props extends RouteComponentProps {}

class ResetPasswordLinkExpiredPage extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    return (
      <React.Fragment>
        <div className="flex flex-col text-center mb-4">
          <Typography is="h1" type="header" className="font-bold mb-4">
            <FormattedMessage id={translations.pages.resetLinkExpired.title} />
          </Typography>

          <Typography is="p" type="regular" className="text-gray-dark">
            <FormattedMessage id={translations.pages.resetLinkExpired.subtitle} />
          </Typography>
        </div>

        <Form
          id="forgot-password"
          schema={schema}
          subscription={{ submitError: true, submitting: true }}
          onSubmit={({ email }) =>
            sendResetPasswordLink(email).then(() => this.props.history.push(urls.forgotPassword.sent))
          }
        >
          {({ submitError, submitting }, id) => (
            <div className="flex flex-col">
              <div className="mb-8">
                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-email`}
                  name="email"
                  type="email"
                  label={this.props.intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                  readOnly={submitting}
                />
              </div>

              {!!submitError && <SubmitError error={submitError} className="mb-8" />}

              <Button type="submit" loading={submitting} appearance="red">
                <FormattedMessage id={translations.navigation.submit} />
              </Button>
            </div>
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default withRouter(injectIntl(ResetPasswordLinkExpiredPage));
