import {
  Page,
  PromoCodeCreation,
  PromoCodeCreationForm,
  PromoCodeUpdate,
  PromoCodeEditForm,
  PromoCodeNumberOfUseType,
  PromoCodeListItem
} from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

const mapPromoCodeCreation = (form: PromoCodeCreationForm): PromoCodeCreation => ({
  discountPercentage: form.discountPercentage,
  maxMinuteLimit: form.maximumHourLimit * 60,
  code: form.code,
  workspaceIds: form.workspaceIds,
  userId: form.userId,
  limitUsagePerUser: form.limitUsagePerUser ?? null,
  limitUsage: form.limitUsage ?? null,
  isSingleUse: form.numberOfUse === PromoCodeNumberOfUseType.SingleUse,
  dailyUseLimit: form.numberOfUse === PromoCodeNumberOfUseType.OncePerDay ? 1 : 0,
  expiresAt: form.validUntil
});

const mapPromoCodeUpdate = (form: PromoCodeEditForm): PromoCodeUpdate => ({
  userId: form.userId,
  workspaceIds: form.workspaceIds,
  discountPercentage: form.discountPercentage,
  limitUsagePerUser: form.limitUsagePerUser,
  limitUsage: form.limitUsage,
  maxMinuteLimit: form.maximumHourLimit * 60,
  isSingleUse: form.numberOfUse === PromoCodeNumberOfUseType.SingleUse,
  dailyUseLimit: form.numberOfUse === PromoCodeNumberOfUseType.OncePerDay ? 1 : 0,
  expiresAt: form.validUntil
});

export const getPromoCodes = (pageNumber: number, pageSize: number) =>
  authorizedApi.get<Page<PromoCodeListItem>>(`/promo-code`, { pageNumber, pageSize });

export const getPromoCodesForWorkspace = (pageNumber: number, pageSize: number, workspaceId: string) =>
  authorizedApi.get<Page<PromoCodeListItem>>(`/promo-code`, { pageNumber, pageSize, workspaceId });

export const createPromoCode = (form: PromoCodeCreationForm) => {
  const promoCodeCreation = mapPromoCodeCreation(form);
  return authorizedApi.post<null>(`/promo-code`, promoCodeCreation);
};

export const updatePromoCode = (id: string, form: PromoCodeEditForm) => {
  const promoCodeUpdate = mapPromoCodeUpdate(form);
  return authorizedApi.patch<null>(`/promo-code/${id}`, promoCodeUpdate);
};

export const deletePromoCode = (id: string) => authorizedApi.delete<null>(`/promo-code/${id}`);
