import React from 'react';
import { Form as OriginalForm, FormProps, FormRenderProps } from 'react-final-form';
import { FORM_ERROR, FormApi, ValidationErrors, SubmissionErrors } from 'final-form';
import * as yup from 'yup';
import set from 'lodash/set';

import ErrorBoundary from '@/components/ErrorBoundary';
import { ValidationError } from '@/components/ValidationMessage';

import FormField from './FormField';

interface BackendError extends Error {
  status: number;
  fields?: {
    [x: string]: string;
  };
  error_message: string;
}

export type ErrorHandler<T> = (error: T) => string | object | undefined;

interface ErrorHandlerMap {
  [code: number]: ErrorHandler<BackendError>;
  default: ErrorHandler<Error | BackendError>;
}

export const handleError = (map: ErrorHandlerMap) => (error: BackendError) => {
  const handler = map[error.status] || map.default;

  return handler(error) || map.default(error);
};

export type RenderProps<T> = FormRenderProps<T>;

export type Props<T extends object> = Omit<FormProps<T>, 'onSubmit'> & {
  id?: string;
  className?: string;
  schema?: yup.ObjectSchema<T>;
  children: (form: RenderProps<T>, id: string) => Object;
  onSubmit: (values: T, form: FormApi<T>) => Promise<Object | null> | Object | null;
};

export default class Form<T extends object> extends React.PureComponent<Props<T>> {
  static Field = FormField;

  private getValue = (values: T) => {
    return this.props.schema ? this.props.schema.cast(values) : values;
  };

  private validate = (values: object): Promise<ValidationErrors | null> | undefined => {
    if (!this.props.schema) return Promise.resolve(undefined);

    return this.props.schema
      .validate(values, { abortEarly: false })
      .then(() => null)
      .catch((error: yup.ValidationError) => {
        const fields = error.inner.reduce((fields, error) => {
          set(fields, error.path, { id: error.message, values: error.params as any });
          return fields;
        }, {} as { [x: string]: ValidationError });

        return fields;
      });
  };

  submit = (values: T, form: FormApi<T>): Promise<SubmissionErrors | null> => {
    return Promise.resolve()
      .then(() => this.props.onSubmit(this.getValue(values as T), form as FormApi<T>))
      .catch((error) => ({ [FORM_ERROR]: error }));
  };

  render() {
    const { id, schema, className, children, ...rest } = this.props;

    return (
      <ErrorBoundary>
        <OriginalForm
          {...rest}
          validate={this.validate}
          // @ts-ignore
          onSubmit={this.submit}
          render={(form) => (
            <form
              id={id}
              className={className}
              onSubmit={form.handleSubmit}
              autoComplete="off"
              spellCheck={false}
              noValidate
            >
              {children(form as any, id)}
            </form>
          )}
        />
      </ErrorBoundary>
    );
  }
}
