import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { Pricing } from '@/domains';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Price from '@/components/Price';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import PurchasePackageModal from '@/components/Modal/PurchasePackageModal';
import minusIcon from './minusIcon.svg';
import nookaLogoIcon from './nookaLogoIcon.svg';
import promoPackBgImg from './promo-pack-background.png';
import Icon from '../Icon';
import EmailListCreditsForm, { SubmitValuesProps } from '../Form/EmailListCreditsForm';
import CreditSelector from '../CreditSelector';
import { useAsyncRetry } from 'react-use';
import { getAvailableCredits } from '@/services/api/wallets';

export interface Props {
  id: string;
  name: string;
  includedCredits: string;
  validityPeriod: string;
  validityValue: number;
  price: Pricing;
  className?: string;
  userId: string;
}

const PromoCreditsAssignCard = ({
  id,
  name,
  includedCredits,
  validityPeriod,
  validityValue,
  price,
  className,
  userId
}: Props) => {
  const intl = useIntl();
  const [bonusTotal, setBonusTotal] = useState<number>(0);
  const [creditTotal, setCreditTotal] = useState<number>(0);
  const [creditSelected, setCreditSelected] = useState<number>(0);

  const handleSetCreditSelected = (value: number) => {
    setCreditSelected(value);
  };

  const creditsAvailable = useAsyncRetry(async () => {
    return await getAvailableCredits(userId);
  }, []);

  useEffect(() => {
    if (!creditsAvailable.loading) {
      setCreditTotal(creditsAvailable.value.availableCredits);
      setBonusTotal(creditsAvailable.value.availableBonus);
    }
  }, [creditsAvailable]);

  const handleSubmit = async (values: SubmitValuesProps) => {};

  return (
    <Card className={classnames('p-4 group', className)}>
      <div className="flex justify-between  border border-solid border-gray-lighter rounded-2xl">
        <div className="flex justify-center p-2">
          <div className="pt-2">
            <img src={nookaLogoIcon} width={47} />
          </div>
          <div className="flex flex-col justify-center p-2">
            <Typography is="span" type="subheader" className="font-bold text-gray-darkest">
              {`${creditTotal} tokens`}
            </Typography>
            <Typography is="span" type="xs" className="text-gray-dark">
              {`Current Wallet Balance`}
            </Typography>
          </div>
        </div>
        <div className="flex justify-center p-2">
          <div className="flex flex-col justify-center p-2">
            <Typography is="span" type="xs" className="text-gray-dark">
              {`Bonus earned so far`}
            </Typography>
            <Typography is="span" type="sm" className="font-bold text-gray-darkest">
              {`${bonusTotal} tokens (€${(bonusTotal / parseInt(includedCredits)).toFixed(2)})`}
            </Typography>
          </div>
        </div>
      </div>
      <div className="pt-2 pl-1">
        <Typography is="span" type="xs" className="text-gray-darkest">
          {`Set tokens amount`}
        </Typography>
      </div>
      <CreditSelector
        creditSelected={creditSelected}
        step={parseInt(includedCredits)}
        handleSetCreditSelected={handleSetCreditSelected}
        className={'my-4 w-1/2 2xl:w-1/5'}
        position={'start'}
        type={'credit'}
      />
      <div className="pt-1 pl-1">
        <Typography is="span" type="xs" className="text-gray-darkest">
          {`Assign Tokens`}
        </Typography>
      </div>

      <div className="pt-1 w-full overflow-y-auto">
        <EmailListCreditsForm
          id="assign-package"
          onSubmit={handleSubmit}
          submitButtonText={intl.formatMessage({ id: translations.pages.promoCredits.assignCredits.title })}
          emailInputPlaceholder={intl.formatMessage({ id: translations.inputs.fields.customerEmail.placeholder })}
          addEmaiButtonText={intl.formatMessage({ id: translations.pages.promoCredits.assignCredits.addEmailButton })}
        />
      </div>

      <div className="flex justify-center items-center">
        <div className="w-1/2">
          <Button block appearance="red-rounded">
            <FormattedMessage id={translations.pages.promoCredits.assignCredits.title} />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PromoCreditsAssignCard;
