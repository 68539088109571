import React from 'react';
import { injectIntl, FormattedDate, FormattedMessage, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { WorkspaceDetails, PromoCodeListItem, PromoCodeNumberOfUseType } from '@/domains';
import { getPromoCodesForWorkspace } from '@/services/api/promo-code';

import If from '@/components/If';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import Refresh from '@/components/Refresh';

export interface Props {
  workspace: WorkspaceDetails;
}

const text = translations.pages.workspaces.details.tabs.promoCodes;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.table.header.code, sm: 3 },
  { name: text.table.header.discount, sm: 2 },
  { name: text.table.header.source, sm: 2 },
  { name: text.table.header.createdAt, sm: 4 },
  { name: text.table.header.validUntil, sm: 4 },
  { name: text.table.header.type, sm: 2 }
];

const PromoCodesTab: React.FC<Props & WrappedComponentProps> = ({ workspace, intl }) => (
  <Refresh>
    {({ refresh, key }) => (
      <React.Fragment>
        <Typography is="h2" type="subheader" className="font-bold mt-3 mb-6">
          <FormattedMessage id={text.title} />
        </Typography>

        <InfiniteScrollableTable
          key={key}
          source={(page, number) => getPromoCodesForWorkspace(page, number, workspace.id)}
          empty={() => (
            <Placeholder className="mt-12">
              <Typography is="span" type="small-header" className="font-bold">
                <FormattedMessage id={text.empty} />
              </Typography>
            </Placeholder>
          )}
          renderHeader={() => (
            <Table.Header>
              {headerList.map((headerItem) => (
                <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                  <Typography is="span" type="regular" className="font-bold uppercase text-gray">
                    <FormattedMessage id={headerItem.name} />
                  </Typography>
                </Table.Data>
              ))}
            </Table.Header>
          )}
        >
          {(data) => (
            <React.Fragment>
              {data.map((promoCode: PromoCodeListItem) => (
                <Table.Row hoverable key={promoCode.code}>
                  <Table.Data sm={headerList[0].sm}>
                    <Typography is="span" className="ml-3">
                      {promoCode.code}
                    </Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[1].sm}>
                    <Typography is="span">{promoCode.discountPercentage}%</Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[2].sm}>
                    <Typography is="span">
                      <If
                        condition={!promoCode.workspaces.length}
                        then={() => (
                          <If
                            condition={!promoCode.owner}
                            then={() => <FormattedMessage id={translations.misc.global} />}
                            else={() => <FormattedMessage id={translations.inputs.fields.owner.label} />}
                          />
                        )}
                        else={() => <FormattedMessage id={translations.inputs.fields.nooka.label} />}
                      />
                    </Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[3].sm}>
                    <FormattedDate value={promoCode.createdAt} day="2-digit" month="long" year="numeric" />
                  </Table.Data>

                  <Table.Data sm={headerList[4].sm}>
                    <Typography is="span">
                      <FormattedDate value={promoCode.expiresAt} day="2-digit" month="long" year="numeric" />
                    </Typography>
                  </Table.Data>

                  <Table.Data sm={headerList[5].sm}>
                    <FormattedMessage
                      id={
                        translations.domains.promoCodeNumberOfUseType[
                          promoCode.limitUsagePerUser
                            ? PromoCodeNumberOfUseType.CustomUse
                            : promoCode.isSingleUse
                            ? PromoCodeNumberOfUseType.SingleUse
                            : PromoCodeNumberOfUseType.UnlimitedUse
                        ]
                      }
                    />
                  </Table.Data>
                </Table.Row>
              ))}
            </React.Fragment>
          )}
        </InfiniteScrollableTable>
      </React.Fragment>
    )}
  </Refresh>
);

export default injectIntl(PromoCodesTab);
