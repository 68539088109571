import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { fullName, getPromoCodeNumberOfUseTypeFromBoolean, isDateInPast } from '@/util';
import { Magnitude } from '@/constants';
import { PromoCodeListItem } from '@/domains';
import { translations } from '@/locale';
import { getPromoCodes } from '@/services/api/promo-code';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Modal from '@/components/Modal';
import AddPromoCodeModal from '@/components/Modal/AddPromoCodeModal';
import EditPromoCodeModal from '@/components/Modal/EditPromoCodeModal';
import Placeholder from '@/components/Placeholder';
import Refresh from '@/components/Refresh';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import If from '@/components/If';
import Optional from '@/components/Optional';

const text = translations.pages.promotions.list;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.table.header.code, sm: 3 },
  { name: text.table.header.discount, sm: 2 },
  { name: text.table.header.scope, sm: 4 },
  { name: text.table.header.createdAt, sm: 3 },
  { name: text.table.header.validUntil, sm: 3 },
  { name: text.table.header.type, sm: 2 }
];

const PromotionsListPage: React.FC = () => (
  <div className="m-8">
    <Refresh>
      {({ refresh, key }) => (
        <React.Fragment>
          <div className="flex justify-between mb-8">
            <Typography is="h1" type="header" className="font-bold text-gray-darkest">
              <FormattedMessage id={text.title} />
            </Typography>

            <Modal modal={AddPromoCodeModal} reloadPromoCodeList={refresh}>
              {({ open }) => (
                <Button appearance="red" onClick={open}>
                  <Icon type="plus" className="text-base leading-none pr-2" />

                  <FormattedMessage id={text.create} />
                </Button>
              )}
            </Modal>
          </div>

          <InfiniteScrollableTable
            key={key}
            source={(page, number) => getPromoCodes(page, number)}
            empty={() => (
              <Placeholder className="mt-12">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.empty} />
                </Typography>
              </Placeholder>
            )}
            renderHeader={() => (
              <Table.Header>
                {headerList.map((headerItem) => (
                  <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                    <Typography is="span" type="regular" className="font-bold uppercase text-gray">
                      <FormattedMessage id={headerItem.name} />
                    </Typography>
                  </Table.Data>
                ))}
              </Table.Header>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((promoCode: PromoCodeListItem) => (
                  <Modal key={promoCode.id} modal={EditPromoCodeModal} value={promoCode} reloadPromoCodeList={refresh}>
                    {({ open }) => (
                      <Table.Row hoverable onClick={open}>
                        <Table.Data sm={headerList[0].sm}>
                          <Typography is="span" className="ml-3">
                            {promoCode.code}
                          </Typography>
                        </Table.Data>

                        <Table.Data sm={headerList[1].sm}>
                          <Typography is="span">{promoCode.discountPercentage}%</Typography>
                        </Table.Data>

                        <Table.Data sm={headerList[2].sm}>
                          {!promoCode.workspaces.length && !promoCode.owner ? (
                            <FormattedMessage id={translations.misc.global} />
                          ) : (
                            <div>
                              <Optional
                                of={promoCode.owner}
                                then={(owner) => fullName(owner.firstName, owner.lastName)}
                              />
                              <span> - </span>
                              {promoCode.workspaces.length > 0 ? (
                                promoCode.workspaces.map((workspace) => workspace.workspace.name).join(', ')
                              ) : (
                                <FormattedMessage id={translations.misc.all} />
                              )}
                            </div>
                          )}
                        </Table.Data>

                        <Table.Data sm={headerList[3].sm}>
                          <FormattedDate value={promoCode.createdAt} day="2-digit" month="long" year="numeric" />
                        </Table.Data>

                        <Table.Data sm={headerList[4].sm}>
                          <Typography
                            is="span"
                            className={classnames({ 'text-red': isDateInPast(promoCode.expiresAt) })}
                          >
                            <FormattedDate value={promoCode.expiresAt} day="2-digit" month="long" year="numeric" />
                          </Typography>
                        </Table.Data>

                        <Table.Data sm={headerList[5].sm}>
                          <FormattedMessage
                            id={
                              translations.domains.promoCodeNumberOfUseType[
                                getPromoCodeNumberOfUseTypeFromBoolean(
                                  promoCode.isSingleUse,
                                  promoCode.dailyUseLimit,
                                  promoCode.limitUsagePerUser
                                )
                              ]
                            }
                          />
                        </Table.Data>
                      </Table.Row>
                    )}
                  </Modal>
                ))}
              </React.Fragment>
            )}
          </InfiniteScrollableTable>
        </React.Fragment>
      )}
    </Refresh>
  </div>
);

export default PromotionsListPage;
