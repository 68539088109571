import Table from '@/components/Table';
import Typography from '@/components/Typography';
import React from 'react';

const WorkspaceTroubleshoootingTable = ({ data }) => {
  return (
    <Table className="flex">
      <Table.Header className="flex">
        <div className=" w-full flex justify-between gap-y-2.5">
          <Table.Data sm={3}>
            <Typography key={'Time Stamp'} is="span" className="p-1 flex-col">
              {'Time Stamp'}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography key={'Editor'} is="span" className="p-1 flex-col">
              {'Editor'}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography key={'State'} is="span" className="pl-10 flex-col">
              {'State'}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography key={'Sensor'} is="span" className="pl-14 flex-col">
              {'Sensor'}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography key={'Command'} is="span" className="pl-20 flex-col">
              {'Command'}
            </Typography>
          </Table.Data>
        </div>
      </Table.Header>

      {data.map((action) => (
        <div key={action.id} className="flex justify-between">
          <Table.Data sm={3}>
            <Typography is="span" className="p-1 flex-col">
              {new Date(action.createdAt).toLocaleDateString() + ' ' + new Date(action.createdAt).toLocaleTimeString()}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography is="span" className="pl-6 flex-col">
              {action.editor}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography is="span" className="pl-12 flex-col">
              {action.state}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography is="span" className="pl-14 flex-col">
              {action.sensor}
            </Typography>
          </Table.Data>

          <Table.Data sm={3}>
            <Typography is="span" className="pl-20 flex-col">
              {action.command}
            </Typography>
          </Table.Data>
        </div>
      ))}
    </Table>
  );
};

export default WorkspaceTroubleshoootingTable;
