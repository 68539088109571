import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { DateRange } from 'react-date-range';
import { endOfWeek, formatISO, isSameDay, startOfWeek } from 'date-fns';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { AssignedPackage, AssignedPackageStatus, PaymentStatus } from '@/domains';
import { getAssignedPackages } from '@/services/api/packages';

import Typography from '@/components/Typography';
import SearchInput from '@/components/SearchInput';
import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import Optional from '@/components/Optional';
import DashPlaceholder from '@/components/Placeholder/DashPlaceholder';
import Info from '@/components/Info';
import AssignedPackagesListItemDropdown from '@/components/Dropdown/AssignedPackagesListItemDropdown';
import If from '@/components/If';
import Dropdown from '@/components/Dropdown';
import PackageSelect from '@/components/PackageSelect';
import AssignedPackageStatusSelect from '@/components/SimpleSelect/AssignedPackageStatusSelect';
import Badge from '@/components/Badge';
import Refresh from '@/components/Refresh';
import { getCreditHistory } from '@/services/api/credits';
import { AssignedCreditsStatus, PurchasedCredits } from '@/domains/purchased-credits';
import AssignedCreditsStatusSelect from '@/components/SimpleSelect/AssignedCreditsStatusSelect.tsx';

const pageTranslation = translations.pages.assignedCredits;

const headerList: { name?: string; info?: string; sm: Magnitude }[] = [
  { name: pageTranslation.table.header.customer, sm: 4 },
  { name: pageTranslation.table.header.date, sm: 3 },
  { name: pageTranslation.table.header.availableCredits, sm: 3 },
  { name: pageTranslation.table.header.status, info: pageTranslation.table.header.statusInfo, sm: 3 },
  { name: pageTranslation.table.header.actions, info: pageTranslation.table.header.actionsInfo, sm: 2 }
];

const assignedPackageStatusToClassNameMap: Record<AssignedPackageStatus, string> = {
  [AssignedPackageStatus.ASSIGNED]: 'text-[#2DCA73] bg-[#2DCA73] bg-opacity-10',
  [AssignedPackageStatus.INVITED]: 'text-[#F8AF6B] bg-[#F8AF6B] bg-opacity-10'
};

interface State {
  keyword: string;
  startDate: Date;
  endDate: Date;
  status: PaymentStatus[];
  key: string;
}

const AssignedCreditsPage = () => {
  const intl = useIntl();

  let [state, setState] = React.useState<State>({
    keyword: '',
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    status: [],
    key: 'assignedCredits'
  });

  return (
    <div className="m-8">
      <Typography is="h1" type="header" className="text-gray-darkest font-bold">
        <FormattedMessage id={pageTranslation.title} />
      </Typography>

      <div className="flex items-center gap-x-2 mb-4 mt-4">
        <SearchInput
          id="assigned-packages-search"
          className="w-[300px] bg-white"
          placeholder={intl.formatMessage({ id: pageTranslation.searchPlaceholder })}
          onChange={(keyword) => {
            setState({ ...state, keyword });
          }}
        />

        <AssignedCreditsStatusSelect
          id="assigned-packages-status"
          value={state.status}
          appearance="normal"
          className="bg-white"
          placeholder={intl.formatMessage({ id: pageTranslation.statusFilterPlaceholder })}
          onChange={(value) => setState({ ...state, status: value as PaymentStatus[] })}
          multiple
        />

        <Dropdown
          renderMenu={({ close }) => (
            <DateRange
              className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
              onChange={(item) => {
                setState({ ...state, ...item[state.key] });
              }}
              months={1}
              maxDate={new Date()}
              direction="vertical"
              ranges={[state]}
            />
          )}
          dropdownClassName="right-0 pl-4"
        >
          {({ isOpen, open, close }) => (
            <div
              className="hover:cursor-pointer inline-flex py-2 px-4 border border-solid border-gray-light transition bg-white whitespace-nowrap"
              onClick={isOpen ? close : open}
            >
              <FormattedDate value={state.startDate} day="2-digit" month="long" />
              <If
                condition={!isSameDay(state.startDate, state.endDate)}
                then={() => {
                  return (
                    <React.Fragment>
                      {` - `}
                      <FormattedDate value={state.endDate} day="2-digit" month="long" />
                    </React.Fragment>
                  );
                }}
              />
            </div>
          )}
        </Dropdown>
      </div>

      <Refresh>
        {({ key, refresh }) => (
          <InfiniteScrollableTable
            key={`${JSON.stringify(state)}${key}`}
            source={(page, number) =>
              getCreditHistory(page, number, {
                status: state.status,
                startDate: formatISO(state.startDate, { representation: 'date' }),
                endDate: formatISO(state.endDate, { representation: 'date' })
              })
            }
            empty={() => (
              <Placeholder className="mt-12">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={pageTranslation.empty} />
                </Typography>
              </Placeholder>
            )}
            renderHeader={() => (
              <Table.Header>
                {headerList.map((headerItem) => (
                  <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                    <If
                      condition={headerItem.info}
                      then={() => (
                        <Info message={intl.formatMessage({ id: headerItem.info })}>
                          {({ open, close }) => (
                            <Typography
                              is="span"
                              type="regular"
                              className="font-bold uppercase text-gray group hover:cursor-default"
                              onMouseOver={open}
                              onMouseOut={close}
                            >
                              <FormattedMessage id={headerItem.name} />

                              <Info.Button open={open} close={close} />
                            </Typography>
                          )}
                        </Info>
                      )}
                      else={() => (
                        <Typography is="span">
                          <FormattedMessage id={headerItem.name} />
                        </Typography>
                      )}
                    />
                  </Table.Data>
                ))}
              </Table.Header>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((credit: PurchasedCredits) => (
                  <Table.LinkRow key={credit.id}>
                    <Table.Data sm={headerList[0].sm} className="overflow-hidden">
                      <div className="flex flex-col w-full">
                        <Typography is="span" className="truncate">
                          {credit.customerName}
                        </Typography>

                        <Typography is="span" className="truncate">
                          {credit.customerEmail}
                        </Typography>
                      </div>
                    </Table.Data>

                    <Table.Data sm={headerList[1].sm}>
                      <Typography is="span">
                        <FormattedDate value={credit.date} day="2-digit" month="long" year="numeric" />
                      </Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[2].sm}>
                      <Typography is="span">{`${credit.availableCredits}/${credit.includedCredits}`}</Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[3].sm}>
                      <Badge className={assignedPackageStatusToClassNameMap[credit.paymentStatus]}>
                        <FormattedMessage id={translations.domains.paymentStatus[credit.paymentStatus]} />
                      </Badge>
                    </Table.Data>

                    <Table.Data sm={headerList[4].sm}>
                      <Optional
                        of={credit.status === AssignedCreditsStatus.INVITED}
                        then={() => (
                          <AssignedPackagesListItemDropdown
                            id={credit.id}
                            email={credit.customerEmail}
                            refreshList={refresh}
                          />
                        )}
                        else={() => <DashPlaceholder />}
                      />
                    </Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </InfiniteScrollableTable>
        )}
      </Refresh>

      <Typography is="div" className="pt-2">
        <FormattedMessage id={pageTranslation.note} />
      </Typography>
    </div>
  );
};

export default AssignedCreditsPage;
