import React from 'react';

import { FormattedDate, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Magnitude } from '@/constants';
import { TransactionHistory, PaymentStatus, PurchasedPackage } from '@/domains';
import { getPurchasedPackages } from '@/services/api/purchased-packages';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import Table from '@/components/Table';
import Price from '@/components/Price';
import Badge from '@/components/Badge';
import PurchasedPackagesListItemDropdown from '@/components/Dropdown/PurchasedPackagesListItemDropdown';
import Refresh from '@/components/Refresh';
import Optional from '@/components/Optional';
import DashPlaceholder from '@/components/Placeholder/DashPlaceholder';

const text = translations.pages.purchasedPackages;

const headerList: { name?: string; sm: Magnitude }[] = [
  { name: text.table.header.userName, sm: 3 },
  { name: text.table.header.packageName, sm: 2 },
  { name: text.table.header.transactionDate, sm: 3 },
  { name: text.table.header.included, sm: 2 },
  { name: text.table.header.availability, sm: 3 },
  { name: text.table.header.price, sm: 2 },
  { name: text.table.header.status, sm: 2 },
  { sm: 1 }
];

const PurchasedPackagesListPage = () => {
  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>

      <Refresh>
        {({ key, refresh }) => (
          <InfiniteScrollableTable
            key={key}
            className="bg-white"
            source={(page, number) => getPurchasedPackages(page, number)}
            empty={() => (
              <Placeholder className="mt-12">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.empty} />
                </Typography>
              </Placeholder>
            )}
            renderHeader={() => (
              <Table.Header>
                {headerList.map((headerItem, index) => (
                  <Table.Data header sm={headerItem.sm} key={`${headerItem.name}-${index}`}>
                    {headerItem.name && (
                      <Typography is="span" className="font-bold uppercase text-gray">
                        <FormattedMessage id={headerItem.name} />
                      </Typography>
                    )}
                  </Table.Data>
                ))}
              </Table.Header>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((purchasedPackage: PurchasedPackage) => (
                  <Table.LinkRow key={purchasedPackage.id}>
                    <Table.Data sm={headerList[0].sm} className="overflow-hidden">
                      <div className="flex flex-col w-full">
                        <Typography is="span" className="truncate">
                          {purchasedPackage.customerName}
                        </Typography>

                        <Typography is="span" className="truncate">
                          {purchasedPackage.customerEmail}
                        </Typography>
                      </div>
                    </Table.Data>

                    <Table.Data sm={headerList[1].sm}>
                      <Typography is="span">{purchasedPackage.packageName}</Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[2].sm}>
                      <Typography is="span">
                        <FormattedDate value={purchasedPackage.date} day="2-digit" month="long" year="numeric" />
                      </Typography>
                    </Table.Data>

                    <Table.Data sm={headerList[3].sm}>
                      {`${purchasedPackage.availableHours}/${purchasedPackage.includedHours}`}
                    </Table.Data>

                    <Table.Data sm={headerList[4].sm}>
                      <Optional
                        of={purchasedPackage.expiresAt}
                        then={(expiresAt) => (
                          <Typography is="span">
                            <FormattedDate value={expiresAt} day="2-digit" month="long" year="numeric" />
                          </Typography>
                        )}
                        else={() => <DashPlaceholder />}
                      />
                    </Table.Data>

                    <Table.Data sm={headerList[5].sm}>
                      <Price value={purchasedPackage.paidPrice} />
                    </Table.Data>

                    <Table.Data sm={headerList[6].sm}>
                      <Badge.PaymentStatus status={purchasedPackage.paymentStatus} />
                    </Table.Data>

                    <Table.Data sm={headerList[7].sm}>
                      {purchasedPackage.paymentStatus === PaymentStatus.Paid && (
                        <PurchasedPackagesListItemDropdown
                          purchasedPackageId={purchasedPackage.id}
                          refreshList={refresh}
                        />
                      )}
                    </Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </InfiniteScrollableTable>
        )}
      </Refresh>
    </div>
  );
};

export default PurchasedPackagesListPage;
