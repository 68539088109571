import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import { translations } from '@/locale';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import TextInput from '@/components/Input/TextInput';
import Button from '@/components/Button';
import { TestingSummary } from './TestingSummary/TestingSummary';
import { getWorkspaceDetails } from '@/services/api/workspace';
import If from '@/components/If';
import { nextTestCase } from '@/page/Testing/TestingProcessPage/util/next-test-case';
import { TestCaseItem } from '@/domains/test-report';
import { testCasesList } from '@/page/Testing/TestingProcessPage/util/test-form';
import { activationCommand } from '../util/activation-testing-command';
import { backTestCase } from '../util/back-test-case';

export interface Props {}

const startDate = new Date();
const text = translations.pages.testing;
const widgets = translations.pages.testReportWidgets;
const dataWithTestCases: TestCaseItem[] = [];

const TestingFormPage: React.FC<Props & WrappedComponentProps & RouteComponentProps<{ id: string }>> = ({ match }) => {
  const workspaceId = match.params.id;

  const { value: workspace } = useAsyncRetry(async () => getWorkspaceDetails(workspaceId), []);
  const [count, setCount] = React.useState(0);
  const [testCase, setTestCase] = React.useState(testCasesList[count]);
  const [value, setValue] = React.useState(-1);
  const [textArea, setText] = React.useState('');
  const [textInternet, setInternet] = React.useState('');
  const intl = useIntl();
  const history = useHistory();

  const renderInternetInput = (textInternet) => {
    return (
      <Card.Row className="flex-col justify-items-start">
        <Typography is="div" className="p-5">
          <FormattedMessage id={translations.placeholders.testInternetSpeed} />

          <button className="text-blue-light" onClick={() => window.open('https://www.speedtest.net/')}>
            <FormattedMessage id={translations.placeholders.speedTest} />
          </button>
        </Typography>

        <div className="flex p-5 gap-10">
          <Typography is="h3">
            <FormattedMessage id={translations.placeholders.internet} />
          </Typography>

          <TextInput
            placeholder={'Input Internet Speed'}
            value={textInternet}
            className=" col-auto row-auto border-fixed border-solid border-black"
            id={'internet'}
            onChange={(e) => {
              setInternet(e.target.value);
            }}
          />
        </div>
      </Card.Row>
    );
  };

  const renderCommandButon = (title: string) => {
    return (
      <Card.Row>
        <div className="flex justify-between p-5 gap-10">
          <Typography is="h3">
            <FormattedMessage id={translations.placeholders.sendCommand} />
          </Typography>

          <Button
            appearance="blue"
            onClick={() => {
              activationCommand(
                workspaceId,
                intl.formatMessage({ id: widgets[testCase].widget }),
                intl.formatMessage({ id: widgets[testCase].status })
              );
            }}
          >
            <FormattedMessage id={title} />
          </Button>
        </div>
      </Card.Row>
    );
  };

  const renderComponents = (value, style: string) => {
    return (
      <Typography key={value[1]} is="div" className={style + 'justify-items-top'}>
        <FormattedMessage id={value[1]} />
        <br />
      </Typography>
    );
  };

  return (
    <If
      condition={testCase == 'summary'}
      then={() => {
        return (
          <TestingSummary
            workspace={workspace}
            name={workspace.name}
            providerId={workspace.providerId}
            data={dataWithTestCases}
            summary={text[testCase]}
            startDate={startDate}
          />
        );
      }}
      else={() => {
        return (
          <div className="m-8">
            {
              <>
                <Typography is="h1" type="header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text[testCase].title} />
                </Typography>

                {workspace && (
                  <Typography is="p" className="font-bold text-gray-darkest p-1 gap-2">
                    {intl.formatMessage({ id: translations.placeholders.identifierWorskpace }) + workspace.providerId}
                  </Typography>
                )}

                <div className="flex justify-between mb-8 mt-8">
                  <Card className="w-full h-full p-3">
                    <Card.Row>
                      <div className="w-full ">
                        <Typography is="h1" className="p-3 font-bold">
                          <FormattedMessage id={translations.placeholders.steps} />
                        </Typography>

                        <div className="flex-col ">
                          {Object.entries(text[testCase].steps).map((value) =>
                            renderComponents(value, 'flex-col  w-25 ml-3 p-2 text-[12px]')
                          )}
                        </div>

                        <Typography is="h1" className="p-3 font-bold">
                          <FormattedMessage id={translations.placeholders.expectedResults} />
                        </Typography>

                        <div>
                          {Object.entries(text[testCase].expectedResults).map((value) =>
                            renderComponents(value, 'flex-col w-30 ml-3 p-2 text-[12px]')
                          )}
                        </div>

                        <Typography is="h1" className="p-3 font-bold">
                          <FormattedMessage id={translations.placeholders.result} />
                        </Typography>
                        <div>
                          <div className=" flex justify-items-top ml-3 mb-3 mr-3 p-3 gap-2">
                            <BsCheckCircleFill
                              type={'button'}
                              className={value == 1 ? 'text-green text-3xl' : 'text-gray text-3xl'}
                              onClick={() => {
                                setValue(1);
                              }}
                            />

                            <BsFillXCircleFill
                              type={'button'}
                              className={value == 0 ? 'text-red text-3xl' : 'text-gray text-3xl'}
                              onClick={() => {
                                setValue(0);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card.Row>

                    {text[testCase].command
                      ? renderCommandButon(text[testCase].command.title)
                      : text[testCase].internet
                      ? renderInternetInput(textInternet)
                      : null}

                    <Card.Row>
                      <div className="flex justify-between p-5 gap-10">
                        <Typography is="h3">
                          <FormattedMessage id={translations.placeholders.comment} />
                        </Typography>

                        <TextInput
                          placeholder={'Input comments'}
                          value={textArea}
                          className=" col-auto row-auto border-fixed border-solid border-black"
                          id={'comment'}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                        />
                      </div>
                    </Card.Row>

                    <Card.Row>
                      <div className="flex justify-between w-full p-3">
                        <Button
                          appearance={'red'}
                          onClick={() => {
                            testCase == testCasesList[0]
                              ? history.goBack()
                              : backTestCase(count, setCount, setTestCase, dataWithTestCases);
                          }}
                        >
                          <FormattedMessage id={translations.placeholders.back} />
                        </Button>

                        <Button
                          appearance={'green'}
                          onClick={() =>
                            nextTestCase(
                              value,
                              textArea,
                              textInternet,
                              count,
                              setCount,
                              setValue,
                              setText,
                              setInternet,
                              setTestCase,
                              dataWithTestCases
                            )
                          }
                          disabled={value === -1 ? true : false}
                        >
                          <FormattedMessage id={translations.placeholders.next} />
                        </Button>
                      </div>
                    </Card.Row>
                  </Card>
                </div>
              </>
            }
          </div>
        );
      }}
    />
  );
};
export default injectIntl(TestingFormPage);
