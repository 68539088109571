import React from 'react';
import debounce from 'lodash/debounce';

import { Props as InputProps } from '@/components/Input';
import TextInput from '@/components/Input/TextInput';

export interface Props extends Omit<InputProps, 'onChange' | 'type'> {
  onChange: (value: string) => void;
}

const SearchInput = ({ onChange, ...props }: Props) => {
  const handleChange = React.useCallback(
    debounce((event) => onChange(event.target.value), 300),
    []
  );

  return <TextInput onChange={handleChange} type="search" {...props} />;
};

export default SearchInput;
