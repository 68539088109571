import React from 'react';
import { useAsync } from 'react-use';
import { GoPrimitiveDot } from 'react-icons/go';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import Typography from '@/components/Typography';
import { WorkspaceDetails } from '@/domains';
import { getWorkspaceMonitoringStatusElements } from '@/services/api/monitoring';
import If from '@/components/If';
import RouterItemDropdown from './RouterItemDropdown';
import { translations } from '@/locale/messages';
import { useIntl } from 'react-intl';
import { rebootRouter } from '@/services/api/router';
import { VersionIOT } from '@/domains/iot-boards/iot-version';

export interface Props {
  workspace: WorkspaceDetails;
  routerStatus: boolean;
}

export const StatusTroubleshooting = ({ workspace, routerStatus }) => {
  const { value: statusTroubleshooting } = useAsync(async () => getWorkspaceMonitoringStatusElements(workspace.id));
  const text = translations.pages.workspaces.details.tabs.troubleshooting.statusElements;
  const intl = useIntl();

  return (
    <If
      condition={statusTroubleshooting}
      then={() => (
        <Grid.Column className="pt-4 pb-4 pr-4 ">
          <Grid.Row className="flex w-auto ml-3 ">
            <Card
              className={
                routerStatus
                  ? 'flex  border-[#2DCA73] text-[#2DCA73] bg-[#2DCA73] bg-opacity-10  border-2 content-center mr-3 rounded-3xl'
                  : 'flex bg-red-opacity-30 text-red border-red  bg-opacity-10 border-2 content-center mr-3 rounded-3xl'
              }
            >
              <div className="gap-2">
                <Grid.Row className="p-2">
                  <GoPrimitiveDot size={24} />

                  <Typography is="h1">{intl.formatMessage({ id: text.router })}</Typography>
                </Grid.Row>
              </div>
              <RouterItemDropdown workspaceId={workspace.id} rebootRouter={() => rebootRouter(workspace.id)} />
            </Card>

            <Card
              className={
                statusTroubleshooting.status === 'OK'
                  ? 'flex  border-[#2DCA73] text-[#2DCA73] bg-[#2DCA73] bg-opacity-10  border-2 content-center mr-3 rounded-3xl'
                  : 'flex bg-red-opacity-30 text-red border-red  bg-opacity-10 border-2 content-center mr-3 rounded-3xl'
              }
            >
              <div className="gap-2">
                <Grid.Row className="p-2">
                  <GoPrimitiveDot size={24} />
                  <Typography is="h1">
                    {intl.formatMessage({
                      id:
                        statusTroubleshooting.status === 'OK'
                          ? text.workspaceStatus.online
                          : text.workspaceStatus.Offline
                    })}
                  </Typography>
                </Grid.Row>
              </div>
            </Card>

            <Card
              className={
                statusTroubleshooting.activeBooking === 'BOOKING'
                  ? 'flex border-[#2DCA73] text-[#2DCA73] bg-[#2DCA73] bg-opacity-10  border-2 content-center rounded-3xl'
                  : 'flex bg-red-opacity-30 text-red border-red bg-opacity-10 border-2 content-center rounded-3xl'
              }
            >
              <div className="gap-2">
                <Grid.Row className="p-2">
                  <GoPrimitiveDot size={24} />
                  <Typography is="h1">
                    {intl.formatMessage({
                      id:
                        statusTroubleshooting.activeBooking === 'BOOKING'
                          ? text.activeBooking.onGoingBooking
                          : text.activeBooking.noBooking
                    })}
                  </Typography>
                </Grid.Row>
              </div>
            </Card>

            {statusTroubleshooting.qrCode && workspace.versionIOT !== VersionIOT.v10 && (
              <Card
                className={
                  statusTroubleshooting.qrCode.value === 1
                    ? 'flex border-[#2DCA73] ml-3 text-[#2DCA73] bg-[#2DCA73] bg-opacity-10  border-2 content-center rounded-3xl'
                    : 'flex bg-red-opacity-30 ml-3 text-red border-red bg-opacity-10 border-2 content-center rounded-3xl'
                }
              >
                <div className="gap-2">
                  <Grid.Row className="p-2">
                    <GoPrimitiveDot size={24} />
                    <Typography is="h1">
                      {intl.formatMessage({
                        id: statusTroubleshooting.qrCode.value === 1 ? text.qrCode.active : text.qrCode.inactive
                      })}
                    </Typography>
                  </Grid.Row>
                </div>
              </Card>
            )}

            {statusTroubleshooting.pir && (
              <Card
                className={
                  statusTroubleshooting.pir.value === 1
                    ? 'flex border-[#2DCA73] ml-3 text-[#2DCA73] bg-[#2DCA73] bg-opacity-10  border-2 content-center rounded-3xl'
                    : 'flex bg-red-opacity-30 ml-3 text-red border-red bg-opacity-10 border-2 content-center rounded-3xl'
                }
              >
                <div className="gap-2">
                  <Grid.Row className="p-2">
                    <GoPrimitiveDot size={24} />
                    <Typography is="h1">
                      {intl.formatMessage({
                        id: statusTroubleshooting.pir.value === 0 ? text.pir.active : text.pir.inactive
                      })}
                    </Typography>
                  </Grid.Row>
                </div>
              </Card>
            )}
          </Grid.Row>
        </Grid.Column>
      )}
    />
  );
};
export default StatusTroubleshooting;
