import React from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { DateLocalizer, EventWrapperProps } from 'react-big-calendar';

import { translations } from '@/locale';

import Typography from '@/components/Typography';

export interface Props extends EventWrapperProps {
  localizer: DateLocalizer;
}

const CalendarMonthEventWrapper = (props: React.PropsWithChildren<Props>) => {
  return (
    <Typography is="div" type="sm" className="flex items-center">
      <span className="inline-block w-1 h-1 bg-red rounded-full mr-2" />

      <span>
        <FormattedMessage
          id={translations.misc.range}
          values={{ start: format(props.event.start, 'HH:mm'), end: format(props.event.end, 'HH:mm') }}
        />
      </span>
    </Typography>
  );
};

export default CalendarMonthEventWrapper;
