import React from 'react';
import classnames from 'classnames';

import Row from '../Row';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

const Header: React.FC<Props> = ({ is: Component = 'div', className, children, ...rest }) => (
  <Component>
    <Row
      className={classnames(
        'flex flex-wrap p-6 border-b border-solid text-gray uppercase font-semibold border-gray-lighter bg-white',
        className
      )}
      {...rest}
    >
      {children}
    </Row>
  </Component>
);

export default Header;
