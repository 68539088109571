import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { AdminBookingListItemDto, LessorBookingListItemDto } from '@/domains';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import { getPaginatedBookingsForUser } from '@/services/api/bookings';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import { Magnitude } from '@/constants';
import Info from '@/components/Info';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import { fullName } from '@/util';
import Price from '@/components/Price';

export interface Props {
  userId: string;
}

const text = translations.pages.users.details.tabs.bookings;

const headerList: { name: string; sm: Magnitude }[] = [
  { name: text.table.header.nookaName, sm: 8 },
  { name: text.table.header.date, sm: 4 },
  { name: text.table.header.timeInterval, sm: 4 },
  { name: text.table.header.cost, sm: 2 }
];

const BookingsTab: React.FC<Props & WrappedComponentProps> = ({ intl, userId }) => (
  <InfiniteScrollableTable
    source={(page, number) => getPaginatedBookingsForUser(page, number, userId)}
    empty={() => (
      <Placeholder className="mt-12">
        <Typography is="span" type="small-header" className="font-bold">
          <FormattedMessage id={text.empty} />
        </Typography>
      </Placeholder>
    )}
    renderHeader={() => (
      <Table.Header>
        {headerList.slice(0, -1).map((headerItem) => (
          <Table.Data header sm={headerItem.sm} key={headerItem.name}>
            <Typography is="span" type="regular" className="font-bold uppercase text-gray">
              <FormattedMessage id={headerItem.name} />
            </Typography>
          </Table.Data>
        ))}

        <Table.Data header sm={headerList[3].sm} key={headerList[3].name}>
          <Info message={intl.formatMessage({ id: translations.misc.revenueInfo })}>
            {({ open, close }) => (
              <Typography
                is="span"
                type="regular"
                className="font-bold uppercase text-gray group hover:cursor-default"
                onMouseOver={open}
                onMouseOut={close}
              >
                <FormattedMessage id={headerList[3].name} />

                <Info.Button open={open} close={close} />
              </Typography>
            )}
          </Info>
        </Table.Data>
      </Table.Header>
    )}
  >
    {(data) => (
      <React.Fragment>
        {data.map((booking: AdminBookingListItemDto) => (
          <Table.Row key={booking.id}>
            <Table.Data sm={headerList[0].sm}>
              <Typography is="span">{booking.workspace.name}</Typography>
            </Table.Data>

            <Table.Data sm={headerList[1].sm}>
              <FormattedDate value={booking.startDate} day="2-digit" month="long" year="numeric" />
            </Table.Data>

            <Table.Data sm={headerList[2].sm} className="break-all">
              <FormattedMessage
                id={translations.misc.range}
                values={{
                  start: intl.formatTime(booking.startDate, { hourCycle: 'h23' }),
                  end: intl.formatTime(booking.endDate, { hourCycle: 'h23' })
                }}
              />
            </Table.Data>

            <Table.Data sm={headerList[3].sm}>
              <Price value={booking.cost} />
            </Table.Data>
          </Table.Row>
        ))}
      </React.Fragment>
    )}
  </InfiniteScrollableTable>
);

export default injectIntl(BookingsTab);
