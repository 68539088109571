import { Pricing } from '@/domains';

export interface Package {
  id: string;
  name: string;
  includedHours: number;
  expires: unknown;
  price: Pricing;
}

export interface WalletPackage {
  id: string;
  name: string;
}

export interface PurchasePackage {
  status: number;
  url: string;
}

export enum AssignedPackageStatus {
  ASSIGNED = 'ASSIGNED',
  INVITED = 'INVITED'
}

export interface AssignedPackage {
  id: string;
  customerName?: string;
  customerEmail: string;
  date: Date;
  packageName: string;
  availableHours: number;
  includedHours: number;
  status: AssignedPackageStatus;
}
