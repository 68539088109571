import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getOverviewMetrics } from '@/services/api/metrics';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Price from '@/components/Price';
import TargetCard from '@/components/TargetCard';
import OverviewCard from '@/components/OverviewCard';

const text = translations.pages.dashboard;

const DashboardOverviewSection: React.FC = () => {
  const intl = useIntl();

  return (
    <div className="mt-8 mb-8">
      <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
        <FormattedMessage id={text.section.overview} />
      </Typography>

      <LoadData load={() => getOverviewMetrics()}>
        {({ value: metrics, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
              </Loading>
            )}
            else={() => (
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
                <TargetCard
                  title={intl.formatMessage({ id: text.overview.activeNookas })}
                  currentAmount={metrics.activeNookas.current}
                  targetAmount={metrics.activeNookas.target}
                  pathColor="#048BA8"
                />

                <TargetCard
                  title={intl.formatMessage({ id: text.overview.registeredUsers })}
                  currentAmount={metrics.registeredUsers.current}
                  targetAmount={metrics.registeredUsers.target}
                  pathColor="#16db93"
                />

                <TargetCard
                  title={intl.formatMessage({ id: text.overview.monthlyRevenue })}
                  currentAmount={metrics.monthlyRevenue.current}
                  targetAmount={metrics.monthlyRevenue.target}
                  getValue={(amount) => amount.price}
                  renderAmount={(amount) => <Price value={amount} />}
                  pathColor="#f29e4c"
                />

                <OverviewCard iconType="users" title={intl.formatMessage({ id: text.overview.activeUsers })}>
                  {metrics.activeUsers}
                </OverviewCard>

                <OverviewCard iconType="bookmark" title={intl.formatMessage({ id: text.overview.bookings })}>
                  {metrics.bookings}
                </OverviewCard>

                <OverviewCard iconType="wallet" title={intl.formatMessage({ id: text.overview.grossRevenue })}>
                  <Price value={metrics.grossRevenue} />
                </OverviewCard>
              </div>
            )}
          />
        )}
      </LoadData>
    </div>
  );
};

export default DashboardOverviewSection;
