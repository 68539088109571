import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { PaymentStatus } from '@/domains';

import Badge from './Badge';

const paymentStatusToClassNameMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Paid]: 'text-[#2DCA73] bg-[#2DCA73] bg-opacity-10',
  [PaymentStatus.Refunded]: 'text-[#F8AF6B] bg-[#F8AF6B] bg-opacity-10',
  [PaymentStatus.Pending]: 'text-[#F8AF6B] bg-[#F8AF6B] bg-opacity-10',
  [PaymentStatus.Annulled]: 'text-gray bg-gray bg-opacity-10',
  [PaymentStatus.Failed]: 'text-red bg-red bg-opacity-10'
};

export interface Props {
  status: PaymentStatus;
  className?: string;
}

const PaymentStatusBadge = ({ status, className }: Props) => {
  return (
    <Badge className={classnames(paymentStatusToClassNameMap[status], className)}>
      <FormattedMessage id={translations.domains.paymentStatus[status]} />
    </Badge>
  );
};

export default PaymentStatusBadge;
