import { getWidgetStatusDoor } from '@/services/api/widgets';

export const setValueTrueWidget = async (widget, set, providerId, state: number, setDoorState, handlePage) => {
  const getDoorState = (response: { value: number }) => {
    Number(response.value) ? setDoorState(0) : setDoorState(0);
    return state;
  };

  if (widget.id === 'door-lock') {
    await set('value', true);
    setTimeout(async () => {
      (await getDoorState(await getWidgetStatusDoor(providerId))) === 0 ? set('value', false) : set('value', true);
      setTimeout(handlePage(1), 1000);
    }, 6000);
    handlePage(1);
  } else {
    await set('value', true);
    handlePage(1);
  }
};
