import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { urls } from '@/constants';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import TestingProcessPage from '@/page/Testing/TestingProcessPage/TestingProcessPage';
import TestingForm from '@/page/Testing/TestingProcessPage/TestingForm';

const TestingPage: React.FC = () => (
  <Switch>
    <Route exact path={urls.testing.list} component={TestingProcessPage} />
    <Route path={urls.testing.nooka.main} component={TestingForm} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.testing.nooka.main} />} />
  </Switch>
);

export default TestingPage;
