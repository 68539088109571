import React from 'react';
import classnames from 'classnames';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Image } from '@/domains';

import CloseIcon from '@/components/Icon/CloseIcon';

interface Props {
  id: number;
  index: number;
  image: Image;
  onImageDeleted: () => void;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

enum ItemType {
  GalleryImage = 'GALLERY_IMAGE'
}

const ImageUploadGalleryItem = ({ id, index, image, onImageDeleted, moveImage }: React.PropsWithChildren<Props>) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemType.GalleryImage,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.GalleryImage,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{ backgroundImage: `url(${image.url})` }}
      className={classnames(
        'bg-cover bg-center bg-no-repeat w-[150px] h-[100px] m-2 relative rounded-xl hover:cursor-move',
        {
          'opacity-25': isDragging
        }
      )}
    >
      <button type="button" onClick={onImageDeleted} className="absolute top-1 right-1 text-white">
        <CloseIcon />
      </button>
    </div>
  );
};

export default ImageUploadGalleryItem;
