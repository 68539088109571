import React, { useEffect } from 'react';
import { debounce, uniqBy } from 'lodash';

import { fullName } from '@/util';
import { UserDetails, UserListItem, UserRole } from '@/domains';
import { getAllUsers, getUserById } from '@/services/api/users';
import usePagination from '@/hooks/usePagination';

import Select, { Props as SelectProps, Item as SelectItem } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<string>, 'items'> {}

interface State {
  selected?: SelectItem<string>[];
  keyword?: string;
}

const toUserSelectItem = (user: UserDetails | UserListItem): SelectItem<string> => ({
  value: user.id,
  name: fullName(user.firstName, user.lastName)
});

const UsersSelect = ({ ...rest }: React.PropsWithChildren<Props>) => {
  const [state, setState] = React.useState<State>({ selected: [] });
  const { loading, more, data, page, loadPage } = usePagination({
    id: state.keyword,
    source: (page, size) => getAllUsers(page, size, [UserRole.IndividualLessor], state.keyword)
  });

  const loadFromId = (ids: string[]) => {
    setState((prevState) => ({ ...prevState, selected: [] }));
    if (ids) {
      ids?.map((el) => {
        getUserById(el).then((user) =>
          setState((prevState) => ({ ...prevState, selected: prevState.selected?.concat(toUserSelectItem(user)) }))
        );
      });
    }
  };

  const memoizedData = React.useMemo(() => data.map(toUserSelectItem), [data]);
  React.useEffect(() => {
    if (!!rest.value) {
      loadFromId(rest.value as string[]);
    }
  }, []);

  const ref = React.useRef<HTMLUListElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  const handleSearch = debounce((value) => {
    if (!value || loading) return;

    setState((prevState) => ({ ...prevState, keyword: value }));
    loadPage(1);
  }, 300);

  const onBlur = () => {
    setState((prevState) => ({ ...prevState, keyword: undefined }));
  };

  return (
    <Select
      {...{ ...rest, onBlur }}
      onChange={(value: any) => {
        loadFromId(value);
        rest.onChange(value);
      }}
      onInputValueChange={handleSearch}
      items={!state.selected ? memoizedData : uniqBy(memoizedData.concat(state.selected), (item) => item.value)}
      dropdownContentLoading={loading}
      onDropdownContentScroll={handleScroll}
      dropdownContentRef={ref}
      multiple={true}
    />
  );
};

export const UsersSelectSelectWithValidation = withValidation(UsersSelect);
export default UsersSelect;
