import React from 'react';
import classnames from 'classnames';

import MenuItem, { Props as MenuItemProps } from './MenuItem';

export interface Props {
  className?: string;
}

export interface StaticComponents {
  Item: React.FC<MenuItemProps>;
}

const Sidebar: React.FC<Props> & StaticComponents = (props) => {
  return (
    <div
      className={classnames(
        'h-full w-[240px] pl-5 py-8 overflow-y-auto bg-white border-r border-solid border-gray-light border-opacity-40',
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

Sidebar.Item = MenuItem as any;

export default Sidebar;
