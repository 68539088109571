import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { WorkspaceFeature as WorkspaceFeatureDomain } from '@/domains';

import Typography from '@/components/Typography';
import ToggleSwitch from '@/components/ToggleSwitch';
import SimpleSelect from '@/components/SimpleSelect';

const NONE_VALUE = 'NONE';

const text = translations.misc.workspaceFeature.list;

export interface Props {
  features: WorkspaceFeatureDomain[];
  selectedFeature: WorkspaceFeatureDomain;
  onChange: (value: string) => void;
  className?: string;
}

const WorkspaceFeatureSelect = ({ features, selectedFeature, onChange, className }: Props) => {
  const intl = useIntl();

  const genericFeatureCategory =
    features.length === 1
      ? features[0]
      : selectedFeature || {
          iconUrl: features[0].iconUrl,
          name: intl.formatMessage({ id: translations.domains.workspaceFeatureCategory[features[0].category] }),
          description: intl.formatMessage({ id: text.genericDescription })
        };

  const items = [
    { value: NONE_VALUE, name: intl.formatMessage({ id: text.noSelection }) },
    ...features.map(({ id, shortDescription }) => ({
      value: id,
      name: shortDescription
    }))
  ];

  const handleChange = (value) => onChange(value === NONE_VALUE ? null : (value as string));

  return (
    <div className={classnames('w-[325px] flex flex-col', className)}>
      <div className="flex justify-items-center">
        <img src={genericFeatureCategory.iconUrl} className="w-[26px] h-[26px] inline-block mr-3" alt="icon" />

        <Typography is="span" type="small-header" className="font-bold mb-2">
          {genericFeatureCategory.name}
        </Typography>
      </div>

      <Typography is="p" type="regular" className="text-blue-dark mb-4">
        {genericFeatureCategory.description}
      </Typography>

      {features.length === 1 ? (
        <ToggleSwitch
          type="checkbox"
          label={false}
          checked={!!selectedFeature}
          onChange={() => handleChange(selectedFeature ? NONE_VALUE : features[0].id)}
        >
          <Typography is="div" type="regular" className="font-bold text-gray-darkest">
            <FormattedMessage id={text[selectedFeature ? 'enabled' : 'disabled']} />
          </Typography>
        </ToggleSwitch>
      ) : (
        <SimpleSelect items={items} value={selectedFeature ? selectedFeature.id : NONE_VALUE} onChange={handleChange} />
      )}
    </div>
  );
};

export default WorkspaceFeatureSelect;
