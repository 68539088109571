import React, { useState } from 'react';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { SubmitError } from '@/components/Error';

import Grid from '@/components/Grid';

import { DateRange } from 'react-date-range';
import { TimeFrame } from '@/domains/metrics';
import { startOfMonth, endOfMonth } from 'date-fns';

import Dropdown from '@/components/Dropdown/Dropdown';
import { FormattedDate } from 'react-intl';
import If from '@/components/If/If';
import isSameDay from 'date-fns/esm/fp/isSameDay/index.js';
import { getActionsReportByWorkspaceId } from '@/services/api/workspace';
import { ActionLog } from '@/domains/action-log';
import { DownloadActionLogReport } from '@/components/Button/DownloadActionLogReport/DownloadActionLogReport';

interface Props extends InstanceProps {
  title?: string;
  message?: string;
  messageInfo?: string;
  workspaceId?: string;
  workspaceName?: string;
  confirmMessage: string;
  cancelMessage: string;
  downloadFileTitle: string;
  onConfirm?: (event: React.MouseEvent) => Promise<any>;
  onCancel?: (event: React.MouseEvent) => Promise<any>;
}

interface State {
  confirmLoading: boolean;
  cancelLoading: boolean;
  error?: any;
}

const DownloadActionLogModal: React.FC<Props> = ({
  title,
  message,
  messageInfo,
  workspaceId,
  workspaceName,
  confirmMessage,
  cancelMessage,
  downloadFileTitle,
  onConfirm,
  onCancel,
  close
}) => {
  const [state, setState] = useState<State>({ confirmLoading: false, cancelLoading: false });

  const loading = state.cancelLoading || state.confirmLoading;
  const [interval, setInterval] = React.useState({
    actionLog: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      timeFrame: TimeFrame.Monthly,
      key: 'actionLog'
    }
  });

  const handleConfirm = () => {
    if (onConfirm) close();
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (onCancel) {
      setState((previous) => ({ ...previous, cancelLoading: true }));
      onCancel(event)
        .then(close)
        .catch((error) => {
          setState((previous) => ({ ...previous, cancelLoading: false, error }));
        });
    } else {
      close();
    }
  };
  const [data, setData] = React.useState<ActionLog[]>();
  React.useEffect(() => {
    getActionsReportByWorkspaceId(workspaceId, interval.actionLog.startDate, interval.actionLog.endDate).then((data) =>
      setData(data)
    );
  }, [interval]);

  return (
    <Card className="w-[560px] p-6 mb-10">
      {title && (
        <Typography is="div" className="mb-4 font-bold text-center">
          {title}
        </Typography>
      )}

      <Grid.Row>
        {message && (
          <div>
            <Typography is="span" className="text-center text-sm text-gray-dark mb-8 mr-3">
              {message}
            </Typography>
            {workspaceId && (
              <Typography is="span" className="text-center text-sm text-black mb-8 text-bold">
                {workspaceName}
              </Typography>
            )}
          </div>
        )}

        {state.error && <SubmitError error={state.error} className="mb-3" />}
      </Grid.Row>

      <Grid.Row>
        {messageInfo && (
          <Typography is="div" className="text-center text-sm text-black mt-8 mb-8 mr-3">
            {messageInfo}
          </Typography>
        )}

        {state.error && <SubmitError error={state.error} className="mb-3" />}
      </Grid.Row>

      <Grid.Row>
        <Dropdown
          renderMenu={({ close }) => (
            <DateRange
              className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl w-full"
              onChange={(item) => {
                setInterval({ ...interval, ...item });
              }}
              months={1}
              maxDate={new Date()}
              direction="vertical"
              ranges={[interval.actionLog]}
            />
          )}
          className="mb-6"
        >
          {({ isOpen, open, close }) => (
            <div
              className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition w-full"
              onClick={isOpen ? close : open}
            >
              <FormattedDate value={interval.actionLog.startDate} day="2-digit" month="long" />
              <If
                condition={!isSameDay(interval.actionLog.startDate, interval.actionLog.endDate)}
                then={() => {
                  return (
                    <React.Fragment>
                      {` - `}
                      <FormattedDate value={interval.actionLog.endDate} day="2-digit" month="long" />
                    </React.Fragment>
                  );
                }}
              />
            </div>
          )}
        </Dropdown>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column className="w-1/2 pr-1">
          <Button appearance="ghost" onClick={handleCancel} loading={state.cancelLoading} disabled={loading} block>
            {cancelMessage}
          </Button>
        </Grid.Column>

        <Grid.Column className="w-1/2 pl-1">
          {data && (
            <DownloadActionLogReport
              data={data}
              title={downloadFileTitle}
              placeholder={confirmMessage}
              className={'w-full'}
              onClick={handleConfirm}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default DownloadActionLogModal;
