import React from 'react';
import classnames from 'classnames';

import Typography from '@/components/Typography';
import Icon, { Type as IconType } from '@/components/Icon';

export interface Props {
  icon: IconType;
  title: string;
  className?: string;
}

const WorkspaceInformation: React.FC<Props> = ({ icon, title, className, children, ...rest }) => (
  <div className={classnames('flex', className)} {...rest}>
    <Icon type={icon} className="text-2xl" />

    <div className="flex flex-col ml-5">
      <Typography is="span" type="regular" className="text-gray-dark mb-1">
        {title}
      </Typography>

      <Typography is="span" type="regular" className="font-bold">
        {children}
      </Typography>
    </div>
  </div>
);

export default WorkspaceInformation;
