import React from 'react';
import classnames from 'classnames';
import { ToolbarProps, View } from 'react-big-calendar';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

export interface Props extends ToolbarProps {}

const CalendarToolbar = (props: React.PropsWithChildren<Props>) => (
  <div className="w-full inline-flex justify-between font-bold text-sm mb-8">
    <div className="flex items-center">
      <Button type="button" appearance="ghost" onClick={() => props.onNavigate('TODAY')} className="mr-4">
        {props.localizer.messages.today}
      </Button>

      <button
        type="button"
        onClick={() => props.onNavigate('PREV')}
        className="px-3 bg-white h-10 border rounded-l border-solid border-gray-lighter transition hover:border-gray-dark focus:outline-none"
      >
        <Icon type="chevronLeft" />
      </button>

      <div className="px-4 h-10 leading-10 text-center min-w-min bg-white text-gray-dark border-t border-b border-solid border-gray-lighter">
        {props.label}
      </div>

      <button
        type="button"
        onClick={() => props.onNavigate('NEXT')}
        className="px-3 bg-white h-10 border rounded-r border-solid border-gray-lighter transition hover:border-gray-dark focus:outline-none"
      >
        <Icon type="chevronRight" />
      </button>
    </div>

    <div className={classnames('inline-flex items-center p-1 rounded-3xl border border-solid border-gray-lighter')}>
      {(props.views as View[]).map((name) => (
        <button
          key={name}
          type="button"
          onClick={() => props.onView(name)}
          className={classnames('px-4 py-2 font-bold rounded-3xl text-sm focus:outline-none transition', {
            'bg-red text-white hover:bg-opacity-80': name === props.view
          })}
        >
          {props.localizer.messages[name]}
        </button>
      ))}
    </div>
  </div>
);

export default CalendarToolbar;
