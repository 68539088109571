import React from 'react';
import classnames from 'classnames';

import Input, { Props as InputProps } from '@/components/Input';
import Icon, { Type } from '@/components/Icon';
import { withValidation } from '@/components/hoc/withValidation';
import If from '@/components/If';

const style = {
  invalid: 'border-red transition ease-in-out',
  withTrail: 'pr-11'
};
export interface Props extends InputProps {
  invalid?: boolean;
  label?: string;
  leadingIcon?: Type;
  icon?: Type;
  onIconClick?: () => any;
  inputClassName?: string;
  required?: boolean;
  typeOfInput?: string;
}

const TextInput = (props: React.PropsWithChildren<Props>) => {
  const {
    invalid,
    className,
    inputClassName,
    label,
    icon,
    leadingIcon,
    onIconClick,
    required = false,
    typeOfInput = 'normal',
    ...rest
  } = props;
  return (
    <div className={classnames('relative flex flex-col', { 'text-gray-light': rest.disabled }, className)}>
      {label && (
        <div className="text-xs">
          <label htmlFor={props.id} className={classnames('inline-block pb-2 text-gray-dark uppercase')}>
            {label}
          </label>

          {required && <span className="text-red ml-1">*</span>}
        </div>
      )}

      <If
        condition={typeOfInput === 'credit'}
        then={() => (
          <Input
            id={props.id}
            type={'number'}
            className={classnames(
              'relative bg-white box-border h-6 my-2 pl-1 py-1 text-sm',
              {
                [style.invalid]: invalid,
                [style.withTrail]: icon
              },
              inputClassName
            )}
            {...rest}
          />
        )}
        else={() => (
          <Input
            id={props.id}
            className={classnames(
              'relative bg-white box-border h-10 px-4 py-2 border border-solid border-gray-light rounded-md text-sm',
              {
                [style.invalid]: invalid,
                // [style.withLead]: leadingIcon,
                [style.withTrail]: icon
              },
              inputClassName
            )}
            {...rest}
          />
        )}
      ></If>

      {icon && (
        <Icon
          type={icon}
          onClick={onIconClick}
          className={classnames(
            'h-10 absolute flex items-center hover:text-gray-darker bottom-0 text-gray-dark right-4',
            {
              [style.invalid]: invalid,
              'cursor-pointer': !!onIconClick
            }
          )}
        />
      )}
    </div>
  );
};

export default TextInput;
export const TextInputWithValidation = withValidation(TextInput);
