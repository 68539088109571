import React from 'react';

import ValidationMessage, { ValidationError } from '@/components/ValidationMessage';

export interface Props {}

interface ExtraProps {
  invalid?: boolean;
  error?: ValidationError | null;
}

export const withValidation = <P extends Props>(Component: React.ComponentType<any>, staticProps?: Partial<Props>) =>
  class WithValidation extends React.PureComponent<P & ExtraProps> {
    render() {
      const { invalid, error, ...rest } = this.props;

      const normalizedError = error || ({} as any);

      return (
        <div>
          <Component invalid={!!error} {...staticProps} {...rest} />

          {Array.isArray(normalizedError) ? (
            normalizedError.map((error, index) => (
              <ValidationMessage key={index} visible={!!invalid} id={error.id} values={error.values} />
            ))
          ) : (
            <ValidationMessage visible={!!invalid} id={normalizedError.id} values={normalizedError.values} />
          )}
        </div>
      );
    }
  };
