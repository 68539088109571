import React, { useMemo } from 'react';
import * as yup from 'yup';
import { formatISO } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { createWorkspace } from '@/services/api/workspace';
import { workspaceStatusMap, workspaceVisibilityMap } from '@/util';
import { QrAccessCodeType, WorkspaceCreationForm, WorkspaceStatus, WorkspaceVisibility } from '@/domains';

import Typography from '@/components/Typography';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Grid from '@/components/Grid';
import ToggleSwitch from '@/components/ToggleSwitch';
import { workspaceCreateOrEditSchema } from '@/validation/schema';
import WorkspaceForm from '@/components/Form/WorkspaceForm';
import { createWifi } from '@/services/api/wifi';

const text = translations.pages.workspaces.create;

const WorkspaceCreatePage: React.FC<RouteComponentProps> = ({ history }) => {
  const memoizedImages = useMemo(() => [], []);
  const [changePassword, setChangePassword] = React.useState(false);

  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>

      <Form
        id="create-workspace"
        schema={workspaceCreateOrEditSchema as yup.ObjectSchema<WorkspaceCreationForm>}
        subscription={{
          dirty: true,
          pristine: true,
          submitting: true,
          submitError: true,
          values: true
        }}
        initialValues={{
          images: memoizedImages,
          installationDate: formatISO(new Date(), { representation: 'date' }),
          constructionDate: formatISO(new Date(), { representation: 'date' }),
          status: WorkspaceStatus.Active,
          visibility: WorkspaceVisibility.Visible,
          hasQrAccess: false,
          preferredQrAccessCode: QrAccessCodeType.Dynamic,
          private: false,
          changePassword: changePassword,
          bookingTimeIncrementStepInMinutes: 30,
          minimumBookingPeriodInMinutes: 30
        }}
        onSubmit={(data) => {
          data.changeWifiPassword = changePassword;

          return createWorkspace(data)
            .then(async (workspace) => {
              await createWifi(data.mainWifiSSID, data.mainWifiPassword, workspace.id, data.mainWifiBand);

              if (data.additionalWifiSSID && data.additionalWifiPassword && data.additionalWifiBand) {
                await createWifi(
                  data.additionalWifiSSID,
                  data.additionalWifiPassword,
                  workspace.id,
                  data.additionalWifiBand
                );
              }

              return workspace;
            })
            .then((workspace) => {
              history.push(urls.locations.about.get(workspace.id));
            });
        }}
      >
        {(formRenderProps, id) => {
          const { dirty, pristine, submitting, values } = formRenderProps;

          return (
            <Card className="w-full p-5">
              <WorkspaceForm
                id={id}
                text={text}
                formProps={formRenderProps}
                scope="create"
                setChangePassword={setChangePassword}
                nookaLive={false}
              />

              <Spacer className="border-b border-solid border-gray-lighter my-7" />

              <Grid.Row>
                <Grid.Column sm={3} className="flex items-center" padded>
                  <Form.Field
                    is={ToggleSwitch}
                    id={`${id}-status`}
                    name="status"
                    label={false}
                    map={workspaceStatusMap}
                  >
                    <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                      <FormattedMessage id={translations.inputs.toggles.workspaceStatus} />
                    </Typography>
                  </Form.Field>
                </Grid.Column>

                <Grid.Column sm={3} className="flex items-center" padded>
                  <Form.Field
                    is={ToggleSwitch}
                    id={`${id}-visibility`}
                    name="visibility"
                    label={false}
                    map={workspaceVisibilityMap}
                  >
                    <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                      <FormattedMessage id={translations.inputs.toggles.workspaceVisibility} />
                    </Typography>
                  </Form.Field>
                </Grid.Column>

                <Grid.Column sm={3} className="flex items-center justify-end" padded>
                  <Button loading={submitting} disabled={!dirty || pristine} appearance="red">
                    <Icon type="tickSquare" className="text-base leading-none pr-2" />

                    <FormattedMessage id={text.addNooka} />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Card>
          );
        }}
      </Form>
    </div>
  );
};

export default withRouter(WorkspaceCreatePage);
