import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useAsync from 'react-use/lib/useAsync';
import Grid from '@/components/Grid';
import LiveSubmit from '@/components/LiveSubmit';
import Typography from '@/components/Typography';
import { Widget } from '@/domains';
import { translations } from '@/locale';
import { getWidgetStatusDoor, updateWidgetValue } from '@/services/api/widgets';
import { setValueFalseWidget } from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/TroubleshootingWidgetsLayout/util/set-value-false-widget';
import { setValueTrueWidget } from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/TroubleshootingWidgetsLayout/util/set-value-true-widget';
import Card from '@/components/Card/Card';
import { GoPrimitiveDot } from 'react-icons/go';
import lightOn from '@/assets/TroubleshootingIcons/light-on.svg';
import lightOff from '@/assets/TroubleshootingIcons/light-off.svg';
import doorLock from '@/assets/TroubleshootingIcons/lock.svg';
import doorUnlock from '@/assets/TroubleshootingIcons/unlock.svg';
import fanOn from '@/assets/TroubleshootingIcons/fan-on.svg';
import fanOff from '@/assets/TroubleshootingIcons/fan-off.svg';
import utilityOff from '@/assets/TroubleshootingIcons/utility-off.svg';
import utilityOn from '@/assets/TroubleshootingIcons/utility-on.svg';
import { VersionIOT } from '@/domains/iot-boards/iot-version';

export interface Props {
  version: VersionIOT;
  widget: Widget;
  workspaceId: string;
  providerId: string;
  setCurrentPage: any;
}

export const TroubleshootingWidgetsLayout = ({ version, widget, workspaceId, providerId, handlePage }) => {
  const text = translations.pages.workspaces.details.tabs.troubleshooting;
  const [doorState, setDoorState] = useState(0);
  const { value: doorSensor, loading } = useAsync((async) => getWidgetStatusDoor(workspaceId));
  const intl = useIntl();

  return (
    <LiveSubmit
      loading={false}
      value={{ value: Boolean(widget.value) }}
      onChange={({ value }) => updateWidgetValue(workspaceId, widget.id, Number(value))}
    >
      {({ value: { value }, set }) => (
        <div>
          <Typography is="h1" className="w-auto text-[20px] font-bold text-gray-darkest p-5">
            {widget.id === 'door-lock' ? (
              <Grid.Row>
                <FormattedMessage id={text.controlTextMapper[widget.id]} />
              </Grid.Row>
            ) : widget.id === 'light-interior' ? (
              widget.title + ' Lights'
            ) : (
              widget.title
            )}
          </Typography>

          <Grid.Column className="w-full h-full flex">
            <Card
              className={
                value == true
                  ? 'flex w-28 ml-5 border-[#2DCA73] text-[#2DCA73] bg-[#2DCA73] bg-opacity-10 border-2 content-center rounded-3xl'
                  : 'flex  w-28  ml-5  bg-red-opacity-30 text-red border-red bg-opacity-10 border-2 content-center rounded-3xl'
              }
            >
              <Grid.Row className="p-2 justify-between">
                <GoPrimitiveDot size={22} />
                <Typography is="h1" className="text-[12px] text-gray-darkest">
                  <FormattedMessage
                    id={
                      widget.id == 'door-lock'
                        ? value
                          ? translations.misc.troubleshootingInfo.lock.unlocked
                          : translations.misc.troubleshootingInfo.lock.locked
                        : value
                        ? translations.misc.troubleshootingInfo.boolean.true
                        : translations.misc.troubleshootingInfo.boolean.false
                    }
                  />
                </Typography>
              </Grid.Row>
            </Card>
            {doorSensor && version !== VersionIOT.v10 && widget.id == 'door-lock' ? (
              <Card
                className={
                  doorSensor.value === 1
                    ? 'flex ml-2 w-28 border-[#2DCA73] text-[#2DCA73] bg-[#2DCA73] bg-opacity-10 border-2 content-center rounded-3xl'
                    : 'flex  ml-2 w-28 bg-red-opacity-30 text-red border-red bg-opacity-10 border-2 content-center rounded-3xl'
                }
              >
                <Grid.Row className="p-2 justify-between">
                  <GoPrimitiveDot size={22} />
                  <Typography is="h1" className="text-[12px] text-gray-darkest">
                    {doorSensor.value === 1
                      ? intl.formatMessage({ id: translations.pages.troubleshooting.doorStatus.open })
                      : intl.formatMessage({ id: translations.pages.troubleshooting.doorStatus.close })}
                  </Typography>
                </Grid.Row>
              </Card>
            ) : null}
          </Grid.Column>

          <div>
            <Grid.Row className="gap-5 place-content-center pt-10 pl-10 pr-10 pb-10 ">
              <button
                className=" align-top text-[10px] border-none  focus:outline-none "
                onClick={async () =>
                  await setValueTrueWidget(widget, set, providerId, doorState, setDoorState, handlePage)
                }
              >
                <img
                  src={
                    widget.id === 'door-lock'
                      ? doorUnlock
                      : widget.id === 'utility-control'
                      ? utilityOn
                      : widget.id === 'fan-control'
                      ? fanOn
                      : lightOn
                  }
                />
                {
                  <FormattedMessage
                    id={
                      widget.id == 'door-lock'
                        ? translations.misc.troubleshootingInfo.lock.open
                        : translations.misc.troubleshootingInfo.boolean.true
                    }
                  />
                }
              </button>
              <button
                className=" align-right  text-[10px]  border-none  focus:outline-none "
                onClick={async () => await setValueFalseWidget(set, handlePage)}
              >
                <img
                  src={
                    widget.id === 'door-lock'
                      ? doorLock
                      : widget.id === 'utility-control'
                      ? utilityOff
                      : widget.id === 'fan-control'
                      ? fanOff
                      : lightOff
                  }
                />
                {
                  <FormattedMessage
                    id={
                      widget.id == 'door-lock'
                        ? translations.misc.troubleshootingInfo.lock.closed
                        : translations.misc.troubleshootingInfo.boolean.false
                    }
                  />
                }
              </button>
            </Grid.Row>
          </div>
        </div>
      )}
    </LiveSubmit>
  );
};
export default TroubleshootingWidgetsLayout;
