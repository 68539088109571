import React from 'react';
import { FormattedMessage, useIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import Card from '@/components/Card';
import WorkspaceSelectList from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/WorkspaceSelectList/WorkspaceSelectList';

const TroubleshootingPage: React.FC<WrappedComponentProps> = () => {
  const text = translations.pages.troubleshooting;
  const intl = useIntl();

  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={text.title} />
        </Typography>
      </div>

      <React.Fragment>
        <Card className="w-full h-full p-3">
          <Grid.Row>
            <Grid.Column sm={5} padded>
              <Typography
                is={WorkspaceSelectList}
                name="workspaceSelect"
                placeholder={intl.formatMessage({
                  id: translations.inputs.fields.troubleshooting.placeholder
                })}
                editable
                required
                usedFor={intl.formatMessage({ id: translations.navigation.troubleshooting })}
              />
            </Grid.Column>
          </Grid.Row>
        </Card>
      </React.Fragment>
    </div>
  );
};
export default TroubleshootingPage;
