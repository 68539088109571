import React from 'react';
import { useIntl } from 'react-intl';
import ProgressBar from './ProgressBar';
import OverviewCard from '@/components/OverviewCard';
import { translations } from '@/locale';
import { MonitoringPreviousDays } from '@/domains/monitoring-page';
import { previousDay } from 'date-fns';

export interface Props {
  visible: number;
  active: number;
  inactive: number;
  prevStatus: MonitoringPreviousDays;
}

const text = translations.pages.overview.header;

const HeaderSection: React.FC<Props> = ({ visible, active, inactive, prevStatus }) => {
  const intl = useIntl();
  const percentActive = (active / (active + inactive)) * 100;

  return (
    <div className="mb-4 grid grid-cols-1 gap-3 sm:grid-cols-4">
      <OverviewCard
        className="h-full flex place-items-center"
        iconType="checkmark"
        title={intl.formatMessage({ id: text.online })}
      >
        <span>
          {active}/{active + inactive}
        </span>
      </OverviewCard>

      <OverviewCard iconType="status" title={intl.formatMessage({ id: text.status })}>
        <span> {percentActive.toFixed(2)}% </span>
        <div className="mt-1">
          <ProgressBar percentage={percentActive} />
        </div>
      </OverviewCard>

      <OverviewCard
        className="h-full flex place-items-center"
        iconType="checkmark"
        title={intl.formatMessage({ id: text.visible })}
      >
        <span>
          {visible}/{active + inactive}
        </span>
      </OverviewCard>

      {prevStatus && (
        <OverviewCard
          className="h-full flex place-items-center"
          iconType="checkmark"
          title={intl.formatMessage({ id: text.prevDay })}
        >
          <span>
            {prevStatus.active}/{prevStatus.active + prevStatus.inactive}
          </span>
        </OverviewCard>
      )}
    </div>
  );
};

export default HeaderSection;
