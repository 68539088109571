import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { urls } from '@/constants';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import TroubleshootingPage from '@/page/Troubleshooting/TroubleshootingPage';
import WorkspaceTroubleshootingDetails from '@/page/Troubleshooting/WorkspaceTroubleshootingDetails/WorkspaceTroubleshootingDetails';

const Troubleshooting: React.FC = () => (
  <Switch>
    <Route exact path={urls.troubleshooting.list} component={TroubleshootingPage} />

    <Route path={urls.troubleshooting.nooka.main} component={WorkspaceTroubleshootingDetails} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.troubleshooting.nooka.main} />} />
  </Switch>
);

export default Troubleshooting;
