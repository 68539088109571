import { WidgetGroup } from '@/domains/widget/widget-group';
import { authorizedApi } from '@/services/network/authorized-api';

export const getAllWorkspaceWidgets = (workspaceId: string) =>
  authorizedApi.get<WidgetGroup[]>(`/workspaces/${workspaceId}/widgets`);

export const updateWidgetValue = (workspaceId: string, widgetId: string, value: number) =>
  authorizedApi.post<null>(`/workspaces/${workspaceId}/widgets/${widgetId}`, { value });

export const discoverWorkspaceSensors = (workspaceId: string) =>
  authorizedApi.post<null>(`/workspaces/${workspaceId}/ping`);

export const getWidgetStatusDoor = (workspaceId: string) =>
  authorizedApi.get<{ value: number } | undefined>(`/workspaces/widgets/status/door`, { workspaceId });

export const getTemperatureHumidity = (providerId: string) =>
  authorizedApi.get<string>(`/workspaces/widgets/temperature-humidity`, { providerId });
