import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { WorkspaceFeatureCategory } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<WorkspaceFeatureCategory>, 'items'>, WrappedComponentProps {}

const FeatureCategorySelect: React.FC<Props> = ({ intl, ...rest }) => {
  const items = Object.values(WorkspaceFeatureCategory).map((item) => ({
    value: item,
    name: intl.formatMessage({ id: translations.domains.workspaceFeatureCategory[item] })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(FeatureCategorySelect);
export const FeatureCategorySelectWithValidation = withValidation(Default);
export default Default;
