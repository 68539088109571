export enum WorkspaceFeatureCategory {
  DIMENSIONS = 'DIMENSIONS',
  DESK = 'DESK',
  CHAIR = 'CHAIR',
  LOCK = 'LOCK',
  WIFI = 'WIFI',
  WHITEBOARD = 'WHITEBOARD',
  LIGHTS = 'LIGHTS',
  AIRCONDITIONER = 'AIRCONDITIONER',
  VENTILLATION = 'VENTILLATION',
  WARDROBE = 'WARDROBE',
  POWER = 'POWER',
  TERRACE = 'TERRACE',
  FOLDING_WINDOW = 'FOLDING_WINDOW',
  TEMPERED_GLASS = 'TEMPERED_GLASS'
}
