import React from 'react';
import classnames from 'classnames';

import style from './Body.sass';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

const Body: React.FC<Props> = ({ is: Component = 'div', className, children, ...rest }) => (
  <Component className={classnames(style.root, className)} {...rest}>
    {children}
  </Component>
);

export default Body;
