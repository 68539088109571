import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import Card from '@/components/Card';
import Icon, { Type as IconType } from '@/components/Icon';
import Typography from '@/components/Typography';

export interface Props {
  iconType: IconType;
  title: string;
  className?: string;
}

const OverviewCard = ({ iconType, title, className, children }: PropsWithChildren<Props>) => {
  return (
    <Card className={classnames('w-full p-4', className)}>
      <div className="flex items-center">
        <Icon type={iconType} />
        <div className="ml-5 w-full flex-1">
          <dl>
            <Typography is="dt" type="sm" className="text-gray-darkest">
              {title}
            </Typography>

            <Typography is="dd" type="regular" className="text-gray-darkest">
              <div className="text-lg font-medium text-gray-900">{children}</div>
            </Typography>
          </dl>
        </div>
      </div>
    </Card>
  );
};

export default OverviewCard;
