import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import WorkspacesListPage from './WorkspacesListPage';
import WorkspaceCreatePage from './WorkspaceCreatePage';
import WorkspaceDetailsPage from './WorkspaceDetailsPage';
import WorkspaceEditPage from './WorkspaceEditPage';

const Workspaces: React.FC = () => (
  <Switch>
    <Route exact path={urls.locations.list} component={WorkspacesListPage} />

    <Route exact path={urls.locations.create} component={WorkspaceCreatePage} />

    <Route exact path={urls.locations.edit.main} component={WorkspaceEditPage} />

    <Route path={urls.locations.bookings.main} component={WorkspaceDetailsPage} />

    <Route path={urls.locations.calendar.main} component={WorkspaceDetailsPage} />

    <Route path={urls.locations.promoCodes.main} component={WorkspaceDetailsPage} />

    <Route path={urls.locations.troubleshooting.main} component={WorkspaceDetailsPage} />

    <Route path={urls.locations.userManagement.main} component={WorkspaceDetailsPage} />

    <Route path={urls.locations.about.main} component={WorkspaceDetailsPage} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.locations.list} />} />
  </Switch>
);

export default Workspaces;
