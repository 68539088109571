import React from 'react';
import { useIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';

import { translations } from '@/locale';

import Form from '@/components/Form';
import Button from '@/components/Button';
import TagInput from '@/components/Input/TagInput';
import HiddenInput from '@/components/Input/HiddenInput';
import Spacer from '@/components/Spacer';
import { SubmitError } from '@/components/Error';

export interface SubmitValuesProps {
  emailList: string[];
  credits: number;
}

export interface Props {
  id: string;
  onSubmit: (values: SubmitValuesProps) => Promise<any>;
  submitButtonText: string;
  emailInputPlaceholder?: string;
  addEmaiButtonText?: string;
}

const schema: yup.ObjectSchema<{ emailList: string[] }> = yup.object({
  emailList: yup
    .array()
    .min(1)
    .of(yup.string().required())
});

const emailSchema = yup
  .string()
  .email()
  .required();

const initialValues: SubmitValuesProps = { emailList: [], credits: 0 };

const EmailListForm = ({ id: formId, onSubmit, submitButtonText, emailInputPlaceholder, addEmaiButtonText }: Props) => {
  const intl = useIntl();

  return (
    <Form
      id={formId}
      initialValues={initialValues}
      schema={schema}
      subscription={{ submitError: true, submitting: true, values: true, hasValidationErrors: true, dirty: true }}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
    >
      {({ submitError, submitting, dirty, values, hasValidationErrors, form: { change } }, id) => (
        <React.Fragment>
          <TagInput
            id={`${id}-emailList`}
            placeholder={
              emailInputPlaceholder ||
              intl.formatMessage({
                id: translations.misc.emailListForm.tagInput.placeholder
              })
            }
            readOnly={submitting}
            tagList={values.emailList}
            onChange={(tagList) => change('emailList', tagList)}
            generateTags={(inputValue) => {
              // validate and create list of emails
              const validTags = inputValue
                .trim()
                .split(/(\s|,)/)
                .filter((tagValue) => emailSchema.isValidSync(tagValue));

              // filter duplicate emails
              return validTags.filter(
                (tagValue, index) => validTags.indexOf(tagValue) === index && !values.emailList.includes(tagValue)
              );
            }}
            inputButton={
              addEmaiButtonText || intl.formatMessage({ id: translations.misc.emailListForm.addEmailButton })
            }
            inputClassName="pr-[100px]"
          />

          <FieldArray name="emailList">
            {({ fields }) =>
              fields.map((fieldName) => (
                <Form.Field
                  key={`${fieldName}`}
                  is={HiddenInput}
                  id={`${id}-${fieldName}`}
                  name={`${fieldName}`}
                  type="text"
                  invalid="false"
                />
              ))
            }
          </FieldArray>

          <Spacer xs={2} />

          <SubmitError error={submitError} />

          <div className="w-full flex justify-end">
            <Button type="submit" appearance="red" form={id} loading={submitting} disabled={hasValidationErrors}>
              {submitButtonText}
            </Button>
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default EmailListForm;
