import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Dropdown from '@/components/Dropdown';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import Card from '@/components/Card';

export interface Props {
  onDeleteConfirm: () => any;
  onRetainDepositClick?: () => any;
}

const BookingListItemDropdown: React.FC<Props> = ({ onDeleteConfirm, onRetainDepositClick }) => {
  const intl = useIntl();

  return (
    <Dropdown
      renderMenu={({}) => (
        <Card className="flex flex-col overflow-hidden">
          <Modal
            modal={ConfirmationModal}
            message={intl.formatMessage({ id: translations.pages.bookings.list.delete.message })}
            confirmMessage={intl.formatMessage({ id: translations.pages.bookings.list.delete.confirm })}
            cancelMessage={intl.formatMessage({ id: translations.pages.bookings.list.delete.cancel })}
            onConfirm={onDeleteConfirm}
          >
            {({ open }) => (
              <button type="button" onClick={open} className="p-3 hover:bg-gray-lightest focus:outline-none">
                <FormattedMessage id={translations.pages.bookings.list.dropdown.delete} />
              </button>
            )}
          </Modal>

          {onRetainDepositClick && (
            <Modal
              modal={ConfirmationModal}
              title={'retain'}
              message={intl.formatMessage({ id: translations.pages.bookings.list.retain.message })}
              confirmMessage={intl.formatMessage({ id: translations.pages.bookings.list.retain.confirm })}
              cancelMessage={intl.formatMessage({ id: translations.pages.bookings.list.retain.cancel })}
              onConfirm={onRetainDepositClick}
            >
              {({ open }) => (
                <button type="button" onClick={open} className="p-3 hover:bg-gray-lightest focus:outline-none">
                  <FormattedMessage id={translations.pages.bookings.list.dropdown.retain} />
                </button>
              )}
            </Modal>
          )}
        </Card>
      )}
      dropdownClassName="mt-1 -left-9"
    >
      {({ open }) => <Icon type="more" className="hover:cursor-pointer hover:font-bold transition" onClick={open} />}
    </Dropdown>
  );
};

export default BookingListItemDropdown;
