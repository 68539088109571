import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import MonitoringPage from './MonitoringPage';

const Monitoring: React.FC = () => (
  <Switch>
    <Route exact path={urls.monitoring} component={MonitoringPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.locations.list} />} />
  </Switch>
);

export default Monitoring;
