import React from 'react';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { isSameDay, subDays, format } from 'date-fns';
import { DateRange } from 'react-date-range';
import { CSVLink } from 'react-csv';

import { translations } from '@/locale';
import { getLessorRevenueMetrics } from '@/services/api/metrics';
import { Magnitude, urls } from '@/constants';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Table from '@/components/Table';
import PieChart from '@/components/Chart/PieChart';
import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import OverviewCard from '@/components/OverviewCard';
import Link from '@/components/Link';
import { BiArrowToBottom } from 'react-icons/bi';

const text = translations.pages.dashboard.lessor.section.revenue;

const headerListLocation: { name?: string; sm: Magnitude; key: string }[] = [
  {
    name: text.revenueLocationTable.header.label.workspace,
    sm: 4,
    key: text.revenueLocationTable.header.key.workspace
  },
  { name: text.revenueLocationTable.header.label.card, sm: 5, key: text.revenueLocationTable.header.key.card },
  { name: text.revenueLocationTable.header.label.hours, sm: 5, key: text.revenueLocationTable.header.key.hours },
  {
    name: text.revenueLocationTable.header.label.totalLocation,
    sm: 3,
    key: text.revenueLocationTable.header.key.totalLocation
  }
];

const headerListTotal: { name?: string; key: string }[] = [
  { name: text.revenueTotalTable.header.label.total, key: text.revenueTotalTable.header.key.total },
  { name: text.revenueTotalTable.header.label.totalCard, key: text.revenueTotalTable.header.key.totalCard },
  { name: text.revenueTotalTable.header.label.totalHours, key: text.revenueTotalTable.header.key.totalHours }
];

export interface Props {
  ownerId: string;
}

const LessorRevenueSection: React.FC<Props> = ({ ownerId }) => {
  const intl = useIntl();

  const [state, setState] = React.useState({
    metrics: {
      ownerId: ownerId,
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'metrics'
    }
  });

  return (
    <div className="mt-8">
      <Typography is="h2" type="small-header" className="font-bold text-gray-darkest mb-2 ml-2">
        <FormattedMessage id={text.overview} />
      </Typography>

      <Card className="w-full">
        <div className="flex">
          <Dropdown
            renderMenu={({ close }) => (
              <DateRange
                className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
                onChange={(item) => {
                  setState({ ...state, ...item });
                }}
                months={1}
                maxDate={new Date()}
                direction="vertical"
                ranges={[state.metrics]}
              />
            )}
            className="p-6"
            dropdownClassName="left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                <FormattedDate value={state.metrics.startDate} day="2-digit" month="long" />

                <If
                  condition={!isSameDay(state.metrics.startDate, state.metrics.endDate)}
                  then={() => {
                    return (
                      <React.Fragment>
                        {` - `}
                        <FormattedDate value={state.metrics.endDate} day="2-digit" month="long" />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            )}
          </Dropdown>
        </div>

        <LoadData key={JSON.stringify(state.metrics)} load={() => getLessorRevenueMetrics(state.metrics)}>
          {({ value: metrics, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center className="h-full">
                  <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <div>
                  <div className="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 place-items-center">
                    <div className="mb-3">
                      <div className="grid place-items-center mb-5 w-full">
                        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                          <FormattedMessage id={text.chart.cardHours} />
                        </Typography>
                      </div>

                      <PieChart
                        className="mt-5 w-1/2"
                        items={[
                          {
                            title: intl.formatMessage({ id: text.revenueLocationTable.header.label.card }),
                            value: parseInt(metrics.totalCard.amount)
                          },
                          {
                            title: intl.formatMessage({ id: text.revenueLocationTable.header.label.hours }),
                            value: parseInt(metrics.totalHours.amount)
                          }
                        ]}
                      />
                    </div>

                    <div className="mb-3">
                      <div className="grid place-items-center mb-5 w-full">
                        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                          <FormattedMessage id={text.chart.perLocation} />
                        </Typography>
                      </div>

                      <PieChart
                        className="mt-5 w-1/2"
                        items={metrics.byLocation.map((e) => ({
                          title: e.name,
                          value: parseInt(e.totalLocation)
                        }))}
                      />
                    </div>
                  </div>

                  <div className="mt-2 mr-2 ml-2 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
                    <OverviewCard
                      iconType="wallet"
                      title={intl.formatMessage({ id: text.total }, { currency: metrics.total.currency ?? 0 })}
                    >
                      {metrics.total.amount}
                    </OverviewCard>

                    <OverviewCard
                      iconType="wallet"
                      title={intl.formatMessage({ id: text.totalCard }, { currency: metrics.totalCard.currency ?? 0 })}
                    >
                      {metrics.totalCard.amount}
                    </OverviewCard>

                    <OverviewCard
                      iconType="wallet"
                      title={intl.formatMessage(
                        { id: text.totalHours },
                        { currency: metrics.totalHours.currency ?? 0 }
                      )}
                    >
                      {metrics.totalHours.amount}
                    </OverviewCard>
                  </div>

                  <Table className="bg-white mt-5">
                    <Table.Header>
                      {headerListLocation.map((headerItem) => (
                        <Table.Data header sm={headerItem.sm} key={headerItem.name}>
                          <Typography is="span">
                            <FormattedMessage id={headerItem.name} />
                          </Typography>
                        </Table.Data>
                      ))}
                    </Table.Header>

                    <Table.Body>
                      {metrics.byLocation.map((e) => {
                        return (
                          <Table.Row key={e.name}>
                            <Table.Data sm={headerListLocation[0].sm}>
                              <Link to={urls.locations.about.get(e.id)} appearance="blue">
                                {e.name}
                              </Link>
                            </Table.Data>

                            <Table.Data sm={headerListLocation[1].sm}>{e.card}</Table.Data>

                            <Table.Data sm={headerListLocation[2].sm}>{e.hours}</Table.Data>

                            <Table.Data sm={headerListLocation[3].sm}>{e.totalLocation}</Table.Data>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>

                  <div className="flex flex-row-reverse mr-2 mb-2 ">
                    <CSVLink
                      data={metrics.byLocation.map((e) => ({
                        name: e.name,
                        card: e.card,
                        hours: e.hours,
                        totalLocation: e.totalLocation
                      }))}
                      headers={headerListLocation.map((e) => ({
                        label: intl.formatMessage({ id: e.name }),
                        key: intl.formatMessage({ id: e.key })
                      }))}
                      filename={
                        intl.formatMessage({ id: text.revenueLocationTable.download.fileName }) +
                        format(state.metrics.startDate, 'dd/MM/yyyy') +
                        '-' +
                        format(state.metrics.endDate, 'dd/MM/yyyy') +
                        '.csv'
                      }
                    >
                      <button
                        type="button"
                        className="mt-2 p-3 bg-gray-lighter hover:bg-gray-light focus:outline-none whitespace-nowrap rounded-xl"
                      >
                        <div className="flex items-center">
                          <BiArrowToBottom className="mr-2"> </BiArrowToBottom>

                          <FormattedMessage id={text.revenueLocationTable.download.button} />
                        </div>
                      </button>
                    </CSVLink>

                    <CSVLink
                      data={[
                        {
                          total: metrics.total,
                          totalCard: metrics.totalCard,
                          totalHours: metrics.totalHours
                        }
                      ]}
                      headers={headerListTotal.map((e) => ({
                        label: intl.formatMessage({ id: e.name }),
                        key: intl.formatMessage({ id: e.key })
                      }))}
                      filename={
                        intl.formatMessage({ id: text.revenueTotalTable.download.fileName }) +
                        format(state.metrics.startDate, 'dd/MM/yyyy') +
                        '-' +
                        format(state.metrics.endDate, 'dd/MM/yyyy') +
                        '.csv'
                      }
                    >
                      <button
                        type="button"
                        className="mt-2 mr-1 p-3 bg-gray-lighter hover:bg-gray-light focus:outline-none whitespace-nowrap rounded-xl"
                      >
                        <div className="flex items-center">
                          <BiArrowToBottom className="mr-2"> </BiArrowToBottom>

                          <FormattedMessage id={text.revenueTotalTable.download.button} />
                        </div>
                      </button>
                    </CSVLink>
                  </div>
                </div>
              )}
            />
          )}
        </LoadData>
      </Card>
    </div>
  );
};

export default LessorRevenueSection;
