import { PromoCodeNumberOfUseType } from '@/domains';

export const roundToTwoDecimals = (number: number): number => Math.round((number + Number.EPSILON) * 100) / 100;
export const fullName = (firstName: string, lastName?: string) =>
  lastName ? `${firstName} ${lastName}` : `${firstName}`;
export const getPromoCodeNumberOfUseTypeFromBoolean = (
  isSingleUse: boolean,
  dailyUseLimit: number,
  limitUsagePerUser?: number
): PromoCodeNumberOfUseType => {
  if (limitUsagePerUser) return PromoCodeNumberOfUseType.CustomUse;
  if (isSingleUse) {
    return PromoCodeNumberOfUseType.SingleUse;
  } else {
    return dailyUseLimit > 0 ? PromoCodeNumberOfUseType.OncePerDay : PromoCodeNumberOfUseType.UnlimitedUse;
  }
};
