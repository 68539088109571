import * as yup from 'yup';

import { translations } from '@/locale';
import {
  MAX_FILE_SIZE_IN_MB,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH
} from '@/constants';

export const phoneNumberSchema = yup
  .string()
  .min(3)
  .max(26)
  .matches(/^[0-9 +()-]+$/, translations.validation.custom.phoneNumber)
  .label(translations.inputs.phoneNumber.label);

export const passwordSchema = yup
  .string()
  .min(PASSWORD_MIN_LENGTH)
  .max(PASSWORD_MAX_LENGTH);

export const shortStringSchema = yup
  .string()
  .min(STRING_MIN_LENGTH)
  .max(SHORT_STRING_MAX_LENGTH);

export const latitudeSchema = yup
  .number()
  .min(-90)
  .max(90);

export const longitudeSchema = yup
  .number()
  .min(-180)
  .max(180);

export const imageSchema = yup.mixed().test({
  message: translations.validation.custom.fileSizeExceeded,
  name: 'fileSizeExceded',
  test: function(value) {
    const isParentArray = Array.isArray(this.parent);
    let index: number;
    if (isParentArray) {
      index = parseInt(this.path.match(/\[[0-9]+\]$/)[0]?.replace(/(\[|\])/g, ''));
    }
    if (!value.file) return true;
    if (value && value.file.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
      throw this.createError({
        path: this.path,
        params: { index: isParentArray ? index + 1 : undefined, size: MAX_FILE_SIZE_IN_MB }
      });
    }
    return true;
  }
});
