import React from 'react';
import { useIntl } from 'react-intl';

import { PaymentStatus } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<PaymentStatus>, 'items'> {}

const AssignedCreditsStatusSelect = ({ ...props }: Props) => {
  const intl = useIntl();

  const items = Object.values(PaymentStatus).map((status) => ({
    value: status,
    name: intl.formatMessage({ id: translations.domains.paymentStatus[status] })
  }));

  return <SimpleSelect {...props} items={items} />;
};

export default AssignedCreditsStatusSelect;
