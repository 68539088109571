import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { refundPackage } from '@/services/api/purchased-packages';

import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Dropdown from '@/components/Dropdown';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import Card from '@/components/Card';
import Loading from '@/components/Loading';

enum ActionState {
  RefundPackage = 'REFUND_PACKAGE'
}

const action: Record<ActionState, (purchasedPackageId: string) => Promise<unknown>> = {
  [ActionState.RefundPackage]: refundPackage
};

export interface Props {
  purchasedPackageId: string;
  refreshList: () => any;
}

const PurchasedPackagesListItemDropdown = ({ purchasedPackageId, refreshList }: Props) => {
  const intl = useIntl();

  const [loadingState, setLoadingState] = React.useState<ActionState>(null);

  const buttonClick = (open: () => any, actionState: ActionState) => () => {
    setLoadingState(actionState);
    action[actionState](purchasedPackageId)
      .then(() => open())
      .finally(() => setLoadingState(null));
  };

  return (
    <Dropdown
      disableStateChange={!!loadingState}
      renderMenu={() => (
        <Card className="flex flex-col overflow-hidden items-start">
          <Modal
            modal={ConfirmationModal}
            title={intl.formatMessage({ id: translations.modals.refundConfirmation.title })}
            message={intl.formatMessage({ id: translations.modals.refundConfirmation.message })}
            confirmMessage={intl.formatMessage({
              id: translations.modals.refundConfirmation.confirm
            })}
            cancelMessage={intl.formatMessage({
              id: translations.modals.refundConfirmation.cancel
            })}
            onClose={refreshList}
          >
            {({ open }) => (
              <button
                type="button"
                onClick={buttonClick(open, ActionState.RefundPackage)}
                disabled={!!loadingState}
                className="p-3 hover:bg-gray-lightest focus:outline-none whitespace-nowrap"
              >
                <div className="flex">
                  <FormattedMessage id={translations.misc.purchasedPackagesDropdown.refund} />

                  <Loading visible={loadingState === ActionState.RefundPackage} className="ml-2">
                    <Loading.Indicator size={20} borderWidth={3} />
                  </Loading>
                </div>
              </button>
            )}
          </Modal>
        </Card>
      )}
      dropdownClassName="mt-1 -left-9"
    >
      {({ open }) => <Icon type="more" className="hover:cursor-pointer hover:font-bold transition" onClick={open} />}
    </Dropdown>
  );
};

export default PurchasedPackagesListItemDropdown;
