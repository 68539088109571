import React from 'react';
import classnames from 'classnames';

import Icon from '@/components/Icon';

export interface Props {
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => any;
}

const SelectItem: React.SFC<Props> = ({ active, selected, disabled, className, onClick, children, ...rest }) => (
  <li
    {...rest}
    className={classnames(
      'relative block w-full h-10 px-4 py-2 pr-8 bg-white text-gray-darkest text-opacity-60 cursor-pointer truncate',
      {
        'bg-gray-lighter hover:bg-gray-lighter': active,
        'text-opacity-100': selected,
        'cursor-default bg-transparent hover:bg-transparent': disabled
      },
      className
    )}
    onClick={disabled ? null : onClick}
  >
    {children}

    <Icon
      type="checkmark"
      className={classnames({
        hidden: !selected,
        'absolute right-3 bottom-3 pointer-events-none text-base text-gray-darkest': selected
      })}
    />
  </li>
);

export default SelectItem;
