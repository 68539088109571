import React from 'react';
import classnames from 'classnames';

import SidebarWrapper, { Props as SidebarWrapperProps } from './SidebarWrapper';
import { SidebarProvider } from '@/components/Context/Sidebar';
import { SessionConsumer } from '@/components/Context/Session';

export interface Props {
  className?: string;
}

export interface StaticComponents {
  Header: React.FC;
  Page: React.FC;
  Content: React.FC;
  Sidebar: React.FC;
}

const AppLayout: React.FC<Props> & StaticComponents = (props) => {
  return (
    <SidebarProvider isOpen={false}>
      <SessionConsumer>
        {({ me }) => <div className={classnames('flex flex-col h-full', props.className)}>{props.children}</div>}
      </SessionConsumer>
    </SidebarProvider>
  );
};

AppLayout.Header = ({ ...props }) => <div className="flex-shrink-0 z-20" {...props} />;
AppLayout.Page = ({ ...props }) => <div className="flex-grow flex h-full overflow-hidden" {...props} />;
AppLayout.Sidebar = ({ ...props }: SidebarWrapperProps) => (
  <SidebarWrapper className="lg:flex-shrink-0 lg:overflow-auto" {...props} />
);
AppLayout.Content = ({ ...props }) => <div className="flex-grow overflow-auto z-10" {...props} />;

export default AppLayout;
