import Typography from '@/components/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translations } from '@/locale';
import Loading from '@/components/Loading';
import carrierType from '@/assets/RouterIcons/antena.svg';
import temperature from '@/assets/RouterIcons/temperature.svg';
import operator from '@/assets/RouterIcons/operate.svg';
import connectType from '@/assets/RouterIcons/connect-type.svg';
import mobileOperator from '@/assets/RouterIcons/mobile-operator.svg';
import signal from '@/assets/RouterIcons/signal.svg';
import { setDefaultRouterInformation } from './util/utils';
import RouterCard from '@/components/RouterCard/RouterCard';
import { getRouterInformations } from '@/services/api/router';
import { useAsyncRetry, useInterval } from 'react-use';
import { getTemperatureHumidity } from '@/services/api/widgets';

const textRouter = translations.pages.troubleshooting.routerInfo;

export interface Props {
  workspaceId: string;
  providerId: string;
  setRouterStatus;
}

const setDeviceTextColor = (deviceSignal, carrierType) => {
  switch (carrierType) {
    case 'LTE':
      if (deviceSignal >= -65) return 'text-lg font-medium text-[#50d71e]';
      else if (deviceSignal < -65 && deviceSignal >= -75) return 'text-lg font-medium text-[#eab308]';
      else if (deviceSignal < -75 && deviceSignal >= -85) return 'text-lg font-medium text-[#ff7849]';
      else if (deviceSignal < -85 && deviceSignal >= -95) return 'text-lg font-medium text-red';
      else if (deviceSignal < -95) return 'text-lg font-medium text-[#7c2d12]';
    default:
      if (deviceSignal >= -70) return 'text-lg font-medium text-[#50d71e]';
      else if (deviceSignal < -70 && deviceSignal >= -85) return 'text-lg font-medium  text-[#eab308]';
      else if (deviceSignal <= -86 && deviceSignal >= -100) return 'text-lg font-medium text-[#ff7849]';
      else if (deviceSignal < -100 && deviceSignal > -110) return 'text-lg font-medium text-red';
      else if (deviceSignal <= -110) return 'text-lg font-medium text-[#7c2d12]';
  }
};

const Router: React.FC<Props> = ({ workspaceId, providerId, setRouterStatus }) => {
  const intl = useIntl();
  const { value: information, loading, retry } = useAsyncRetry(async () => getRouterInformations(workspaceId));
  const [routerInformation, setRouterInformation] = React.useState<any>();
  //const { value: temperatureHumidity } = useAsyncRetry(async () => getTemperatureHumidity(providerId));

  React.useEffect(() => {
    information
      ? (setRouterInformation(information), setRouterStatus(true))
      : setRouterInformation(setDefaultRouterInformation());
  }, [information]);

  useInterval(() => {
    retry();
  }, 60000);

  return (
    <div className="ml-3 mt-5">
      <div className="flex flex-row">
        {loading && (
          <Loading visible={loading} className="ml-5">
            <Loading.Indicator size={25} borderWidth={3} color="#F72431" />
          </Loading>
        )}
      </div>

      {!loading && !routerInformation && (
        <Typography
          is="h2"
          type="small-header"
          className="mt-5 grid place-items-center w-full font-bold text-gray-darkest mb-5"
        >
          <FormattedMessage id={textRouter.information.unreachable} />
        </Typography>
      )}

      {!loading && routerInformation && (
        <div>
          <div className="mb-2 grid grid-cols-1 sm:grid-cols-3 gap-2 xl:grid-cols-6">
            <RouterCard
              IconType={connectType}
              title={intl.formatMessage({ id: textRouter.information.information.connectionType })}
            >
              {routerInformation.wanConnection === 'Connected'
                ? intl.formatMessage({ id: textRouter.router.connected })
                : intl.formatMessage({ id: textRouter.router.disconnected })}
            </RouterCard>

            <RouterCard
              IconType={carrierType}
              title={intl.formatMessage({ id: textRouter.information.information.carrierType })}
            >
              {routerInformation.carrierType ? routerInformation.carrierType : 'N/A'}
            </RouterCard>

            <RouterCard
              IconType={mobileOperator}
              title={intl.formatMessage({ id: textRouter.information.information.mobileOperator })}
            >
              {routerInformation.operator ? routerInformation.operator : 'N/A'}
            </RouterCard>

            <RouterCard
              IconType={signal}
              title={intl.formatMessage({ id: textRouter.information.information.deviceSignal })}
            >
              <p
                className={
                  routerInformation.gsmSignalLevel
                    ? setDeviceTextColor(routerInformation.gsmSignalLevel, routerInformation.carrierType)
                    : null
                }
              >
                {routerInformation.gsmSignalLevel ? routerInformation.gsmSignalLevel : 'N/A'}
              </p>
            </RouterCard>

            <RouterCard
              IconType={operator}
              title={intl.formatMessage({ id: textRouter.information.information.uptime })}
            >
              {routerInformation.bootTime
                ? new Date(routerInformation.bootTime).toLocaleDateString() +
                  ' ' +
                  new Date(routerInformation.bootTime).toLocaleTimeString()
                : 'N/A'}
            </RouterCard>

            {/* {temperatureHumidity && (
              <RouterCard
                IconType={temperature}
                title={intl.formatMessage({ id: textRouter.information.information.temperature })}
              >
                {temperatureHumidity ? temperatureHumidity : 'N/A'}
              </RouterCard>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};
export default Router;
