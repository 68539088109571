import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { endOfWeek, isSameDay, startOfWeek } from 'date-fns';
import { DateRange } from 'react-date-range';

import { translations } from '@/locale';
import { TimeFrame } from '@/domains/metrics';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import { getUserMetrics } from '@/services/api/metrics';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Card from '@/components/Card';
import Dropdown from '@/components/Dropdown';
import { getDaysBetween } from '@/util';
import RegisteredUsersChart from '@/components/Chart/RegisteredUsersChart';
import BookingsActiveUsersChart from '@/components/Chart/BookingsActiveUsersChart';
import { users } from '@/constants/url';
import RevenueChart from '@/components/Chart/RevenueChart';

const text = translations.pages.dashboard;

const DashboardUserSection: React.FC = () => {
  const [state, setState] = React.useState({
    users: {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
      timeFrame: TimeFrame.Monthly,
      key: 'users'
    }
  });

  return (
    <div className="mt-8 mb-8">
      <Card className="w-full">
        <div className="flex">
          <Dropdown
            renderMenu={({ close }) => (
              <DateRange
                className="overflow-hidden box-border border border-solid border-gray-lighter rounded-xl"
                onChange={(item) => {
                  setState({ ...state, ...item });
                }}
                months={1}
                maxDate={new Date()}
                direction="vertical"
                ranges={[state.users]}
              />
            )}
            className="p-6"
            dropdownClassName="left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                <FormattedDate value={state.users.startDate} day="2-digit" month="long" />
                <If
                  condition={!isSameDay(state.users.startDate, state.users.endDate)}
                  then={() => {
                    return (
                      <React.Fragment>
                        {` - `}
                        <FormattedDate value={state.users.endDate} day="2-digit" month="long" />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            )}
          </Dropdown>

          {/* <Dropdown
            renderMenu={({ close }) => (
              <Card className="flex flex-col overflow-hidden">
                <button
                  type="button"
                  onClick={() => {
                    setState({ ...state, users: { ...state.users, timeFrame: TimeFrame.Weekly } });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Weekly
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setState({ ...state, users: { ...state.users, timeFrame: TimeFrame.Monthly } });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Monthly
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setState({ ...state, users: { ...state.users, timeFrame: TimeFrame.Yearly } });
                    close();
                  }}
                  className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
                >
                  Yearly
                </button>
              </Card>
            )}
            className="p-6"
            dropdownClassName=" left-0 pl-4"
          >
            {({ isOpen, open, close }) => (
              <div
                className="hover:cursor-pointer inline-flex px-2 py-1 border border-solid border-gray-lighter rounded-full mr-2.5 group-hover:shadow-red transition"
                onClick={isOpen ? close : open}
              >
                {`Granularity: ${state.users.timeFrame}`}
              </div>
            )}
          </Dropdown> */}
        </div>

        <Typography is="h2" type="small-header" className="font-bold text-gray-darkest pl-6 p-4">
          <FormattedMessage id={text.section.users} />
        </Typography>

        <LoadData key={JSON.stringify(state.users)} load={() => getUserMetrics(state.users)}>
          {({ value: metrics, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center className="h-full">
                  <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <div className="flex flex-col">
                  <If
                    condition={getDaysBetween(state.users.startDate, state.users.endDate) > 0}
                    then={() => (
                      <React.Fragment>
                        <RegisteredUsersChart metrics={metrics} className="max-h-80 px-4" />

                        {/* <BookingsActiveUsersChart
                          metrics={{
                            periods: [
                              { year: 2022, periodOfYear: 1, subPeriod: null },
                              { year: 2022, periodOfYear: 2, subPeriod: null },
                              { year: 2022, periodOfYear: 3, subPeriod: null },
                              { year: 2022, periodOfYear: 4, subPeriod: null }
                            ],
                            data: [
                              { activeUsers: 20, bookings: 50 },
                              { activeUsers: 18, bookings: 40 },
                              { activeUsers: 10, bookings: 10 },
                              { activeUsers: 5, bookings: 12 }
                            ]
                          }}
                          className="max-h-80 px-4"
                        /> */}
                      </React.Fragment>
                    )}
                  />
                </div>
              )}
            />
          )}
        </LoadData>

        {/* <Typography is="h2" type="small-header" className="font-bold text-gray-darkest pl-6 p-4">
          Revenue
        </Typography>

        <RevenueChart
          metrics={{
            byMonth: [
              {
                year: 2021,
                month: 8,
                total: 1700
              },
              {
                year: 2021,
                month: 9,
                total: 2300
              },
              {
                year: 2021,
                month: 10,
                total: 3700
              },
              {
                year: 2021,
                month: 11,
                total: 2000
              },
              {
                year: 2022,
                month: 0,
                total: 2550
              },
              {
                year: 2022,
                month: 1,
                total: 5900
              },
              {
                year: 2022,
                month: 2,
                total: 2200
              },
              {
                year: 2022,
                month: 3,
                total: 2950
              }
            ],
            // TODO: Remove ts-ignore
            // @ts-ignore
            lastUpdatedAt: '2022-04-27T12:11:02.753Z'
          }}
          className="max-h-80 px-4"
        /> */}
      </Card>
    </div>
  );
};

export default DashboardUserSection;
