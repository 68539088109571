import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Typography from '@/components/Typography';
import Button from '@/components/Button';

export interface Props extends RouteComponentProps {}

const text = translations.pages.resetLinkSent;

const ForgotPasswordLinkSentPage: React.FC<Props & RouteComponentProps> = ({ history }) => (
  <div className="flex flex-col justify-around p-5 text-center w-full">
    <div className="flex flex-col">
      <Typography is="h1" type="header" className="font-bold mb-4">
        <FormattedMessage id={text.title} />
      </Typography>

      <Typography is="p" type="regular" className="text-gray-dark mb-6">
        <FormattedMessage id={text.subtitle} />
      </Typography>

      <Button appearance="red" onClick={() => history.push(urls.signIn)}>
        <FormattedMessage id={text.back} />
      </Button>
    </div>
  </div>
);

export default withRouter(ForgotPasswordLinkSentPage);
