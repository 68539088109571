import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Country } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<Country['id']>, 'items'>, WrappedComponentProps {}

class CountrySelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.keys(translations.countries).map((id) => ({
      value: id,
      name: intl.formatMessage({ id: translations.countries[id] })
    }));

    return <Select {...rest} items={items} />;
  }
}

const Default = injectIntl(CountrySelect);
export const CountrySelectWithValidation = withValidation(Default);
export default Default;
