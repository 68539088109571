import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translations } from '@/locale';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import { RouterBitCard } from './RouterBitCard/RouterBitCard';
import { useAsync } from 'react-use';
import { getRouterBits } from '@/services/api/router';

export interface Props {
  workspaceId: string;
  rebootRouter: () => any;
}

const RouterItemDropdown: React.FC<Props> = ({ workspaceId, rebootRouter }) => {
  const intl = useIntl();
  const { value: routerBits } = useAsync(() => getRouterBits(workspaceId));
  return (
    <Dropdown
      renderMenu={({}) => (
        <Card className="flex flex-col overflow-hidden">
          <Modal modal={() => null}>
            {({ open }) => (
              <React.Fragment>
                {routerBits && <RouterBitCard routerBits={routerBits} />}
                <button
                  type="button"
                  onClick={() => rebootRouter()}
                  className="p-3 hover:bg-gray-lightest focus:outline-none flex w-40"
                >
                  <FormattedMessage id={translations.pages.troubleshooting.routerInfo.router.reboot} />
                </button>
              </React.Fragment>
            )}
          </Modal>
        </Card>
      )}
      dropdownClassName="mt-1 -left-24 w-[320px]"
      className="pt-2 mr-2 -left align-text-center"
    >
      {({ open }) => <Icon type="more" className="hover:cursor-pointer hover:font-bold transition" onClick={open} />}
    </Dropdown>
  );
};

export default RouterItemDropdown;
