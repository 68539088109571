import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { translations } from '@/locale';

import { UpsertWorkspaceFeatureTemplate, WorkspaceFeature } from '@/domains';
import { getWorkspaceFeatures } from '@/services/api/workspace-features';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import Grid from '@/components/Grid';
import ModalHeader from '../ModalHeader';
import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import WorkspaceFeatureSelect from '@/components/WorkspaceFeatureSelect';
import Form from '@/components/Form';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import { createFeatureTemplate, updateFeatureTemplate } from '@/services/api/workspace-feature-templates';
import { TextInputWithValidation } from '@/components/Input/TextInput';

export interface Props extends InstanceProps {
  templateId?: string;
  selectedIds: string[];
  onSuccess?: () => any;
}

const schema: yup.ObjectSchema<UpsertWorkspaceFeatureTemplate> = yup.object({
  name: yup
    .string()
    .required()
    .min(5),
  featureIds: yup
    .array()
    .min(1)
    .of(yup.string().required())
});

const AddFeatureTemplateModal: React.FC<Props & WrappedComponentProps> = ({ templateId, onSuccess, close, intl }) => {
  const getSelectedFeature = (featureSet: WorkspaceFeature[], featureIds: string[] = []) =>
    featureSet.find((feature) => featureIds.includes(feature.id));

  const getNewFeatureIds = (
    featureId: string,
    featureSet: WorkspaceFeature[],
    values: UpsertWorkspaceFeatureTemplate
  ) => {
    const newFeatureIds = values.featureIds
      ? values.featureIds.filter((featureId) => featureSet.findIndex((feature) => feature.id === featureId) === -1)
      : [];
    if (featureId) {
      newFeatureIds.push(featureId);
    }
    return newFeatureIds;
  };

  const handleSubmit = async (data: UpsertWorkspaceFeatureTemplate) => {
    if (templateId) {
      updateFeatureTemplate(templateId, data).then(() => {
        onSuccess();
        close();
      });
    } else {
      createFeatureTemplate(data).then(() => {
        onSuccess();
        close();
      });
    }
  };

  const text = translations.pages.featureManagement.tabs.templates.addModal;

  return (
    <Card className="md:w-[870px] p-6 mb-10">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close} className="mb-8">
            <Typography is="span" type="small-header" className="font-bold">
              <FormattedMessage id={text.title} />
            </Typography>
          </ModalHeader>
          <div className="flex flex-wrap">
            <Form
              id="edit-template"
              // initialValues={{}}
              schema={schema}
              subscription={{
                submitError: true,
                submitting: true,
                values: true,
                hasValidationErrors: true,
                dirty: true
              }}
              onSubmit={(data) => handleSubmit(data)}
            >
              {({ submitError, submitting, dirty, values, hasValidationErrors, form: { change } }, id) => (
                <React.Fragment>
                  <Form.Field
                    is={TextInputWithValidation}
                    type="text"
                    id={`${id}-name`}
                    name="name"
                    label={intl.formatMessage({ id: translations.inputs.fields.templateName.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.fields.templateName.placeholder })}
                    readOnly={submitting}
                    required
                  />

                  <LoadData load={() => getWorkspaceFeatures()}>
                    {({ value: workspaceFeatureItems, loading }) => (
                      <If
                        condition={loading}
                        then={() => (
                          <Loading visible={loading} center className="h-full">
                            <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                          </Loading>
                        )}
                        else={() => (
                          <React.Fragment>
                            <div className="flex flex-wrap -mx-4">
                              {workspaceFeatureItems.map(({ features }) => (
                                <WorkspaceFeatureSelect
                                  key={features[0].id}
                                  features={features}
                                  selectedFeature={getSelectedFeature(features, values.featureIds)}
                                  onChange={(value) => change('featureIds', getNewFeatureIds(value, features, values))}
                                  className="m-8"
                                />
                              ))}
                            </div>

                            {/* This will update pristine / dirty props when we call form.change() */}
                            <Field name="featureIds">{() => null}</Field>
                          </React.Fragment>
                        )}
                      />
                    )}
                  </LoadData>

                  <SubmitError error={submitError} />

                  <div className="w-full flex justify-end">
                    <Button
                      type="submit"
                      appearance="red"
                      form={id}
                      loading={submitting}
                      disabled={hasValidationErrors}
                    >
                      <FormattedMessage id={text.submit} />
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Form>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default injectIntl(AddFeatureTemplateModal);
