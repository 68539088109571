import * as rules from './rule';
import * as urls from './url';

export const PRIVACY_POLICY = 'https://www.nookaspace.com/app-privacy-policy-admin?redirect=no';
export const TERMS_AND_CONDITIONS = 'https://www.nookaspace.com/app-terms-and-conditions-admin?redirect=no';

export * from './chart-option';
export * from './language';
export * from './size';
export * from './validation';
export { urls, rules };
