import React from 'react';
import classnames from 'classnames';

import Card from '@/components/Card';
import Dropdown, { Props as DropdownProps } from '@/components/Dropdown';

import InfoStatusButton from './InfoStatusButton';

export interface Props extends Pick<DropdownProps, 'children'> {
  message: string;
  customStyle: string;
  className?: string;
}

const InfoStatus = ({ children, message, customStyle, ...props }: React.PropsWithChildren<Props>) => (
  <div {...props} className={classnames('relative  normal-case font-normal w-4', props.className)}>
    <Dropdown renderMenu={() => <Card className={customStyle}>{message}</Card>}>{children}</Dropdown>
  </div>
);

InfoStatus.Button = InfoStatusButton;

export default InfoStatus;
