import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

export interface Props<T> {
  title: string;
  currentAmount: T;
  targetAmount: T;
  getValue?: (amount: T) => number;
  renderAmount?: (amount: T) => any;
  pathColor?: string;
  className?: string;
}

const TargetCard = <T extends unknown>({
  title,
  currentAmount,
  targetAmount,
  getValue = (amount) => amount as number,
  renderAmount,
  pathColor,
  className
}: PropsWithChildren<Props<T>>) => {
  const currentPercentage = (getValue(currentAmount) * 100) / getValue(targetAmount);

  return (
    <Card className={classnames('w-full p-4', className)}>
      <Typography is="div" type="large-header" className="mb-4">
        {title}
      </Typography>

      <div className="w-[150px] mx-auto">
        <CircularProgressbar
          value={currentPercentage}
          styles={buildStyles({ pathColor, textColor: pathColor })}
          text={`${currentPercentage.toFixed(2)}%`}
        />
      </div>

      <div className="flex justify-around pt-4">
        <div>
          <Typography is="div" type="header" className="text-center">
            {renderAmount ? renderAmount(currentAmount) : currentAmount}
          </Typography>
          <Typography is="div" type="sm" className="text-center text-gray-dark uppercase">
            <FormattedMessage id={translations.misc.targetCard.current} />
          </Typography>
        </div>

        <div>
          <Typography is="div" type="header" className="text-center">
            {renderAmount ? renderAmount(targetAmount) : targetAmount}
          </Typography>
          <Typography is="div" type="sm" className="text-center text-gray-dark uppercase">
            <FormattedMessage id={translations.misc.targetCard.target} />
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default TargetCard;
