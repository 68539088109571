export enum WorkspaceType {
  NookaOneOrange = 'NOOKA_ONE_ORANGE',
  NookaTwoOrange = 'NOOKA_TWO_ORANGE',
  NookaOneYellow = 'NOOKA_ONE_YELLOW',
  NookaTwoYellow = 'NOOKA_TWO_YELLOW',
  NookaOneBlue = 'NOOKA_ONE_BLUE',
  NookaTwoBlue = 'NOOKA_TWO_BLUE',
  NookaAirAsk = 'NOOKA_AIR_ASK',
  NookaAirBp = 'NOOKA_AIR_BP'
}
