import { AssetType, Image } from '@/domains';

import { httpClient } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';

interface PresignedFileUrl {
  url: string;
  fields: any;
  assetUrl: string;
}

const uploadFileToS3 = (file: File) => (presignedFileUrl: PresignedFileUrl) => {
  const { fields, url, assetUrl } = presignedFileUrl;
  const form = Object.entries(fields).reduce((form, [key, value]) => {
    form.append(key, value as any);
    return form;
  }, new FormData());

  form.append('file', file, file.name);

  return httpClient.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => assetUrl);
};

const getPresignedFileUrl = (filename: string, type: AssetType) =>
  authorizedApi.post<PresignedFileUrl>('/presigned-file-urls', null, {
    params: { type, filename }
  });

export const uploadImage = (image: Image, type: AssetType) => {
  if (!image.file) return Promise.resolve(image.url);

  return getPresignedFileUrl(image.file.name, type).then(uploadFileToS3(image.file));
};

export const uploadImagesOfType = (images: Image[], type: AssetType) => {
  const promises = images.map((image) => uploadImage(image, type));

  return Promise.all(promises);
};
