export enum WorkspaceUserType {
  USER = 'USER',
  INVITATION = 'INVITATION'
}
export interface WorkspaceUser {
  id: string;
  type: WorkspaceUserType;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
}
