import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { createTestReport } from '@/services/api/test-reports';
import { DownloadTestReport } from '@/page/Testing/TestingProcessPage/TestingForm/DownloadTestReport/DownloadTestReport';
import { SessionContext } from '@/components/Context/Session/Session';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import { CreateStatusElement } from '@/page/Testing/TestingProcessPage/TestingForm/TestingSummary/CreateStatusElement/CreateStatusElement';
import { testReportMapper } from '@/page/Testing/TestingProcessPage/TestingForm/TestingSummary/util/test-report-mapper';
import { translations } from '@/locale/messages';
import { urls } from '@/constants';
import { TestCaseItem } from '@/domains/test-report';
import { WorkspaceDetails } from '@/domains';

export interface Props {
  workspace: WorkspaceDetails;
  name: string;
  providerId: string;
  data: TestCaseItem[];
  summary: any;
  startDate: Date;
}

export const TestingSummary: React.FC<Props> = ({ workspace, name, providerId, data, summary, startDate }) => {
  const { me } = React.useContext(SessionContext);
  const intl = useIntl();

  React.useEffect(() => {
    createTestReport(testReportMapper(workspace.id, name, new Date(), data, me.id));
  }, []);

  return (
    <div className="m-8">
      <div className="flex justify-between mb-8">
        <Typography is="h1" type="header" className="font-bold text-gray-darkest">
          <FormattedMessage id={summary.title} />
        </Typography>
      </div>

      <React.Fragment>
        <Card className="w-full h-full p-3">
          <Typography key={name} is="h1" className="flex p-5 ">
            <FormattedMessage id={translations.placeholders.workspace} />
            {name}
          </Typography>

          <Typography key={providerId} is="h2" className="flex p-5">
            <FormattedMessage id={translations.placeholders.identifier} />
            {providerId}
          </Typography>

          <div className="flex gap-5 p-5 w-full">
            <Typography is="h2">
              <FormattedMessage id={summary.startDate} />

              <FormattedTime value={startDate} year="numeric" month="short" day="numeric" weekday="short" />
            </Typography>

            <Typography is="h2">
              <FormattedMessage id={summary.endDate} />

              <FormattedTime value={new Date()} year="numeric" month="short" day="numeric" weekday="short" />
            </Typography>
          </div>

          <div className="flex gap-10 p-6">
            <Typography is="h2" className="flex">
              <FormattedMessage id={translations.placeholders.testCases} />
            </Typography>

            <Typography is="h2" className="flex ml-20">
              <FormattedMessage id={translations.placeholders.results} />
            </Typography>

            <Typography is="p" className="flex pr-18 ml-14">
              <FormattedMessage id={translations.placeholders.comments} />
            </Typography>
          </div>
          {data.map((value: TestCaseItem) => (
            <CreateStatusElement key={value.id} data={value} />
          ))}

          <Card.Row className="mt-3">
            <div className="flex gap-10 w-full p-3 mt-3">
              <DownloadTestReport
                data={data}
                title={intl.formatMessage(
                  {
                    id: translations.placeholders.downloadTestReportTitle
                  },
                  {
                    name: workspace.name,
                    providerId: workspace.providerId,
                    date: intl.formatDate(new Date())
                  }
                )}
                placeholder={intl.formatMessage({ id: translations.placeholders.downloadTestReport })}
                className="text-sm"
              />

              <Link
                type="button"
                to={urls.testing.list}
                className="flex bg-red text-white rounded px-4 h-10 leading-10 min-w-[40px] text-sm focus:outline-none'"
              >
                <FormattedMessage id={summary.startTestButton} />
              </Link>
            </div>
          </Card.Row>
        </Card>
      </React.Fragment>
    </div>
  );
};
