import React from 'react';
import classnames from 'classnames';

import style from './LoadingIndicator.sass';

export interface Props {
  size: number;
  borderWidth: number;
  color?: string;
  center?: boolean;
}

export default class LoadingIndicator extends React.PureComponent<Props> {
  render() {
    const { size, borderWidth, color, center } = this.props;

    return (
      <span
        className={classnames(style.root, {
          [style.center]: center
        })}
        style={{
          width: size,
          height: size,
          borderWidth,
          borderRightColor: color
        }}
      />
    );
  }
}
