import React, { PureComponent } from 'react';
import classnames from 'classnames';

import Row from './Row';

interface Props {
  className?: string;
}
export default class Card extends PureComponent<Props & React.HTMLProps<HTMLDivElement>> {
  static Row = Row;

  render() {
    const { className, children, ...rest } = this.props;
    return (
      <div
        className={classnames(
          'relative inline-block box-border border border-solid border-gray-lighter rounded-xl bg-white',
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
