import { Page, BookingCoverage, AdminBookingListItemDto, CleaningDepositStatus } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getWorkspaceBookingsCoverageWithinTimeFrame = (workspaceId: string, startDate: Date, endDate: Date) =>
  authorizedApi.get<BookingCoverage[]>(`/booking-coverage`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    target: 'WORKSPACE',
    workspaceId
  });

export const getPaginatedBookings = (pageNumber: number, pageSize: number, workspaceId?: string, searchKey?: string) =>
  authorizedApi.get<Page<AdminBookingListItemDto>>(`/admin/bookings`, { pageSize, pageNumber, workspaceId, searchKey });

export const getPaginatedBookingsForUser = (pageNumber: number, pageSize: number, userId: string) =>
  authorizedApi.get<Page<AdminBookingListItemDto>>(`/admin/bookings`, { pageSize, pageNumber, userId });

export const retainCleaningDepositFee = (bookingId: string) =>
  authorizedApi.patch(`/admin/bookings/${bookingId}`, { cleaningDepositStatus: CleaningDepositStatus.Retained });

export const deleteBooking = (bookingId: string) => authorizedApi.delete<null>(`/booking/${bookingId}`);
