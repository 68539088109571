import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { UserRole } from '@/domains';

import Grid from '@/components/Grid';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';

import mail from './mail.svg';

export interface Props extends Pick<InstanceProps, 'close'> {
  email: string;
  type: UserRole;
}

const text = translations.modals.addUserModal.invitationSentStep;

const InvitationSentStep = ({ email, type, close }: Props) => {
  return (
    <Grid className="text-center">
      <Grid.Row>
        <Grid.Column className="w-full flex flex-col items-center">
          <img src={mail} className="w-16 h-16 mb-4" />

          <Typography type="small-header" is="h6" className="mb-6">
            <FormattedMessage id={text.title} />
          </Typography>

          <Typography type="regular" is="p" className="text-gray mb-2">
            <FormattedMessage
              id={text.sent}
              values={{
                email,
                t: (children) => (
                  <Typography is="span" type="regular" className="text-gray-darkest">
                    {children}
                  </Typography>
                )
              }}
            />
          </Typography>

          <Typography type="regular" is="p" className="text-gray mb-6">
            <FormattedMessage
              id={text.userType}
              values={{
                t: () => (
                  <Typography is="span" type="regular" className="text-gray-darkest">
                    <FormattedMessage id={translations.domains.userRole[type]} />
                  </Typography>
                )
              }}
            />
          </Typography>

          <Typography type="regular" is="p" className="text-gray mb-8 px-4">
            <FormattedMessage id={text.opens} />
          </Typography>

          <Button appearance="red" className="w-full" onClick={close}>
            <FormattedMessage id={text.done} />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default InvitationSentStep;
