import { CountryCode } from 'src/domains/country';
import { Pricing } from 'src/domains/pricing';
import { UserRole } from 'src/domains/user';
import { WorkspaceType } from 'src/domains/workspace';

export enum BillingType {
  business = 'BUSINESS',
  personal = 'PERSONAL'
}

interface AdminBookingListItemUserDto {
  id: string;
  firstName: string;
  lastName: string;
  type: UserRole;
  phoneNumber?: string;
  billingInformation?: BillingType | null;
}

interface AdminBookingListItemWorkspaceDto {
  id: string;
  name: string;
  type: WorkspaceType;
  country: CountryCode;
  city: string;
}

export enum CleaningDepositStatus {
  StandBy = 'STAND_BY',
  Refunded = 'REFUNDED',
  Retained = 'RETAINED'
}

export interface AdminBookingListItemDto {
  id: string;
  user: AdminBookingListItemUserDto;
  workspace: AdminBookingListItemWorkspaceDto;
  startDate: Date;
  endDate: Date;
  cost: Pricing;
  cleaningDepositStatus?: CleaningDepositStatus;
  billingInformation?: { type?: BillingType };
}
