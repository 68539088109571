import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { SignIn as SignInDomain } from '@/domains';

import Form from '@/components/Form';
import Link from '@/components/Link';
import Button from '@/components/Button';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import { SessionConsumer } from '@/components/Context/Session';
import { PasswordInputWithValidation } from '@/components/Input/PasswordInput';

const schema: yup.ObjectSchema<SignInDomain> = yup.object({
  email: yup
    .string()
    .email()
    .label(translations.inputs.email.label)
    .required(),
  password: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
});

export interface Props extends RouteComponentProps {}

const SignInPage: React.FC<Props & WrappedComponentProps> = (props) => {
  const email = parse(location.search).email as string;

  return (
    <SessionConsumer>
      {({ signInWithEmail }) => (
        <React.Fragment>
          <Typography is="h1" type="header" className="text-center font-bold mb-8">
            <FormattedMessage id={translations.pages.signIn.title} />
          </Typography>

          <Form
            id="sign-in"
            schema={schema}
            initialValues={{ email }}
            subscription={{ submitError: true, submitting: true, values: true }}
            onSubmit={(values) => signInWithEmail(values.email, values.password)}
          >
            {({ submitError, submitting, values }, id) => (
              <React.Fragment>
                <div className="flex flex-col">
                  <div className="mb-8">
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${id}-email`}
                      name="email"
                      type="email"
                      label={props.intl.formatMessage({ id: translations.inputs.email.label })}
                      placeholder={props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={PasswordInputWithValidation}
                      id={`${id}-password`}
                      name="password"
                      label={props.intl.formatMessage({ id: translations.inputs.password.label })}
                      placeholder={props.intl.formatMessage({ id: translations.inputs.password.placeholder })}
                      readOnly={submitting}
                      className="mt-4"
                    />
                  </div>

                  {!!submitError && <SubmitError error={submitError} className="mb-4" />}

                  <Button type="submit" loading={submitting} appearance="red">
                    <FormattedMessage id={translations.pages.signIn.signInButton} />
                  </Button>
                </div>

                <Link
                  to={{
                    pathname: urls.forgotPassword.main,
                    search: stringify({ email: values.email })
                  }}
                  appearance="red"
                >
                  <Typography is="div" type="regular" className="text-center mt-8">
                    <FormattedMessage id={translations.pages.signIn.forgotPassword} />
                  </Typography>
                </Link>
              </React.Fragment>
            )}
          </Form>
        </React.Fragment>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(SignInPage);
