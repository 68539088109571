import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getAvailableCredits } from '@/services/api/wallets';

import If from '@/components/If';
import Loading from '@/components/Loading';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import AssignCreditsModal from '@/components/Modal/AssignCreditsModal';
import { useAsyncRetry } from 'react-use';

interface Props {
  userId: string;
}

const AssignedCreditsStatus = ({ userId }: Props) => {
  const credits = useAsyncRetry(async () => getAvailableCredits(userId));

  return (
    <If
      condition={credits.loading}
      then={() => (
        <Loading visible={credits.loading} className="h-[30px] pt-2">
          <Loading.Indicator size={30} borderWidth={4} color="#F72431" />
        </Loading>
      )}
      else={() => (
        <React.Fragment>
          <Typography is="div">{credits.value.availableCredits}</Typography>

          <Modal modal={AssignCreditsModal} userId={userId} onSuccess={() => credits.retry()}>
            {({ open }) => (
              <Button className="underline hover:no-uderline" onClick={open}>
                <FormattedMessage id={translations.inputs.fields.assignedCredits.assignCredits} />
              </Button>
            )}
          </Modal>
        </React.Fragment>
      )}
    />
  );
};

export default AssignedCreditsStatus;
