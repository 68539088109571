import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { fullName } from '@/util';
import { urls } from '@/constants';
import { translations } from '@/locale';

import { SessionConsumer } from '@/components/Context/Session';
import Dropdown from '@/components/Dropdown';
import Typography from '@/components/Typography';
import Icon from '@/components/Icon';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';
import Card from '@/components/Card';

const text = translations.header.profile;

const ProfileDropdown: React.FC<RouteComponentProps> = ({ history }) => (
  <SessionConsumer>
    {({ me, signOut }) => (
      <Dropdown
        renderMenu={({ close }) => (
          <Card className="flex flex-col overflow-hidden">
            <button
              type="button"
              onClick={() => {
                history.push(urls.users.detail.get(me.id));
                close();
              }}
              className="p-3 border-b border-solid border-gray-lighter hover:bg-gray-lightest focus:outline-none"
            >
              <FormattedMessage id={text.view} />
            </button>

            <button type="button" onClick={signOut} className="p-3 hover:bg-gray-lightest focus:outline-none">
              <FormattedMessage id={text.signOut} />
            </button>
          </Card>
        )}
        dropdownClassName="mt-2 right-0 pl-4"
      >
        {({ isOpen, open, close }) => (
          <div className="inline-flex items-center group hover:cursor-pointer" onClick={isOpen ? close : open}>
            <div className="flex flex-col items-end mr-3">
              <Typography is="span" type="regular" className="font-bold">
                {fullName(me.firstName, me.lastName)}
              </Typography>

              <Typography is="span" type="sm" className="font-light text-gray-dark">
                <FormattedMessage id={translations.domains.userRole[me.role]} />
              </Typography>
            </div>

            <UserProfileImage
              url={me.profileImageUrl}
              firstName={me.firstName}
              lastName={me.lastName}
              className="w-10 h-10 rounded-full mr-2.5 group-hover:shadow-red transition"
            />

            <Icon type={isOpen ? 'triangleUp' : 'triangleDown'} className="text-xs" />
          </div>
        )}
      </Dropdown>
    )}
  </SessionConsumer>
);

export default withRouter(ProfileDropdown);
