import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

interface Props {
  metrics: {
    date: string;
    value: number;
  }[];
}

const SensorChart = ({ metrics }: Props) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const currentData = metrics.map((e) => {
    return { x: e.date, y: e.value };
  });
  const labels = metrics.map((e) => {
    return e.date;
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Signal Strength'
      }
    },
    scales: {
      x: {
        ticks: {
          min: metrics.length > 0 ? 1 : 0,
          maxTicksLimit: 25,
          stepSize: metrics.length > 25 ? Math.round(metrics.length / 20) : 2,
          callback: (value, index, values) => {
            return labels[value];
          }
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Power',
        data: currentData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  };

  return <Line options={options} data={data} />;
};

export default SensorChart;
