import React from 'react';
import { Bar } from 'react-chartjs-2';

import { COMMON_CHART_OPTIONS } from '@/constants';
import { UserMetrics } from '@/domains/metrics';

interface Props {
  metrics: UserMetrics;
  className?: string;
}

const RegisteredUsersChart = ({ metrics, className }: Props) => {
  const data = {
    labels: metrics.byDay.map((e) => e.date),
    datasets: [
      {
        label: 'Total registered users',
        data: metrics.byDay.map((item, index) =>
          metrics.byDay
            .map((e) => e.total)
            .slice(0, index + 1)
            .reduce((sum, current) => sum + current, 0)
        ),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        type: 'line'
      },
      {
        label: 'Registered users',
        data: metrics.byDay.map((e) => e.total),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  // TODO: Remove ts-ignore
  // @ts-ignore
  return <Bar className={className} options={COMMON_CHART_OPTIONS} data={data} />;
};

export default RegisteredUsersChart;
