import { PaymentStatus } from './payment-status';
import { Pricing } from './pricing';

export enum AssignedCreditsStatus {
  ASSIGNED = 'ASSIGNED',
  INVITED = 'INVITED'
}
export interface PurchasedCredits {
  id: string;
  customerId: string;
  name: string;
  customerName: string;
  customerEmail: string;
  purchasingUserId: string;
  purchasingName: string;
  availableCredits: number;
  includedCredits: number;
  date: Date;
  expiresAt: Date;
  packageName: string;
  paidPrice: Pricing;
  status: AssignedCreditsStatus;
  paymentStatus: PaymentStatus;
}
