import React from 'react';
import classnames from 'classnames';

import style from './Placeholder.sass';

export interface Props {
  className?: string;
}

const Placeholder: React.FC<Props> = ({ className, children }) => (
  <div className={classnames(style.root, className)}>{children}</div>
);

export default Placeholder;
