import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';

import Toggle, { Props as ToggleProps } from '@/components/Toggle';
import Typography from '@/components/Typography';

import style from './ToggleSwitch.sass';

type MapKey<T> = 'true' | 'false' | keyof T;
type MapValue<T> = MapKey<T>;

export interface Props<T> extends Omit<ToggleProps, 'renderToggle' | 'value'> {
  value?: string;
  label?: boolean;
  map?: Record<MapKey<T>, MapValue<T>>;
}

const ToggleSwitch = <T,>(props: React.PropsWithChildren<Props<T>>) => {
  const { label = true, map, value, checked, onChange, ...rest } = props;
  const isChecked = map ? map[value] === 'true' : checked;

  return (
    <Toggle
      {...rest}
      type="checkbox"
      checked={isChecked}
      onChange={(event) => {
        const value = map ? map[event.target.checked.toString()] : event;
        onChange(value);
      }}
      renderToggle={(toggled, disabled) => (
        <div className={style.root}>
          {label && (
            <Typography is="span" type="regular" color="blue">
              <FormattedMessage id={translations.stateSwitch[toggled ? 'active' : 'inactive']} />
            </Typography>
          )}

          <div
            className={classnames(
              'inline-block rounded-2xl transition-colors',
              style.toggle,
              toggled ? `${style.active} bg-red` : 'bg-gray-light',
              { 'ml-2': label }
            )}
          >
            <div
              className={classnames(
                'inline-block rounded-full m-[3px] bg-white transition-transform shadow',
                style.dot
              )}
            />
          </div>
        </div>
      )}
    />
  );
};

export default ToggleSwitch;
