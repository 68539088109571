import {
  AssetType,
  Currency,
  Page,
  WorkspaceCreation,
  WorkspaceCreationForm,
  WorkspaceDetails,
  WorkspaceEditForm,
  WorkspaceListItem
} from '@/domains';
import { ActionLog } from '@/domains/action-log';
import { VersionIOT } from '@/domains/iot-boards/iot-version';
import { MonitoringPage } from '@/domains/monitoring-page';
import { WorkspaceBoothStatus, WorkspaceBoothStatusResponse } from '@/domains/workspace-status';
import { uploadImagesOfType } from '@/services/api/media';
import { authorizedApi } from '@/services/network/authorized-api';

const mapWorkspace = (workspaceForm: WorkspaceCreationForm): Omit<WorkspaceCreation, 'imagesUrls'> => ({
  name: workspaceForm.name,
  address: workspaceForm.address,
  description: workspaceForm.description,
  city: workspaceForm.city,
  country: workspaceForm.country,
  location: { latitude: workspaceForm.latitude, longitude: workspaceForm.longitude },
  pricing: {
    price: workspaceForm.price ?? 0,
    currency: workspaceForm.currency ? workspaceForm.currency : Currency.EUR
  },
  hasQrAccess: workspaceForm.hasQrAccess,
  accessMessage: workspaceForm.accessMessage,
  preferredQrAccessCode: workspaceForm.preferredQrAccessCode,
  private: workspaceForm.private,
  status: workspaceForm.status,
  visibilityOnMap: workspaceForm.visibility,
  ownerIds: workspaceForm.ownerIds,
  provider: workspaceForm.iotEnabled ? workspaceForm.provider : null,
  providerId: workspaceForm.iotEnabled ? workspaceForm.providerId : null,
  installationDate: workspaceForm.installationDate,
  constructionDate: workspaceForm.constructionDate,
  featureIds: workspaceForm.featureIds,
  routerIp: workspaceForm.routerIp,
  changeWifiPassword: workspaceForm.changeWifiPassword,
  cleaningDeposit: workspaceForm.cleaningDeposit,
  cleaningDepositFee: workspaceForm.cleaningDepositFee ? workspaceForm.cleaningDepositFee : undefined,
  versionIOT: workspaceForm.versionIOT ? workspaceForm.versionIOT : null,
  minimumBookingPeriodInMinutes: workspaceForm.minimumBookingPeriodInMinutes,
  bookingTimeIncrementStepInMinutes: workspaceForm.bookingTimeIncrementStepInMinutes
});

export const getWorkspaces = (
  pageNumber: number,
  pageSize: number,
  ownedBy?: string[],
  keyword?: string,
  provider?: string
) =>
  authorizedApi.get<Page<WorkspaceListItem>>(`/admin/workspaces`, {
    pageNumber,
    pageSize,
    ownedBy,
    keyword,
    provider
  });

export const getWorkspaceDetails = (id: string) => authorizedApi.get<WorkspaceDetails>(`/workspaces/${id}`);

export const getActionsByWorkspacdId = (pageNumber: number, pageSize: number, workspaceId: string) =>
  authorizedApi.get<Page<ActionLog>>(`/admin/workspaces/action-logs`, { pageNumber, pageSize, workspaceId });

export const getActionsReportByWorkspaceId = (workspaceId: string, startDate: Date, endDate: Date) =>
  authorizedApi.get<ActionLog[]>(`/admin/workspaces/action-logs/report`, { workspaceId, startDate, endDate });

export const createNewActionLog = (newActionLog: ActionLog) =>
  authorizedApi.post<ActionLog>(`/admin/workspaces/action-logs`, newActionLog);

export const createWorkspace = (workspaceCreationForm: WorkspaceCreationForm) => {
  const workspaceCreation = mapWorkspace(workspaceCreationForm);

  return uploadImagesOfType(workspaceCreationForm.images, AssetType.WorkspaceImage).then((imagesUrls) =>
    authorizedApi.post<WorkspaceDetails>(`/workspaces`, { ...workspaceCreation, imagesUrls })
  );
};

export const updateWorkspace = (workspaceId: string, workspaceUpdateForm: WorkspaceEditForm) => {
  const workspaceEdit = mapWorkspace(workspaceUpdateForm);
  return uploadImagesOfType(workspaceUpdateForm.images, AssetType.WorkspaceImage).then((imagesUrls) =>
    authorizedApi.patch<WorkspaceDetails>(`/workspaces/${workspaceId}`, { ...workspaceEdit, imagesUrls })
  );
};

export const getWorkspaceById = (workspaceId: string) =>
  authorizedApi.get<WorkspaceListItem>(`/workspaces/${workspaceId}`);

export const deleteWorkspaceById = (workspaceId: string) => authorizedApi.delete<null>(`/workspaces/${workspaceId}`);

export const getMonitoringStatusWorkspace = (
  pageNumber: number,
  pageSize: number,
  ownedBy?: string[],
  keyword?: string,
  provider?: string
) =>
  authorizedApi.get<MonitoringPage<WorkspaceBoothStatusResponse<WorkspaceBoothStatus>>>(`/monitoring/status-booth`, {
    pageNumber,
    pageSize,
    ownedBy,
    keyword,
    provider
  });
