import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';

export interface Props extends RouteComponentProps {
  is?: React.ElementType;
  className?: string;
  hoverable?: boolean;
  url?: string;
  isExternalUrl?: boolean;
}

const handleClick = (url, isExternalUrl, history) => {
  url ? (isExternalUrl ? window.open(url, '_blank') : history.push(url)) : null;
};

const LinkRow: React.FC<Props> = ({
  is: Component = 'div',
  className,
  hoverable,
  url,
  isExternalUrl,
  staticContext,
  history,
  children,
  ...rest
}) => (
  <Component
    className={classnames(
      'flex flex-wrap px-6 py-5 bg-white even:bg-gray-lightest border-b border-solid border-gray-lighter last:border-none',
      { 'hover:bg-gray-lighter hover:bg-opacity-50': hoverable },
      { 'cursor-pointer': url },
      className
    )}
    onClick={() => handleClick(url, isExternalUrl, history)}
    {...rest}
  >
    {children}
  </Component>
);

export default withRouter(LinkRow);
