import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router';

import { rules, urls } from '@/constants';
import { translations } from '@/locale';
import { getDefaultUrl, hasRole } from '@/permission';

import { SessionContext } from '@/components/Context/Session';
import AppLayout from '@/components/AppLayout';
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import HasRole from '@/components/HasRole';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import ErrorBoundary from '@/components/ErrorBoundary';
import Typography from '@/components/Typography';

import Workspaces from '@/page/Workspaces';
import Users from '@/page/Users';
import Promotions from '@/page/Promotions';
import Bookings from '@/page/Bookings';
import Dashboard from '@/page/Dashboard';
import PurchasedPackages from '@/page/PurchasedPackages';
import WorkspaceFeatures from '@/page/WorkspaceFeatures';
import Troubleshooting from '@/page/Troubleshooting';
import Testing from '@/page/Testing/Testing';
import TestingReportsPage from '@/page/Testing/TestingReportsPage';
import Overview from '@/page/Overview';
import PromoCredits from '@/page/PromoCredits';
import Monitoring from '@/page/Monitoring';

export interface Props {}

const HomePage: React.FC<Props> = () => {
  const { me } = React.useContext(SessionContext);

  return (
    <AppLayout className="min-w-[768px]">
      <AppLayout.Header>
        <Header />
      </AppLayout.Header>

      <AppLayout.Page>
        <AppLayout.Sidebar>
          <Sidebar>
            <HasRole to={rules.MAIN_METRICS_VIEW}>
              <Sidebar.Item icon="stats" url={urls.dashboard}>
                <FormattedMessage id={translations.navigation.dashboard} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_LOCATIONS_VIEW}>
              <Sidebar.Item icon="location" url={urls.locations.list}>
                <FormattedMessage id={translations.navigation.locations} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_USERS_VIEW}>
              <Sidebar.Item icon="users" url={urls.users.list}>
                <FormattedMessage id={translations.navigation.users} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_BOOKINGS_VIEW}>
              <Sidebar.Item icon="bookmark" url={urls.bookings.list}>
                <FormattedMessage id={translations.navigation.bookings} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_PROMOTIONS_VIEW}>
              <Sidebar.Item icon="wallet" url={urls.promotions.list}>
                <FormattedMessage id={translations.navigation.promotions} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_PROMO_PACKS_VIEW}>
              <Sidebar.Item icon="tag" url={urls.promoCredits.list}>
                <FormattedMessage id={translations.navigation.promoCredits} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_PURCHASED_PACKAGES_VIEW}>
              <Sidebar.Item icon="tag" url={urls.purchasedPackages.list}>
                <FormattedMessage id={translations.navigation.purchasedPackages} />
              </Sidebar.Item>
            </HasRole>

            <Typography is="p" className="pt-8">
              Platform
            </Typography>

            <HasRole to={rules.MAIN_FEATURES_VIEW}>
              <Sidebar.Item icon="starFull" url={urls.features.list.main} newIcon={'AiOutlineStar'}>
                <FormattedMessage id={translations.navigation.featureManagement} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_STATUS_VIEW}>
              <Sidebar.Item icon="status" url={urls.status} external={true} newIcon={'GrStatusWarning'}>
                <FormattedMessage id={translations.navigation.status} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAP_STATUS_VIEW}>
              <Sidebar.Item icon="category" url={urls.overview} newIcon={'MdTravelExplore'}>
                <FormattedMessage id={translations.navigation.overview} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MONITORING_VIEW}>
              <Sidebar.Item icon="category" url={urls.monitoring} newIcon={'BsDisplay'}>
                <FormattedMessage id={translations.navigation.monitoring} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_TROUBLESHOOTING_VIEW}>
              <Sidebar.Item icon="status" url={urls.troubleshooting.list} newIcon="AiOutlineTool">
                <FormattedMessage id={translations.navigation.troubleshooting} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_TESTING_VIEW}>
              <Sidebar.Item icon="starFull" url={urls.testing.list} newIcon="VscGithubAction">
                <FormattedMessage id={translations.navigation.testing} />
              </Sidebar.Item>
            </HasRole>

            <HasRole to={rules.MAIN_TESTING_REPORTS_VIEW}>
              <Sidebar.Item icon="starFull" url={urls.testingReports.list} newIcon="TbReportAnalytics">
                <FormattedMessage id={translations.navigation.testingReports} />
              </Sidebar.Item>
            </HasRole>
          </Sidebar>
        </AppLayout.Sidebar>

        <AppLayout.Content>
          <ErrorBoundary>
            <Switch>
              {hasRole(me.role, rules.MAIN_METRICS_VIEW) && <Route path={urls.dashboard} component={Dashboard} />}

              {hasRole(me.role, rules.MAIN_LOCATIONS_VIEW) && (
                <Route path={urls.locations.list} component={Workspaces} />
              )}

              {hasRole(me.role, rules.MAIN_USERS_VIEW) && <Route path={urls.users.list} component={Users} />}

              {hasRole(me.role, rules.MAIN_PROMOTIONS_VIEW) && (
                <Route path={urls.promotions.list} component={Promotions} />
              )}

              {hasRole(me.role, rules.MAIN_BOOKINGS_VIEW) && <Route path={urls.bookings.list} component={Bookings} />}

              {hasRole(me.role, rules.MAIN_PROMO_PACKS_VIEW) && (
                <Route path={urls.promoCredits.list} component={PromoCredits} />
              )}

              {hasRole(me.role, rules.MAIN_PURCHASED_PACKAGES_VIEW) && (
                <Route path={urls.purchasedPackages.list} component={PurchasedPackages} />
              )}

              {hasRole(me.role, rules.MAIN_TROUBLESHOOTING_VIEW) && (
                <Route path={urls.troubleshooting.list} component={Troubleshooting} />
              )}

              {hasRole(me.role, rules.MAIN_TESTING_VIEW) && <Route path={urls.testing.list} component={Testing} />}
              {hasRole(me.role, rules.MAIN_TESTING_REPORTS_VIEW) && (
                <Route path={urls.testingReports.list} component={TestingReportsPage} />
              )}

              {hasRole(me.role, rules.MAIN_FEATURES_VIEW) && (
                <Route path={urls.features.list.main} component={WorkspaceFeatures} />
              )}

              {hasRole(me.role, rules.MAP_STATUS_VIEW) && <Route path={urls.overview} component={Overview} />}

              {hasRole(me.role, rules.MONITORING_VIEW) && <Route path={urls.monitoring} component={Monitoring} />}
              <ConsumeRedirectRoute render={() => <Redirect to={getDefaultUrl(me.role)} />} />
            </Switch>
          </ErrorBoundary>
        </AppLayout.Content>
      </AppLayout.Page>
    </AppLayout>
  );
};

export default HomePage;
