import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { translations } from '@/locale';
import Typography from '@/components/Typography';
import Card from '@/components/Card';
import { getAllTestReportNames } from '@/services/api/test-reports';
import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import { SessionContext } from '@/components/Context/Session/Session';
import RenderWorkspace from './RenderWorkspace/RenderWorkspace';

export const TestReports: React.FC<WrappedComponentProps> = () => {
  const { me } = React.useContext(SessionContext);
  const text = translations.pages.testReports;

  return (
    <LoadData load={() => getAllTestReportNames(me.id)}>
      {({ value: workspaceNames, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className="h-full">
              <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
            </Loading>
          )}
          else={() => (
            <div className="m-8">
              <div className="flex justify-between mb-8">
                <Typography is="h1" type="header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.title} />
                </Typography>
              </div>

              <React.Fragment>
                <Card className="w-full h-full p-3 ">
                  <div className=" justify-items-top  ">
                    <div className="justify-between">
                      {workspaceNames.map((value: any) => (
                        <RenderWorkspace
                          key={value.workspaceName}
                          workspace={value.workspaceName}
                          style={' w-25 ml-3 p-3 '}
                        />
                      ))}
                    </div>
                  </div>
                </Card>
              </React.Fragment>
            </div>
          )}
        />
      )}
    </LoadData>
  );
};
export default injectIntl(TestReports);
