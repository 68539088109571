import React from 'react';

export interface Props<T> {
  of: T;
  then: (field: T) => React.ReactNode;
  else?: () => React.ReactNode;
}

const Optional = <T,>(props: React.PropsWithChildren<Props<T>>) => (
  <>{!!props.of ? props.then(props.of) : props.else && props.else()}</>
);

export default Optional;
