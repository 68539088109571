import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Pricing } from '@/domains';
import { translations } from '@/locale';
import { roundToTwoDecimals } from '@/util';

export interface Props {
  value: Pricing;
}

const Price = ({ value }: React.PropsWithChildren<Props>) =>
  value.tokens ? (
    <FormattedMessage id={translations.domains.tokens.amount} values={{ amount: value.tokens }} />
  ) : (
    <FormattedMessage
      id={translations.domains.currency[value.currency].amount}
      values={{ amount: roundToTwoDecimals(value.price) }}
    />
  );

export default Price;
