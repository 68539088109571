import React, { useEffect } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { Me, PromoCodeNumberOfUseType, UserRole } from '@/domains';

import Form, { RenderProps as FormRenderProps } from '@/components/Form';
import { SubmitError } from '@/components/Error';
import Grid from '@/components/Grid';
import { DateInputWithValidation } from '@/components/Input/DateInput';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { PromoCodeNumberOfUseSelectWithValidation } from '@/components/Select/PromoCodeUseSelect';
import { UserSelectSelectWithValidation } from '@/components/Select/UserSelect';
import { WorkspacesSelectSelectWithValidation } from '@/components/Select/WorkspacesSelect';

type PromoCodeFormScope = 'create' | 'edit';

export interface Props extends Pick<WrappedComponentProps, 'intl'> {
  id: string;
  me: Me;
  scope: PromoCodeFormScope;
  formProps: FormRenderProps<any>;
}

const PromoCodeForm: React.FC<Props> = ({
  me,
  id,
  scope,
  intl,
  formProps: { submitting, values, form, submitError }
}) => {
  if (values['numberOfUse'] != PromoCodeNumberOfUseType.CustomUse) values['limitUsagePerUser'] = null;

  return (
    <React.Fragment>
      <Grid.Row className="mb-4">
        <Grid.Column className="w-full">
          <Form.Field
            is={UserSelectSelectWithValidation}
            id={`${id}-user-id`}
            name="userId"
            label={intl.formatMessage({ id: translations.inputs.fields.nookian.label })}
            placeholder={
              me.role === UserRole.SuperAdmin
                ? intl.formatMessage({ id: translations.inputs.fields.nookian.placeholderForSuperAdmin })
                : intl.formatMessage({ id: translations.inputs.fields.nookian.placeholder })
            }
            readOnly={submitting}
            onChange={() => {
              form.change('workspaceIds', undefined);
            }}
            editable
            disabled={me.role !== UserRole.SuperAdmin}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Column className="w-full">
          <Form.Field
            is={WorkspacesSelectSelectWithValidation}
            key={values.userId}
            id={`${id}-workspaces-ids`}
            name="workspaceIds"
            label={intl.formatMessage({ id: translations.inputs.fields.nooka.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.nooka.placeholder })}
            readOnly={submitting}
            disabled={!values.userId}
            ownedBy={me.role !== UserRole.SuperAdmin ? me.id : values.userId}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Column className="w-full">
          <Form.Field
            is={TextInputWithValidation}
            type="text"
            id={`${id}-code`}
            name="code"
            label={intl.formatMessage({ id: translations.inputs.fields.promoCode.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.promoCode.placeholder })}
            readOnly={submitting}
            disabled={scope === 'edit'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Column className="w-1/2 pr-1">
          <Form.Field
            is={TextInputWithValidation}
            type="number"
            id={`${id}-discount-percentage`}
            name="discountPercentage"
            label={intl.formatMessage({ id: translations.inputs.fields.discountPercentage.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.discountPercentage.placeholder })}
            readOnly={submitting}
          />
        </Grid.Column>

        <Grid.Column className="w-1/2 pl-1">
          <Form.Field
            is={PromoCodeNumberOfUseSelectWithValidation}
            id={`${id}-number-of-use`}
            name="numberOfUse"
            label={intl.formatMessage({ id: translations.inputs.fields.numberOfUses.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.numberOfUses.placeholder })}
            readOnly={submitting}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Column className="w-1/2 pr-1">
          <Form.Field
            is={TextInputWithValidation}
            type="number"
            id={`${id}-limit-usage`}
            name="limitUsage"
            label={intl.formatMessage({ id: translations.inputs.fields.numberOfUsesInTotal.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.numberOfUsesInTotal.placeholder })}
            readOnly={submitting}
          />
        </Grid.Column>

        {values['numberOfUse'] == 'CUSTOM_USE' && (
          <Grid.Column className="w-1/2 pl-1">
            <Form.Field
              is={TextInputWithValidation}
              type="number"
              id={`${id}-limit-usage-per-user`}
              name="limitUsagePerUser"
              label={intl.formatMessage({ id: translations.inputs.fields.numberOfUsesPerUser.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.fields.numberOfUsesPerUser.placeholder })}
              readOnly={submitting}
            />
          </Grid.Column>
        )}
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Column className="w-1/2 pr-1">
          <Form.Field
            is={TextInputWithValidation}
            type="number"
            id={`${id}-maximum-hour-limit`}
            name="maximumHourLimit"
            label={intl.formatMessage({ id: translations.inputs.fields.timeLimitInHours.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.timeLimitInHours.placeholder })}
            readOnly={submitting}
            className="mb-4"
          />
        </Grid.Column>

        <Grid.Column className="w-1/2 pl-1">
          <Form.Field
            is={DateInputWithValidation}
            type="text"
            id={`${id}-valid-until`}
            name="validUntil"
            label={intl.formatMessage({ id: translations.inputs.fields.validUnit.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.fields.date.placeholder })}
            readOnly={submitting}
            className="mb-4"
          />
        </Grid.Column>
      </Grid.Row>

      <SubmitError error={submitError} className="mb-4" />
    </React.Fragment>
  );
};

export default injectIntl(PromoCodeForm);
