import { Currency } from './currency';
import { Pricing } from './pricing';

export enum TimeFrame {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export interface Period {
  periodOfYear: number;
  year: number;
  subPeriod: number;
}
export interface MetricsRange {
  startDate: Date;
  endDate: Date;
}

export enum BookingGroupBy {
  USER = 'USER',
  NOOKA = 'NOOKA'
}

export interface BookingMetricsData {
  startDate: Date;
  endDate: Date;
  groupBy: BookingGroupBy;
}

export enum PurchasedPackageGroupBy {
  USER = 'USER',
  PACKAGE = 'PACKAGE'
}

export interface PurchasedPackageMetricsData {
  startDate: Date;
  endDate: Date;
  groupBy: PurchasedPackageGroupBy;
}

export interface TargetMetric<T> {
  current: T;
  target: T;
}

export interface OverviewMetrics {
  activeNookas: TargetMetric<number>;
  registeredUsers: TargetMetric<number>;
  monthlyRevenue: TargetMetric<Pricing>;
  activeUsers: number;
  bookings: number;
  grossRevenue: Pricing;
  lastUpdatedAt: string;
}

export interface UserMetrics {
  byDay: {
    date: string;
    total: number;
    paying: number;
  }[];
  lastUpdatedAt: Date;
}

export interface RevenueMetrics {
  byMonth: {
    year: number;
    month: number;
    total: number;
  }[];
  lastUpdatedAt: Date;
}

export interface ChartMetrics<T> {
  periods: Period[];
  data: T[];
}

export interface BookingMetrics {
  total: number;
  topPerformers: TopPerformerMetrics[];
  lastUpdatedAt: Date;
}

export interface TopPerformerMetrics {
  title: string;
  value: number;
}

export interface LessorData {
  ownerId: string;
  startDate: Date;
  endDate: Date;
}

export class LessorRevenueByLocationDto {
  name!: string;
  id!: string;
  currency!: Currency;
  card!: string;
  hours!: string;
  totalLocation!: string;
}

export class LessorRevenue {
  totalCard!: { amount: string; currency?: Currency };
  totalHours!: { amount: string; currency?: Currency };
  total!: { amount: string; currency?: Currency };
  byLocation!: LessorRevenueByLocationDto[];
}

export interface LessorBookings {
  total: number;
  byLocation: {
    name: string;
    id: string;
    totalLocation: number;
  }[];
}

export interface SignalStrengthMetrics {
  byDay: {
    date: string;
    signal: number;
    paying: number;
  }[];
  lastUpdatedAt: Date;
}
